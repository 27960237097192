<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>听评教研</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!lessonId">新增听评课</el-breadcrumb-item>
                <el-breadcrumb-item v-if="lessonId">编辑听评课</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="市区县" prop="countyId">
                            <!-- <el-select @change="changeCounty" v-model="form.countyId" placeholder="请选择学段">
                                <el-option v-for="item in countyList" :key="item.orgId" :label="item.orgName"
                                    :value="item.orgId" />
                            </el-select> -->
                            <el-tree-select :props="defaultProps" @change="changeCounty" v-model="form.countyId"
                                :default-expand-all="true" :data="orgList" check-strictly :render-after-expand="true"
                                style="width: 240px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select v-model="form.schoolId" placeholder="请选择学校">
                                <el-option v-for="item in schoolList" :key="item.orgId" :label="item.orgName"
                                    :value="item.orgId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="学段" prop="periodKey">
                            <el-select @change="changePeriod" v-model="form.periodKey" placeholder="请选择学段">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="年级" prop="gradeKey">
                            <el-select @change="changeGrade" v-model="form.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="学科" prop="subjectKey">
                            <el-select v-model="form.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课程标题" prop="title">
                            <el-input v-model="form.title" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-icon @click="toDirectory" class="cursor" :size="24" style="margin-top: 3px;margin-left: 7px;">
                            <Notebook></Notebook>
                        </el-icon>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="听评课性质" prop="lessonNature">
                            <el-select @change="changeLessonNature" style="width: 100%;" v-model="form.lessonNature"
                                placeholder="请选听评课性质">
                                <el-option v-for="item in natureList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.lessonNature != '1'">
                    <el-col :span="12">
                        <el-form-item :label="form.lessonNature == 4 ? '群组' : '工作室'" prop="groupId">
                            <el-select @change="changeQun" style="width: 100%;" v-model="form.groupId"
                                placeholder="请选择工作室/群组">
                                <el-option v-for="item in groupList" :key="item.groupId" :label="item.groupName"
                                    :value="item.groupId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="类型" prop="type">
                            <el-radio-group v-model="form.type">
                                <el-radio :label="0">推门</el-radio>
                                <el-radio :label="1">线上</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="听课地址" prop="address">
                            <el-input v-model="form.address" placeholder="最多256个字" maxlength="256" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="" v-show="form.type == 0">
                    <!-- <span>搜索框和地图试图都展示</span> -->
                    <aMap v-if="isShow" ref="zdyMap" @getGeocoder="getGeocoder" :oldCenter="oldCenter" :isShowAMap="true">
                    </aMap>

                    <!-- <span>只展示搜索框 不展示地图试图</span>
                    <aMap :toParentsMap="toParentsMap" :isShowAMap="true" :isDisplayAMap="false"></aMap> -->
                </el-form-item>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="任务时段" prop="formTime">
                            <el-col :span="11" class="date_picker">
                                <el-date-picker format="YYYY-MM-DD HH:mm" @change="startDateConfirm" :disabled-date="disabledDate"
                                    style="width: 100%;" v-model="form.beginTime" type="datetime" placeholder="开始时间"
                                    value-format="x" />
                            </el-col>
                            <el-col :span="2" class="text-center">
                                <span class="text-gray-500">至</span>
                            </el-col>
                            <el-col :span="11">
                                <el-date-picker format="YYYY-MM-DD HH:mm" class="date_picker" @change="endDateConfirm"
                                    :disabled-date="enddisabledFormDate" style="width: 100%;" v-model="form.endTime"
                                    type="datetime" placeholder="结束时间" value-format="x" />
                            </el-col>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="评课截止时间" prop="rateEndTime">
                            <el-date-picker format="YYYY-MM-DD HH:mm" :disabled-date="disabledDate" style="width: 100%;" v-model="form.rateEndTime"
                                type="datetime" placeholder="请选择评课截止时间" value-format="x" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="授课教师" prop="lectureTeacher">
                            <!-- <el-select style="width: 100%;" filterable v-model="form.lectureTeacher" placeholder="请选择授课教师">
                                <el-option v-for="item in userList" :key="item.userId" :label="item.trueName"
                                    :value="item.userId" />
                            </el-select> -->
                            <choosePeople v-show="role != 6" :showTitle="'选择人员'" @gxDisable="gxDisable" :radio="true"
                                ref="lectureTeacher" />
                            <div v-show="role == 6">{{ form.trueName }}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="评课教师" prop="rateTeacherList">
                            <choosePeople :showTitle="'选择人员'" @gxDisable="gxDisable" ref="rateTeacherList" />
                            <!-- <el-select @change="changeRateTeacher" style="width: 100%;" multiple filterable
                                v-model="form.rateTeacherList" placeholder="请选择评课教师">
                                <el-option v-for="item in userList" :key="item.userId" :label="item.trueName"
                                    :value="item.userId" />
                            </el-select> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="听课教师" prop="listenTeacherList">
                            <choosePeople :showTitle="'选择人员'" @gxDisable="gxDisable" ref="listenTeacherList" />
                            <!-- <el-select style="width: 100%;" multiple filterable v-model="form.listenTeacherList"
                                placeholder="请选择听课教师">
                                <el-option v-for="item in userList" :key="item.userId" :label="item.trueName"
                                    :value="item.userId" />
                            </el-select> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="评课组长" prop="leadTeacher">
                            <el-select style="width: 100%;" filterable v-model="form.leadTeacher" placeholder="请选择评课组长">
                                <el-option v-for="item in leadTeacherList" :key="item.userId" :label="item.trueName"
                                    :value="item.userId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-col :span="12">
                    <el-form-item label="听评量表" prop="scaleIds">
                        <el-select style="width:100%" filterable v-model="form.scaleIds" placeholder="请选择听评量表">
                            <el-option v-for="item in rateScaleList" :key="item.scaleId" :label="item.title"
                                :value="item.scaleId">
                                <span style="float: left">{{ item.title }}</span>
                                <span @click.stop="preview(item)" style="float: right; color: #8492a6; font-size: 13px">
                                    <el-icon>
                                        <View />
                                    </el-icon></span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <div class="mb30" v-for="(item, index) in previewData.questionList">
                <div class="mb15" v-if="item.type != 3">
                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                    }}.</span>{{ item.questionName }}
                        <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                            item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容'
                                : ''
                        }}]</span><span v-if="item.type == 4 && item.totalScore"
                            style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                                item.totalScore }})</span>
                        <span v-if="item.type == 3" style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                            item.totalScore }})</span>
                    </h3>
                </div>
                <div class="mb15" v-if="item.type == 3">
                    <div class="mb15">
                        <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                        }}.</span>{{ item.questionName }}
                            <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ?
                                    '文字内容'
                                    : ''
                            }}]</span>
                            <span style="color: #efa030;font-weight: normal;font-size: 14px;margin-right: 15px;">(满分：{{
                                item.totalScore }})</span>
                        </h3>
                    </div>
                    <div>

                        <el-input-number size="small" step-strictly disabled :min="0" :max="100" />
                    </div>
                </div>
                <div v-if="item.type == 1">
                    <el-radio-group disabled class="ml-4">
                        <el-radio label="1" size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span></el-radio>
                    </el-radio-group>
                </div>
                <div v-if="item.type == 2">
                    <el-checkbox-group disabled class="ml-4">
                        <el-checkbox size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div v-if="item.type == 4" style="width: 100%;">
                    <el-input style="width: 100%;" disabled :rows="2" type="textarea" placeholder="" />
                </div>
            </div>
        </el-dialog>
        <el-dialog title="课程目录" v-model="isDirectory" width="60%">
            <el-row>
                <el-empty v-if="directoryList.length == 0" style="margin: 0 auto;" description="暂无数据" />
                <div style="margin-bottom: 15px;margin-right:40px;" v-for="item in directoryList">
                    <el-card @click="goCourse(item)" class="cursor" style="width: 150px;">
                        <div class="csbbb">
                            <el-image fit="cover" style="width: 130px;height:185px" :src="item.coverUrl"></el-image>
                        </div>
                        <div class="mt5 text-one">{{ item.className }}</div>
                    </el-card>
                </div>
            </el-row>
        </el-dialog>
        <el-dialog title="课程目录" v-model="isCourse" width="60%">
            <el-table :data="tableData" :span-method="objectSpanMethod" border style="width: 100%; margin-top: 20px">
                <el-table-column prop="ptit" label="单元名" width="180">
                    <template #default="scope">
                        <div style="display: flex;justify-content: space-between;">
                            <div @click="changeTitle(scope.row)" :style="scope.row.title ? '' : 'color: #409efe;'"
                                :class="scope.row.title ? '' : 'cursor'" :title="scope.row.ptit" style="" class="text-one ">
                                {{ scope.row.ptit }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="课程名">
                    <template #default="scope">
                        <div style="display: flex;justify-content: space-between;" v-if="scope.row.id">
                            <div @click="changeTitle(scope.row)" :title="scope.row.title" style="color: #409efe;"
                                class="text-one cursor">
                                {{ scope.row.title }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>

import aMap from '@/components/aMap/index' //引入封装好的组件
import { classLibraryListAllBook, classLibraryListTree } from "@/api/admin/classLibrary";
import { queryDict, userList, listHeadmasterGroup, listTeacherGroup, groupListAuthGroup } from "@/api/admin/public";
import { lessonInfo } from "@/api/admin/lesson";
import { orgListTypeOrg, orgListSchoolOrg, orgListTree, userListOrgUsers } from "@/api/admin/system";
import { rateScaleList, evaluationCreate, evaluationInfo, evaluationUpdate, rateScaleInfo } from "@/api/admin/evaluation";
import choosePeople from "@/components/choosePeople"

export default {
    name: "evaluationAdd",
    components: {
        aMap,
        choosePeople
    },
    data() {
        return {
            orgList: [],
            toParentsMap: {},
            isShow: false,
            oldCenter: [],
            rateScaleList: [],
            lessonId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            lessonLevelList: [],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                groupId: '',
                lessonNature: '1',
                countyId: '',
                schoolId: '',
                type: 0,
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                lectureTeacher: '',
                rateTeachers: '',
                rateTeacherList: [],
                listenTeacherList: [],
                scaleIds: '',
                address: '',
                rateEndTime: '',
                leadTeacher: '',
            },
            defaultProps: {
                children: 'groupList',
                label: 'title',
            },
            // 表单校验
            rules: {
                lessonNature: [
                    { required: true, message: "请选择备课性质", trigger: "change" },
                ],
                groupId: [
                    { required: true, message: "请选择工作室/群组", trigger: "change" },
                ],
                countyId: [
                    { required: true, message: "请选择区县", trigger: "change" },
                ],
                schoolId: [
                    { required: true, message: "请选择学校", trigger: "change" },
                ],
                periodKey: [
                    { required: true, message: "请选择学段", trigger: "change" },
                ],
                rateEndTime: [
                    { required: true, message: "请选择评课截止时间", trigger: "change" },
                ],
                gradeKey: [
                    { required: true, message: "请选择年级", trigger: "change" },
                ],
                subjectKey: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                type: [
                    { required: true, message: "请选择类型", trigger: "change" },
                ],
                title: [
                    { required: true, message: "请输入课程标题", trigger: "blur" },
                ],
                address: [
                    { required: true, message: "请输入听课地址", trigger: "blur" },
                ],
                lectureTeacher: [
                    { required: true, message: "请选择授课教师", trigger: "change" },
                ],
                rateTeacherList: [
                    { required: true, validator: this.checkrateTeacherList },
                ],
                listenTeacherList: [
                    { required: true, validator: this.checklistenTeacherList },
                ],
                formTime: [
                    { required: true, validator: this.checkValidator }
                ],
            },
            groupList: [],
            natureList: [],
            delFileList: [],
            countyList: [],
            schoolList: [],
            previewData: {},
            previewShow: false,
            leadTeacherList: [],
            isDirectory: false,
            directoryList: [],
            tableData: [],
            isCourse: false,
            role: 0,
        };
    },
    created() {
        this.role = this.$storage.get("role");
        this.getQueryDict();
        this.getOrgListTypeOrg();
        this.getRateScaleList();
    },
    mounted() {
        if (this.$storage.get("role") == 6) {
            this.form.lectureTeacher = JSON.parse(this.$storage.get("userInfo")).userId;
            this.form.trueName = JSON.parse(this.$storage.get("userInfo")).trueName;
        }
        if (this.$route.query.lessonId) {
            this.lessonId = this.$route.query.lessonId
            console.log(this.form)
            this.getlessonInfo()
        } else {

            this.isShow = true
        }
    },
    unmounted() {
    },
    computed: {
    },
    methods: {
        changeQun(e) {
            console.log(this.form.groupId);
            // if (this.form.lessonNature == 4) {
            this.sxKj()
            this.$refs.lectureTeacher.getQunPeople(this.form.groupId)
            this.$refs.rateTeacherList.getQunPeople(this.form.groupId)
            this.$refs.listenTeacherList.getQunPeople(this.form.groupId)
            // }
        },
        gxDisable() {
            console.log('32323');
            this.$refs.lectureTeacher.disabledList = [...this.$refs.rateTeacherList.checkList, ...this.$refs.listenTeacherList.checkList]
            this.$refs.rateTeacherList.disabledList = [...this.$refs.lectureTeacher.checkList, ...this.$refs.listenTeacherList.checkList]
            this.$refs.listenTeacherList.disabledList = [...this.$refs.lectureTeacher.checkList, ...this.$refs.rateTeacherList.checkList]
            this.leadTeacherList = [];
            console.log(this.form.rateTeacherList);
            console.log(this.form.leadTeacher);
            this.$refs.rateTeacherList.checkList.forEach(e => {
                this.leadTeacherList.push(e)
            })
            var rateCheckList = []
            this.$refs.rateTeacherList.checkList.forEach(e => {
                rateCheckList.push(e.userId)
            })
            if (rateCheckList.indexOf(this.form.leadTeacher) === -1) {
                this.form.leadTeacher = ''
            }

            console.log(this.form.leadTeacher);

            this.$forceUpdate()
        },
        changeTitle(row) {
            console.log(row);
            this.form.title = row.className + '­—' + row.ptit + (row.title ? '­—' + row.title : '')
            this.isDirectory = false;
            this.isCourse = false;
        },
        goCourse(item) {
            this.tableData = []
            classLibraryListTree().then(res => {
                res.data.data.forEach(e => {
                    if (item.classId == e.classId) {
                        if (e.childrenList) {
                            e.childrenList.forEach(j => {
                                if (j.childrenList) {
                                    j.childrenList.forEach((m, n) => {
                                        if (n == 0) {
                                            this.tableData.push({ pid: j.classId, ptit: j.className, id: m.classId, title: m.className, col: j.childrenList.length, className: e.className })
                                        } else {
                                            this.tableData.push({ pid: j.classId, ptit: j.className, id: m.classId, title: m.className, col: 0, className: e.className })
                                        }
                                    })
                                } else {
                                    this.tableData.push({ pid: j.classId, ptit: j.className, id: '', title: '', col: 1, className: e.className })
                                }
                            })
                        }
                    }
                })
                console.log(this.tableData);
                this.isCourse = true
            })
        },
        toDirectory() {
            classLibraryListAllBook().then(res => {
                if (this.form.periodKey !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.periodKey == this.form.periodKey
                    })
                }
                if (this.form.gradeKey !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.gradeKey == this.form.gradeKey
                    })
                }
                if (this.form.subjectKey !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.subjectKey == this.form.subjectKey
                    })
                }
                this.directoryList = res.data.data;
                this.isDirectory = true;
            });
        },
        objectSpanMethod(e) {
            // console.log(e);
            if (e.columnIndex === 0) {
                if (e.row.col != 0) {
                    return {
                        rowspan: e.row.col,
                        colspan: 1,
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            }
        },
        checkrateTeacherList(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.$refs.rateTeacherList.checkList.length == 0) {
                callback(new Error('请选择评课教师'))
            } else {
                callback()
            }
        },
        checklistenTeacherList(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.$refs.listenTeacherList.checkList.length == 0) {
                callback(new Error('请选择听课教师'))
            } else {
                callback()
            }
        },
        changeRateTeacher() {
            this.leadTeacherList = [];
            console.log(this.form.rateTeacherList);
            if (this.form.rateTeacherList.indexOf(this.form.leadTeacher) === -1) {
                this.form.leadTeacher = ''
            }
            this.userList.forEach(e => {
                this.form.rateTeacherList.forEach(j => {
                    if (e.userId == j) {
                        this.leadTeacherList.push(e)
                    }
                })
            })
        },
        changeLessonNature(e) {
            console.log(e);
            this.groupList = [];
            // if (!this.lessonId) {
            this.form.groupId = '';
            // }
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            if (e == 1) {
                this.sxKj()
                this.$refs.lectureTeacher.getUserList()
                this.$refs.rateTeacherList.getUserList()
                this.$refs.listenTeacherList.getUserList()
            } else if (e == 2) {
                groupListAuthGroup({ groupNature: 2 }).then(res => {
                    this.groupList = res.data.data;
                })
                this.sxKj()
                this.$refs.lectureTeacher.getUserList()
                this.$refs.rateTeacherList.getUserList()
                this.$refs.listenTeacherList.getUserList()
            } else if (e == 3) {
                groupListAuthGroup({ groupNature: 3 }).then(res => {
                    this.groupList = res.data.data;
                })
                this.sxKj()
                this.$refs.lectureTeacher.getUserList()
                this.$refs.rateTeacherList.getUserList()
                this.$refs.listenTeacherList.getUserList()
            } else if (e == 4) {
                this.sxKj()
                groupListAuthGroup({ groupNature: 1 }).then(res => {
                    this.groupList = res.data.data;
                })
            }
        },
        sxKj() {
            this.$refs.lectureTeacher.checkList = []
            this.$refs.rateTeacherList.checkList = []
            this.$refs.listenTeacherList.checkList = []
            this.$refs.lectureTeacher.disabledList = []
            this.$refs.rateTeacherList.disabledList = []
            this.$refs.listenTeacherList.disabledList = []
        },
        preview(item) {
            console.log(item)
            rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                this.previewData = res.data.data;
                this.previewShow = true;
            })
        },
        getGeocoder(e) {
            console.log(e);
            if (this.form.type == 0) {
                this.form.lat = e.center[1];
                this.form.lng = e.center[0];

                this.form.address = e.formattedAddress;
            }

        },
        getRateScaleList() {
            rateScaleList({ scaleType: 1 }).then(res => {
                this.rateScaleList = res.data.data;
            })
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getlessonInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                evaluationInfo({ lessonId: this.lessonId }).then(res => {
                    this.changeLessonNature(res.data.data.lessonNature)
                    this.form = res.data.data;
                    this.$refs.lectureTeacher.checkList = [{ userId: this.form.lectureTeacher, trueName: this.form.lectureTeacherName }];
                    this.$refs.rateTeacherList.checkList = this.form.rateTeacherList;
                    this.$refs.listenTeacherList.checkList = this.form.listenTeacherList;
                    if (this.form.lessonNature == 2 || this.form.lessonNature == 3 || this.form.lessonNature == 4) {
                        this.$refs.lectureTeacher.getQunPeople(this.form.groupId)
                        this.$refs.rateTeacherList.getQunPeople(this.form.groupId)
                        this.$refs.listenTeacherList.getQunPeople(this.form.groupId)
                    }
                    // var scaleList = []
                    this.gxDisable();
                    this.$refs.lectureTeacher.disabledList = [...this.$refs.rateTeacherList.checkList, ...this.$refs.listenTeacherList.checkList]
                    this.$refs.rateTeacherList.disabledList = [...this.$refs.lectureTeacher.checkList, ...this.$refs.listenTeacherList.checkList]
                    this.$refs.listenTeacherList.disabledList = [...this.$refs.lectureTeacher.checkList, ...this.$refs.rateTeacherList.checkList]

                    // this.form.rateTeacherList = this.form.rateTeachers.split(',')
                    // this.form.listenTeacherList = this.form.listenTeachers.split(',')
                    // this.form.scaleList.forEach(e => {
                    //     scaleList.push(e.scaleId)
                    // })
                    orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                        this.schoolList = res.data.data;
                    })

                    if (this.form.type == 0) {
                        this.oldCenter = [this.form.lng, this.form.lat]

                    }
                    if (this.form.scaleList.length != 0) {
                        this.form.scaleIds = this.form.scaleList[0].scaleId;
                    }

                    console.log(this.periodList)
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey == j.dictKey) {
                                    this.subjectList = j.childrenList;
                                }
                            })
                        }
                    })
                    this.isShow = true;
                    if (this.form.type == 0) {
                        setTimeout(() => {
                            this.$refs.zdyMap.center = this.oldCenter;
                            this.$refs.zdyMap.componentMarker.position = this.oldCenter;
                        }, 100)
                    }

                })
            })

        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)

        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e >= this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e <= this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changeCounty() {
            console.log(this.form.countyId);
            this.form.schoolId = '';
            this.schoolList = [];
            orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                this.schoolList = res.data.data;
            })
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getOrgListTypeOrg() {
            orgListTypeOrg({ orgType: 2 }).then(res => {
                this.countyList = res.data.data;
            })
            if (this.$storage.get("role") == 1 || this.$storage.get("role") == 2) {
                this.defaultProps = {
                    children: 'childrenList',
                    label: 'orgName',
                    value: 'orgId'
                }
                orgListTree().then(res => {
                    res.data.data.forEach(e => {
                        if (e.childrenList) {
                            e.childrenList = e.childrenList.filter(itemA => {
                                return itemA.orgType != 5;
                            })
                            e.childrenList.forEach(m => {
                                if (m.childrenList) {
                                    m.childrenList = m.childrenList.filter(itemB => {
                                        return itemB.orgType != 5;
                                    })
                                }
                            })
                        }
                    })
                    this.orgList = res.data.data;
                    this.$forceUpdate()
                })
            } else {
                // this.defaultProps = {
                //     children: 'childrenList',
                //     label: 'orgName',
                //     value: 'orgId'
                // }
                // orgListTree().then(res => {
                //     res.data.data.forEach(e => {
                //         if (e.childrenList) {
                //             e.childrenList = e.childrenList.filter(itemA => {
                //                 return itemA.orgType != 5;
                //             })
                //             e.childrenList.forEach(m => {
                //                 if (m.childrenList) {
                //                     m.childrenList = m.childrenList.filter(itemB => {
                //                         return itemB.orgType != 5;
                //                     })
                //                 }
                //             })
                //         }
                //     })
                //     this.orgList = res.data.data;
                //     this.$forceUpdate()
                // })
                this.defaultProps = {
                    children: '',
                    label: 'orgName',
                    value: 'orgId'
                }
                userListOrgUsers().then(res => {
                    res.data.data.orgList.forEach(e => {
                        // if (e.groupList) {
                        //     e.groupList = e.groupList.filter(itemA => {
                        //         return itemA.orgType != 5 && itemA.orgType != 4;
                        //     })
                        //     e.groupList.forEach(m => {
                        //         if (m.groupList) {
                        //             m.groupList = m.groupList.filter(itemB => {
                        //                 return itemB.orgType != 5 && itemA.orgType != 4;;
                        //             })
                        //         }
                        //     })
                        // }
                    })
                    this.orgList = res.data.data.orgList;
                    this.$forceUpdate()
                })
            }
            // this.orgList
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'topic_level' }).then(res => {
                this.lessonLevelList = res.data.data;
            })
            queryDict({ code: 'activity_nature' }).then(res => {
                this.natureList = res.data.data;
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.bannerUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            this.form.beginTime = this.setHms(this.form.beginTime);
            this.form.endTime = this.setHms(this.form.endTime);
            this.form.rateEndTime = this.setHms(this.form.rateEndTime);

            console.log(this.form)
            if (this.$refs.lectureTeacher.checkList.length != 0) {
                this.form.lectureTeacher = this.$refs.lectureTeacher.checkList[0].userId;
            } else {
                this.form.lectureTeacher = '';
            }
            this.form.rateTeacherList = [];
            this.form.listenTeacherList = [];
            this.$refs.rateTeacherList.checkList.forEach(e => {
                this.form.rateTeacherList.push(e.userId)
            })
            this.$refs.listenTeacherList.checkList.forEach(e => {
                this.form.listenTeacherList.push(e.userId)
            })

            if (this.role == 6) {
                this.form.lectureTeacher = JSON.parse(this.$storage.get("userInfo")).userId;
            }
            this.form.rateTeachers = this.form.rateTeacherList.toString()
            this.form.listenTeachers = this.form.listenTeacherList.toString()
            // this.form.scaleIds = this.form.scaleList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    if (obj.type == 1) {
                        obj.lat = ''
                        obj.lng = ''
                    }
                    delete obj.rateTeacherList
                    // delete obj.scaleList

                    if (this.lessonId) {
                        evaluationUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/evaluation', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });

                    } else {
                        evaluationCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/evaluation', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            // this.jAFileList = [];
            this.replace('/evaluation')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>