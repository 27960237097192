import request from "@/utils/request";

// 查询用户消息列表
export const  userMessages =(query) =>{
    return request({
        url: "/businessservice/message/userMessages",
        method:"get",
        params: query
    })
}
// 清除未读消息（所有）
export const  messageCleanAllUnread =(query) =>{
    return request({
        url: "/businessservice/message/cleanAllUnread",
        method:"get",
        params: query
    })
}
// 清除未读消息（单个）
export const  cleanUnread =(query) =>{
    return request({
        url: "/businessservice/message/cleanUnread",
        method:"get",
        params: query
    })
}