import request from "@/utils/request";

// 查询表单(不传客户标识查询全部)
export const formQuery =(query) =>{
    return request({
        url: "/adminservice/form/queryForm",
        method:"get",
        params: query
    })
}
// 创建表单
export const formCreate =(data) =>{
    return request({
        url: "/adminservice/form/createForm",
        method:"post",
        ContentType:'multipart/form-data',
        data:data
    })
}
// 删除表单
export const deleteForm =(query) =>{
    return request({
        url: "/adminservice/form/deleteForm",
        method:"get",
        params: query
    })
}
// 查询表单信息
export const formInfo =(query) =>{
    return request({
        url: "/adminservice/form/info",
        method:"get",
        params: query
    })
}
// 修改表单
export const updateForm =(data) =>{
    return request({
        url: "/adminservice/form/updateForm",
        method:"post",
        data:data
    })
}
// 发布表单
export const formRelease =(query) =>{
    return request({
        url: "/adminservice/form/release",
        method:"get",
        params: query
    })
}
// 查询表单信息
export const formStop =(query) =>{
    return request({
        url: "/adminservice/form/stop",
        method:"get",
        params: query
    })
}

// 铺设表单
export const print =(query) =>{
    return request({
        url: "/adminservice/form/print",
        method:"get",
        params: query
    })
}