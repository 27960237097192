import request from "@/utils/request";
// 创建专题活动
export const activityCreate =(data) =>{
    return request({
        url: "/businessservice/activity/create",
        method:"post",
        data: data
    })
}
// 删除专题活动
export const  activityDelete =(query) =>{
    return request({
        url: "/businessservice/activity/delete",
        method:"get",
        params: query
    })
}
// 删除专题活动文件
export const  activityDeleteFile =(query) =>{
    return request({
        url: "/businessservice/activity/deleteFile",
        method:"get",
        params: query
    })
}
// 查询全部专题活动
export const  activityListAll =(query) =>{
    return request({
        url: "/businessservice/activity/listAll",
        method:"get",
        params: query
    })
}
// 分页专题活动
export const activityPage =(data) =>{
    return request({
        url: "/businessservice/activity/page",
        method:"post",
        data: data
    })
}
// 查询用户参与专题活动
export const  activityListJoin =(query) =>{
    return request({
        url: "/businessservice/activity/listJoin",
        method:"get",
        params: query
    })
}
// 专题活动详情
export const  activityInfo =(query) =>{
    return request({
        url: "/businessservice/activity/info",
        method:"get",
        params: query
    })
}
// 发布专题活动
export const  activityRelease =(query) =>{
    return request({
        url: "/businessservice/activity/release",
        method:"get",
        params: query
    })
}
// 停止专题活动
export const  activityStop =(query) =>{
    return request({
        url: "/businessservice/activity/stop",
        method:"get",
        params: query
    })
}
// 更新专题活动
export const activityUpdate =(data) =>{
    return request({
        url: "/businessservice/activity/update",
        method:"post",
        data: data
    })
}
// 上传专题活动内容图
export const activityUploadImg =(data) =>{
    return request({
        url: "/businessservice/activity/uploadImg",
        method:"post",
        data: data
    })
}
// 上传专题活动附件
export const activityUploadFile =(data) =>{
    return request({
        url: "/businessservice/activity/uploadFile",
        method:"post",
        data: data
    })
}
// 同意专题活动申报
export const  activityHandleAgree =(query) =>{
    return request({
        url: "/businessservice/activityHandle/apply/agree",
        method:"get",
        params: query
    })
}
// 申报专题活动
export const  activityHandleCreate =(data) =>{
    return request({
        url: "/businessservice/activityHandle/apply/create",
        method:"post",
        data: data
    })
}
// 删除申报文件
export const  activityHandleDeleteFile =(query) =>{
    return request({
        url: "/businessservice/activityHandle/apply/deleteFile",
        method:"get",
        params: query
    })
}
// 专题活动申报详情
export const  activityHandleInfo =(query) =>{
    return request({
        url: "/businessservice/activityHandle/apply/info",
        method:"get",
        params: query
    })
}
// 查询全部申报列表
export const  activityHandleListAll =(query) =>{
    return request({
        url: "/businessservice/activityHandle/apply/listAll",
        method:"get",
        params: query
    })
}
// 分页查询申报列表
export const  activityHandlePage =(data) =>{
    return request({
        url: "/businessservice/activityHandle/apply/page",
        method:"post",
        data: data
    })
}
// 查询待处理申报列表
export const activityHandleListHandle =(query) =>{
    return request({
        url: "/businessservice/activityHandle/apply/listHandle",
        method:"get",
        params: query
    })
}
// 查询已处理申报列表
export const  activityHandleListHistory =(query) =>{
    return request({
        url: "/businessservice/activityHandle/apply/listHistory",
        method:"get",
        params: query
    })
}
// 拒绝专题活动申报
export const  activityHandleRefuse =(query) =>{
    return request({
        url: "/businessservice/activityHandle/apply/refuse",
        method:"get",
        params: query
    })
}
// 上传申报附件
export const  activityHandleUploadFile =(data) =>{
    return request({
        url: "/businessservice/activityHandle/apply/uploadFile",
        method:"post",
        data: data
    })
}