<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>{{scaleType==1?'听评课':scaleType==2?'师资培训':scaleType==3?'课题引领':scaleType==4?'教研赛事':scaleType==5?'专题活动':''}}</a></el-breadcrumb-item>
                <el-breadcrumb-item>新增{{scaleType==1?'听评课':scaleType==2?'师资培训':scaleType==3?'课题引领':scaleType==4?'教研赛事':scaleType==5?'专题活动':''}}量表</el-breadcrumb-item>
                <!-- <el-breadcrumb-item v-if="lessonId">编辑协同备课</el-breadcrumb-item> -->
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-row>
                <el-col :span="6" style="box-shadow:0 0 0 1px #dcdfe6;padding: 10px 5px;height:calc(100vh - 210px);">
                    <div style="position: relative;">
                        <div style="position: absolute;left: 0;right: 0;">
                            <h3 style="margin: 0;">从模板库添加</h3>
                            <div class="" style="padding: 10px 5px;height:calc(100vh - 270px);overflow: auto;">
                                <div v-for="(item, index) in rateScaleList" class="pt10"
                                    style="display: flex;align-items: center;width: 100%;">
                                    <div :title="item.title" class="text-one fs-14" style="flex: 1;">{{ index + 1 }}.{{
                                        item.title }}</div>
                                    <div>
                                        <el-button size="small" @click="preview(item)" style="color:#67c23a;">预览</el-button>
                                        <el-button @click="addMb(item)" size="small" style="color:#337ecc;">添加</el-button>
                                        <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                            icon-color="#626AEF" title="确定删除该模板" @confirm="delMb(item)">
                                            <template #reference>
                                                <el-button size="small" style="color:#f56c6c;">删除</el-button>
                                            </template>
                                        </el-popconfirm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="18">
                    <div
                        style="margin-left: 10px;box-shadow:0 0 0 1px #dcdfe6;padding: 10px 5px;height:calc(100vh - 230px);overflow: auto;">
                        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                            <el-row justify="center" class="mt10">
                                <el-col :span="12">
                                    <el-form-item label="量表标题" prop="title">
                                        <el-input v-model="form.title" placeholder="最多64个字" maxlength="64" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item class="tpk" label="问题列表" prop="questionList">
                                        <el-empty v-if="form.questionList.length == 0" description="暂无数据" />
                                        <div class="mb30" v-for="(item, index) in form.questionList">
                                            <div v-if="item.type != 3">
                                                <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                                                }}.</span>{{
    item.questionName }}<span
                                                        style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                                            item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ?
                                                                '评分' :
                                                                item.type == 4 ? '文字内容'
                                                                    : ''
                                                        }}]</span><span v-if="item.type == 4 && (item.totalScore || item.totalScore == 0)"
                                                        style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                                                            item.totalScore }})</span></h3>
                                            </div>
                                            <div v-if="item.type == 3" style="display: flex;">
                                                <div style="">
                                                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                                                    }}.</span>{{ item.questionName }}<span
                                                            style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                                                item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ?
                                                                    '评分' :
                                                                    item.type == 4 ? '文字内容'
                                                                        : ''
                                                            }}]</span><span
                                                            style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                                                                item.totalScore }})</span></h3>
                                                </div>
                                            </div>
                                            <div v-if="item.type == 1">
                                                <el-radio-group disabled class="ml-4">
                                                    <el-radio label="1" size="small"
                                                        v-for="(items, indexs) in item.itemList">
                                                        <span style="color: #333;">{{ items.itemName }}</span><span
                                                            style="color: #efa030;">(分值：{{ items.itemValue
                                                            }})</span></el-radio>
                                                </el-radio-group>
                                            </div>
                                            <div v-if="item.type == 2">
                                                <el-checkbox-group disabled class="ml-4">
                                                    <el-checkbox size="small" v-for="(items, indexs) in item.itemList">
                                                        <span style="color: #333;">{{ items.itemName }}</span><span
                                                            style="color: #efa030;">(分值：{{ items.itemValue }})</span>
                                                    </el-checkbox>
                                                </el-checkbox-group>
                                            </div>
                                            <div v-if="item.type == 4" style="width: 100%;">
                                                <el-input style="width: 100%;" disabled :rows="2" type="textarea"
                                                    placeholder="" />
                                            </div>
                                            <el-row>
                                                <el-col :span="4">
                                                    <el-button @click="nextQuestion(index)" type="primary"
                                                        link>在此题后插入新题</el-button>
                                                </el-col>
                                                <el-col :span="20">
                                                    <el-button @click="editQuestion(index)" type="primary" size="small"
                                                        icon="edit">编辑</el-button>
                                                    <el-button @click="copyQuestion(index)" type="primary" size="small"
                                                        icon="documentCopy">复制</el-button>
                                                    <el-button @click="delQuestion(index)" type="primary" size="small"
                                                        icon="delete">删除</el-button>
                                                    <el-button @click="topQuestion(index)" type="primary" size="small"
                                                        icon="top">上移</el-button>
                                                    <el-button @click="bottomQuestion(index)" type="primary" size="small"
                                                        icon="bottom">下移</el-button>
                                                    <el-button @click="zdQues(index)" type="primary" size="small"
                                                        icon="upload">置顶</el-button>
                                                    <el-button @click="zbQues(index)" type="primary" size="small"
                                                        icon="download">置底</el-button>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row justify="center" class="mt15">
                                <el-button style="width: 300px;" @click="addQuestion" type="primary">新增评价维度</el-button>
                            </el-row>
                        </el-form>
                    </div>
                </el-col>
            </el-row>

        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="preview(null)">预 览</el-button>

                <el-button type="primary" @click="submitForm">完 成</el-button>
            </div>
        </div>

        <el-dialog :title="editNum != null ? '编辑评价维度' : '新增评价维度'" v-model="addQuse" width="60%">
            <el-form ref="quseform" :model="addQuseForm" :rules="addQuesrules" label-width="120px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item :label="addQuseForm.type == 3 ? '考核项目' : '评价维度名称'" prop="questionName">
                            <el-input type="textarea" v-model="addQuseForm.questionName" placeholder="最多1000个字"
                                maxlength="1000" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="题型" prop="type">
                            <el-select @change="setType" style="width: 100%;" v-model="addQuseForm.type" placeholder="请选择课题级别">
                                <el-option v-for="item in quesTypeList" :key="item.value" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                            <span style="color:red;" v-if="addQuseForm.type == 4">文字内容不支持设置分数</span>

                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="addQuseForm.type == 3">
                    <el-col :span="12">
                        <el-form-item label="选项分数" prop="totalScore">
                            <el-input-number :disabled="addQuseForm.type == 4" step-strictly v-model="addQuseForm.totalScore" :min="-100" :max="100" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="addQuseForm.type == 1 || addQuseForm.type == 2">
                    <el-col>
                        <el-form-item label="选项" prop="quesXXList">
                            <el-table ref="quesXXList" border :data="addQuseForm.itemList" style="width: 100%"
                                max-height="250">
                                <el-table-column prop="itemName" label="选项文字">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.itemName" placeholder="最多32个字" maxlength="32" />
                                    </template>
                                </el-table-column>
                                <el-table-column prop="itemValue" label="选项分数">
                                    <template #default="scope">
                                        <el-input-number step-strictly v-model="scope.row.itemValue" :min="-100" :max="100" />
                                    </template>
                                </el-table-column>
                                <el-table-column fixed="right" label="操作" width="200">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteRow(scope.$index)">
                                            删除
                                        </el-button>
                                        <el-button link type="primary" size="small"
                                            @click.prevent="addQuesRow(scope.$index)">
                                            增加
                                        </el-button>
                                        <el-button link type="primary" size="small"
                                            @click.prevent="moveUpRow(scope.$index)">
                                            上移
                                        </el-button>
                                        <el-button link type="primary" size="small" @click.prevent="downRow(scope.$index)">
                                            下移
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button plain type="primary" class="mt10" style="width: 100%"
                                @click="onAddItem">增加</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="addQuesSumbit">确 定</el-button>
                    <el-button @click="addQuse = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <div class="mb30" v-for="(item, index) in previewData.questionList">
                <div class="mb15" v-if="item.type != 3">
                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                    }}.</span>{{ item.questionName }}
                        <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                            item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容'
                                : ''
                        }}]</span><span v-if="item.type == 4 && (item.totalScore || item.totalScore == 0)"
                            style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                                item.totalScore }})</span>
                        <span v-if="item.type == 3" style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                            item.totalScore }})</span>
                    </h3>
                </div>
                <div class="mb15" v-if="item.type == 3">
                    <div class="mb15">
                        <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                        }}.</span>{{ item.questionName }}
                            <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ?
                                    '文字内容'
                                    : ''
                            }}]</span>
                            <span style="color: #efa030;font-weight: normal;font-size: 14px;margin-right: 15px;">(满分：{{
                                item.totalScore }})</span>
                        </h3>
                    </div>
                    <div>

                        <el-input-number size="small" step-strictly disabled :min="-100" :max="100" />
                    </div>
                </div>
                <div v-if="item.type == 1">
                    <el-radio-group disabled class="ml-4">
                        <el-radio label="1" size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span></el-radio>
                    </el-radio-group>
                </div>
                <div v-if="item.type == 2">
                    <el-checkbox-group disabled class="ml-4">
                        <el-checkbox size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div v-if="item.type == 4" style="width: 100%;">
                    <el-input style="width: 100%;" disabled :rows="2" type="textarea" placeholder="" />
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { lessonCreate, lessonUpdate, delLessonFile } from "@/api/admin/lesson"
import { rateScaleCreate, rateScaleList, rateScaleInfo, rateScaleDel } from "@/api/admin/evaluation"
import { queryDict, userList } from "@/api/admin/public";
import { lessonInfo } from "@/api/admin/lesson"
import { j } from "vue3-pdfjs/esm/index-19f89c12";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            lessonId: '',
            jAFileList: [],
            kJFileList: [],
            xTFileList: [],
            periodList: [],
            gradeList: [],
            subjectList: [],
            lessonLevelList: [],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                title: '',
                questionList: [
                ]
            },
            addQuesrules: {
                questionName: [
                    { required: true, message: "请输入题目名称", trigger: "blur" },
                ],
                totalScore: [
                    { required: true, message: "请输入选项分数", trigger: "blur" },
                ],
                type: [
                    { required: true, message: "请选择题型", trigger: "change" },
                ],
                quesXXList: [
                    { required: true, validator: this.checkQUXXValidator }
                ]
            },
            // 表单校验
            rules: {
                periodKey: [
                    { required: true, message: "请选择学段", trigger: "change" },
                ],
                gradeKey: [
                    { required: true, message: "请选择年级", trigger: "change" },
                ],
                subjectKey: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                lessonLevelKey: [
                    { required: true, message: "请选择课题级别", trigger: "change" },
                ],

                title: [
                    { required: true, message: "请输入备课标题", trigger: "blur" },
                ],
                leadUser: [
                    { required: true, message: "请选择主备人", trigger: "change" },
                ],
                teamuseList: [
                    { required: true, message: "请选择协备人员", trigger: "change" },
                ],
                formTime: [
                    { required: true, validator: this.checkValidator }
                ],
            },
            delFileList: [],
            quesTypeList: [
                { label: '单选', value: 1 },
                { label: '多选', value: 2 },
                { label: '评分', value: 3 },
                { label: '文字内容', value: 4 },
            ],
            addQuse: false,
            addQuseForm: {
                questionName: '',
                type: 1,
                itemValue: '',
                itemList: [
                    { itemName: '', itemNo: 1, itemValue: 1 }
                ],
                totalScore:0
            },
            editNum: null,
            createNum: null,
            rateScaleList: [],
            previewData: {},
            previewShow: false,
            scaleType: 0
        };
    },
    created() {
    },
    mounted() {
        if (this.$route.query.scaleType) {
            this.scaleType = this.$route.query.scaleType;
            this.getRateScaleList();
        } else {

        }
    },
    unmounted() {
    },
    computed: {
    },
    methods: {
        setType(){
            if(this.addQuseForm.type == 3 || this.addQuseForm.type == 4){
                this.addQuseForm.totalScore = 0;
            }
        },
        delMb(item) {
            rateScaleDel({ scaleId: item.scaleId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('删除成功')
                    this.getRateScaleList()
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        preview(item) {
            console.log(item)
            if (item) {
                rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                    this.previewData = res.data.data;
                    this.previewShow = true;
                })
            } else {
                this.previewData = JSON.parse(JSON.stringify(this.form))
                this.previewShow = true;
            }

        },
        addMb(item) {
            rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                this.form = res.data.data;
            })
        },
        getRateScaleList() {
            rateScaleList({ scaleType: this.scaleType }).then(res => {
                this.rateScaleList = res.data.data;
            })
        },
        nextQuestion(index) {
            this.createNum = index;
            this.addQuseForm = {
                questionName: '',
                type: 1,
                itemList: [
                    { itemName: '', itemNo: 1, itemValue: 1 }
                ],
                totalScore:0
            }
            this.addQuse = true;
        },
        editQuestion(index) {
            this.addQuseForm = JSON.parse(JSON.stringify(this.form.questionList[index]));
            this.editNum = index;
            this.addQuse = true;
        },
        checkQUXXValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.addQuseForm.itemList.length; i++) {
                var e = this.addQuseForm.itemList[i];
                if (e.itemName == '' || (e.itemValue == '' && e.itemValue != 0)) {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        deleteRow(index) {
            console.log(index)
            if (this.addQuseForm.itemList.length == 1) {
                this.$message.error("不能全部删除")
            } else {
                this.addQuseForm.itemList.splice(index, 1)
            }
        },
        copyQuestion(index) {
            this.form.questionList.splice(index, 0, this.form.questionList[index])
        },
        delQuestion(index) {
            this.form.questionList.splice(index, 1)
        },
        addQuesRow(index) {
            this.addQuseForm.itemList.splice(index, 0, { itemName: '', itemNo: 1, itemValue: 1 })
        },
        zdQues(index) {
            if (index != 0) {
                this.form.questionList.unshift(this.form.questionList.splice(index, 1)[0]);
            }
        },
        zbQues(index) {
            if (index != this.form.questionList.length - 1) {
                this.form.questionList.push(this.form.questionList.splice(index, 1)[0]);
            }
        },
        moveUpRow(index) {
            if (index != 0) {
                this.addQuseForm.itemList[index] = this.addQuseForm.itemList.splice(index - 1, 1, this.addQuseForm.itemList[index])[0];
            } else {
                this.addQuseForm.itemList.push(this.addQuseForm.itemList.shift());
            }
        },
        downRow(index) {
            if (index != this.addQuseForm.itemList.length - 1) {
                this.addQuseForm.itemList[index] = this.addQuseForm.itemList.splice(index + 1, 1, this.addQuseForm.itemList[index])[0];
            } else {
                this.addQuseForm.itemList.unshift(this.addQuseForm.itemList.splice(index, 1)[0]);
            }
        },
        topQuestion(index) {
            if (index != 0) {
                this.form.questionList[index] = this.form.questionList.splice(index - 1, 1, this.form.questionList[index])[0];
            } else {
                this.$message.error('第一题不能上移')
            }
        },
        bottomQuestion(index) {
            if (index != this.form.questionList.length - 1) {
                this.form.questionList[index] = this.form.questionList.splice(index + 1, 1, this.form.questionList[index])[0];
            } else {
                this.$message.error('最后一题不能下移')
            }
        },

        onAddItem() {
            this.addQuseForm.itemList.push(
                { itemName: '', itemNo: 1, itemValue: 1 }
            )
            setTimeout(() => {
                // this.$refs.memberList.setScrollTop(1000000);
            })
        },
        addQuestion() {
            this.editNum = null;
            this.createNum = null;
            this.addQuseForm = {
                questionName: '',
                type: 1,
                itemList: [
                    { itemName: '', itemNo: 1, itemValue: 1 }
                ],
                totalScore:0
            }
            this.addQuse = true;
        },
        addQuesSumbit() {
            this.$refs["quseform"].validate(valid => {
                if (valid) {
                    this.addQuseForm.itemList.forEach((e, i) => {
                        e.itemNo = i;
                    })
                    if (this.editNum != null) {
                        this.form.questionList[this.editNum] = this.addQuseForm
                    } else if (this.createNum != null) {
                        this.form.questionList.splice(this.createNum + 1, 0, this.addQuseForm)
                    } else {
                        this.form.questionList.push(this.addQuseForm)
                    }
                    this.addQuse = false;
                    console.log(this.form);
                }
            });
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getlessonInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                lessonInfo({ lessonId: this.lessonId }).then(res => {
                    var textList = [];
                    res.data.data.teamUserList.forEach(j => {
                        textList.push(j.trueName)
                    })
                    console.log(textList)
                    res.data.data.teamUserText = textList.join('、')
                    this.form = res.data.data;
                    this.form.teamuseList = this.form.teamUsers.split(',')
                    this.form.jaFiles.forEach(e => {
                        this.jAFileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    this.form.kjFiles.forEach(e => {
                        this.kJFileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    this.form.xtFiles.forEach(e => {
                        this.xTFileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    console.log(this.periodList)
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey == j.dictKey) {
                                    this.subjectList = j.childrenList;
                                }
                            })
                        }
                    })
                })
            })

        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)

        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'topic_level' }).then(res => {
                this.lessonLevelList = res.data.data;
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.bannerUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.form.scaleType = this.scaleType;
                    // this.form.questionList.forEach(e => {
                    //     if (e.type != 4) {
                    //         e.totalScore = ''
                    //     }
                    // })
                    var obj = JSON.parse(JSON.stringify(this.form))
                    obj.questionList.forEach(e => {
                        if (e.type == 1 || e.type == 2) {
                            e.totalScore = ''
                        }
                        if (e.type == 4 || e.type == 3) {
                            delete e.itemList
                        }
                    })
                    rateScaleCreate(obj).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success('新增成功')
                            this.form = {
                                title: '',
                                questionList: [
                                ]
                            }
                            this.getRateScaleList()
                        } else {
                            this.$message.error('操作失败')
                        }
                    })
                }
            });
        },

        // 取消按钮
        cancel() {
            if(this.scaleType == 1){
                this.replace('/evaluation')
            }else if(this.scaleType == 2){
                this.replace('/train')
            }else if(this.scaleType == 3){
                this.replace('/lead')
            }else if(this.scaleType == 4){
                this.replace('/race')
            }else if(this.scaleType == 5){
                this.replace('/activity')
            }
            // this.jAFileList = [];
            
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
th,
td {
    text-align: center;
    padding: 5px;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.tpk /deep/ .el-form-item__content {
    display: block;
    background-color: #e8ebef;
    padding: 15px;
}
</style>