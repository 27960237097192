import { createApp } from 'vue'
import { createStore } from 'vuex'
import getters from './getters';
import tagsView from './modules/tagsView';
import zdy from './modules/zdy'

const store = createStore({
    modules: {
        tagsView,
        zdy
    },
    getters,
});

export default store;