<template>
    <div class="app-container">
        <el-card class="mb15">
            <el-row>
                <el-col v-has-btn="'1,2,3,4,5'" :span="8" class="mb0">
                    <el-button v-has-btn="'1,2,3,4,5'" type="primary" @click="handleAdd">新增教研赛事</el-button>
                    <el-button v-has-btn="'1,2,3,4,5'" type="primary" @click="handleLbAdd">新增教研赛事量表</el-button>
                </el-col>
                <el-col :span="16" class="mb0">
                    <div class="pull-right"
                        style="display: flex;align-items: center;transform: translateY(-3px);font-size: 14px;">
                        <el-checkbox @change="getList()" v-model="isSHowMine" size="large" />
                        <div class="ml5" style="white-space: nowrap;">我参与的</div>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="赛事状态">
                            <el-select clearable @change="getList()" v-model="queryParams.status" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="赛事性质">
                            <el-select clearable @change="getList()" v-model="queryParams.nature" placeholder="请选择">
                                <el-option v-for="item in natureList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.name" placeholder="请输入课题标题" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table v-if="!loading" header-cell-class-name="bgf2" show-overflow-tooltip
                    @current-change="handleCurrentChange" height="calc(100vh - 380px)" v-loading="loading" :data="dataList"
                    highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column width="90" label="赛事状态" prop="status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 0">初始阶段</div>
                            <div v-if="scope.row.status == 1">申报阶段</div>
                            <div v-if="scope.row.status == 2">开始阶段</div>
                            <div v-if="scope.row.status == 3">结束阶段</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="300" label="赛事名称" prop="topicName">
                        <template #default="scope">
                            <div :title="scope.row.raceName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.raceName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="90" label="申报状态" prop="checkStatus">
                        <template #default="scope">
                            <div v-if="scope.row.checkStatus == 0">未申请</div>
                            <div v-if="scope.row.checkStatus == 1">审核中</div>
                            <div v-if="scope.row.checkStatus == 2">已通过</div>
                            <div v-if="scope.row.checkStatus == 3">未通过</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="160" label="赛事性质" prop="raceNature">
                        <template #default="scope">
                            <div v-if="scope.row.raceNature == 1" class="text-one">普通</div>
                            <div v-if="scope.row.raceNature == 2" class="text-one">名师工作室</div>
                            <div v-if="scope.row.raceNature == 3" class="text-one">名校长工作室</div>
                            <div v-if="scope.row.raceNature == 4" class="text-one">群组任务</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="160" label="赛事级别" prop="raceLevelName">
                    </el-table-column>
                    <el-table-column width="160" label="组织单位" prop="orgTxt">
                        <template #default="scope">
                            <div :title="scope.row.orgTxt" class="text-one">{{ scope.row.orgTxt }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="260" label="专家评委" prop="judgesUserText">
                        <template #default="scope">
                            <div :title="scope.row.judgesUserText" class="text-one">{{ scope.row.judgesUserText }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="260" label="审核人员" prop="checkUserList">
                        <template #default="scope">
                            <div :title="scope.row.checkUserTxt" class="text-one">{{
                                scope.row.checkUserTxt }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="260" label="申报时段" prop="time">
                        <template #default="scope">
                            <div>
                                {{ timestampToTime(scope.row.beginTime) }}至{{ timestampToTime(scope.row.endTime) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="260" label="申报人员" prop="memberText">
                        <template #default="scope">
                            <div :title="scope.row.memberText" class="text-one">{{ scope.row.memberText }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="160" label="学段" prop="periodName">
                    </el-table-column>
                    <el-table-column width="160" label="年级" prop="gradeName">
                    </el-table-column>
                    <el-table-column width="160" label="学科" prop="subjectName">
                    </el-table-column>
                    <el-table-column width="80" label="附件" prop="file">
                        <template #default="scope">
                            <el-popover placement="bottom-start" :width="125" trigger="click" :min-width="'auto'">
                                <div>
                                    <div v-if="scope.row.fileList.length != 0">
                                        <div :title="item.fileName" style="display: flex;align-items:center;"
                                            v-for="(item, index) in scope.row.fileList">
                                            <div class="filtxt text-one cursor" @click="goFileDetail(item)">{{ index + 1 }}.
                                                {{ item.fileName }}</div>
                                            <el-icon @click="xz(item)" style="color: #409efe;" class="cursor ml15">
                                                <Download />
                                            </el-icon>
                                        </div>
                                    </div>
                                </div>
                                <template #reference>
                                    <div style="color: #409efe;" class="cursor">
                                        {{ scope.row.fileList.length }}
                                    </div>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column width="120" label="参与附件" prop="file">
                        <template #default="scope">
                            <el-popover v-if="scope.row.joinFileList" placement="bottom-start" :width="125" trigger="click" :min-width="'auto'">
                                <div>
                                    <div v-if="scope.row.joinFileList.length != 0" style="width: 100%;">
                                        <div :title="item.fileName" v-for="(item, index) in scope.row.joinFileList"
                                            style="display: flex;align-items:center;">
                                            <div class="filtxt text-one cursor" @click="goFileDetail(item)">{{ index + 1
                                            }}. {{ item.fileName }}</div>

                                            <el-icon @click="xz(item)" style="color: #409efe;" class="cursor ml15">
                                                <Download />
                                            </el-icon>
                                        </div>
                                    </div>
                                </div>
                                <template #reference>
                                    <div style="color: #409efe;" class="cursor">
                                        {{ scope.row.joinFileList.length }}
                                    </div>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column width="150" label="发布时间" prop="releaseTime">
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.releaseTime) }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column fixed="right" :width="jsHqWidth" label="操作" class-name="small-padding fixed-width">
                        <template #default="scope">
                            <div class="jsHqWidth" style="display: inline-block;">

                                <div style="display: flex;" class="disnone">
                                    <el-upload v-model:file-list="filesList" class="mr10"
                                        v-if="scope.row.checkStatus == 2 && scope.row.status != 3"
                                        action="https://www.jingzhunjy.com/businessservice/file/join/uploadFile"
                                        :on-change="handleChange" :headers="{ token }"
                                        :data="{ activityId: scope.row.raceId, type: 2 }"
                                        accept=".pdf,.ppt,.pptx,.doc,.docx,.jpeg,.png,.jpg,.MP4,.mp4,.avi,.AVI,.flv,.Flv"
                                        :before-upload="beforeUpload">
                                        <el-button type="primary">上传附件</el-button>
                                    </el-upload>
                                    <!-- <el-button size="mini" type="text" icon="el-icon-view" @click="goDetail(scope.row)">查看
                        </el-button> -->
                                    <el-button v-has-btn="'1,2,3'" size="mini" v-if="scope.row.isCreate" type="primary" icon="el-icon-edit" @click="handleUpdate(scope.row)">编辑
                                    </el-button>
                                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                        icon-color="#626AEF" title="确定删除该赛事" @confirm="handleDelete(scope.row)">
                                        <template #reference>
                                            <el-button v-has-btn="'1,2,3'" size="mini" type="danger"
                                                icon="el-icon-delete">删除
                                            </el-button>
                                        </template>
                                    </el-popconfirm>
                                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                        icon-color="#626AEF" title="确定删除该赛事" @confirm="handleDelete(scope.row)">
                                        <template #reference>
                                            <el-button v-has-btn="'4'" v-if="scope.row.raceNature != 1" size="mini"
                                                type="danger" icon="el-icon-delete">删除
                                            </el-button>
                                        </template>
                                    </el-popconfirm>
                                    <el-button v-has-btn="'1,2,3,4'" v-if="scope.row.status == 0" style="margin-left: 12px;"
                                        size="mini" type="primary" icon="" @click="handleRelease(scope.row)">发布赛事
                                    </el-button>
                                    <el-button size="mini" v-if="scope.row.status == 1" type="primary" icon=""
                                        @click="handleApply(scope.row)">去申报
                                    </el-button>
                                    <el-popover placement="bottom-start" :width="125" trigger="click" :min-width="'auto'">
                                        <div>
                                            <div v-if="scope.row.scaleList && scope.row.scaleList.length != 0">
                                                <div @click="handleComplete(item, scope.row)" :title="item.title"
                                                    class="filtxt text-one cursor"
                                                    v-for="(item, index) in scope.row.scaleList">
                                                    {{ index + 1 }}. {{ item.title }}
                                                </div>
                                            </div>
                                        </div>
                                        <template #reference>
                                            <el-button
                                                v-if="(scope.row.status == 1 || scope.row.status == 3) && scope.row.raceType == 0 && scope.row.scaleList && scope.row.scaleList.length != 0"
                                                style="" size="mini" type="primary" icon="">{{ scope.row.status == 1 ? '去完成'
                                                    :
                                                    '去查看'
                                                }}
                                            </el-button>
                                        </template>
                                    </el-popover>
                                    <el-button v-has-btn="'1,2,3'" size="mini"
                                        v-if="scope.row.status == 1 && scope.row.raceNature == 1" type="primary" icon=""
                                        @click="handleStop(scope.row)">停止赛事
                                    </el-button>
                                    <el-button v-has-btn="'4'" size="mini"
                                        v-if="scope.row.status == 1 && scope.row.raceNature != 1" type="primary" icon=""
                                        @click="handleStop(scope.row)">停止赛事
                                    </el-button>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy" v-if="!isSHowMine">
                <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                    :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>
        <el-dialog @close="closeReport" title="课题申报" v-model="toReport" width="60%">
            <el-form ref="reportForm" :rules="reportRules" :model="reportForm" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名" prop="trueName">
                            {{ reportForm.trueName }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="电话" prop="mobile">
                            {{ reportForm.mobile }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="归属" prop="orgName">
                            {{ reportForm.orgName }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="职称" prop="rank">
                            {{ reportForm.rank }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="指导教师" prop="instructor">
                            <el-input maxlength="32" v-model="reportForm.instructor" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="上传附件" prop="">
                            <el-upload v-model:file-list="sbfileList" class="upload-demo" :on-remove="sbhandleRemove"
                                action="https://www.jingzhunjy.com/businessservice/raceHandle/apply/uploadFile"
                                :on-change="handleJAChange" :headers="{ token }" accept=".pdf,.ppt,.pptx,.doc,.docx"
                                :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="留言" prop="leaveWord">
                            <el-input type="textarea" maxlength="1000" v-model="reportForm.leaveWord" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitToReportForm">确 定</el-button>
                    <el-button @click="closeReport">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <previewData :disabled="disabled" ref="editData" v-if="previewShow" :previewData="previewData" :isHide="isHide">
            </previewData>
            <template #footer v-if="!disabled">
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitPreviewForm">确 定</el-button>
                    <el-button @click="closePreview">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-image-viewer v-if="isOpen" :url-list="activeimgs" @close="closePic" />

    </div>
</template>

<script>
import { raceListAll, classRacePage, raceListJoin, raceRelease, raceStop, raceHandleCreate, raceHandleDeleteFile, raceDelete } from "@/api/admin/race";
import { leadListAll, leadRelease, leadDelete, leadStop, topicApplyCreate, topicApplyDeleteFile } from "@/api/admin/lead"
import { lessonList, lessonSubmit, lessonStop, lessonRelease, lessonGaFiles, delLessonFile } from "@/api/admin/lesson"
import { rateScaleInfo, rateRecordScore, rateRecordScoreInfo } from "@/api/admin/evaluation";
import {
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryDict, userList, fileJoinQueryFile } from "@/api/admin/public";
import previewData from "@/components/previewData.vue";
import { download } from "@/api/adminservice/public";


export default {
    name: "race",
    components: {
        previewData
    },
    data() {
        return {
            jsHqWidth: 300,
            activeimgs: [],
            isOpen: false,
            isSHowMine: false,
            sbfileList: [],
            delFileList: [],
            isSHowMine: false,
            toReport: false,
            reportForm: {
                trueName: '',
                mobile: '',
                orgId: '',
                rank: '',
                instructor: '',
                leaveWord: '',
                fileUrls: '',
                raceId: ''
            },
            // 表单校验
            reportRules: {
            },
            selectRow: {},
            scFa: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            gAFileList: [],
            token: this.$storage.get("token"),
            statusList: [
                { name: '初始阶段', value: 0 },
                { name: '申报阶段', value: 1 },
                // { name: '已开始', value: 2 },
                { name: '结束阶段', value: 3 },
            ],
            typeList: [
                { name: '线上培训', value: 0 },
                { name: '线下培训', value: 1 },
            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                name: '',
                status: '',
                title: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                type: '',
                nature: '',
                pageNum: 1,
                pageSize: 10,
            },
            natureList: [],
            // 表单参数
            form: {},
            raceLevelList: [],
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            previewData: {},
            previewShow: false,
            lessonId: '',
            isHide: false,
            disabled: false,
            showFileList: [],
        };
    },
    created() {
        this.getQueryDict()
        this.getList();
        this.reportForm.trueName = JSON.parse(this.$storage.get("userInfo")).trueName
        this.reportForm.mobile = JSON.parse(this.$storage.get("userInfo")).username
        this.reportForm.orgId = JSON.parse(this.$storage.get("userInfo")).orgId
        this.reportForm.orgName = JSON.parse(this.$storage.get("userInfo")).orgName
        this.reportForm.rank = JSON.parse(this.$storage.get("userInfo")).teacherRankName

    },
    activated() {

        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        closePic() {
            this.isOpen = false;
        },
        xz(e) {
            download(e.fileUrl, e.fileName)
        },
        getShowList(e) {
            this.showFileList = [];
            fileJoinQueryFile({ activityId: e.raceId, type: 2 }).then(res => {
                if (res.data.data) {
                    this.showFileList = res.data.data;
                }

            })
        },
        submitPreviewForm() {
            console.log(this.previewData);
            var obj = {};
            obj.scaleId = this.$refs.editData.previewData.scaleId;
            obj.lessonId = this.lessonId;
            obj.answerList = [];
            if (this.$refs.editData.isHide) {
                obj.isHide = 1;
            } else {
                obj.isHide = 0;
            }
            var totalScore = 0;
            this.$refs.editData.previewData.questionList.forEach(e => {
                if (e.type == 2) {
                    var txt = e.questionAnswer.toString()
                    obj.answerList.push({
                        questionId: e.questionId,
                        questionAnswer: txt
                    })
                    e.questionAnswer.forEach(m => {
                        e.itemList.forEach(j => {
                            if (m == j.itemId) {
                                totalScore += Number(j.itemValue)
                            }
                        })
                    })
                } else {
                    if (e.type == 1) {
                        e.itemList.forEach(j => {
                            if (e.questionAnswer == j.itemId) {
                                totalScore += Number(j.itemValue)
                            }
                        })
                    }
                    if (e.type == 3) {
                        totalScore += Number(e.questionAnswer)
                    }
                    obj.answerList.push({
                        questionId: e.questionId,
                        questionAnswer: e.questionAnswer
                    })
                }
            })
            obj.totalScore = totalScore;
            rateRecordScore(obj).then(res => {
                if (res.data.code == 1) {
                    this.previewShow = false;
                    this.isHide = false;
                    this.$message.success('提交成功')
                    this.getList();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
            console.log(this.previewData);
            console.log(obj);

        },
        closePreview() {
            this.previewShow = false;
        },
        handleComplete(item, row) {
            this.disabled = false;
            if (row.status == 3) {
                this.disabled = true
            }
            this.lessonId = row.raceId;
            this.isHide = false;
            if (item.isSub == 1) {
                rateRecordScoreInfo({ lessonId: this.lessonId, scaleId: item.scaleId }).then(res => {
                    if (res.data.data.info.isHide == 1) {
                        this.isHide = true;
                    }
                    this.previewData = res.data.data.info;
                    this.previewData.questionList.forEach(e => {
                        if (e.type == 2) {
                            e.questionAnswer = e.questionAnswer.split(',');
                        } else {
                            // e.questionAnswer = '';
                        }
                    })
                    this.previewShow = true;
                    console.log(this.previewData);
                })
            } else {
                rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                    this.previewData = res.data.data;
                    this.previewData.questionList.forEach(e => {
                        if (e.type == 2) {
                            e.questionAnswer = [];
                        } else {
                            e.questionAnswer = '';
                        }
                    })
                    this.previewShow = true;
                    console.log(this.previewData);
                })
            }

        },
        tableRowClassName: function (row, index) {
            console.log(row.row)
            if (!row.row.isMine) {
                return 'hidden-row';
            }
            return '';
        },
        handleApply(row) {
            this.reportForm.raceId = row.raceId;
            this.toReport = true;
            this.reportForm.instructor = ''
            this.reportForm.leaveWord = ''
            this.delFileList = [];
        },
        closeReport() {
            this.sbfileList.forEach(e => {
                raceHandleDeleteFile({ fileUrl: e.response.data });
            })
            this.toReport = false;
            this.sbfileList = [];
        },
        sbhandleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        submitToReportForm() {
            var fileList = [];
            this.sbfileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.$refs["reportForm"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.reportForm));
                    obj.fileUrls = fileList.toString();
                    raceHandleCreate(obj).then(res => {
                        if (res.data.code == 1) {
                            this.sbfileList = []
                            this.delFileList.forEach(e => {
                                raceHandleDeleteFile({ fileUrl: e });
                            })
                            this.toReport = false
                            this.$message.success("申报成功");
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    })

                }
            });
        },
        changeShowMine() {

        },
        goFileDetail(item) {
            this.activeimgs = []
            if (item.fileType == 'pdf' || item.fileType == 'ppt' || item.fileType == 'pptx' || item.fileType == 'doc' || item.fileType == 'docx') {
                if (item.imgUrl) {
                    console.log(item);
                    let routeUrl = this.$router.resolve({
                        path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                        query: { data: JSON.stringify(item) }
                    })
                    window.open(routeUrl.href, '_blank')
                } else {
                    this.activeimgs.push(item.fileUrl)
                    this.isOpen = true;
                }
            } else if (item.fileType == 'jpg' || item.fileType == 'jpeg' || item.fileType == 'png') {
                this.activeimgs.push(item.fileUrl)
                this.isOpen = true;
            } else {
                this.xz(item)
            }
        },
        handleDiscuss(row) {
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: row.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleExceed(e) {
            console.log(e)
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.url) {
                delLessonFile({ fileUrl: file.url })
            }
            if (file.response) {
                delLessonFile({ fileUrl: file.response.data })
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.selectRow.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'activity_nature' }).then(res => {
                this.natureList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAJaChange(e, type) {
            console.log(this.selectRow.gajaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gajaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAkjChange(e, type) {
            console.log(this.selectRow.gakjFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gakjFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxtChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gaxtFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || rawFile.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || rawFile.type == 'application/msword')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx,.doc,.docx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleGA(row) {
            this.scFa = true;
            this.selectRow = row;
            console.log(this.selectRow)
            // lessonGaFiles({lessonId:row.lessonId}).then(res=>{

            // })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.dataList = []
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            if (!this.isSHowMine) {

                queryDict({ code: 'topic_level' }).then(resb => {
                    this.raceLevelList = resb.data.data;
                    classRacePage(this.queryParams).then(res => {
                        this.total = res.data.data.total;

                        var userId = JSON.parse(this.$storage.get("userInfo")).userId
                        res.data.data.list.forEach(e => {
                            this.raceLevelList.forEach(j => {
                                if (j.dictKey == e.raceLevel) {
                                    e.raceLevelName = j.dictValue
                                }
                            })
                            var orgTxt = [];
                            var isMine = false;
                            e.checkUserList.forEach(j => {
                                if (j.userId == userId) {
                                    isMine = true;
                                }
                            })
                            e.orgList.forEach(j => {
                                orgTxt.push(j.orgName)
                            })
                            e.orgTxt = orgTxt.join('、')
                            var memberText = [];
                            e.memberList.forEach(j => {
                                memberText.push(j.trueName)
                                if (j.userId == userId) {
                                    isMine = true;
                                }
                            })
                            e.memberText = memberText.join('、')
                            var judgesUserText = [];
                            e.judgesUserList.forEach(j => {
                                judgesUserText.push(j.trueName)
                                if (j.userId == userId) {
                                    isMine = true;
                                }
                            })
                            e.judgesUserText = judgesUserText.join('、')
                            var checkUserTxt = [];
                            e.checkUserList.forEach(j => {
                                checkUserTxt.push(j.trueName)
                                if (j.userId == userId) {
                                    isMine = true;
                                }
                            })
                            e.checkUserTxt = checkUserTxt.join('、')
                            e.isMine = isMine;
                        })
                        this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
                        this.dataList.sort(function (a, b) {
                            if (a.releaseTime > b.releaseTime) { return -1; }
                            if (a.releaseTime < b.releaseTime) { return 1; }
                            return 0;
                        })
                        this.dataList.forEach(e => {
                            if (e.createUser == userId) {
                                e.isCreate = true;
                            }
                        })
                        setTimeout(() => {
                            var elements = document.getElementsByClassName("jsHqWidth");
                            var widths = [];

                            // 遍历所有元素并获取它们的宽度
                            for (var i = 0; i < elements.length; i++) {
                                widths.push(elements[i].offsetWidth);
                            }
                            this.jsHqWidth = Math.max(...widths) + 30;
                            console.log(widths, this.jsHqWidth);
                        },);
                        console.log(this.dataList);
                        this.loading = false;
                    });
                })
            } else {
                queryDict({ code: 'topic_level' }).then(resb => {
                    this.raceLevelList = resb.data.data;
                    raceListJoin().then(res => {
                        if (this.queryParams.nature !== '') {
                            res.data.data = res.data.data.filter(itemA => {
                                return itemA.raceNature == this.queryParams.nature
                            })
                        }
                        if (this.queryParams.status !== '') {
                            res.data.data = res.data.data.filter(itemA => {
                                return itemA.status == this.queryParams.status
                            })
                        }
                        if (this.queryParams.type !== '') {
                            res.data.data = res.data.data.filter(itemA => {
                                return itemA.type == this.queryParams.type
                            })
                        }
                        if (this.queryParams.name !== '') {
                            res.data.data = res.data.data.filter(itemA => {
                                return itemA.topicName.toString().indexOf(this.queryParams.name) != -1
                            })
                        }

                        var userId = JSON.parse(this.$storage.get("userInfo")).userId
                        res.data.data.forEach(e => {
                            this.raceLevelList.forEach(j => {
                                if (j.dictKey == e.raceLevel) {
                                    e.raceLevelName = j.dictValue
                                }
                            })
                            var orgTxt = [];
                            var isMine = false;
                            e.checkUserList.forEach(j => {
                                if (j.userId == userId) {
                                    isMine = true;
                                }
                            })
                            e.orgList.forEach(j => {
                                orgTxt.push(j.orgName)
                            })
                            e.orgTxt = orgTxt.join('、')
                            var memberText = [];
                            e.memberList.forEach(j => {
                                memberText.push(j.trueName)
                                if (j.userId == userId) {
                                    isMine = true;
                                }
                            })
                            e.memberText = memberText.join('、')
                            var judgesUserText = [];
                            e.judgesUserList.forEach(j => {
                                judgesUserText.push(j.trueName)
                                if (j.userId == userId) {
                                    isMine = true;
                                }
                            })
                            e.judgesUserText = judgesUserText.join('、')
                            var checkUserTxt = [];
                            e.checkUserList.forEach(j => {
                                checkUserTxt.push(j.trueName)
                                if (j.userId == userId) {
                                    isMine = true;
                                }
                            })
                            e.checkUserTxt = checkUserTxt.join('、')
                            e.isMine = isMine;
                        })
                        this.dataList = JSON.parse(JSON.stringify(res.data.data));
                        this.dataList.sort(function (a, b) {
                            if (a.releaseTime > b.releaseTime) { return -1; }
                            if (a.releaseTime < b.releaseTime) { return 1; }
                            return 0;
                        })
                        this.dataList.forEach(e => {
                            if (e.createUser == userId) {
                                e.isCreate = true;
                            }
                        })
                        setTimeout(() => {
                            var elements = document.getElementsByClassName("jsHqWidth");
                            var widths = [];

                            // 遍历所有元素并获取它们的宽度
                            for (var i = 0; i < elements.length; i++) {
                                widths.push(elements[i].offsetWidth);
                            }
                            this.jsHqWidth = Math.max(...widths) + 30;
                            console.log(widths, this.jsHqWidth);
                        },);
                        console.log(this.dataList);
                        this.loading = false;
                    })
                })
            }
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            raceDelete({ raceId: row.raceId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/raceDetail",
                query: {
                    raceId: row.raceId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/raceAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        handleLbAdd() {
            this.$router.push({
                path: "/rateScale",
                query: {
                    scaleType: 4
                }
            });
        },
        handleRelease(row) {
            raceRelease({ raceId: row.raceId }).then(res => {
                this.loading = true
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("发布失败");
                    this.loading = false
                }
            })
        },
        handleSubmit(row) {
            console.log(row)
            if (row.jaFiles.length == 0 && row.kjFiles.length == 0 && row.xtFiles.length == 0) {
                this.$message.error("请先上传文件");
                return
            }
            lessonSubmit({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("提请成功");
                    this.getList();
                } else {
                    this.$message.error("提请失败");
                }
            })
        },
        handleStop(row) {
            raceStop({ raceId: row.raceId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停止成功");
                    this.getList();
                } else {
                    this.$message.error("停止失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/raceAdd",
                query: {
                    raceId: row.raceId,
                }
            });
        }
    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.el-table /deep/ .hidden-row {
    display: none;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}</style>