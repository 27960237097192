<template>
    <div class="app-container" :data-wz="1" @dragenter="dragName" @dragstart="dragStart" @dragend="dragend">
        <div class="x-nav" :data-wz="1" @dragenter="dragName" @dragstart="dragStart" @dragend="dragend">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>课件管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="isLook == 0">上传课件</el-breadcrumb-item>
                <el-breadcrumb-item v-if="isLook == 1">查看课件</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row>
            <el-col :span="6">
                <div :data-wz="1" @dragenter="dragName" @dragstart="dragStart" @dragend="dragend" id="vdWidth"
                    class="custtoo" style="margin-right: 15px;height: calc(100vh - 140px);overflow: auto;">
                    <el-tree :data="dataSource" highlight-current ref="tree" @node-click="changeTree"
                        :props="{ label: 'name', id: 'coursewareId', children: 'childrenList' }" node-key="coursewareId"
                        default-expand-all :expand-on-click-node="false">
                        <template #default="{ node, data }">
                            <div :style="activeCourseObj.coursewareId && (activeCourseObj.coursewareId == data.coursewareId) ? 'background:#ff7f2e;color:#fff;display: flex;' : 'display: flex;'"
                                :data-coursewareId="data.coursewareId" :data-level="node.level" class="custom-tree-node"
                                :draggable="false" @dragenter="dragName" @dragstart="dragStart" @dragend="dragend">
                                <div :data-coursewareId="data.coursewareId" :data-level="node.level"
                                    style="flex: 1;align-items: center; display: flex;" :title="node.label">
                                    <el-icon :size="17" v-if="data.swfList && data.swfList.length != 0">
                                        <VideoPlay /> &nbsp;
                                    </el-icon>
                                    <div :data-coursewareId="data.coursewareId" :data-level="node.level"
                                        :style="'width:' + (vdWidth - 150) + 'px;'" class="text-one" style="">{{ node.label
                                        }}
                                    </div>
                                </div>
                                <div v-if="isLook == 0" :data-coursewareId="data.coursewareId" :data-level="node.level">
                                    <a title="新增课件" v-if="isType == node.level" style="margin-left: 8px"
                                        @click.stop="addVideo(data)"><el-icon :size="17">
                                            <FolderAdd />
                                        </el-icon></a>
                                    <a title="新增" v-if="node.level != 3 && (isVideo == 0 ? true : (isVideo != node.level))"
                                        style="margin-left: 8px" @click.stop="append(data)"><el-icon :size="17">
                                            <Plus />
                                        </el-icon></a>
                                    <a title="编辑" style="margin-left: 8px" @click.stop="editCourse(data)"><el-icon
                                            :size="17">
                                            <EditPen />
                                        </el-icon></a>
                                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                        icon-color="#626AEF" title="确定删除该章节及其课件附件" @confirm="delCourse(node, data)">
                                        <template #reference>
                                            <a title="删除" v-if="node.level != 1" style="margin: 0 8px"> <el-icon :size="17">
                                                    <Delete />
                                                </el-icon></a>
                                        </template>
                                    </el-popconfirm>
                                </div>
                            </div>
                        </template>
                    </el-tree>
                </div>
            </el-col>
            <el-col :span="18">
                <el-card shadow="never" style="height: calc(100vh - 140px);overflow: auto;" :data-wz="1"
                    @dragenter="dragName" @dragstart="dragStart" @dragend="dragend">
                    <el-row>
                        <div style="display: flex;flex-wrap:wrap;">
                            <div :span="8" v-for="item in activeObj.swfList" style="width: 320px;">
                                <div :data-wz="1" :data-videoId="item.videoId" style="" class="tstih" :draggable="false"
                                    @dragenter="dragName" @dragstart="dragStart" @dragend="dragend">
                                    <div :data-wz="1" :data-videoId="item.videoId">
                                        <el-popover :visible="isAddVN[item.videoId]" placement="right-end" :width="400">
                                            <div>
                                                <span style="font-weight: bold;">插入节点</span>
                                                <div class="pull-right">
                                                    <el-icon class="cursor" @click="isAddVN[item.videoId] = false">
                                                        <Close />
                                                    </el-icon>
                                                </div>
                                            </div>
                                            <div style="width: 100%;max-height:200px;overflow-y:auto;">
                                                <el-row v-for="(items, indexs) in videoNodeList" class="mt15">
                                                    <el-col :span="11">
                                                        <el-time-picker :disabled-hours="disabledHours" :clearable="false"
                                                            :disabled-minutes="disabledMinutes"
                                                            :disabled-seconds="disabledSeconds" style="width: 100%;"
                                                            v-model="items.nodeDateTime" placeholder="请选择节点时间" />
                                                    </el-col>
                                                    <el-col :span="11">
                                                        <div class="ml15">
                                                            <el-input v-model="items.nodeName" style="width: 100%"
                                                                placeholder="请选择节点名称" />
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="2">
                                                        <div style="display: flex;align-items: center;justify-content: center;
                                                        width:100%;height:100%">
                                                            <el-icon class="cursor" @click="delVideoNode(items, indexs)">
                                                                <Delete />
                                                            </el-icon>
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                            </div>
                                            <el-button class="mt15" @click="addNode">增加节点</el-button>
                                            <el-button class="mt15" @click="subNode">保存</el-button>
                                            <el-empty v-if="videoNodeList.length == 0" class="qun-empty"
                                                description="暂无节点" />
                                            <template #reference>
                                                <!-- <video v-if="isshow" :data-wz="1" :data-videoId="item.videoId"
                                                :id="item.videoId"
                                                :poster="item.swfUrl + '?x-oss-process=video/snapshot,t_1,m_fast'"
                                                preload="none" controls="controls" style="width: 100%;height: 200px;"
                                                :src="item.swfUrl"></video> -->
                                                <div class="cursor" @click="bfFlash(item.swfUrl)"
                                                    style="width: 100%;height:200px;background: #034306;text-align: center;color: #fff;font-size: 18px;display: flex;align-items: center;justify-content: center;flex-direction: column;position: relative;">
                                                    <!-- <img :style="activeVideo.swfId == item.swfId ? 'border:1px solid #409eff' : ''"
                                        class="w-100 csvideo" :src="form.cover" alt=""> -->
                                                    <div style="width:200px;white-space: normal;word-wrap: break-word;">{{ item.swfName }}</div>
                                                    <img style="width: 40px;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                                                        src="../../assets/img/played.png" alt="">
                                                </div>
                                            </template>
                                        </el-popover>
                                    </div>
                                    <div :data-wz="1" :data-videoId="item.videoId" style="text-align: center;"
                                        class="text-one" :title="item.videoTitle">{{
                                            item.videoTitle
                                        }}</div>
                                    <div :data-wz="1" :data-videoId="item.videoId" class="start"
                                        style="position: absolute;right: 0;top:0;padding: 10px;background: #f1f1f1;">
                                        <el-icon v-if="isLook == 0" class="cursor" style="margin: 0 10px;"
                                            @click.stop="editFileName(item)" title="重命名">
                                            <EditPen />
                                        </el-icon>
                                        <el-icon v-if="isLook == 0" class="cursor" @click.stop="delFile(item)" title="删除">
                                            <Delete />
                                        </el-icon>
                                        <el-icon v-if="isLook == 0" class="cursor" style="margin: 0 10px;" title="上传资料"
                                            @click="addFile(item)">
                                            <DocumentAdd />
                                        </el-icon>
                                        <!-- <el-icon @click.stop="downloadFile(item)" title="下载" class="ml10">
                                        <Download />
                                    </el-icon> -->
                                        <el-icon class="cursor" @click="tofileShow(item)" title="查看资料">
                                            <Document />
                                        </el-icon>
                                        {{ item.fileList.length }}
                                        <!-- <el-icon class="cursor ml5" @click="addVideoNode(item)" title="插入节点">
                                        <Place />
                                    </el-icon> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;" v-if="!activeObj.swfList || activeObj.swfList.length == 0">
                            <el-empty class="qun-empty" description="暂无课件" />
                        </div>
                    </el-row>
                    <!-- <el-row>
                        <el-col :span="12">
                            <el-form-item label="上传资料" prop="">
                                <el-upload v-model:file-list="fileList" class="upload-demo" :on-remove="handleRemove"
                                    :data="{ coursewareId: activeObj.coursewareId }"
                                    action="/businessservice/famousCourse/uploadVideoFile" :on-change="handleJAChange"
                                    :headers="{ token }" accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                    <el-button type="primary">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            只允许上传.pdf,.ppt,.pptx格式文件。
                                        </div>
                                    </template>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                    </el-row> -->
                </el-card>
            </el-col>
        </el-row>
        <el-dialog draggable :title="'创建章节'" v-model="appCourseShow" width="60%">
            <el-form ref="appCourseForm" :model="appCourseForm" :rules="appCourseRules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="章节名称" prop="name">
                            <el-input v-model="appCourseForm.name" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitCourseForm">确 定</el-button>
                    <el-button @click="appCourseShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'编辑章节'" v-model="editCourseShow" width="60%">
            <el-form ref="editCourseForm" :model="appCourseForm" :rules="appCourseRules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="章节名称" prop="name">
                            <el-input v-model="appCourseForm.name" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submiteditCourseForm">确 定</el-button>
                    <el-button @click="editCourseShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'上传课件'" v-model="addVideoShow" width="60%">
            <!-- <el-radio-group v-model="uploadType">
                <el-radio-button label="文件" value="文件" />
                <el-radio-button label="链接" value="链接" />
            </el-radio-group> -->
            <div class="mt15" v-if="uploadType == '文件'">
                <el-upload ref="upload" :auto-upload="false" v-model:file-list="fileList" class="upload-demo" multiple
                    :on-change="handleJAChange" :data="{ coursewareId: addVideocoursewareId }" :headers="{ token }"
                    accept=".swf,.SWF" :http-request="handleUpload">
                    <el-button type="primary">点击上传</el-button>
                    <template #tip>
                        <div class="el-upload__tip">
                            只允许上传.swf、.SWF格式文件。
                        </div>
                    </template>
                </el-upload>
            </div>
            <div class="mt15" v-if="uploadType == '链接'">
                <el-form-item required label="课件标题">
                    <el-input v-model="ljObj.videoTitle" maxlength="256" placeholder="请输入课件自定义标题" />
                </el-form-item>
                <el-form-item required label="课程地址">
                    <el-input v-model="ljObj.videoUrl" maxlength="256" placeholder="请输入课程地址" />
                </el-form-item>
            </div>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" v-if="uploadType == '文件'" @click="uploadVideo">确 定</el-button>
                    <el-button type="primary" v-if="uploadType == '链接'" @click="uploadljVideo">确 定</el-button>

                    <el-button @click="addVideoShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'上传资料'" v-model="addFileShow" width="60%">
            <el-upload :auto-upload="false" v-model:file-list="fileList" class="upload-demo" multiple
                :on-change="handleJAChange" :before-upload="beforeJAUpload" :data="{ coursewareId: activeObj.coursewareId }"
                :headers="{ token }" accept=".pdf,.ppt,.pptx,.doc,.docx">
                <el-button type="primary">点击上传</el-button>
                <template #tip>
                    <div class="el-upload__tip">
                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                    </div>
                </template>
            </el-upload>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="uploadVideoFile">确 定</el-button>
                    <el-button @click="addFileShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'附件'" v-model="fileShow" width="80%">
            <div style="display: flex;flex-wrap: wrap;">
                <div @click.stop="xz(item)" style="margin: 15px;" :key="item" v-for="(item, index) in showFileList"
                    class="mb10 cursor tstih">
                    <img style="width: 250px;height:300px;object-fit: cover;" :src="item.imgUrl + '/1.png'" alt="">
                    <div style="text-align: center;width: 250px;" :title="item.fileName" class="text-one">{{ item.fileName
                    }}</div>
                    <div @click.stop="delVideolmFile(item, index)"
                        style="position: absolute;right: 0;top:0;padding: 10px;background: #f1f1f1;">
                        <el-icon v-if="isLook == 0" class="cursor" title="删除">
                            <Delete />
                        </el-icon>
                    </div>
                </div>
            </div>
            <div style="width: 100%;" v-if="showFileList.length == 0">
                <el-empty class="qun-empty" description="暂无附件" />
            </div>
        </el-dialog>

        <el-dialog draggable :title="'编辑文件名称'" v-model="editFileNameShow" width="60%">
            <el-form ref="editFileNameForm" :model="editFileNameForm" :rules="editFileNameRules" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="课件名称" prop="swfName">
                            <el-input v-model="editFileNameForm.swfName" placeholder="最多64个字" maxlength="64" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submiteditFileNameForm">确 定</el-button>
                    <el-button @click="editFileNameShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>

        <div class="loading-overlay" v-if="loadingVisible">
            <el-progress type="circle" :percentage="loadingPercentage" :stroke-width="8"></el-progress>
            <p class="tips_css">上传中，请等待...</p>
        </div>

    </div>
</template>

<script>
import flvjs from 'flv.js/dist/flv.min.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { put, signatureUrl, getFileNameUUID, multipartUpload } from '@/utils/tools'
import { videoNodeUpdate, videoNodeDelete, coursewareRenameSwf, courseVideoUpload, famousCourseCreate, coursewareInfo, famousCourseUpdate, coursewareCreateChapter, famousCourseUploadTrainVideos, coursewareDelete, coursewareUpdateChapter, coursewareUploadAnnex, coursewareDeleteSwf, courseVideoMove, videoNodeList, coursewareUploadSwfs, coursewareDeleteAnnex } from "@/api/admin/famousCourse"
import { queryDict, userList, famousCourseDeleteVideoFile } from "@/api/admin/public";
import { ElLoading } from 'element-plus';
import { s } from 'vue3-pdfjs/esm/index-19f89c12';

let OSS = require('ali-oss');
let client = new OSS({
    region: 'oss-cn-beijing',    //创建的时候，bucket所在的区域，华北2->oss-cn-beijing ；其他的可以去百度
    accessKeyId: 'LTAI5tCqpy6gH2Kfn5F7mS7H',// 阿里云控制台创建的AccessKey
    accessKeySecret: 'aVdDcMLLrcWYW5WBePRglyd3oqNIF1', //阿里云控制台创建的AccessSecret
    bucket: 'free-back'  //创建的bucket的名称
})

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            delNodeIdList: [],
            videoNodeList: [],
            oldVideoNodeList: [],
            isAddVN: {},
            flvPlayerList: {},
            activeVideoId: '',
            uploadType: '文件',
            editFileNameShow: false,
            editFileNameRules: {
                videoTitle: [
                    { required: true, message: "请输入课件名称", trigger: "blur" }
                ]
            },
            editFileNameForm: {
                videoId: '',
                videoTitle: '',
            },
            ljObj: {
                videoTitle: '',
                videoUrl: '',
            },
            loadingPercentage: 0,//进度百分比
            timer: null,//定时器
            loadingVisible: false,//开启遮罩
            isLook: 0,
            isVideo: 0,// 1 课 2 章 3 节
            isType: 1, // 1 课 2 章 3 节
            addFileShow: false,
            showFileList: [],
            isshow: false,
            fileShow: false,
            addVideoShow: false,
            addVideocoursewareId: '',
            addVideoId: '',
            appCourseRules: {
                name: [
                    { required: true, message: "请输入章节名称", trigger: "blur" }
                ],
            },
            appCourseShow: false,
            appCourseForm: {
                name: '',
                parentId: ''
            },
            fileList: [],
            treedata: [],
            dataSource: [],
            coursewareId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            courseClassifyList: [],
            activeObj: {},
            activeVideo: {},
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            editCourseShow: false,
            // 表单参数
            form: {
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                leadUser: '',
                teamUsers: '',
                teamuseList: [],
            },
            vdWidth: 250,
            // 表单校验
            rules: {
            },
            delFileList: [],
            courseVideoObj: {},
            activeCourseObj: {
                level: '',
                coursewareId: ''
            },
            videoDurtion: 0,
        };
    },
    created() {
        this.getQueryDict();
    },
    mounted() {
        this.vdWidth = (document.getElementById("vdWidth").clientWidth)
        console.log(this.vdWidth);
        this.coursewareId = this.$route.query.coursewareId
        if (this.$route.query.isLook) {
            this.isLook = this.$route.query.isLook
        }

        console.log(this.isLook)
        this.getfamousCourseInfo()
    },
    unmounted() {
    },
    computed: {
    },
    methods: {
        dragStart(cs) {
            console.log(cs);
            console.log(cs.target.dataset);
            if (cs.target.dataset.videoid) {
                this.activeVideoId = cs.target.dataset.videoid
            }
        },
        dragName(cs) {
            console.log(cs);
            console.log(cs.target.dataset.level);
            console.log(cs.target.dataset.coursewareId);
            console.log(cs.target.dataset.wz);
            if (cs.target.dataset.wz == 1) {
                this.activeCourseObj = {
                    level: '',
                    coursewareId: ''
                }
            }

            if (cs.target.dataset.level && cs.target.dataset.coursewareId) {
                this.activeCourseObj = {
                    level: cs.target.dataset.level,
                    coursewareId: cs.target.dataset.coursewareId
                }
            }
        },
        dragend(cs) {
            if (this.activeCourseObj.level && this.activeVideoId) {
                if (this.activeCourseObj.level != this.isType) {
                    this.activeCourseObj = {
                        level: '',
                        coursewareId: ''
                    }
                    this.activeVideoId = ''
                    this.$message.error('请拖拽至同等级的章节下')
                    return;
                }
                if (this.activeCourseObj.coursewareId && this.activeVideoId) {
                    courseVideoMove({ coursewareId: this.activeCourseObj.coursewareId, videoId: this.activeVideoId }).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("移动成功");
                            this.getfamousCourseInfo()
                        } else {
                            this.$message.error("操作失败")
                        }
                        this.activeCourseObj = {
                            level: '',
                            coursewareId: ''
                        }
                        this.activeVideoId = ''
                    })
                }
            }

            this.activeCourseObj = {
                level: '',
                coursewareId: ''
            }
            this.activeVideoId = ''
            console.log(cs);
            // console.log(cs.target.dataset.level);

        },
        submiteditFileNameForm() {
            this.$refs["editFileNameForm"].validate(valid => {
                if (valid) {
                    coursewareRenameSwf(this.editFileNameForm).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("创建成功");
                            this.editFileNameShow = false;
                            this.getfamousCourseInfo()
                        } else {
                            this.$message.error("操作失败")
                        }
                    })
                }
            });
        },
        editFileName(row) {
            console.log(row);
            this.editFileNameForm.swfName = row.swfName;
            this.editFileNameForm.swfId = row.swfId;
            this.editFileNameShow = true;
        },
        getFileName(name) {
            return name.substring(name.lastIndexOf("."));//.txt
        },
        handleUpload(option) {
            console.log(this.courseVideoObj.swfList);
            console.log(option);
            // coursewareUploadSwfs(this.courseVideoObj).then(res => {
            //     if (res.data.code == 1) {
            //         this.addVideoShow = false;
            //         this.getfamousCourseInfo()
            //     }
            // })
            // // 获取文件的后缀名
            // // let objName = getFileNameUUID()
            // var obj = option.file.name
            // var index = obj.lastIndexOf(".");
            // obj = obj.substring(index, obj.length);
            // // 生成的文件名，保留文件后缀名，进行拼接      
            // let objName = getFileNameUUID() + this.getFileName(option.file.name)
            // var courseVideoUpload
            // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
            // put(`/ygjy/kck/video/${objName}`, option.file).then(res => {
            //     console.log(res, 'res')
            //     this.courseVideoObj.swfList = [{
            //         videoUrl:res.url,
            //         videoTitle:option.file.name
            //     }]
            //     this.loadingInstance.close()
            //     courseVideoUpload(this.courseVideoObj).then(res=>{
            //         if(res.data.code == 1){
            //             this.addVideoShow = false;
            //             this.getfamousCourseInfo()
            //         }
            //     })
            //     // 上传成功之后，转换真实的地址
            //     signatureUrl(`/ygjy/kck/video/${objName}`).then(res => {
            //         console.log(res)
            //         this.loadingInstance.close()
            //     })
            // })
            // this.loadingVisible = true;
            // this.loadingPercentage = 0;
            // // this.multipartUpload(`/ygjy/kck/video/${objName}`, option.file).then(res => {
            // console.log(res, 'res')
            // this.courseVideoObj.swfList = [{
            //     videoUrl: res.url,
            //     videoTitle: option.file.name
            // }]

            // // this.loadingInstance.close()
            // this.loadingVisible = false;
            // coursewareUploadSwfs(this.courseVideoObj).then(res => {
            //     if (res.data.code == 1) {
            //         this.addVideoShow = false;
            //         this.getfamousCourseInfo()
            //     }
            // })
            // // 上传成功之后，转换真实的地址
            // signatureUrl(`/ygjy/kck/video/${objName}`).then(res => {
            //     console.log(res)
            //     var lastQuestionMarkIndex = res.lastIndexOf("?")
            //     var result = res;
            //     if (lastQuestionMarkIndex !== -1) {
            //         result = res.substring(0, lastQuestionMarkIndex); // 从开头到最后一个？之间的子字符串
            //         console.log(result); // 输出结果为"https://www.example.com/path/"
            //     } else {
            //         console.log("URL不包含参数部分");
            //     }
            //     this.courseVideoObj.swfList = [{
            //         videoUrl: result,
            //         videoTitle: option.file.name
            //     }]
            //     courseVideoUpload(this.courseVideoObj).then(res => {
            //         if (res.data.code == 1) {
            //             this.addVideoShow = false;
            //             this.getfamousCourseInfo()
            //         }
            //     })
            //     // this.loadingInstance.close()
            //     this.loadingVisible = false;
            // })
            // })
        },
        async multipartUpload(ObjName, fileUrl) {
            try {
                let result = await client.multipartUpload(`${ObjName}`, fileUrl, {// 获取分片上传进度、断点和返回值。
                    progress: (p, cpt, res) => {
                        console.log(p);
                        this.loadingPercentage = parseInt(p * 100);
                    },
                    timeout: 600000000,
                    // 设置并发上传的分片数量。
                    parallel: 9999,
                    // 设置分片大小。默认值为1 MB，最小值为100 KB。
                    partSize: 1024 * 1024 * (50),
                })
                // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称    
                return result
            } catch (e) {
                console.log(e)
                this.$message.error('上传失败')
                // this.loadingInstance.close()
                this.loadingVisible = false;
            }
        },
        delVideolmFile(item, index) {
            console.log(item);
            ElMessageBox.confirm(
                '是否删除该附件?',
                '删除',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                coursewareDeleteAnnex({ fileId: item.fileId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("删除成功");
                        this.showFileList.splice(index, 1)
                        this.getfamousCourseInfo()
                        // this.$emit('isTg', true);
                    } else {
                        this.$message.error("操作失败")
                    }
                })

            }).catch(() => {

            })
        },
        delFile(item) {
            console.log(item);
            var _this = this;
            ElMessageBox.confirm(
                '是否删除该课件及附件?',
                '删除',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                coursewareDeleteSwf({ swfId: item.swfId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("删除成功");
                        this.getfamousCourseInfo()
                        // this.$emit('isTg', true);
                    } else {
                        this.$message.error("操作失败")
                    }
                })

            }).catch(() => {

            })

            console.log(item);
        },
        xz(item) {
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
            // download(item.fileUrl, item.fileName)
        },
        tofileShow(item) {
            this.showFileList = item.fileList;
            this.fileShow = true;
        },
        addVideoNode(item) {
            this.videoDurtion = 0;
            this.activeVideo = item;
            this.videoNodeList = [];
            this.delNodeIdList = [];
            var video = document.getElementById(this.activeVideo.videoId);
            if (video) {
                this.videoDurtion = video.duration;
            }
            videoNodeList({ videoId: item.videoId }).then(res => {
                this.videoNodeList = res.data.data;
                this.oldVideoNodeList = JSON.parse(JSON.stringify(res.data.data))
                this.videoNodeList.forEach(e => {
                    if (e.nodeTime > 60 * 60) {

                    }
                    var time = this.fdsfs(e.nodeTime).split(':')
                    console.log(this.fdsfs(e.nodeTime).split(':'));

                    e.nodeDateTime = new Date(2017, 5, 1, time[0], time[1], time[2])
                })
                this.isAddVN[this.activeVideo.videoId] = true;
            })
        },
        disabledHours() {
            // var video = document.getElementById(this.activeVideo.videoId);
            // if (video) {
            //     var duration = video.duration;
            var time = this.fdsfs(this.videoDurtion).split(':')
            return this.makeRange(0, Number(time[0]), 24)
            // }
        },
        disabledMinutes(hour) {
            // var video = document.getElementById(this.activeVideo.videoId);
            // if (video) {
            // var duration = video.duration;
            var time = this.fdsfs(this.videoDurtion).split(':')
            if (hour < time[0]) {
                return []
            } else if (hour == Number(time[0])) {
                return this.makeRange(0, Number(time[1]), 60)
            }
            // }
        },
        disabledSeconds(hour, minute) {
            // var video = document.getElementById(this.activeVideo.videoId);
            // if (video) {
            //     var duration = video.duration;
            var time = this.fdsfs(this.videoDurtion).split(':')
            console.log(time[0], time[1], time[2]);
            if (hour === Number(time[0]) && minute === Number(time[1])) {
                return this.makeRange(0, Number(time[2]), 60)
            }
            // }
        },
        makeRange(start, end, all) {
            console.log(end);
            const result = []
            for (var i = 0; i < all; i++) {
                if (i < start || i > end) {
                    result.push(i)
                }
            }
            return result
        },
        fdsfs(ss) {
            var hh = 0;
            var mm = 0;
            var ss = ~~ss;// 秒
            if (ss > 3599) {
                hh = Math.floor(ss / 3600);
                mm = Math.floor(ss % 3600 / 60);
                ss = ss % 60;
                return (hh > 9 ? hh : '0' + hh) + ":" + (mm > 9 ? mm : '0' + mm) + ":" + (ss > 9 ? ss : '0' + ss);
            } else if (ss > 59) {
                mm = Math.floor(ss / 60);
                ss = ss % 60;
                return "00:" + (mm > 9 ? mm : '0' + mm) + ":" + (ss > 9 ? ss : '0' + ss);
            } else {
                return "00:00:" + (ss > 9 ? ss : '0' + ss);
            }
        },
        addNode() {
            console.log(this.videoNodeList);
            this.videoNodeList.push({
                nodeName: '',
                nodeTime: 0,
                nodeDateTime: new Date(2017, 5, 1, 0, 0, 0),
                videoId: this.activeVideo.videoId
            })
        },
        delVideoNode(item, index) {
            if (item.nodeId) {
                this.delNodeIdList.push(item.nodeId)
            }
            this.videoNodeList.splice(index, 1)
        },
        subNode() {
            var video = document.getElementById(this.activeVideo.videoId);
            var duration = video.duration;
            console.log('课件时长：' + duration + '秒');
            var isTg = true;
            var isSc = true;
            for (var i = 0; i < this.videoNodeList.length; i++) {
                var e = this.videoNodeList[i];
                if (!e.nodeDateTime || !e.nodeName) {
                    isTg = false;
                    break
                }
                var hour = e.nodeDateTime.getHours();
                var minutes = e.nodeDateTime.getMinutes()
                var seconds = e.nodeDateTime.getSeconds()
                e.nodeTime = hour * 60 * 60 + minutes * 60 + seconds;
                if (e.nodeTime > duration) {
                    isSc = false;
                    break;
                }
            }
            console.log(this.videoNodeList);
            if (!isSc) {
                this.$message.error("时长不能超过课件时长")
                return
            }
            if (!isTg) {
                this.$message.error("请填写完整信息")
                return
            }
            this.videoNodeList.forEach(e => {
                videoNodeUpdate(e).then(res => {

                })
            })
            this.delNodeIdList.forEach(e => {
                videoNodeDelete({ nodeId: e }).then(res => {

                })
            })
            this.isAddVN[this.activeVideo.videoId] = false;
            // this.oldVideoNodeList
        },
        addFile(item) {
            console.log(item);
            this.addVideoId = item.swfId
            this.fileList = [];
            this.addFileShow = true;
        },
        uploadVideoFile() {
            this.loadingInstance = ElLoading.service({
                lock: true,
                text: '提交中',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            console.log(this.fileList)
            let formdata = new FormData();
            this.fileList.map((item, index) => {
                console.log(item, index)
                var okey = 'files'
                formdata.append(okey, item.raw)
            })
            formdata.append('swfId', this.addVideoId)
            coursewareUploadAnnex(formdata).then(res => {
                if (res.data.code == 1) {
                    this.loadingInstance.close()
                    this.$message.success("新增成功");
                    this.addFileShow = false;
                    this.getfamousCourseInfo()
                    // this.$emit('isTg', true);
                } else {
                    this.loadingInstance.close()
                    this.$message.error("操作失败")
                }
            })
        },
        uploadljVideo() {
            if (this.ljObj.videoTitle == '') {
                this.$message.error('请输入课件标题');
                return
            }
            if (this.ljObj.videoUrl == '') {
                this.$message.error('请输入链接');
                return
            }
            this.courseVideoObj.coursewareId = this.addVideocoursewareId;
            this.courseVideoObj.swfList = [];
            this.courseVideoObj.swfList.push(this.ljObj)
            courseVideoUpload(this.courseVideoObj).then(res => {
                if (res.data.code == 1) {
                    this.addVideoShow = false;
                    this.getfamousCourseInfo()
                }
            })
        },
        uploadVideo() {
            this.courseVideoObj.coursewareId = this.addVideocoursewareId;
            this.courseVideoObj.swfList = [];
            // this.$refs.upload.submit();
            console.log(this.addVideocoursewareId)
            // this.loadingInstance = ElLoading.service({
            //     lock: true,
            //     text: '提交中',
            //     background: 'rgba(0, 0, 0, 0.7)',
            // })
            console.log(this.fileList)
            let formdata = new FormData();
            this.fileList.map((item, index) => {
                console.log(item, index)
                var okey = 'files'
                formdata.append(okey, item.raw)
            })
            formdata.append('coursewareId', this.addVideocoursewareId)
            coursewareUploadSwfs(formdata).then(res => {
                if (res.data.code == 1) {
                    // this.loadingInstance.close()
                    this.$message.success("新增成功");
                    this.addVideoShow = false;
                    this.getfamousCourseInfo()
                    // this.$emit('isTg', true);
                } else {
                    this.loadingInstance.close()
                    this.$message.error("操作失败")
                }
            })
        },
        changeTree(data) {
            this.isAddVN = {}
            console.log(data);
            // for (let key in this.flvPlayerList) {
            //     this.flvPlayerList[key].pause()
            //     this.flvPlayerList[key].unload()
            //     this.flvPlayerList[key].detachMediaElement()
            //     this.flvPlayerList[key].destroy()
            // }
            this.isshow = false;

            this.activeObj = data;
            if (this.activeObj.swfList) {
                this.activeObj.swfList.forEach(e => {
                    this.isAddVN[e.videoId] = false;
                })
            }
            this.flvPlayerList = {}
            setTimeout(() => {
                this.isshow = true;
                setTimeout(() => {
                    if (this.activeObj.swfList) {
                        this.activeObj.swfList.forEach(e => {
                            // var videoElement = document.getElementById(e.videoId);
                            // var videoType = e.swfUrl.substring(e.swfUrl.length - 3).toLowerCase()
                            // this.flvPlayerList[e.videoId] = flvjs.createPlayer({
                            //     type: videoType,
                            //     isLive: true,
                            //     hasAudio: true,
                            //     url: e.swfUrl
                            // },
                            //     {
                            //         enableWorker: false, //不启用分离线程
                            //         enableStashBuffer: false, //关闭IO隐藏缓冲区
                            //         reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
                            //         autoCleanupSourceBuffer: true //自动清除缓存
                            //     })
                            // this.flvPlayerList[e.videoId].attachMediaElement(videoElement);
                            // this.$forceUpdate()
                            // this.flvPlayerList[e.videoId].load();
                        })
                    }

                }, 1000)
            })


        },
        submiteditCourseForm() {
            this.$refs["editCourseForm"].validate(valid => {
                if (valid) {
                    coursewareUpdateChapter({
                        coursewareId: this.appCourseForm.parentId,
                        name: this.appCourseForm.name,
                    }).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("创建成功");
                            this.editCourseShow = false;
                            this.appCourseForm = {
                                name: '',
                                parentId: ''
                            }
                            this.getfamousCourseInfo()
                        } else {
                            this.$message.error("操作失败")
                        }
                    })
                }
            });
        },
        submitCourseForm() {
            this.$refs["appCourseForm"].validate(valid => {
                if (valid) {
                    coursewareCreateChapter(this.appCourseForm).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("创建成功");
                            this.appCourseShow = false;
                            this.appCourseForm = {
                                name: '',
                                parentId: ''
                            }
                            this.getfamousCourseInfo()
                        } else {
                            this.$message.error("操作失败")
                        }
                    })
                }
            });
        },
        append(data) {
            this.appCourseForm = {
                name: '',
                parentId: data.coursewareId
            }
            if (data.swfList.length != 0) {
                this.$message.error('该层有课件，无法创建下级')
            } else {
                this.appCourseShow = true;
            }

            // famousCourseChapter({
            //     name: '节点111',
            //     parentId: data.coursewareId
            // }).then(res => {

            // })
            console.log(data);
        },
        addVideo(data) {
            console.log(data);
            this.fileList = [];
            this.ljObj.videoTitle = '';
            this.ljObj.swfUrl = '';

            if (data.childrenList) {
                if (data.childrenList.length == 0) {
                    this.addVideocoursewareId = data.coursewareId
                    this.addVideoShow = true;
                } else {
                    this.$message.error('请在最下一级添加课件')
                }
            } else {
                this.addVideocoursewareId = data.coursewareId
                this.addVideoShow = true;
            }


        },
        editCourse(data) {
            this.appCourseForm = {
                name: data.courseName,
                parentId: data.coursewareId
            }
            this.editCourseShow = true;
        },
        delCourse(node, data) {
            console.log(node, data);
            coursewareDelete({ coursewareId: data.coursewareId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getfamousCourseInfo()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        remove(node, data) {
            console.log(node, data);
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.courseCover != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        // handleRemove(file, uploadFiles) {
        //     console.log(file, uploadFiles)
        //     if (file.response.data) {
        //         this.delFileList.push(file.response.data)
        //     }
        // },
        getfamousCourseInfo() {
            console.log(this.activeObj);
            delete this.activeObj.swfList
            this.isType = 1;
            this.isVideo = 0;
            this.dataSource = [];
            // userList().then(res => {
            //     this.userList = res.data.data;
            coursewareInfo({ coursewareId: this.coursewareId }).then(res => {
                this.form = res.data.data;
                this.dataSource.push(this.form)
                if (this.isLook == 0) {
                    var isz = false;
                    var isj = false;
                    if (this.form.swfList.length != 0) {
                        this.isVideo = 1;
                    } else {
                        this.isVideo = 0;
                    }
                    if (this.form.childrenList) {
                        this.form.childrenList.forEach(j => {
                            if (j.swfList.length != 0) {
                                this.isVideo = 2;
                            }
                            if (j.childrenList) {
                                j.childrenList.forEach(m => {
                                    if (m.swfList.length != 0) {
                                        this.isVideo = 3;
                                    }
                                    isj = true;
                                })
                            } else {
                                isz = true;
                            }

                        })
                    } else {

                    }
                    if (isj == true) {
                        this.isType = 3;
                    } else {
                        if (isz == true) {
                            this.isType = 2;
                        }
                    }
                    console.log(this.isType, this.isVideo);

                    // this.dataSource.push({
                    //     id: this.form.coursewareId,
                    //     label: this.form.courseName,
                    // })
                    console.log(this.dataSource);
                    if (this.activeObj.coursewareId) {
                        // this.form.forEach(e => {
                        if (this.form.childrenList) {
                            this.form.childrenList.forEach(j => {
                                if (j.childrenList) {
                                    j.childrenList.forEach(m => {
                                        if (m.coursewareId == this.activeObj.coursewareId) {
                                            this.changeTree(m)
                                            // this.activeObj = m;
                                        }
                                        if (m.swfList && m.swfList.length != 0) {

                                        }
                                    })
                                }
                                if (j.swfList && j.swfList.length != 0) {

                                }
                                if (j.coursewareId == this.activeObj.coursewareId) {
                                    this.changeTree(j)
                                    // this.activeObj = j;
                                }
                            })
                        }

                        if (this.form.coursewareId == this.activeObj.coursewareId) {
                            this.changeTree(this.form)
                            // this.activeObj = this.form;
                        }
                        if (this.activeObj.swfList) {

                        } else {
                            this.changeTree(this.dataSource[0])
                            // this.activeObj = this.dataSource[0]
                        }
                        // })
                        this.$nextTick(() => {
                            // selectId：绑定的 node-key
                            this.$refs.tree.setCurrentKey(this.activeObj.coursewareId);
                        });
                    } else {
                        this.$nextTick(() => {
                            // selectId：绑定的 node-key
                            this.$refs.tree.setCurrentKey(this.dataSource[0].coursewareId);
                            this.changeTree(this.dataSource[0])

                            // this.activeObj = this.dataSource[0]
                        });
                    }
                } else if (this.isLook == 1) {
                    if (this.form.swfList.length != 0) {
                        this.$nextTick(() => {
                            this.$refs.tree.setCurrentKey(this.dataSource[0].coursewareId);
                        });
                        this.changeTree(this.dataSource[0])

                        // this.activeObj = this.dataSource[0]
                    } else {
                        if (this.form.childrenList) {
                            try {
                                this.form.childrenList.forEach(j => {
                                    if (j.childrenList) {
                                        j.childrenList.forEach(m => {
                                            if (m.coursewareId == this.activeObj.coursewareId) {
                                                this.changeTree(this.dataSource[m])
                                                // this.activeObj = m;
                                            }
                                            if (m.swfList && m.swfList.length != 0) {
                                                console.log(m.coursewareId);
                                                this.changeTree(this.dataSource[m])
                                                // this.activeObj = m;
                                                this.$nextTick(() => {
                                                    // selectId：绑定的 node-key
                                                    this.$refs.tree.setCurrentKey(m.coursewareId);
                                                });
                                                throw new Error()
                                            }
                                        })
                                    }
                                    if (j.swfList && j.swfList.length != 0) {
                                        console.log(j.coursewareId);
                                        this.changeTree(this.dataSource[j])
                                        // this.activeObj = j;
                                        this.$nextTick(() => {
                                            // selectId：绑定的 node-key
                                            this.$refs.tree.setCurrentKey(j.coursewareId);
                                        });
                                        throw new Error()
                                    }
                                })
                            } catch (error) {
                                console.log(error);
                            }

                        }
                    }
                }

            })
            // })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(2121, file)
        },
        handleXAChange(file) {
            console.log(file);
        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || rawFile.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || rawFile.type == 'application/msword')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx,.doc,.docx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getQueryDict() {
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.courseCover = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        bfFlash(url){
            let routeUrl = this.$router.resolve({
                path: '/lookflash',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { page: window.btoa(window.encodeURIComponent(url)) }
            })
            window.open(routeUrl.href, '_blank')
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)

            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.coursewareId) {
                        famousCourseUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/famousCourse', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        famousCourseCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/famousCourse', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            if (this.isLook == 0) {
                this.replace('/courseware')
            } else {
                this.replace('/courseware')
            }

            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.tstih {
    position: relative;
    padding: 15px;
}

.start {
    display: none;
}

.tstih:hover .start {
    display: block;
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.custtoo /deep/ .el-tree-node__label {
    flex: 1;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.loading-overlay {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

::v-deep .el-progress__text {
    font-size: 24px !important;
    color: rgb(30, 160, 225) !important;
}

::v-deep .el-progress-circle {
    width: 150px !important;
    height: 150px !important;
}

.tips_css {
    font-size: 20px;
    color: rgb(30, 160, 225)
}
</style>