<template>
    <div class="app-container">
        <el-card shadow="never">
            <el-row>
                <el-col :span="16">
                    <el-radio-group @change="changeCativeType" v-model="cativeType" size="mini">
                        <el-radio-button label="个人备课" />
                        <el-radio-button label="协同备课" />
                        <el-radio-button label="听评课" />
                        <!-- <el-radio-button label="课题引领" />
                        <el-radio-button label="教研赛事" />
                        <el-radio-button label="专题活动" /> -->
                    </el-radio-group>
                </el-col>
            </el-row>
            <cooperate v-if="cativeType == '个人备课' || cativeType == '协同备课'" ref="cooperate"></cooperate>
            <listeningEvaluat v-if="cativeType == '听评课'" ref="listeningEvaluat"></listeningEvaluat>
        </el-card>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import listeningEvaluat from "./listeningEvaluat.vue";
import cooperate from "./cooperate.vue";

export default {
    name: "ptStatistics",
    components: {
        listeningEvaluat,
        cooperate
    },
    data() {
        return {
            cativeType: '个人备课',
            timestampToTime,

        };
    },
    created() {
    },
    mounted() {
        this.$refs.cooperate.changeCativeType('个人备课')
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {

        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        changeCativeType() {
            if (this.cativeType == '个人备课' || this.cativeType == '协同备课') {
                this.$refs.cooperate.changeCativeType(this.cativeType)
            }

        },

    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}
</style>