let OSS = require('ali-oss')

let client = new OSS({
  region: 'oss-cn-beijing',    //创建的时候，bucket所在的区域，华北2->oss-cn-beijing ；其他的可以去百度
  accessKeyId: 'LTAI5tCqpy6gH2Kfn5F7mS7H',// 阿里云控制台创建的AccessKey
  accessKeySecret: 'aVdDcMLLrcWYW5WBePRglyd3oqNIF1', //阿里云控制台创建的AccessSecret
  bucket: 'free-back'  //创建的bucket的名称
})

export const put = async (ObjName, fileUrl) => {
  try {
    let result = await client.put(`${ObjName}`, fileUrl, { timeout: 6000000 })
    // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称    
    return result
  } catch (e) {
    console.log(e)
  }
}

export const multipartUpload = async (ObjName, fileUrl) => {
  try {
    let result = await client.multipartUpload(`${ObjName}`, fileUrl, {// 获取分片上传进度、断点和返回值。
      progress: (p, cpt, res) => {
        console.log(p);
      },
      timeout: 6000000,
      // 设置并发上传的分片数量。
      parallel: 9999,
      // 设置分片大小。默认值为1 MB，最小值为100 KB。
      partSize: 1024 * 1024 * (50),
    })
    // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称    
    return result
  } catch (e) {
    console.log(e)
  }
}

// 上传成功之后，转换真实的地址
export const signatureUrl = async (ObjName) => {
  try {
    let result = await client.signatureUrl(`${ObjName}`)
    return result
  } catch (e) {
    console.log(e)
  }
}

export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}