<template>
    <div class="app-container">
        <el-card shadow="never">
            <teachStudio></teachStudio>
        </el-card>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import listeningEvaluat from "./listeningEvaluat.vue";
import teachStudio from "./teachStudio.vue"

export default {
    name: "ptStatistics",
    components: {
        teachStudio
    },
    data() {
        return {
            cativeType: '线下培训',
            timestampToTime,

        };
    },
    created() {
    },
    mounted() {
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {

        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {

    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}
</style>