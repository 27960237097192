import request from "@/utils/request";
// 创建成果
export const  achieveCreate =(data) =>{
    return request({
        url: "/businessservice/achieve/create",
        method:"post",
        data: data
    })
}
// 删除成果
export const  achieveDel =(query) =>{
    return request({
        url: "/businessservice/achieve/delete",
        method:"get",
        params: query
    })
}
// 删除成果文件
export const  achieveDelFile =(query) =>{
    return request({
        url: "/businessservice/achieve/deleteFile",
        method:"get",
        params: query
    })
}
// 查询成果详情
export const  achieveInfo =(query) =>{
    return request({
        url: "/businessservice/achieve/info",
        method:"get",
        params: query
    })
}
// 查询所有成果列表
export const  achieveListAll =(query) =>{
    return request({
        url: "/businessservice/achieve/listAll",
        method:"get",
        params: query
    })
}
// 分页查询成果
export const  achievePage =(data) =>{
    return request({
        url: "/businessservice/achieve/page",
        method:"post",
        data: data
    })
}
// 发布成果
export const  achieveRelease =(query) =>{
    return request({
        url: "/businessservice/achieve/release",
        method:"get",
        params: query
    })
}
// 更新成果
export const  achieveUpdate =(data) =>{
    return request({
        url: "/businessservice/achieve/update",
        method:"post",
        data: data
    })
}
// 上传成果封面
export const  achieveUploadCover =(data) =>{
    return request({
        url: "/businessservice/achieve/uploadCover",
        method:"post",
        data: data
    })
}
// 上传成果附件
export const  achieveUploadFile =(data) =>{
    return request({
        url: "/businessservice/achieve/uploadFile",
        method:"post",
        data: data
    })
}