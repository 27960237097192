<template>
    <div class="app-container"></div>
</template>

<script>
    export default {
        name: 'Index',
        components: {},
        data() {
            return {};
        },
        methods: {},
    };
</script>

<style lang="scss" scoped>
    .app-container {
        height: calc(100vh - 84px);
        background-image: url(@/assets/indexbg.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
    }
</style>