<template>
    <div ref="myDiv" :title="text" :class="isZk ? '' : 'text-three'" style="width: 100%;">{{ text }}</div>
    <div class="cursor" style="color: #409eff;" @click="isZk = true" v-if="!isZk && (this.divHeight/22>3)">展开</div>
    <div class="cursor" style="color: #409eff;" @click="isZk = false" v-if="isZk && (this.divHeight/22>3)">收起</div>
</template>
  
<script>

export default {
    name: 'expendText',
    props: {
        text: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            divHeight:0,
            isZk: true,
        };
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.divHeight = this.$refs.myDiv.clientHeight;
                if(this.divHeight/22>3){
                    this.isZk = false;
                }
                console.log(3322,this.divHeight);
            })

        });
    },
    computed: {
    },
    methods: {
    },
};
</script>
  
<style scoped></style>
  