<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>工作室管理</a></el-breadcrumb-item>
                <el-breadcrumb-item>{{ formData.type ==
                    1 ? '工作简报' : formData.type == 2 ? '教师文章' : formData.type == 3 ? '成果展示' : formData.type == 4 ? '工作日志' : ''
                }}详情</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 140px);overflow: auto;">
            <h2 style="text-align: center;">{{ formData.title }}</h2>
            <div class="pt10">创建时间：{{ timestampToTime(formData.createTime) }} <span style="padding-left: 100px;">阅读人数：{{
                formData.readNum }}</span></div>
            <div class="pt15" v-html="formData.appContent">

            </div>
            <el-row v-if="formData.fileList && formData.fileList.length != 0">
                <el-col :span="24">
                    <el-form-item label-width="60px" label="附件" prop="">
                        <div @click="xz(item)" v-for="item in formData.fileList" class="csbbb mb10 cursor">
                            <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                :src="item.imgUrl + '/1.png'" alt="">
                            <div>{{ item.fileName }}</div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import { studioInfo, studioRead } from "@/api/admin/studio";

export default {
    name: "scenarioDetail",
    components: {},
    data() {
        return {
            formData: {},
            infoId: '',
            isStudioList: 0,
            isTeachStudioList: 0,
            isStudioRoom: 0,
            timestampToTime,
            iswork: 0,
        };
    },
    created() {

    },
    mounted() {
        if (this.$route.query.isStudioList) {
            this.isStudioList = this.$route.query.isStudioList
        }
        if (this.$route.query.iswork) {
            this.iswork = this.$route.query.iswork
        }
        if (this.$route.query.isTeachStudioList) {
            this.isTeachStudioList = this.$route.query.isTeachStudioList
        }
        if (this.$route.query.isStudioRoom) {
            this.isStudioRoom = this.$route.query.isStudioRoom
        }
        if (this.$route.query.infoId) {
            this.infoId = this.$route.query.infoId;
            this.getStudioRead();
            this.getStudioInfo();
        } else {
        }

    },
    computed: {
    },
    methods: {
        xz(item) {
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
            // download(item.fileUrl, item.fileName)
        },
        getStudioRead() {
            studioRead({ infoId: this.infoId }).then(res => {

            })
        },
        getStudioInfo() {
            studioInfo({ infoId: this.infoId }).then(res => {
                this.formData = res.data.data;
            })
        },
        // 取消按钮
        cancel() {
            if (this.isStudioList == 1) {
                this.replace('/studioList')
            } else if (this.iswork == 1) {
                this.replace('/workBrieManage')
            } else if (this.isStudioRoom == 1) {
                this.replace('/studioRoomDetail', { groupId: this.$route.query.groupId })
            } else if (this.isTeachStudioList == 1) {
                this.replace('/teachStudioList')
            } else {
                this.replace('/studioManage')
            }

        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.custom /deep/ .el-checkbox__input {
    position: absolute;
    bottom: 0;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    /* height: 270px; */
    /* overflow-y: auto; */
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}</style>