import request from "@/utils/request";
// 查询所有组织信息(树形结构)
export const  orgListTree =(query) =>{
    return request({
        url: "/businessservice/org/listTree",
        method:"get",
        params: query
    })
}
// 删除组织
export const orgDelete =(query) =>{
    return request({
        url: "/businessservice/org/delete",
        method:"get",
        params: query
    })
}
// 添加组织性质
export const orgAddClassify =(query) =>{
    return request({
        url: "/businessservice/org/addClassify",
        method:"get",
        params: query
    })
}
// 查询组织列表(按照类型)
export const  orgListTypeOrg =(query) =>{
    if(query.orgType == 1 || query.orgType == 2){
        query.orgType = 3
    }else{
        
    }
    return request({
        url: "/businessservice/org/listTypeOrg",
        method:"get",
        params: query
    })
}
// 查询用户管理组织及下级组织
export const orgListUserOrg =(query) =>{
    return request({
        url: "/businessservice/org/listUserOrg",
        method:"get",
        params: query
    })
}
// 创建组织
export const  orgCreate =(data) =>{
    return request({
        url: "/businessservice/org/create",
        method:"post",
        data: data
    })
}
// 更新组织
export const  orgUpdate =(data) =>{
    return request({
        url: "/businessservice/org/update",
        method:"post",
        data: data
    })
}
// 查询组织下学校
export const  orgListSchoolOrg =(query) =>{
    return request({
        url: "/businessservice/org/listSchoolOrg",
        method:"get",
        params: query
    })
}
// 创建教研组
export const  groupCreate =(data) =>{
    return request({
        url: "/businessservice/teachingGroup/group/create",
        method:"post",
        data: data
    })
}
// 删除群组
export const  groupDel =(query) =>{
    return request({
        url: "/businessservice/teachingGroup/group/delete",
        method:"get",
        params: query
    })
}
// 查询群组详情
export const  groupInfo =(query) =>{
    return request({
        url: "/businessservice/teachingGroup/group/info",
        method:"get",
        params: query
    })
}
// 查询所有群组列表
export const  groupListAll =(query) =>{
    return request({
        url: "/businessservice/teachingGroup/group/listAll",
        method:"get",
        params: query
    })
}
// 更新教研组
export const  groupUpdate =(data) =>{
    return request({
        url: "/businessservice/teachingGroup/group/update",
        method:"post",
        data: data
    })
}
// 查询用户所在组织及其下级组织用户
export const  userListOrgUsers =(query) =>{
    return request({
        url: "/businessservice/user/listOrgUsers",
        method:"get",
        params: query
    })
}
// 查询组织下教研组成员
export const  userListOrgMember =(query) =>{
    return request({
        url: "/businessservice/user/listGroupMember",
        method:"get",
        params: query
    })
}
// 用户关注工作室列表
export const  userCareStudio =(query) =>{
    return request({
        url: "/businessservice/user/userCareStudio",
        method:"get",
        params: query
    })
}