<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>通知banner管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!$route.query.data">新增banner</el-breadcrumb-item>
                <el-breadcrumb-item v-if="$route.query.data">编辑banner</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form style="padding-bottom: 15px;" ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="banner标题" prop="title">
                            <el-input v-model="form.title" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="12">
                        <el-form-item label="banner类型" prop="title">
                            <el-select v-model="form.type" placeholder="Select">
                                <el-option key="item.value" label="图片"
                                    :value="0" />
                                <el-option key="item.value" label="新闻"
                                    :value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="form.type == 0?'内容':'banner封面'" prop="bannerUrl">
                            <el-upload class="avatar-uploader"
                                action="https://www.jingzhunjy.com/businessservice/banner/upload" :show-file-list="false"
                                :on-success="handleAvatarSuccess" :headers="{ token }" :before-upload="beforeAvatarUpload">
                                <img v-if="form.bannerUrl" :src="form.bannerUrl" class="avatar" style="position:absolute" />
                                <el-icon class="avatar-uploader-icon" style="font-size:100px;color:#fff;">
                                    <Plus />
                                </el-icon>
                            </el-upload>
                            <div style="color: #a9abad;width:100%;">推荐使用540*304尺寸的图片</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 2">
                    <el-col :span="24">
                        <el-form-item label="内容" prop="content">
                            <!-- <el-input type="textarea" v-model="form.content" /> -->

                            <TEditor :uploadType="2" :height="'40vh'" ref="editor" :content="form.content" v-model="form.content" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <!-- <div style="width: 390px;height:844px" v-html="form.content">

        </div> -->
        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { noticeBannerCreate, noticeBannerUpdate } from "@/api/admin/news"
import TEditor from '@/components/TEditor.vue';

export default {
    name: "demoAdd",
    components: {
        TEditor
    },
    data() {
        return {
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                title: '',
                content: '',
                appContent: '',
                bannerUrl: 'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/banner.png',
                type: 2,
            },
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入通知banner标题", trigger: "blur" },
                ],
                bannerUrl: [
                    { required: true, validator: this.checkCoverValidator }
                ],
                content: [
                    { required: true, validator: this.checkContentValidator },
                ],
            },
        };
    },
    created() {

    },
    mounted() {
        if (this.$route.query.data) {
            this.form = JSON.parse(this.$route.query.data)
            console.log(this.form)
        } else {
        }
    },
    computed: {
    },
    methods: {
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.bannerUrl != '') {
                callback()
            } else {
                callback(new Error('请上传轮播图图片'))
            }
        },
        checkContentValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.content != '') {
                callback()
            } else {
                callback(new Error('请填写内容'))
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.bannerUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            var text = JSON.parse(JSON.stringify(this.form.content))
            text = text.replace(/<video/gi,"<video style='width:100% !important;height:30% !important;background:#333 !important;'")
            text = text.replace(/<img/gi,"<img style='width:auto !important;max-width: 100% !important;height:auto !important;vertical-align: middle !important;max-height:100%;margin:0 auto !important;'")
            text = text.replace(/<p style="/gi,'<p style="text-indent: unset !important;')

            console.log(text)
            this.form.appContent = text
            // this.form.content = this.form.appContent
            this.form.type = 2
            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.$route.query.data) {
                        noticeBannerUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/bannerNotice', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        noticeBannerCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/bannerNotice', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/bannerNotice')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>