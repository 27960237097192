import request from "@/utils/request";

// 数据统计-个人备课
export const statsOwnLesson =(query) =>{
    return request({
        url: "/businessservice/stats/ownLesson",
        method:"get",
        params: query
    })
}
// 数据统计-协同备课
export const statsTeamLesson =(query) =>{
    return request({
        url: "/businessservice/stats/teamLesson",
        method:"get",
        params: query
    })
}
// 更新用户信息
export const userUpdate =(data) =>{
    return request({
        url: "/businessservice/user/update",
        method:"post",
        data: data
    })
}
// 数据统计-听评课
export const statsEvaluationLesson =(query) =>{
    return request({
        url: "/businessservice/stats/evaluationLesson",
        method:"get",
        params: query
    })
}
// 创建或更新听评课总结
export const summaryCreateOrUpdate =(data) =>{
    return request({
        url: "/businessservice/summary/createOrUpdate",
        method:"post",
        data: data
    })
}
// 查询课程所有听课记录
export const lessonRecordListAll =(query) =>{
    return request({
        url: "/businessservice/lessonRecord/listAll",
        method:"get",
        params: query
    })
}
// 数据统计-课题引领
export const statsTopic =(query) =>{
    return request({
        url: "/businessservice/stats/topic",
        method:"get",
        params: query
    })
}
// 数据统计-赛事统计
export const statsRace =(query) =>{
    return request({
        url: "/businessservice/stats/race",
        method:"get",
        params: query
    })
}
// 数据统计-专题活动
export const statsActivity =(query) =>{
    return request({
        url: "/businessservice/stats/activity",
        method:"get",
        params: query
    })
}
// 数据统计-师资培训
export const statsTrain =(query) =>{
    return request({
        url: "/businessservice/stats/train",
        method:"get",
        params: query
    })
}
// 数据统计-名校长工作室
export const statsPrincipalStudio =(query) =>{
    return request({
        url: "/businessservice/stats/principalStudio",
        method:"get",
        params: query
    })
}
// 数据统计-名师工作室
export const statsTeacherStudio =(query) =>{
    return request({
        url: "/businessservice/stats/teacherStudio",
        method:"get",
        params: query
    })
}
// 查询培训签到数据情况
export const statsTrainSign =(data) =>{
    return request({
        url: "/businessservice/stats/trainSign",
        method:"post",
        data: data
    })
}