import request from "@/utils/request";
// 整体提交档案填报
export const archiveFillBatch =(data) =>{
    return request({
        url: "/businessservice/archive/fill/batch",
        method:"post",
        data: data
    })
}
// 删除档案填报
export const  archiveFillDelete =(query) =>{
    return request({
        url: "/businessservice/archive/fill/delete",
        method:"get",
        params: query
    })
}
// 单条档案填报（修改或更新）
export const archiveFillSingle =(data) =>{
    return request({
        url: "/businessservice/archive/fill/single",
        method:"post",
        data: data
    })
}
// 上传档案文件
export const archiveFillUploadFile =(data) =>{
    return request({
        url: "/businessservice/archive/fill/uploadFile",
        method:"post",
        data: data
    })
}
// 查询教师档案
export const  archiveQueryArchive =(query) =>{
    return request({
        url: "/businessservice/archive/query/archive",
        method:"get",
        params: query
    })
}