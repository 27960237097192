<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>资源管理</a></el-breadcrumb-item>
                <el-breadcrumb-item>资源课程详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-descriptions :column="3" class="detailFF" title="基本信息">
                <el-descriptions-item label="学段">{{ form.periodName }}</el-descriptions-item>
                <el-descriptions-item label="年级">{{ form.gradeName }}</el-descriptions-item>
                <el-descriptions-item label="学科">{{ form.subjectName }}</el-descriptions-item>
                <el-descriptions-item label="优课类型">{{ form.stageTypeName }}</el-descriptions-item>
                <el-descriptions-item v-if="form.groupId" width="33.33%" label="工作室">
                    <el-select disabled v-model="form.groupId" placeholder="请选择工作室/群组">
                        <el-option v-for="item in groupList" :key="item.groupId" :label="item.groupName"
                            :value="item.groupId" />
                    </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="是否公共资源">{{ form.isCommon == 0 ? '否' : '是' }}</el-descriptions-item>
                <el-descriptions-item label="课题名称">{{ form.courseName }}</el-descriptions-item>
                <el-descriptions-item label="主讲人">{{ form.userName }}</el-descriptions-item>
            </el-descriptions>
            <el-form class="detailFF detailFFss" ref="form" :model="form" :rules="rules" label-width="70px">
                <el-row v-if="form.isCommon == 0">
                    <el-col :span="24">
                        <el-form-item label="查看组织" prop="orgIds">
                            <el-tree-select disabled style="width: 100%;" clearable multiple @change="changeOrg"
                                :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                                v-model="form.orgIdList" default-expand-all :data="treeData" :render-after-expand="false"
                                show-checkbox check-strictly check-on-click-node />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="40" class="avatar-uploader" label="封面" prop="courseCover">
                            <img v-if="form.courseCover" :src="form.courseCover" class="avatar" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="40" label="简介" prop="courseRecommend">
                            {{ form.courseRecommend }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="recommendImgList.length != 0">
                    <el-col :span="24">
                        <el-form-item label-width="75" label="介绍图片" prop="recommendImgList">
                            <div style="display: flex;flex-wrap:wrap;">
                                <div v-for="(item, index) in recommendImgList" class="csbbb">
                                    <img :src="item.response.data"
                                        style="width: 148px;height: 148px;margin-right: 5px;margin-bottom: 5px;">
                                </div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <!-- <div style="width: 390px;height:844px" v-html="form.content">

        </div> -->
    </div>
</template>

<script>
import { famousCourseCreate, famousCourseInfo, famousCourseUpdate } from "@/api/admin/famousCourse"
import { queryDict, userList, listHeadmasterGroup, listTeacherGroup } from "@/api/admin/public";
import { orgListTree } from "@/api/admin/system";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            courseId: '',
            jAFileList: [],
            recommendImgList: [],
            groupList: [],
            xAFileList: [],
            kJFileList: [],
            xTFileList: [],
            periodList: [],
            gradeList: [],
            subjectList: [],
            courseClassifyList: [],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                leadUser: '',
                teamUsers: '',
                teamuseList: [],
            },
            // 表单校验
            rules: {

            },
            delFileList: [],
            treeData: [],
        };
    },
    created() {
        this.getGroup();
        this.getOrgListTree();
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.courseId) {
            this.courseId = this.$route.query.courseId
            console.log(this.form)
            this.getfamousCourseInfo()
        } else {
        }
    },
    unmounted() {
    },
    computed: {
    },
    methods: {
        getGroup() {
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            listTeacherGroup().then(res => {
                res.data.data.forEach(j => {
                    // j.memberList.forEach(m => {
                    //     if (m.memberRole == 0 || m.memberRole == 1) {
                    //         if (m.memberUserId == userId) {
                    this.groupList.push(j)
                    // throw new Error();
                    //         }
                    //     }
                    // })
                })
                listHeadmasterGroup().then(res => {
                    res.data.data.forEach(j => {
                        // j.memberList.forEach(m => {
                        //     if (m.memberRole == 0 || m.memberRole == 1) {
                        //         if (m.memberUserId == userId) {
                        this.groupList.push(j)
                        // throw new Error();
                        //     }
                        // }
                        // })
                    })
                })
            })
        },
        getOrgListTree() {
            orgListTree().then(res => {
                res.data.data.forEach(e => {
                    e.value = e.orgId
                    e.orgName = e.orgName
                    if (e.childrenList && e.childrenList != null) {
                        e.children = e.childrenList;
                        e.children.forEach(m => {
                            m.value = m.orgId
                            m.orgName = m.orgName
                            if (m.childrenList && m.childrenList != null) {
                                m.children = m.childrenList;
                                m.children.forEach(j => {
                                    j.value = j.orgId
                                    j.orgName = j.orgName
                                })
                            }
                        })
                    }
                })
                this.treeData = res.data.data;
            })
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.courseCover != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getfamousCourseInfo() {
            // userList().then(res => {
            //     this.userList = res.data.data;
            famousCourseInfo({ courseId: this.courseId }).then(res => {
                this.form = res.data.data;
                this.recommendImgList = []
                if (res.data.data.recommendImgList) {
                    res.data.data.recommendImgList.forEach(e => {
                        this.recommendImgList.push({ response: { data: e } })
                    })
                }
                if (this.form.orgIds) {
                    this.form.orgIdList = this.form.orgIds.split(',')
                }
                this.periodList.forEach(j => {
                    if (this.form.periodKey == j.dictKey) {
                        this.gradeList = j.childrenList;
                    }
                })
                this.periodList.forEach(e => {
                    if (this.form.periodKey == e.dictKey) {
                        e.childrenList.forEach(j => {
                            if (this.form.gradeKey == j.dictKey) {
                                this.subjectList = j.childrenList;
                            }
                        })
                    }
                })
            })
            // })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)
        },
        handleXAChange(file) {
            console.log(file);
        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getQueryDict() {
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.courseCover = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)

            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.courseId) {
                        famousCourseUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/famousCourse', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        famousCourseCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/famousCourse', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            // this.jAFileList = [];
            this.replace('/famousCourse')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.detailFF /deep/ .el-select__suffix {
    display: none;
}
.detailFF /deep/ .el-select {
    width: 70%;
}
.detailFFss  /deep/ .el-select{
    width: 100% !important;
}
.detailFF /deep/ .el-select__wrapper.is-disabled {
    cursor: unset;
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
}

.detailFF /deep/ .el-select__wrapper.is-disabled .el-select__selected-item {
    color: #333;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__inner {
    color: #333;
    -webkit-text-fill-color: #333;
}

.detailFF /deep/ .el-select__wrapper.is-disabled {
    cursor: unset;
}
</style>