<template>
    <div class="app-container">
        <el-card v-has-btn="'1,2,3'" class="mb15">
            <el-row>
                <el-col :span="16" class="mb0">
                    <el-button v-has-btn="'1,2,3'" type="primary" @click="handleAdd">新增课件</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学段">
                            <el-select clearable @change="changePeriod" v-model="queryParams.periodKey" placeholder="请选择学科">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="年级">
                            <el-select clearable @change="changeGrade" v-model="queryParams.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学科">
                            <el-select clearable @change="getList()" v-model="queryParams.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="状态">
                            <el-select clearable @change="getList()" v-model="queryParams.isRelease" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    <el-col :span="24" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.name" placeholder="请输入课件名称" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                    :height="`calc(100vh - ${this.$store.state.zdy.role != 1 && this.$store.state.zdy.role != 2 && this.$store.state.zdy.role != 3 ? 340 : 420}px)`"
                    v-loading="loading" :data="dataList" highlight-current-row>
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column width="160" label="课件状态" prop="isRelease">
                        <template #default="scope">
                            <div v-if="scope.row.isRelease == 0">未发布</div>
                            <div v-if="scope.row.isRelease == 1">已发布</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="300" label="课时名称" prop="name">
                        <template #default="scope">
                            <div :title="scope.row.name" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="学段" prop="periodName" />
                    <el-table-column width="80" label="年级" prop="gradeName" />
                    <el-table-column width="100" label="学科" prop="subjectName" />
                    <el-table-column width="180" label="发布时间" prop="releaseTime">
                        <template #default="scope">
                            <div class="text-one" :title="timestampToTime(scope.row.releaseTime)">{{
                                timestampToTime(scope.row.releaseTime) }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column fixed="right" width="620" label="操作" class-name="small-padding fixed-width">
                        <template #default="scope">
                            <!-- <el-button size="mini" type="text" icon="el-icon-view" @click="goDetail(scope.row)">查看
                        </el-button> -->
                            <el-button v-if="scope.row.isTop != 1" v-has-btn="'1,2,3'" size="mini" type="primary"
                                @click="handleTop(scope.row)">置顶
                            </el-button>
                            <el-button style="background: #5c89ff;color:#fff;" v-if="scope.row.isTop == 1"
                                v-has-btn="'1,2,3'" size="mini" type="primary" @click="handleTop(scope.row)">取消置顶
                            </el-button>
                            <el-button v-has-btn="'1,2,3'" size="mini" type="primary" icon="el-icon-edit"
                                @click="handleUpdate(scope.row)">编辑
                            </el-button>
                            <el-button v-has-btn="'1,2,3'" v-if="scope.row.isRelease == 0" type="primary" @click="handleUploadVideo(scope.row)">上传课件
                            </el-button>
                            <el-button v-if="scope.row.isRelease == 1" size="mini" type="primary"
                                @click="handleLookVideo(scope.row)">查看课件
                            </el-button>
                            <el-button v-has-btn="'1,2,3'" v-if="scope.row.isRelease == 0" style="margin-left: 12px;"
                                size="mini" type="primary" icon="" @click="handleRelease(scope.row)">发布课件
                            </el-button>
                            <el-button v-has-btn="'1,2,3'" v-if="scope.row.isRelease == 1" style="margin-left: 12px;"
                                size="mini" type="primary" icon="" @click="handleRelease(scope.row)">取消发布
                            </el-button>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定同步到励耘" @confirm="tblyjy(scope.row)">
                                <template #reference>
                                    <el-button v-has-btn="'1,2,3'" size="mini" type="primary">同步到励耘
                                    </el-button>
                                </template>
                            </el-popconfirm>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定确定删除该课件及其课件附件" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button v-has-btn="'1,2,3'" size="mini" type="danger" icon="el-icon-delete">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy">
                <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                    :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>
    </div>
</template>

<script>
import { famousCourseListFamous, coursewareSync, coursewareReleaseOrCancel, famousCourseInfo, coursewareDelete, famousCourseListTreeFamous, coursewareTopOrCancel, coursewarePage } from "@/api/admin/famousCourse"
import { trainListAll, trainRelease, trainStop } from "@/api/admin/train"
import { evaluationListAll, evaluationCreate, evaluationDel, evaluationRelease, evaluationStop, evaluationSubmit } from "@/api/admin/evaluation"
import { lessonList, lessonSubmit, lessonStop, lessonRelease, lessonGaFiles, delLessonFile } from "@/api/admin/lesson"
import {
    exportData
} from "@/api/demo/data";
import { timestampToTime, timestampToDate } from "@/utils/date";
import { queryDict, userList, listTeacherGroup, listHeadmasterGroup, groupListAuthGroup } from "@/api/admin/public";

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            isVideo: 0,// 1 课 2 章 3 节
            isType: 1, // 1 课 2 章 3 节
            selectRow: {},
            scFa: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            timestampToDate,
            gAFileList: [],
            token: this.$storage.get("token"),
            courseClassifyList: [],
            statusList: [
                { name: '未发布', value: 0 },
                { name: '已发布', value: 1 },
            ],
            typeList: [

            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                name: '',
                isRelease: '',
                title: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                type: '',
                stageType: '',
                groupId: '',
                pageNum: 1,
                pageSize: 10,
            },
            groupList: [],
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            selectList: [],
        };
    },
    created() {
        this.getGroup();
        this.getQueryDict()

    },
    activated() {
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        tblyjy(row) {
            console.log(row);
            coursewareSync({ coursewareId: row.coursewareId }).then(res => {
                console.log(res);
                if (res.data.code == 1) {
                    this.$message.success("同步成功");
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        getGroup() {
            this.groupList = [];
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            groupListAuthGroup({ groupNature: 2 }).then(res => {
                res.data.data.forEach(e => {
                    this.groupList.push(e)
                })
                groupListAuthGroup({ groupNature: 3 }).then(response => {
                    response.data.data.forEach(j => {
                        this.groupList.push(j)
                    })
                    this.getList();
                })
            })
            // listTeacherGroup().then(res => {
            //     res.data.data.forEach(j => {
            //         j.memberList.forEach(m => {
            //             if (m.memberRole == 0 || m.memberRole == 1) {
            //                 if (m.memberUserId == userId) {
            //                     this.groupList.push(j)
            //                     // throw new Error();
            //                 }
            //             }
            //         })
            //     })
            //     listHeadmasterGroup().then(res => {
            //         res.data.data.forEach(j => {
            //             j.memberList.forEach(m => {
            //                 if (m.memberRole == 0 || m.memberRole == 1) {
            //                     if (m.memberUserId == userId) {
            //                         this.groupList.push(j)
            //                         // throw new Error();
            //                     }
            //                 }
            //             })
            //         })
            //         this.getList();
            //     })
            // })
        },
        goFileDetail(item) {
            console.log(item);
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleDiscuss(row) {
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: row.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleExceed(e) {
            console.log(e)
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.url) {
                delLessonFile({ fileUrl: file.url })
            }
            if (file.response) {
                delLessonFile({ fileUrl: file.response.data })
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.selectRow.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getQueryDict() {
            queryDict({ code: 'famous_course_classify' }).then(res => {
                this.courseClassifyList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAJaChange(e, type) {
            console.log(this.selectRow.gajaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gajaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAkjChange(e, type) {
            console.log(this.selectRow.gakjFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gakjFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxtChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gaxtFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleGA(row) {
            this.scFa = true;
            this.selectRow = row;
            console.log(this.selectRow)
            // lessonGaFiles({lessonId:row.lessonId}).then(res=>{

            // })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            this.loading = true;

            coursewarePage(this.queryParams).then(res => {
                // if (this.queryParams.groupId !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.groupId == this.queryParams.groupId
                //     })
                // }
                // if (this.queryParams.periodKey !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.periodKey == this.queryParams.periodKey
                //     })
                // }
                // if (this.queryParams.gradeKey !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.gradeKey == this.queryParams.gradeKey
                //     })
                // }
                // if (this.queryParams.subjectKey !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.subjectKey == this.queryParams.subjectKey
                //     })
                // }
                // if (this.queryParams.status !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.status == this.queryParams.status
                //     })
                // }
                // if (this.queryParams.stageType !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.stageType == this.queryParams.stageType
                //     })
                // }
                // if (this.queryParams.courseName !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.courseName.toString().indexOf(this.queryParams.courseName) != -1
                //     })
                // }
                this.total = res.data.data.total;

                res.data.data.list.forEach(e => {
                    this.courseClassifyList.forEach(j => {
                        if (e.stageType == j.dictKey) {
                            e.stageTypeName = j.dictValue
                        }
                    })
                    this.groupList.forEach(j => {
                        if (e.groupId == j.groupId) {
                            e.groupName = j.groupName
                        }
                    })
                })
                this.loading = false;

                this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
                this.dataList.sort(function (a, b) {
                    if (a.releaseTime > b.releaseTime) { return -1; }
                    if (a.releaseTime < b.releaseTime) { return 1; }
                    return 0;
                })
                this.dataList.sort(function (a, b) {
                    if (a.isTop > b.isTop) { return -1; }
                    if (a.isTop < b.isTop) { return 1; }
                    return 0;
                })
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            coursewareDelete({ coursewareId: row.coursewareId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/coursewareDetail",
                query: {
                    coursewareId: row.coursewareId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/coursewareAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        handleLbAdd() {
            this.$router.push({
                path: "/rateScale",
            });
        },
        handleRelease(row) {
            this.isType = 1;
            this.isVideo = 0;
            console.log(row);
            var isz = false;
            var isj = false;
            // coursewareReleaseOrCancel({ coursewareId: row.coursewareId }).then(res => {
                // row = res.data.data;
                // if (row.videoList && row.videoList.length != 0) {
                //     this.isVideo = 1;
                // } else {
                //     this.isVideo = 0;
                // }
                // if (row.childrenList) {
                //     row.childrenList.forEach(j => {
                //         if (j.videoList && j.videoList.length != 0) {
                //             this.isVideo = 2;
                //         }
                //         if (j.childrenList) {
                //             j.childrenList.forEach(m => {
                //                 if (m.videoList && m.videoList.length != 0) {
                //                     this.isVideo = 3;
                //                 }
                //                 isj = true;
                //             })
                //         } else {
                //             isz = true;
                //         }

                //     })
                // } else {

                // }
                // if (isj == true) {
                //     this.isType = 3;
                // } else {
                //     if (isz == true) {
                //         this.isType = 2;
                //     }
                // }
                // console.log(this.isType, this.isVideo);
                // if (this.isVideo == 0) {
                //     this.$message.error('请上传视频')
                //     return;
                // }

                // else if (this.isVideo == 1) {
                //     famousCourseRelease({ courseId: row.courseId }).then(res => {
                //         if (res.data.code == 1) {
                //             this.$message.success("发布成功");
                //             this.getList();
                //         } else {
                //             this.$message.error("发布失败");
                //         }
                //     })
                // } else if (this.isVideo == 2) {
                //     console.log(row.childrenList.length);
                //     var conunt = 0;
                //     row.childrenList.forEach((e, i) => {
                //         if (e.videoList.length != 0) {
                //             conunt++;
                //         }
                //     })
                //     if (row.childrenList.length != conunt) {
                //         this.$message.error("请先上传完整信息");
                //         return
                //     } else {
                //         famousCourseRelease({ courseId: row.courseId }).then(res => {
                //             if (res.data.code == 1) {
                //                 this.$message.success("发布成功");
                //                 this.getList();
                //             } else {
                //                 this.$message.error("发布失败");
                //             }
                //         })
                //     }
                //     console.log(conunt);
                // } else if (this.isVideo == 3) {
                //     var istg = true;
                //     row.childrenList.forEach((e, i) => {
                //         // console.log(e)
                //         if (e.childrenList) {
                //             if (e.childrenList.length == 0) {
                //                 this.$message.error("请先上传完整信息");
                //                 istg = false;
                //                 throw new Error()
                //             }
                //             e.childrenList.forEach((j, k) => {
                //                 console.log(j);
                //                 if (j.videoList.length == 0) {
                //                     this.$message.error("请先上传完整信息");
                //                     istg = false;
                //                     throw new Error()
                //                 }
                //             })
                //         } else {
                //             this.$message.error("请先上传完整信息");
                //             istg = false;
                //             throw new Error()
                //         }
                //     })
                //     console.log(333, istg)
                //     if (istg) {
                coursewareReleaseOrCancel({ coursewareId: row.coursewareId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("操作成功");
                        this.getList();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
                //     }
                // }
            // })

        },
        handleSubmit(row) {
            console.log(row)
            if (row.jaFiles.length == 0 && row.kjFiles.length == 0 && row.xtFiles.length == 0) {
                this.$message.error("请先上传文件");
                return
            }
            lessonSubmit({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("提请成功");
                    this.getList();
                } else {
                    this.$message.error("提请失败");
                }
            })
        },
        handleStop(row) {
            trainStop({ trainId: row.trainId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停止成功");
                    this.getList();
                } else {
                    this.$message.error("停止失败");
                }
            })
        },
        handleTop(row) {
            coursewareTopOrCancel({ coursewareId: row.coursewareId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/coursewareAdd",
                query: {
                    coursewareId: row.coursewareId,
                }
            });
        },
        handleLookVideo(row) {
            this.$router.push({
                path: "/coursewareLookVideo",
                query: {
                    coursewareId: row.coursewareId,
                    isLook: 1,
                    isFamous: 1
                }
            });
        },
        handleUploadVideo(row) {
            this.$router.push({
                path: "/coursewareUploadVideo",
                query: {
                    coursewareId: row.coursewareId,
                }
            });
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}
</style>