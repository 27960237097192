import request from "@/utils/request";
// 查询全部新闻资讯
export const  newsListAll =(query) =>{
    return request({
        url: "/businessservice/news/listAll",
        method:"get",
        params: query
    })
}
// 删除新闻资讯
export const  newsDelete =(query) =>{
    return request({
        url: "/businessservice/news/delete",
        method:"get",
        params: query
    })
}
// 置顶新闻资讯
export const  newsTopping =(query) =>{
    return request({
        url: "/businessservice/news/topping",
        method:"get",
        params: query
    })
}
// 取消置顶新闻咨询
export const  newsCancelTop =(query) =>{
    return request({
        url: "/businessservice/news/cancelTop",
        method:"get",
        params: query
    })
}
// 隐藏新闻资讯
export const  newsHide =(query) =>{
    return request({
        url: "/businessservice/news/hide",
        method:"get",
        params: query
    })
}
// 创建新闻
export const  newsCreate =(data) =>{
    return request({
        url: "/businessservice/news/create",
        method:"post",
        data: data
    })
}
// 创建新闻
export const  newsUpdate =(data) =>{
    return request({
        url: "/businessservice/news/update",
        method:"post",
        data: data
    })
}
// 查询已发布新闻资讯
export const  newsListRelease =(query) =>{
    return request({
        url: "/businessservice/news/listRelease",
        method:"get",
        params: query
    })
}
// 发布新闻资讯
export const  newsRelease =(query) =>{
    return request({
        url: "/businessservice/news/release",
        method:"get",
        params: query
    })
}
// 上传新闻资讯文件
export const  newsUpload =(data) =>{
    return request({
        url: "/businessservice/news/upload",
        method:"post",
        ContentType:'multipart/form-data',
        data: data
    })
}

// 创建轮播图
export const  bannerCreate =(data) =>{
    return request({
        url: "/businessservice/banner/create",
        method:"post",
        data: data
    })
}
// 删除轮播图
export const  bannerDelete =(query) =>{
    return request({
        url: "/businessservice/banner/delete",
        method:"get",
        params: query
    })
}
// 隐藏轮播图
export const  bannerHide =(query) =>{
    return request({
        url: "/businessservice/banner/hide",
        method:"get",
        params: query
    })
}
// 查询全部轮播图
export const  bannerListAll =(query) =>{
    return request({
        url: "/businessservice/banner/listAll",
        method:"get",
        params: query
    })
}
// 发布轮播图
export const  bannerRelease =(query) =>{
    return request({
        url: "/businessservice/banner/release",
        method:"get",
        params: query
    })
}
// 置顶轮播图
export const  bannerTopping =(query) =>{
    return request({
        url: "/businessservice/banner/topping",
        method:"get",
        params: query
    })
}
// 更新轮播图
export const  bannerUpdate =(data) =>{
    return request({
        url: "/businessservice/banner/update",
        method:"post",
        data: data
    })
}
// 上传轮播图
export const  bannerUpload =(data) =>{
    return request({
        url: "/businessservice/banner/upload",
        method:"post",
        ContentType:'multipart/form-data',
        data: data
    })
}

// 创建通知轮播图
export const  noticeBannerCreate =(data) =>{
    return request({
        url: "/businessservice/noticeBanner/create",
        method:"post",
        data: data
    })
}
// 删除通知轮播图
export const  noticeBannerDelete =(query) =>{
    return request({
        url: "/businessservice/noticeBanner/delete",
        method:"get",
        params: query
    })
}
// 隐藏通知轮播图
export const  noticeBannerHide =(query) =>{
    return request({
        url: "/businessservice/noticeBanner/hide",
        method:"get",
        params: query
    })
}
// 查询全部通知轮播图
export const  noticeBannerListAll =(query) =>{
    return request({
        url: "/businessservice/noticeBanner/listAll",
        method:"get",
        params: query
    })
}
// 发布通知轮播图
export const  noticeBannerRelease =(query) =>{
    return request({
        url: "/businessservice/noticeBanner/release",
        method:"get",
        params: query
    })
}
// 置顶通知轮播图
export const  noticeBannerTopping =(query) =>{
    return request({
        url: "/businessservice/noticeBanner/topping",
        method:"get",
        params: query
    })
}
// 更新通知轮播图
export const  noticeBannerUpdate =(data) =>{
    return request({
        url: "/businessservice/noticeBanner/update",
        method:"post",
        data: data
    })
}
// 上传通知轮播图
export const  noticeBannerUpload =(data) =>{
    return request({
        url: "/businessservice/noticeBanner/upload",
        method:"post",
        ContentType:'multipart/form-data',
        data: data
    })
}
// 阅读新闻咨询
export const  newsRead =(query) =>{
    return request({
        url: "/businessservice/news/read",
        method:"get",
        params: query
    })
}