<template>
  <PdfView class="pdf-content" :pdfUrl="wordPdf"></PdfView>
</template>

<script>
import PdfView from "@/components/pdfView"
export default {
  name: "workBook",
  data(){
    return{
      wordPdf:"https://group.zbform.com/file/workBook.pdf"
    }
  },
  components:{
    PdfView
  },
  created() {
    /*this.openPdf()*/
  },
  methods:{
    openPdf(){
      window.open(this.wordPdf, "_self")
    }
  }
}
</script>

<style scoped>
.pdf-content{
  height: 100%;
  width: 98%;
  margin: auto;
}
</style>
