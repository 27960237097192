import request from "@/utils/request";
// 手机号查询用户信息
export const  qunQueryUser =(query) =>{
    return request({
        url: "/adminservice/qun/queryUser",
        method:"get",
        params: query
    })
}
// 手机号查询用户信息
export const  adminqueryUser =(query) =>{
    return request({
        url: "/authservice/admin/queryUser",
        method:"get",
        params: query
    })
}
// 创建群
export const  qunCreate =(data) =>{
    return request({
        url: "/adminservice/qun/create",
        method:"post",
        data: data
    })
}
// 查询客户群组
export const  queryCustomQun =(query) =>{
    return request({
        url: "/adminservice/qun/queryCustomQun",
        method:"get",
        params: query
    })
}
// 修改群
export const  qunUpdate =(data) =>{
    return request({
        url: "/adminservice/qun/update",
        method:"post",
        data: data
    })
}