<template>
    <div class="app-container">
        <el-row style="max-height:calc(100vh - 140px);overflow-y: auto;" v-loading="loading">
            <el-col :xs="12" :span="8" :lg="6" v-for="(item, index) in dataList" :key="index">
                <div style="margin: 10px;">
                    <div style="background: #fff;padding:15px;">
                        <div class="cd-top">
                            <div>
                                <div class="text-one cd-bgtit mr10">{{ item.groupName }}</div>
                                <div class="cd-gz mt5">关注人数 <span>{{ item.careNum }}</span></div>
                            </div>
                            <div class="cd-rgbtn cursor" @click="getstudioCareOrCancel(item)">{{ item.isCare == 0 ? '+关注' :
                                '取消关注' }}</div>
                        </div>
                        <div class="cd-rg mt15">
                            <el-row style="width: 100%;">
                                <el-col :span="9" :lg="12">
                                    <img style="width: 100%;height: 120px;object-fit: cover;" :src="item.groupAvatar"
                                        alt="">
                                </el-col>
                                <el-col :span="15" :lg="12">
                                    <div class="cd-cl-bt">
                                        <div class="disflex-sb ml10" style="font-size: 13px;">
                                            <div @click="goInfo(item, 0)" class="disflex-sb cd-rg-box cursor"
                                                style="white-space: nowrap;">
                                                <div>成员{{ item.memberNum }}</div>
                                                <el-icon class="el-icon--right">
                                                    <ArrowRight />
                                                </el-icon>
                                            </div>
                                            <div @click="goInfo(item, 2)" class="disflex-sb cd-rg-box cursor"
                                                style="white-space: nowrap;">
                                                <div>日志{{ item.logNum }}</div>
                                                <el-icon class="el-icon--right">
                                                    <ArrowRight />
                                                </el-icon>
                                            </div>
                                        </div>
                                        <div class="disflex-sb ml10" style="font-size: 13px;">
                                            <div @click="goInfo(item, 3)" class="disflex-sb cd-rg-box cursor"
                                                style="white-space: nowrap;color: #07bbf2;background: #dcf3fa;">
                                                <div>文章{{ item.articleNum }}</div>
                                                <el-icon class="el-icon--right">
                                                    <ArrowRight />
                                                </el-icon>
                                            </div>
                                            <div @click="goInfo(item, 5)" class="disflex-sb cd-rg-box cursor"
                                                style="white-space: nowrap;color: #07bbf2;background: #dcf3fa;">
                                                <div>简报{{ item.briefList ? item.briefList.length :
                                                    0
                                                }}</div>
                                                <el-icon class="el-icon--right">
                                                    <ArrowRight />
                                                </el-icon>
                                            </div>
                                        </div>
                                        <div class="disflex-sb ml10" style="font-size: 13px;">
                                            <div @click="goInfo(item, 4)" class="disflex-sb cd-rg-box cursor"
                                                style="white-space: nowrap;color: #ff7f2e;background: #ffece0;">
                                                <div>示范课{{ item.courseNum }}</div>
                                                <el-icon class="el-icon--right">
                                                    <ArrowRight />
                                                </el-icon>
                                            </div>
                                            <div @click="goInfo(item, 1)" class="disflex-sb cd-rg-box cursor"
                                                style="white-space: nowrap;color: #ff7f2e;background: #ffece0;">
                                                <div>成果{{ item.achieveNum }}</div>
                                                <el-icon class="el-icon--right">
                                                    <ArrowRight />
                                                </el-icon>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                        </div>

                    </div>
                    <div class="cd-bt" style="position: relative;">
                        <div v-has-btn="'1,2,3,4,5'" class="cursor cd-bt-box" style="position: absolute;"
                            @click="createdInformation(item, 1)">
                            <el-icon :size="12">
                                <Plus />
                            </el-icon>
                            <div>工作简报</div>
                        </div>
                        <div v-has-btn="'1,2,3,4,5,6'" class="cursor cd-bt-box" style="position: absolute;left: 25%;"
                            @click="createdInformation(item, 4)">
                            <el-icon :size="12">
                                <Plus />
                            </el-icon>
                            <div>工作日志</div>
                        </div>
                        <div v-has-btn="'1,2,3,4,5'" class="cursor cd-bt-box" style="position: absolute;left: 50%;"
                            @click="createdInformation(item, 2)">
                            <el-icon :size="12" style="transform: rotate(180deg);">
                                <Download />
                            </el-icon>
                            <div>教师文章</div>
                        </div>
                        <div v-has-btn="'1,2,3,4,5'" class="cursor cd-bt-box" style="position: absolute;right:0"
                            @click="createdInformation(item, 3)">
                            <el-icon :size="12" style="transform: rotate(180deg);">
                                <Download />
                            </el-icon>
                            <div>成果展示</div>
                        </div>
                        <img src="../../assets/image/bjbox.png" style="width: 100%;height:auto;" alt="">
                    </div>
                </div>
            </el-col>
            <el-empty style="margin: 0 auto;padding-top: 10vh;" v-if="dataList.length == 0" :image-size="200" />
        </el-row>
        <!-- <el-card shadow="never">
            <el-row style="max-height:calc(100vh - 140px);overflow-y: auto;" v-loading="loading">
                <el-col class="w-90" v-for="(item, index) in dataList" :key="index">
                    <el-card :id="'cs' + index" :body-style="{ padding: '0px' }">
                        <el-row>
                            <el-col :span="12">
                                <div style="width: 100%;display: flex;flex-direction: column;align-items: center;">
                                    <el-avatar class="mb15 mt15" :size="120" :src="item.groupAvatar" />
                                    <div class="mb15">{{ item.groupName }}</div>
                                    <div class="mb15" style="display: flex;align-items: center;">
                                        <el-button @click="getstudioCareOrCancel(item)" v-if="item.isCare == 0" size="small"
                                            type="primary">+关注</el-button>
                                        <el-button @click="getstudioCareOrCancel(item)" v-if="item.isCare == 1" size="small"
                                            type="primary">取消关注</el-button>
                                        <div class="ml40"><span style="color: red;">{{ item.careNum }}</span>人关注</div>
                                    </div>
                                    <div class="mb15" style="display: flex;align-items:center">
                                        <div @click="goInfo(item, 0)" class="cursor">成员<span
                                                style="color: rgba(56,148,255);">{{ item.memberNum
                                                }}</span></div>
                                        <div @click="goInfo(item, 5)" class="ml15 cursor">简报<span
                                                style="color: rgba(226,149,17);">{{ item.briefList ? item.briefList.length :
                                                    0
                                                }}</span></div>
                                        <div @click="goInfo(item, 1)" class="ml15 cursor">成果<span
                                                style="color: rgba(226,149,17);">{{
                                                    item.achieveNum }}</span></div>
                                        <div @click="goInfo(item, 2)" class="ml15 cursor">日志<span
                                                style="color: rgba(173,31,211);">{{ item.logNum
                                                }}</span>
                                        </div>
                                        <div @click="goInfo(item, 3)" class="ml15 cursor">文章<span
                                                style="color: rgba(202,213,7);">{{ item.articleNum
                                                }}</span>
                                        </div>
                                        <div @click="goInfo(item, 4)" class="ml15 cursor">示范课<span
                                                style="color: rgba(226,149,17);">{{ item.courseNum
                                                }}</span></div>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div style="padding: 16px 10px 10px 10p;display: flex;align-items: center;height: 100%;justify-content: space-around;"
                                    class="fs-14 wbba">
                                    <div
                                        style="display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;height: 100%;">
                                        <el-button v-has-btn="'1,2,3,4,5'" @click="createdInformation(item, 1)"
                                            type="primary">创建工作简报</el-button>
                                        <el-button v-has-btn="'1,2,3,4,5,6'" @click="createdInformation(item, 4)"
                                            style="margin-left: 0 !important;" type="primary">创建工作日志</el-button>
                                    </div>
                                    <div
                                        style="display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;height: 100%;">
                                        <el-button v-has-btn="'1,2,3,4,5'" @click="createdInformation(item, 3)"
                                            type="primary">上传成果展示</el-button>
                                        <el-button v-has-btn="'1,2,3,4,5'" @click="createdInformation(item, 2)"
                                            style="margin-left: 0 !important;" type="primary">上传教师文章</el-button>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
                <el-empty style="margin: 0 auto;padding-top: 10vh;" v-if="dataList.length == 0" :image-size="200" />
            </el-row>
        </el-card> -->
        <el-dialog v-model="isStudioPeopleList" :title="studioTitle" width="1000px">
            <el-row>
                <el-col :span="6" v-for="item in studioPeopleList">
                    <div style="display: flex;width: 100%;align-items: center;">
                        <el-avatar class="mb15 mt15" :size="50" :src="item.memberAvatar" />
                        <div class="ml15">
                            <div>{{ item.memberRole == 0 ? '主持人' : item.memberRole == 1 ? '管理' : '成员' }}:{{ item.memberName
                            }}</div>
                            <div>所属：{{ item.orgName }}</div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
        <el-dialog v-model="isStudioList" :title="studioTitle" width="80%">
            <el-row>
                <el-table v-if="isStudioList" show-overflow-tooltip class="mt15" height="50vh" border :data="studioList"
                    highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column width="350" label="标题" prop="title">
                        <template #default="scope">
                            <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.title }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="作者" prop="trueName" />
                    <el-table-column width="140" label="阅读量" prop="readNum" />
                    <el-table-column width="140" label="创建时间" prop="createTime">
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.createTime) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column v-has-btn="'1,2,3,4,5,6'" v-if="isRz" fixed="right" width="350" label="操作"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button size="mini" type="primary" icon="el-icon-edit" @click="handleUpdate(scope.row)">编辑
                            </el-button>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该资讯" @confirm="handleDelete(scope.row, scope.$index)">
                                <template #reference>
                                    <el-button size="mini" type="danger" icon="el-icon-delete">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                    <el-table-column v-has-btn="'1,2,3,4,5'" v-else fixed="right" width="350" label="操作"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button size="mini" type="primary" icon="el-icon-edit" @click="handleUpdate(scope.row)">编辑
                            </el-button>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该资讯" @confirm="handleDelete(scope.row, scope.$index)">
                                <template #reference>
                                    <el-button size="mini" type="danger" icon="el-icon-delete">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-dialog>
        <el-dialog v-model="isCourseList" :title="studioTitle" width="80%">
            <el-row>
                <el-table show-overflow-tooltip class="mt15" v-if="isCourseList" height="50vh" border :data="courseList"
                    highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column width="300" label="课题名称" prop="courseName">
                        <template #default="scope">
                            <div :title="scope.row.courseName" class="text-one cursor">
                                {{ scope.row.courseName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="120" label="类型" prop="stageTypeName">
                        <template #default="scope">
                            {{ scope.row.stageTypeName }}
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="学段" prop="periodName" />
                    <el-table-column width="80" label="年级" prop="gradeName" />
                    <el-table-column width="100" label="学科" prop="subjectName" />
                    <el-table-column label="发布时间" prop="releaseTime">
                        <template #default="scope">
                            <div class="text-one" :title="timestampToTime(scope.row.releaseTime)">{{
                                timestampToTime(scope.row.releaseTime) }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column fixed="right" width="120" label="操作" class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button v-if="scope.row.status == 1" size="mini" type="primary"
                                @click="handleLookVideo(scope.row)">查看视频
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { famousCourseListReleaseCourse } from '@/api/admin/famousCourse';
import { lessonListCommonLessons, lessonSubmit, delLessonFile, lessonStop, lessonRelease } from "@/api/admin/lesson"
import { timestampToTime } from "@/utils/date";
import { queryDict } from "@/api/admin/public";
import { download } from "@/api/adminservice/public";
import { manageStudioList, studioCareOrCancel, studioInfo, studioDelete, infoStudio } from "@/api/admin/studio";

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            isRz: false,
            isCourseList: false,
            courseList: [],
            isStudioPeopleList: false,
            studioTitle: '',
            studioPeopleList: [],
            studioList: [],
            isStudioList: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            gAFileList: [],
            token: this.$storage.get("token"),
            roleList: [
                { name: '已参与', value: 1 },
                { name: '未参与', value: 2 },
            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                role: '',
                courseName: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                stageType: ''
            },
            courseClassifyList: [],
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            smLogo: 110,
            selectObj: [],
            type: 1,     // 1-工作简报，2-教师文章，3-成果展示,4-工作日志
        };
    },
    created() {
        this.getQueryDict()
        this.getList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        handleLookVideo(row) {
            this.$router.push({
                path: "/famousCourseLookVideo",
                query: {
                    courseId: row.courseId,
                    isLook: 1,
                    isStudio: 1
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row, index) {
            console.log(index);
            studioDelete({ infoId: row.infoId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.studioList.splice(index, 1)
                    this.getList()
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.$router.push({
                path: "/studioAdd",
                query: {
                    groupId: row.groupId,
                    type: row.type,
                    infoId: row.infoId,
                }
            });
        },
        goInfo(row, type) {
            this.isRz = false;
            if (type == 0) {
                this.studioPeopleList = row.memberList;
                this.studioTitle = row.groupName + '成员列表'
                this.isStudioPeopleList = true;
            } else if (type == 1) {
                this.isStudioList = true;
                this.studioTitle = row.groupName + '成果'
                this.studioList = row.achieveList;
            } else if (type == 2) {
                this.isRz = true;
                this.isStudioList = true;
                this.studioTitle = row.groupName + '日志'
                this.studioList = row.logList;
            } else if (type == 3) {
                this.isStudioList = true;
                this.studioTitle = row.groupName + '文章'
                this.studioList = row.articleList;
            } else if (type == 4) {
                console.log(row)
                infoStudio({ groupId: row.groupId }).then(res => {
                    this.isCourseList = true;
                    this.studioTitle = res.data.data.groupName + '示范课'
                    this.courseList = res.data.data.courseList;
                })

            } else if (type == 5) {
                this.isStudioList = true;
                this.studioTitle = row.groupName + '工作简报'
                this.studioList = row.briefList;
            }
        },
        getstudioCareOrCancel(row) {
            studioCareOrCancel({ groupId: row.groupId }).then(res => {
                if (res.data.code == 1) {
                    if (row.isCare == 1) {
                        row.isCare = 0;
                        row.careNum--;
                    } else {
                        row.isCare = 1;
                        row.careNum++;
                    }
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        createdInformation(row, type) {
            // debugger
            this.$router.push({
                path: "/studioAdd",
                query: {
                    groupId: row.groupId,
                    type
                }
            });
        },
        delFile(item) {
            var _this = this;
            ElMessageBox.confirm(
                '是否删除该共享课件?',
                '删除',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                let arr = []
                item.jaFiles.forEach(e => {
                    arr.push(new Promise(function (resolve, reject) {
                        delLessonFile({ fileUrl: e.fileUrl }).then(res => {
                            resolve(res.data.code)
                        })
                    }))
                })
                item.xaFiles.forEach(e => {
                    arr.push(new Promise(function (resolve, reject) {
                        delLessonFile({ fileUrl: e.fileUrl }).then(res => {
                            resolve(res.data.code)
                        })
                    }))
                })
                item.xtFiles.forEach(e => {
                    arr.push(new Promise(function (resolve, reject) {
                        delLessonFile({ fileUrl: e.fileUrl }).then(res => {
                            resolve(res.data.code)
                        })
                    }))
                })
                item.kjFiles.forEach(e => {
                    arr.push(new Promise(function (resolve, reject) {
                        delLessonFile({ fileUrl: e.fileUrl }).then(res => {
                            resolve(res.data.code)
                        })
                    }))
                })
                Promise.all(arr).then((result) => {
                    _this.getList()
                })

            }).catch(() => {

            })

            console.log(item);
        },
        downloadFile(item) {
            item.jaFiles.forEach(e => {
                download(e.fileUrl, e.fileName)
            })
            item.xaFiles.forEach(e => {
                download(e.fileUrl, e.fileName)
            })
            item.xtFiles.forEach(e => {
                download(e.fileUrl, e.fileName)
            })
            item.kjFiles.forEach(e => {
                download(e.fileUrl, e.fileName)
            })
            console.log(item);
        },
        lessonFileGADetail(item) {
            console.log(item);
            let routeUrl = this.$router.resolve({
                path: '/lessonFileGADetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: item.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'famous_course_classify' }).then(res => {
                this.courseClassifyList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAChange(e) {
            console.log(e)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('上传成功')
                    this.getList();
                } else {
                    this.$message.error('上传失败')
                }
            }

            // this.gAFileList = [];
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleGA(row) {

        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            manageStudioList().then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.groupNature == 3
                })
                // res.data.data.forEach(e => {
                //     // var textList = [];
                //     // e.teamUserList.forEach(j => {
                //     //     textList.push(j.trueName)
                //     // })
                //     // console.log(textList)
                //     // e.teamUserText = textList.join('、')
                //     // console.log(e.teamUserText)
                // })
                this.dataList = res.data.data;
                this.loading = false;
            });
        },
        goDetail(row) {
            this.$router.push({
                path: "/studioDetail",
                query: {
                    infoId: row.infoId,
                }
            });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        handleRelease(row) {
            lessonRelease({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("发布失败");
                }
            })
        },
        handleSubmit(row) {
            console.log(row)
            if (row.jaFiles.length == 0 && row.kjFiles.length == 0 && row.xtFiles.length == 0) {
                this.$message.error("请先上传文件");
                return
            }
            lessonSubmit({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("提请成功");
                    this.getList();
                } else {
                    this.$message.error("提请失败");
                }
            })
        },
        handleStop(row) {
            lessonStop({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停止成功");
                    this.getList();
                } else {
                    this.$message.error("停止失败");
                }
            })
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.w-90 {
    margin-bottom: 15px;
    margin-top: 10px;
}

.w-90 /deep/ .el-card.is-always-shadow {
    width: 90%;
    margin: 0 auto;
    /* cursor: pointer; */
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.image {
    width: 100%;
    display: block;
}

.chosebtn {
    padding-top: 10px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

.chosebtn div {
    margin-right: 10px;
}

.chosebtn img {
    padding: 5px;

    margin: 0 auto;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.bor-lan {
    border: 1px solid #409eff
}

.wbba {
    word-break: break-all;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.pxbutton {
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.start {
    display: none;
}

.btnStart:hover .start {
    display: block;
}

.cjtag {
    position: absolute;
    top: 14px;
    right: 0;
    font-size: 14px;
    background: #f1f1f1;
    padding: 2px 7px;
    border-radius: 4px;
    color: #d44c2d;
}

.stpBox {
    display: flex;
    align-items: center;
}

.cd-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
}

.cd-rgbtn {
    white-space: nowrap;
    padding: 2px 10px;
    border: 1px solid;
    line-height: 20px;
    font-size: 14px;
    border-radius: 15px;
    color: #ff7f2e;
    font-weight: bold;
}

.cd-bgtit {
    font-size: 15px;
    color: #666666;
    font-weight: bold;
}

.cd-gz {
    font-size: 14px;
    color: #999999;
}

.cd-gz span {
    color: #ff7f2e;
}

.cd-rg {
    display: flex;
    justify-content: space-between;
}

.cd-rg-box {
    background: #dee2fc;
    color: #3b64fc;
    padding: 5px 5px;
    border-radius: 5px;
    min-width: 40%;
    font-weight: bold;
}

.cd-cl-bt {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cd-bt-box {
    white-space: nowrap;
    width: 25%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    top: 50%;
    transform: translateY(-60%);
}
</style>