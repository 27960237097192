<template>
    <div id="gyroContain">
        <div id="mySidenav" class="sidenav">
            <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
        </div>
        <div id="container"></div>
        <div id="games"></div>
    </div>
</template>

<script>
export default {
    name: "flash",
    components: {
    },
    data() {
        return {
        };
    },
    beforeCreate() {
        
    },
    created() {
    },
    mounted() {
        let whichOne = this.getUrlParamsByKey(window.location.href, 'page');
        const ruffle = window.RufflePlayer.newest();
        console.log(ruffle);
        let player;
        player = ruffle.createPlayer();
        player.style.height = "100vh"
        player.style.width = "100vw"
        console.log(player);
        const container = document.getElementById("container");
        container.appendChild(player);
        console.log(whichOne);
        setTimeout(() => {
            console.log(player);
            player.load(whichOne);
        }, 3000)

        // startGame(whichOne);
    },
    activated() {
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        getUrlParamsByKey(url, key) {
            // 通过 ? 分割获取后面的参数字符串
            let urlStr = url.split('?')[1]
            // 创建空对象存储参数
            if (!urlStr) {
                return ""
            }
            let obj = {};
            // 再通过 & 将每一个参数单独分割出来
            let paramsArr = urlStr.split('&')
            for (let i = 0, len = paramsArr.length; i < len; i++) {
                // 再通过 = 将每一个参数分割为 key:value 的形式
                let arr = paramsArr[i].split('=')
                obj[arr[0]] = arr[1];
            }
            // console.log(window.decodeURIComponent(window.atob(obj[key])));
            return window.decodeURIComponent(window.atob(obj[key]))
            // return obj[key]
        }
    }
};
</script>
<style scoped>
#gyroContain {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    footer {
        display: none;
    }

    #gyroContain {
        width: 100vh;
        height: 100vw;
        transform-origin: top left;
        transform: rotate(90deg) translate(0, -100vmin);
    }
}

body {
    /* height: 100vh; */
    /* background-color: antiquewhite; */
}

#games {
    display: flex;
    row-gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
}

#games button {
    padding: 10px;
}

#games button:hover {
    background-color: aqua;
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #111;
    overflow-x: scroll;
    transition: 0.5s;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover,
.offcanvas a:focus {
    color: #f1f1f1;
}

.sidenav .closebtn {
    top: 0;
    left: 25px;
    font-size: 36px;
    position: sticky;
    color: black;
    background-color: beige;
}
</style>