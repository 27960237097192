<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>banner管理</a></el-breadcrumb-item>
                <el-breadcrumb-item>banner详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 140px);overflow: auto;">
            <h2 style="text-align: center;">{{ formData.title }}</h2>
            <div class="pt10">发布时间：{{ timestampToTime(formData.releaseTime) }}
                <!-- <span style="padding-left: 100px;">阅读人数：{{ formData.readNum }}</span> -->
            </div>
            <div v-if="formData.type == 1" class="pt15" v-html="formData.content">

            </div>
            <div v-if="formData.type == 0" class="pt15" style="margin:0 auto;width: 50%;">
                <img style="width: 100%;" :src="formData.bannerUrl" alt="">
            </div>
        </el-card>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";

export default {
    name: "scenarioDetail",
    components: {},
    data() {
        return {
            formData:{},
            timestampToTime
        };
    },
    created() {

    },
    mounted() {
        if (this.$route.query.data) {
            this.formData = JSON.parse(this.$route.query.data)
            console.log(this.formData.appContent)
        } else {
        }

    },
    computed: {
    },
    methods: {
        // 取消按钮
        cancel() {
            this.replace('/banner')
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.custom /deep/ .el-checkbox__input {
    position: absolute;
    bottom: 0;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    /* height: 270px; */
    /* overflow-y: auto; */
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
</style>