import request from "@/utils/request";
// // 创建课时
// export const  classLibraryCreate =(data) =>{
//     return request({
//         url: "/businessservice/classLibrary/create",
//         method:"post",
//         data: data
//     })
// }
// 查询所有课时列表
export const  classLibraryListAll =(query) =>{
    return request({
        url: "/businessservice/classLibrary/listAll",
        method:"get",
        params: query
    })
}
// // 更新课时
// export const  classLibraryUpdate =(data) =>{
//     return request({
//         url: "/businessservice/classLibrary/update",
//         method:"post",
//         data: data
//     })
// }
// Excel批量导入课程
export const  classLibraryBatchImport =(data) =>{
    return request({
        url: "/businessservice/classLibrary/batchImport",
        method:"post",
        data: data
    })
}

// 创建课本
export const  classLibraryCreate =(data) =>{
    return request({
        url: "/businessservice/classLibrary/create",
        method:"post",
        data: data
    })
}

// 创建单元或课程
export const  classLibraryCreateUnit =(data) =>{
    return request({
        url: "/businessservice/classLibrary/createUnit",
        method:"post",
        data: data
    })
}
// 删除课本
export const  classLibraryDelete =(query) =>{
    return request({
        url: "/businessservice/classLibrary/delete",
        method:"get",
        params: query
    })
}
// 查询课本详情
export const  classLibraryInfo =(query) =>{
    return request({
        url: "/businessservice/classLibrary/info",
        method:"get",
        params: query
    })
}
// 查询所有课本
export const  classLibraryListAllBook =(query) =>{
    return request({
        url: "/businessservice/classLibrary/listAllBook",
        method:"get",
        params: query
    })
}
// 查询所有课本(包括单元及课)
export const  classLibraryListTree =(query) =>{
    return request({
        url: "/businessservice/classLibrary/listTree",
        method:"get",
        params: query
    })
}
// 更新课时(课本、单元、课程)
export const  classLibraryUpdate =(data) =>{
    return request({
        url: "/businessservice/classLibrary/update",
        method:"post",
        data: data
    })
}
// 上传课本封面
export const  classLibraryUploadCover =(data) =>{
    return request({
        url: "/businessservice/classLibrary/uploadCover",
        method:"post",
        ContentType:'multipart/form-data',
        data: data
    })
}

// 添加版本分类
export const  classLibraryAddClassify =(query) =>{
    return request({
        url: "/businessservice/classLibrary/addClassify",
        method:"get",
        params: query
    })
}