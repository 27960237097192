<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>课题审核</a></el-breadcrumb-item>
                <el-breadcrumb-item>课题审核详情</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 140px);overflow: auto;">
            <el-descriptions :column="3" class="detailFF" title="基本信息">
                <el-descriptions-item width="33.33%" label="申报课题" v-if="form.topicName">{{ form.topicName
                }}</el-descriptions-item>
                <el-descriptions-item label="姓名" v-if="form.trueName">{{ form.trueName }}</el-descriptions-item>
                <el-descriptions-item label="电话" v-if="form.mobile">{{ form.mobile }}</el-descriptions-item>
                <el-descriptions-item width="33.33%" label="归属" v-if="form.orgName">{{ form.orgName
                }}</el-descriptions-item>
                <el-descriptions-item label="职称" v-if="form.rankInfo">{{ form.rankInfo }}</el-descriptions-item>
                <el-descriptions-item label="申报时间" v-if="form.applyTime">{{ timestampToTime(form.applyTime)
                }}</el-descriptions-item>
            </el-descriptions>
            <el-form ref="form" :model="form" :rules="rules" label-width="68px">
                <el-row v-if="form.fileList && form.fileList.length != 0">
                    <el-col :span="24">
                        <el-form-item label-width="40px" label="附件" prop="">
                            <div @click="xz(item)" v-for="item in form.fileList" class="mb10 cursor">
                                <img v-if="item.fileType == 'pdf' || item.fileType == 'ppt' || item.fileType == 'pptx' || item.fileType == 'doc' || item.fileType == 'docx'"
                                    style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl ? item.imgUrl + '/1.png' : item.fileUrl" alt="">
                                <img v-else style="width: 350px;height:300px;object-fit: cover;" :src="item.fileUrl" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.achievement">
                    <el-col :span="24">
                        <el-form-item label="已有成果" prop="achievement">
                            {{ form.achievement }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.leaveWord">
                    <el-col :span="24">
                        <el-form-item label-width="40px" label="留言" prop="leaveWord">
                            {{ form.leaveWord }}
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import { lessonInfo } from "@/api/admin/lesson"
import { timestampToTime } from "@/utils/date";
import { queryDict } from "@/api/admin/public";
import { topicApplyInfo } from "@/api/admin/lead"

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            applyId: '',
            timestampToTime,
            // 表单参数
            form: {
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                leadUser: '',
                teamUsers: '',
                teamuseList: [],
            },
            // 表单校验
            rules: {
            },
        };
    },
    created() {

    },
    mounted() {
        this.applyId = this.$route.query.applyId
        this.getlessonInfo()
    },
    computed: {
    },
    methods: {
        xz(item) {
            if (item.fileType == 'pdf' || item.fileType == 'ppt' || item.fileType == 'pptx' || item.fileType == 'doc' || item.fileType == 'docx') {
                if (item.imgUrl) {
                    let routeUrl = this.$router.resolve({
                        path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                        query: { data: JSON.stringify(item) }
                    })
                    window.open(routeUrl.href, '_blank')
                } else {
                    window.open(item.fileUrl, '_blank')
                }
            }else{
                window.open(item.fileUrl, '_blank')
            }
            // download(item.fileUrl, item.fileName)
        },
        getlessonInfo() {
            topicApplyInfo({ applyId: this.applyId }).then(res => {
                // var textList = [];
                // res.data.data.teamUserList.forEach(j => {
                //     textList.push(j.trueName)
                // })
                // console.log(textList)
                // res.data.data.teamUserText = textList.join('、')
                this.form = res.data.data;
                queryDict({ code: 'teacher_rank' }).then(response => {
                    response.data.data.forEach(e => {
                        if (this.form.rankInfo == e.dictKey) {
                            this.form.rankInfo = e.dictValue
                        }
                    })
                })
            })
        },
        // 取消按钮
        cancel() {
            this.replace('/topicApply')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>