<template>
    <div class="app-container">
        <el-card class="mb15" v-has-btn="'1,2,3,4,5'">
            <el-row>
                <el-col :span="8" class="mb0">
                    <el-button v-has-btn="'1,2,3,4,5'" type="primary" @click="handleAdd">新增协同备课</el-button>
                    <!-- <el-button type="primary" @click="hadleDz">铺设点阵</el-button> -->
                </el-col>
                <el-col :span="16" class="mb0">
                    <div class="pull-right"
                        style="display: flex;align-items: center;transform: translateY(-3px);font-size: 14px;">
                        <el-checkbox @change="getList()" v-model="isSHowMine" size="large" />
                        <div class="ml5" style="white-space: nowrap;">我参与的</div>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学段">
                            <el-select clearable @change="changePeriod" v-model="queryParams.periodKey" placeholder="请选择学科">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="年级">
                            <el-select clearable @change="changeGrade" v-model="queryParams.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学科">
                            <el-select clearable @change="getList()" v-model="queryParams.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="状态">
                            <el-select clearable @change="getList()" v-model="queryParams.status" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="备课性质">
                            <el-select clearable @change="getList()" v-model="queryParams.lessonNature" placeholder="请选择">
                                <el-option v-for="item in natureList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.title" placeholder="请输入备课标题" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                    :height="`calc(100vh - ${this.$store.state.zdy.role == 6 ? 340 : 420}px)`" v-loading="loading"
                    :data="dataList" highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column width="100" label="状态" prop="status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 1">初始</div>
                            <div v-if="scope.row.status == 2">一轮研讨</div>
                            <div v-if="scope.row.status == 3">初案修正</div>
                            <div v-if="scope.row.status == 4">二轮研讨</div>
                            <div v-if="scope.row.status == 5">共案修正</div>
                            <div v-if="scope.row.status == 6">发布待审核</div>
                            <div v-if="scope.row.status == 7">审核通过</div>
                            <div v-if="scope.row.status == 8">审核拒绝</div>
                            <div v-if="scope.row.status == 9">结束</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="300" label="备课标题" prop="title">
                        <template #default="scope">
                            <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.title }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="160" label="备课性质" prop="lessonNature">
                        <template #default="scope">
                            <div v-if="scope.row.lessonNature == 1" class="text-one">普通</div>
                            <div v-if="scope.row.lessonNature == 2" class="text-one">名师工作室</div>
                            <div v-if="scope.row.lessonNature == 3" class="text-one">名校长工作室</div>
                            <div v-if="scope.row.lessonNature == 4" class="text-one">群组任务</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="主备人" prop="leadUserName" />
                    <el-table-column width="160" label="协备人" prop="teamUserList">
                        <template #default="scope">
                            <div :title="scope.row.teamUserText" class="text-one">{{ scope.row.teamUserText }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="160" label="指导专家" prop="guideUserList">
                        <template #default="scope">
                            <div :title="scope.row.guideUserText" class="text-one">{{ scope.row.guideUserText }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="160" label="课题级别" prop="lessonLevelName" />

                    <el-table-column width="80" label="学段" prop="periodName" />
                    <el-table-column width="80" label="年级" prop="gradeName" />
                    <el-table-column width="160" label="学科" prop="subjectName" />

                    <el-table-column width="260" label="备课时段" prop="time">
                        <template #default="scope">
                            <div>
                                {{ timestampToTime(scope.row.beginTime) }}至{{ timestampToTime(scope.row.endTime) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="附件" prop="file">
                        <template #default="scope">
                            <el-popover placement="bottom-start" :width="125" trigger="click" :min-width="'auto'">
                                <div>
                                    <div v-if="scope.row.caFileList.length != 0">
                                        初案
                                        <hr>
                                        <div style="display: flex;align-items:center;"
                                            v-for="(item, index) in scope.row.caFileList">
                                            <div @click="goFileDetail(item)" :title="item.fileName"
                                                class="filtxt text-one cursor">
                                                {{ index + 1 }}. {{ item.fileName }}
                                            </div>
                                            <el-icon @click="xz(item)" style="color: #409efe;" class="cursor ml15">
                                                <Download />
                                            </el-icon>
                                        </div>
                                    </div>
                                    <div v-if="scope.row.ytFileList.length != 0">
                                        修正案
                                        <hr>
                                        <div style="display: flex;align-items:center;"
                                            v-for="(item, index) in scope.row.ytFileList">
                                            <div @click="goFileDetail(item)" :title="item.fileName"
                                                class="filtxt text-one cursor">
                                                {{ index + 1 }}. {{ item.fileName }}
                                            </div>
                                            <el-icon @click="xz(item)" style="color: #409efe;" class="cursor ml15">
                                                <Download />
                                            </el-icon>
                                        </div>
                                    </div>
                                    <div class="mt15" v-if="scope.row.gaFileList.length != 0">
                                        共案
                                        <hr>
                                        <div style="display: flex;align-items:center;"
                                            v-for="(item, index) in scope.row.gaFileList">
                                            <div @click="goFileDetail(item)" :title="item.fileName" class="filtxt text-one cursor">
                                                {{ index + 1 }}. {{ item.fileName }}
                                            </div>
                                            <el-icon @click="xz(item)" style="color: #409efe;" class="cursor ml15">
                                                <Download />
                                            </el-icon>
                                        </div>
                                    </div>
                                </div>
                                <template #reference>
                                    <div style="color: #409efe;" class="cursor">
                                        {{ scope.row.caFileList.length + scope.row.gaFileList.length +
                                            scope.row.ytFileList.length }}
                                    </div>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column v-has-btn="'1,2,3,4,5'" fixed="right" width="610" label="操作"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button size="mini" type="primary" icon="" v-if="scope.row.status == 1"
                                @click="handleSubmit(scope.row)">开始备课
                            </el-button>
                            <el-button v-if="scope.row.status == 2 && scope.row.role == 1" type="primary" size="mini"
                                icon="" @click="handleCA(scope.row)">上传初案
                            </el-button>
                            <el-button v-if="scope.row.status == 3 && scope.row.role == 1" type="primary" size="mini"
                                icon="" @click="handleYT(scope.row)">上传修正案
                            </el-button>
                            <el-button size="mini" type="primary" icon=""
                                v-if="scope.row.status == 3 && scope.row.role == 1" @click="handleSubmit(scope.row)">开始协同
                            </el-button>
                            <el-button size="mini"
                                v-if="(scope.row.status == 2 || scope.row.status == 4) && (scope.row.role == 1 || scope.row.role == 2)"
                                type="primary" icon="" @click="handleDiscuss(scope.row)">协同备课
                            </el-button>
                            <el-button size="mini" v-if="scope.row.status == 2 || scope.row.status == 4" type="primary"
                                icon="" @click="handleStop(scope.row)">停止协同
                            </el-button>
                            <el-button
                                v-if="(scope.row.status == 5 || scope.row.status == 3 || scope.row.status == 8) && scope.row.role == 1"
                                type="primary" size="mini" icon="" @click="handleGA(scope.row)">上传共案
                            </el-button>
                            <el-button size="mini" type="primary" icon="el-icon-edit" @click="handleUpdate(scope.row)">编辑
                            </el-button>
                            <el-button v-if="scope.row.status == 5 || scope.row.status == 3 || scope.row.status == 8"
                                type="primary" style="" size="mini" icon="" @click="handleRelease(scope.row)">发布共案
                            </el-button>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该备课" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button v-has-btn="'1,2,3'" size="mini" type="danger" icon="el-icon-delete">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                v-if="scope.row.isCreate" icon-color="#626AEF" title="确定删除该备课"
                                @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button v-has-btn="'4,5'" size="mini" type="danger" icon="el-icon-delete">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                    <el-table-column v-has-btn="'6'" fixed="right" width="440" label="操作"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button v-if="scope.row.status == 2 && scope.row.role == 1" type="primary" size="mini"
                                icon="" @click="handleCA(scope.row)">上传初案
                            </el-button>
                            <el-button v-if="scope.row.status == 3 && scope.row.role == 1" type="primary" size="mini"
                                icon="" @click="handleYT(scope.row)">上传修正案
                            </el-button>
                            <el-button size="mini" type="primary" icon=""
                                v-if="scope.row.status == 3 && scope.row.role == 1" @click="handleSubmit(scope.row)">开始协同
                            </el-button>
                            <el-button size="mini"
                                v-if="(scope.row.status == 2 || scope.row.status == 4) && (scope.row.role == 1 || scope.row.role == 2)"
                                type="primary" icon="" @click="handleDiscuss(scope.row)">协同备课
                            </el-button>
                            <el-button size="mini"
                                v-if="(scope.row.status == 2 || scope.row.status == 4) && scope.row.role == 1"
                                type="primary" icon="" @click="handleStop(scope.row)">停止协同
                            </el-button>
                            <el-button
                                v-if="(scope.row.status == 5 || scope.row.status == 3 || scope.row.status == 8) && scope.row.role == 1"
                                type="primary" size="mini" icon="" @click="handleGA(scope.row)">上传共案
                            </el-button>
                            <el-popconfirm v-if="scope.row.isCreate" confirm-button-text="是" cancel-button-text="否"
                                :icon="InfoFilled" icon-color="#626AEF" title="确定删除该备课" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button size="mini" type="danger" icon="el-icon-delete">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy" v-if="!isSHowMine">
                <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                    :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>
        <el-dialog :title="selectRow.title + '上传初案'" v-model="scCa" width="60%" @close="getList()">
            <el-form ref="scgaform" :model="selectRow" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="教案" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.jaFiles" class="upload-demo"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadFile" :headers="{ token }"
                                :on-remove="handleRemove"
                                :data="{ fileType: 1, lessonType: 0, lessionId: selectRow.lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学案" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.xaFiles" class="upload-demo"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadFile" :headers="{ token }"
                                :on-remove="handleRemove"
                                :data="{ fileType: 4, lessonType: 0, lessionId: selectRow.lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课件" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.kjFiles" class="upload-demo"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadFile" :headers="{ token }"
                                :on-remove="handleRemove"
                                :data="{ fileType: 2, lessonType: 0, lessionId: selectRow.lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="习题" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.xtFiles" class="upload-demo"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadFile" :headers="{ token }"
                                :on-remove="handleRemove"
                                :data="{ fileType: 3, lessonType: 0, lessionId: selectRow.lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        <el-dialog :title="selectRow.title + '上传修正案'" v-model="scYt" width="60%" @close="getList()">
            <el-form ref="scgaform" :model="selectRow" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="教案" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.ytjaFiles" class="upload-demo"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadFile" :headers="{ token }"
                                :on-remove="handleRemove"
                                :data="{ fileType: 1, lessonType: 2, lessionId: selectRow.lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学案" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.ytxaFiles" class="upload-demo"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadFile" :headers="{ token }"
                                :on-remove="handleRemove"
                                :data="{ fileType: 4, lessonType: 2, lessionId: selectRow.lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课件" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.ytkjFiles" class="upload-demo"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadFile" :headers="{ token }"
                                :on-remove="handleRemove"
                                :data="{ fileType: 2, lessonType: 2, lessionId: selectRow.lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="习题" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.ytxtFiles" class="upload-demo"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadFile" :headers="{ token }"
                                :on-remove="handleRemove"
                                :data="{ fileType: 3, lessonType: 2, lessionId: selectRow.lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        <el-dialog :title="selectRow.title + '上传共案'" v-model="scGa" width="60%">
            <el-form ref="scgaform" :model="selectRow" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="共案封面" prop="bannerUrl">
                            <el-upload class="avatar-uploader"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadCover" :show-file-list="false"
                                :on-success="handleAvatarSuccess" :headers="{ token }"
                                :data="{ lessonId: selectRow.lessonId }" :before-upload="beforeAvatarUpload">
                                <img v-if="selectRow.coverUrl" :src="selectRow.coverUrl" class="avatar"
                                    style="position:absolute" />
                                <el-icon class="avatar-uploader-icon" style="font-size:100px;">
                                    <Plus />
                                </el-icon>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="教案" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.gajaFiles" class="upload-demo"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadFile"
                                :on-change="handleGAJaChange" :headers="{ token }" :on-remove="handleRemove"
                                :data="{ fileType: 1, lessonType: 1, lessionId: selectRow.lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学案" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.gaxaFiles" class="upload-demo"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadFile"
                                :on-change="handleGAxaChange" :headers="{ token }" :on-remove="handleRemove"
                                :data="{ fileType: 4, lessonType: 1, lessionId: selectRow.lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课件" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.gakjFiles" class="upload-demo"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadFile"
                                :on-change="handleGAkjChange" :headers="{ token }" :on-remove="handleRemove"
                                :data="{ fileType: 2, lessonType: 1, lessionId: selectRow.lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="习题" prop="title">
                            <el-upload v-if="!loading" v-model:file-list="selectRow.gaxtFiles" class="upload-demo"
                                action="https://www.jingzhunjy.com/businessservice/lesson/uploadFile"
                                :on-change="handleGAxtChange" :headers="{ token }" :on-remove="handleRemove"
                                :data="{ fileType: 3, lessonType: 1, lessionId: selectRow.lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        <el-dialog class="zdylesson" :title="selectRow.title + '可查看人员'" v-model="ckPeople" width="60%">
            <!-- <div style="transform: translateY(-30px);">
                <el-checkbox v-model="qxchecked" @change='selectAll'>全选</el-checkbox>
                <el-checkbox @change="changezbr" v-model="zbr" label="主备人" size="large" />
                <el-checkbox @change="changexbr" v-model="xbr" label="协备人" size="large" />
            </div> -->
            <!-- <div style="height: 30vh;transform: translateY(-20px);">
                <el-select @change="changeckPeo" clearable filterable v-model="ckPeopleList" multiple placeholder="请选择查看人员"
                    style="width: 100%;">
                    <el-option v-for="item in userList" :key="item.userId" :label="item.trueName" :value="item.userId" />
                </el-select>
            </div> -->
            <choosePeople :noShowIdList="noShowIdList" v-if="ckPeople" :showTitle="'选择人员'" ref="ckPeople" />
            <div class="mt5" style="color: red;">*主备人、协备人、指导专家默认可查看。</div>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitCkPeopleForm">确 定</el-button>
                    <el-button @click="closeCkPeople">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { orgListTree, groupListAll, userListOrgUsers, userListOrgMember } from "@/api/admin/system";

import { newsDelete } from "@/api/admin/news";
import { lessonList, lessonPageLessons, lessonListJoin, lessonSubmit, lessonStop, lessonRelease, lessonGaFiles, delLessonFile, delLesson } from "@/api/admin/lesson"
import {
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryDict, userList } from "@/api/admin/public";
import { download } from "@/api/adminservice/public";

import { print } from "@/api/adminservice/form"

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            role: this.$storage.get("role"),
            isSHowMine: false,
            userList: [],
            ckPeopleList: [],
            noShowIdList: [],
            ckPeople: false,
            selectRow: {},
            scCa: false,
            scGa: false,
            scYt: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            gAFileList: [],
            token: this.$storage.get("token"),
            natureList: [],
            statusList: [
                { name: '初始', value: 1 },
                { name: '一轮研讨', value: 2 },
                { name: '初案修正', value: 3 },
                { name: '二轮研讨', value: 4 },
                { name: '共案修正', value: 5 },
                { name: '发布待审核', value: 6 },
                { name: '审核通过', value: 7 },
                { name: '审核拒绝', value: 8 },
                { name: '结束', value: 9 },

            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                status: '',
                title: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonNature: '',
                pageNum: 1,
                pageSize: 10,
                type: 0
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            zbr: false,
            xbr: false,
            qxchecked: false,
            addPeoplesssOpen: false,
            orgList: [],
            defaultProps: {
                children: 'groupList',
                label: 'title',
            },
            peopletitle: '',
            peoList: [],
            peoOldList: [],
            checkList: [],
            isAllCative: false,
        };
    },
    created() {
        if (this.$storage.get("role") == 6) {
            this.isSHowMine = true;
        }
        this.getQueryDict();
        this.getList();
    },
    activated() {

        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        xz(e) {
            download(e.fileUrl, e.fileName)
        },
        tableRowClassName: function (row, index) {
            console.log(row.row)
            if (!row.row.isMine) {
                return 'hidden-row';
            }
            return '';
        },
        tagClose(tag) {
            this.checkList.forEach((e, i) => {
                if (tag.userId == e.userId) {
                    this.checkList.splice(i, 1)
                }
            })
        },
        openPeople() {
            this.peoList.forEach(e => {
                e.checked = false;
                e.disabled = false;
                this.checkList.forEach(j => {
                    if (j.userId == e.userId) {
                        e.checked = true;
                    }
                })
            })
            this.addPeoplesssOpen = true;
            var isAllCative = false;
            for (var i = 0; i < this.peoList.length; i++) {
                if (this.peoList[i].checked) {
                    isAllCative = true;
                } else {
                    isAllCative = false;
                    break
                }
            }
            this.isAllCative = isAllCative;
        },
        changeAllCative() {
            this.peoList.forEach((j, k) => {
                this.checkList.forEach((e, i) => {
                    if (j.userId == e.userId) {
                        this.checkList.splice(i, 1)
                    }
                })
            })
            this.isAllCative = !this.isAllCative;
            if (!this.isAllCative) {

            } else {
                this.peoList.forEach((j, k) => {
                    this.checkList.push({ userId: j.userId, trueName: j.trueName, username: j.username })
                })
            }
            for (var i = 0; i < this.peoList.length; i++) {
                this.peoList[i].checked = this.isAllCative;
            }
        },
        getPeotttList() {
            this.peoList = this.peoOldList;
            if (this.peopletitle !== '') {
                this.peoList = this.peoList.filter(itemA => {
                    return itemA.trueName.indexOf(this.peopletitle) != -1
                })
            } else {
                this.peoList = this.peoOldList;
            }
        },
        checkChange(item) {
            if (item.disabled == false) {
                item.checked = !item.checked;
                if (!item.checked) {
                    this.checkList.forEach((e, i) => {
                        if (item.userId == e.userId) {
                            this.checkList.splice(i, 1)
                        }
                    })
                } else {
                    this.checkList.push({ userId: item.userId, trueName: item.trueName, username: item.username })
                }
            }
            var isAllCative = false;
            for (var i = 0; i < this.peoList.length; i++) {
                if (this.peoList[i].checked) {
                    isAllCative = true;
                } else {
                    isAllCative = false;
                    break
                }
            }
            this.isAllCative = isAllCative;
        },
        handleNodeClick(e, i) {
            this.peoOldList = [];
            console.log(e, i)
            if (!e.groupId) {
            } else {
                userListOrgMember({ groupId: e.groupId }).then(res => {
                    res.data.data.forEach(e => {
                        e.checked = false;
                        e.disabled = false;
                        this.checkList.forEach(j => {
                            if (j.userId == e.userId) {
                                e.checked = true;
                            }
                        })
                    })
                    this.peoList = res.data.data;
                    this.peoOldList = res.data.data;
                })
            }
        },
        changeSchool(e) {
            queryOrgTreeList({
                schoolId: e
            }).then(res => {
                this.teacherChooseList = res.data.data;
            })
        },
        submitCkPeopleForm() {
            var checkList = []
            this.$refs.ckPeople.checkList.forEach(e => {
                checkList.push(e.userId)
            })
            lessonRelease({ lessonId: this.selectRow.lessonId, viewUsers: checkList.toString() }).then(res => {
                if (res.data.code == 1) {
                    this.ckPeople = false;
                    this.ckPeopleList = [];
                    this.$message.success("已发布，等待审核");
                    this.getList();
                } else {
                    this.$message.error("发布失败");
                }
            })
        },
        closeCkPeople() {
            this.ckPeople = false;
        },
        goFileDetail(item) {
            console.log(item);
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleDiscuss(row) {
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: row.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleExceed(e) {
            console.log(e)
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.url) {
                delLessonFile({ fileUrl: file.url })
            }
            if (file.response) {
                delLessonFile({ fileUrl: file.response.data })
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.selectRow.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'activity_nature' }).then(res => {
                this.natureList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAJaChange(e, type) {
            console.log(this.selectRow.gajaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gajaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleJaChange(e, type) {
            console.log(this.selectRow.jaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.jaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxaChange(e, type) {
            console.log(this.selectRow.gaxaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gaxaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAkjChange(e, type) {
            console.log(this.selectRow.gakjFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gakjFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxtChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gaxtFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || rawFile.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || rawFile.type == 'application/msword')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx,.doc,.docx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleCA(row) {
            this.scCa = true;
            this.selectRow = row;
        },
        handleYT(row) {
            this.scYt = true;
            this.selectRow = row;
        },
        handleGA(row) {
            this.scGa = true;
            this.selectRow = row;
            console.log(this.selectRow)
            if (this.selectRow.coverUrl) {

            } else {
                this.selectRow.coverUrl = ''
            }
            // lessonGaFiles({lessonId:row.lessonId}).then(res=>{

            // })
        },
        hadleDz() {
            if (!this.selectObj || !this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.status == 4 || this.selectObj.status == 5) {
                this.$message.error("发布和停用的数据不能铺设");
                return;
            }
            print({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("铺设成功");
                    this.getList();
                } else {
                    this.$message.error("铺设失败");
                }
            })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.dataList = [];
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            if (!this.isSHowMine) {
                lessonPageLessons(this.queryParams).then(res => {
                    this.total = res.data.data.total;
                    var userId = JSON.parse(this.$storage.get("userInfo")).userId
                    res.data.data.list.forEach(e => {
                        var textList = [];
                        var isMine = false;
                        e.teamUserList.forEach(j => {
                            textList.push(j.trueName)
                            if (userId == j.userId) {
                                e.isTeam = true;
                                isMine = true;
                            }
                        })
                        console.log(textList)
                        e.teamUserText = textList.join('、')
                        var guideUserList = []
                        e.guideUserList.forEach(j => {
                            guideUserList.push(j.trueName)
                            if (userId == j.userId) {
                                e.isGuide = true;
                                isMine = true;
                            }
                        })
                        e.guideUserText = guideUserList.join('、')
                        if (userId == e.leadUser) {
                            e.isLead = true;
                            isMine = true;
                        }
                        console.log(e.teamUserText)
                        e.jaFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.xaFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.kjFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.xtFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.ytjaFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.ytxaFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.ytkjFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.ytxtFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.gajaFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.gaxaFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.gakjFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.gaxtFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.isMine = isMine;
                        if (this.selectRow && this.selectRow.lessonId == e.lessonId) {
                            this.selectRow = e
                        }
                    })

                    this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
                    this.dataList.forEach(e => {

                        if (e.createUser == userId) {
                            e.isCreate = true;
                        }
                        e.caFileList = ((e.jaFiles.concat(e.kjFiles)).concat(e.xtFiles)).concat(e.xaFiles)
                        e.gaFileList = ((e.gajaFiles.concat(e.gakjFiles)).concat(e.gaxtFiles)).concat(e.gaxaFiles)
                        e.ytFileList = ((e.ytjaFiles.concat(e.ytkjFiles)).concat(e.ytxtFiles)).concat(e.ytxaFiles)
                        e.isteamUser = false;
                        e.teamUserList.forEach(j => {
                            if (j.userId == userId) {
                                e.isteamUser = true;
                            }
                        })
                        if (e.isLead) {
                            e.role = 1;
                        } else {
                            if (e.isGuide || e.isTeam) {
                                e.role = 2;
                            }
                        }
                    })
                    console.log(this.selectRow)

                    this.loading = false;
                });
            } else {
                lessonListJoin().then(res => {
                    if (this.queryParams.periodKey !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.periodKey == this.queryParams.periodKey
                        })
                    }
                    if (this.queryParams.lessonNature !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.lessonNature == this.queryParams.lessonNature
                        })
                    }
                    if (this.queryParams.gradeKey !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.gradeKey == this.queryParams.gradeKey
                        })
                    }
                    if (this.queryParams.subjectKey !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.subjectKey == this.queryParams.subjectKey
                        })
                    }
                    if (this.queryParams.status !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.status == this.queryParams.status
                        })
                    }
                    if (this.queryParams.title !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.title.toString().indexOf(this.queryParams.title) != -1
                        })
                    }
                    var userId = JSON.parse(this.$storage.get("userInfo")).userId
                    res.data.data.forEach(e => {
                        var textList = [];
                        var isMine = false;
                        e.teamUserList.forEach(j => {
                            textList.push(j.trueName)
                            if (userId == j.userId) {
                                e.isTeam = true;
                                isMine = true;
                            }
                        })
                        console.log(textList)
                        e.teamUserText = textList.join('、')
                        var guideUserList = []
                        e.guideUserList.forEach(j => {
                            guideUserList.push(j.trueName)
                            if (userId == j.userId) {
                                e.isGuide = true;
                                isMine = true;
                            }
                        })
                        e.guideUserText = guideUserList.join('、')
                        if (userId == e.leadUser) {
                            e.isLead = true;
                            isMine = true;
                        }
                        console.log(e.teamUserText)
                        e.jaFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.xaFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.kjFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.xtFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.ytjaFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.ytxaFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.ytkjFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.ytxtFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.gajaFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.gaxaFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.gakjFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.gaxtFiles.forEach(m => {
                            m.name = m.fileName
                            m.url = m.fileUrl
                        })
                        e.isMine = isMine;
                        if (this.selectRow && this.selectRow.lessonId == e.lessonId) {
                            this.selectRow = e
                        }
                    })

                    this.dataList = JSON.parse(JSON.stringify(res.data.data));
                    this.dataList.forEach(e => {

                        if (e.createUser == userId) {
                            e.isCreate = true;
                        }
                        e.caFileList = ((e.jaFiles.concat(e.kjFiles)).concat(e.xtFiles)).concat(e.xaFiles)
                        e.gaFileList = ((e.gajaFiles.concat(e.gakjFiles)).concat(e.gaxtFiles)).concat(e.gaxaFiles)
                        e.ytFileList = ((e.ytjaFiles.concat(e.ytkjFiles)).concat(e.ytxtFiles)).concat(e.ytxaFiles)
                        e.isteamUser = false;
                        e.teamUserList.forEach(j => {
                            if (j.userId == userId) {
                                e.isteamUser = true;
                            }
                        })
                        if (e.isLead) {
                            e.role = 1;
                        } else {
                            if (e.isGuide || e.isTeam) {
                                e.role = 2;
                            }
                        }
                    })
                    console.log(this.selectRow)

                    this.loading = false;
                })
            }

        },
        /** 删除按钮操作 */
        handleDelete(row) {
            delLesson({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/lessonDetail",
                query: {
                    lessonId: row.lessonId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/lessonAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        selectAll() {
            this.ckPeopleList = []
            if (this.qxchecked) {
                this.zbr = true
                this.xbr = true
                this.userList.map((item) => {
                    this.ckPeopleList.push(item.userId)
                })
            } else {
                this.zbr = false
                this.xbr = false
                this.ckPeopleList = []
            }
        },
        changezbr() {
            if (this.zbr) {
                if (this.ckPeopleList.indexOf(this.selectRow.leadUser) == -1) {
                    this.ckPeopleList.push(this.selectRow.leadUser);
                }
            } else {
                this.ckPeopleList.splice(this.ckPeopleList.indexOf(this.selectRow.leadUser), 1);
            }
        },
        changexbr() {
            if (this.xbr) {
                this.selectRow.teamUserList.forEach(e => {
                    if (this.ckPeopleList.indexOf(e.userId) == -1) {
                        this.ckPeopleList.push(e.userId);
                    }

                })
            } else {
                this.selectRow.teamUserList.forEach(e => {
                    this.ckPeopleList.splice(this.ckPeopleList.indexOf(e.userId), 1);
                })
            }
        },
        changeckPeo(e) {
            if (this.ckPeopleList.indexOf(this.selectRow.leadUser) == -1) {
                this.zbr = false;
            } else {
                this.zbr = true;
            }
            this.selectRow.teamUserList.forEach(e => {
                if (this.ckPeopleList.indexOf(e.userId) == -1) {
                    this.xbr = false;
                    throw new Error()
                } else {
                    this.xbr = true;
                }
            })
            console.log(e);
            console.log(this.ckPeopleList);
        },
        handleRelease(row) {
            this.noShowIdList = []
            row.guideUserList.forEach(e => {
                this.noShowIdList.push(e.userId)
            })
            row.teamUserList.forEach(e => {
                this.noShowIdList.push(e.userId)
            })
            this.noShowIdList.push(row.leadUser)
            console.log(row);
            if (!row.coverUrl) {
                this.$message.error("请先上传公案封面")
                return
            }
            if (row.gaFileList.length == 0) {
                this.$message.error("请先上传公案课件")
                return
            }
            this.selectRow = row;
            this.checkList = [];
            this.peoOldList = [];
            this.peoList = [];
            userListOrgUsers().then(res => {
                res.data.data.orgList.forEach(e => {
                    e.title = e.orgName;
                    if (e.groupList) {
                        e.groupList.forEach(j => {
                            j.title = j.groupName
                        })
                    }
                })
                this.orgList = res.data.data.orgList;
                this.ckPeople = true;
            })


            // this.addPeoplesssOpen = true;

            // userList().then(res => {
            //     this.userList = res.data.data;
            // })
            // this.zbr = true;
            // this.xbr = true;
            // this.ckPeople = true;
            // console.log(this.selectRow);
            // this.leadUser;
            // this.teamUserList
            // this.ckPeopleList.push(this.selectRow.leadUser);
            // this.selectRow.teamUserList.forEach(e => {
            //     if (this.ckPeopleList.indexOf(e.userId) == -1) {
            //         this.ckPeopleList.push(e.userId);
            //     }

            // })
        },
        handleSubmit(row) {
            console.log(row)
            // if (row.jaFiles.length == 0 && row.kjFiles.length == 0 && row.xtFiles.length == 0) {
            //     this.$message.error("请先上传文件");
            //     return
            // }
            if (row.status == 3) {
                if (row.ytjaFiles.length == 0 && row.ytkjFiles.length == 0 && row.ytxtFiles.length == 0) {
                    this.$message.error("请先上传修正文件");
                    return
                }
            }
            lessonSubmit({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("开始成功");
                    this.getList();
                } else {
                    this.$message.error("提请失败");
                }
            })
        },
        handleStop(row) {
            lessonStop({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停止成功");
                    this.getList();
                } else {
                    this.$message.error("停止失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/lessonAdd",
                query: {
                    lessonId: row.lessonId,
                }
            });
        }
    }
};
</script>
<style scoped>
.zdylesson /deep/ .el-dialog__body {
    padding-top: 0px !important;
}

.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}

.el-table /deep/ .hidden-row {
    display: none;
}</style>