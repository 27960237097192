<template>
    <div id="" class="" :style="'margin:0 auto;width: ' + imwidth + 'px;height: ' + imheight + 'px;position: relative;'">

        <img :style="'pointer-events: none;position:absolute;border:0px solid #eeeeee;border-radius:10px;width:' + imwidth + 'px;left:0;height:' + imheight + 'px'"
            :src="picSrc" alt="">
        <canvas :width="imwidth * smScale" :height="imheight * smScale" type="2d" :id="canvasid"
            :style="'pointer-events: none;position: absolute;width:' + imwidth + 'px;height:' + imheight + 'px;z-index: 1000;left:0;'">
        </canvas>
        <img v-if="isShow" v-for="items in imgList"
            :style="'width: ' + items.w0 + 'px;height: ' + items.h0 + 'px;position: absolute;left: ' + items.x0 + 'px;top: ' + items.y0 + 'px;transform: rotate(' + items.rotate + 'deg);z-index:' + items.z + ';pointer-events: none;'"
            :src="items.illustrationUrl">
    </div>
</template>
<script>

export default {
    name: 'perviewCanvas',
    props: {
        canvasid: '',
        picSrc: '',
        strokeList: {
            type: Array,
            default: []
        },
        xS:{
            type: Number,
            default: 0
        },
        yS:{
            type: Number,
            default: 0
        },
        bookData: {
            type: Object,
            default: {}
        },
        imwidth: {
            type: Number,
            default: 0
        },
        imheight: {
            type: Number,
            default: 0
        },
        detail: {
            type: Array,
            default: []
        },
        imgList: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            canvasHeight: 0,
            scale: 1,
            smScale: 2,
            current: 0,
            ctxList: [],
            clickPage: [],
            isShow: false,
            ctx: null,

        }
    },
    created() {
        // 监听网页大小
        // this.bookData = JSON.parse(this.$storage.get("zsdata"));
        // this.strokeList = JSON.parse(this.$storage.get("zsdata")).strokeList;
    },
    mounted() {
        this.scale = (this.imwidth / this.bookData.pageWidth / 8 * 0.3)
        setTimeout(() => {
            // console.log(this.xS,this.yS)
            this.imgList.forEach((e, i) => {
                e.x0 = e.x / this.bookData.pageWidth * this.imwidth;
                e.y0 = e.y / this.bookData.pageHeight * this.imheight;
                e.w0 = e.width / this.bookData.pageWidth * this.imwidth;
                e.h0 = e.height / this.bookData.pageHeight * this.imheight;
            })
            setTimeout(() => {
                this.isShow = true
            })

            this.startCanvas()
        });
    },
    methods: {
        closedd() {
            this.$emit('canvasClose', true)
        },
        lastPage() {
            if (this.current > 0) {
                this.current--;
                this.translateX = this.current * this.swiperWidth;
                if (this.clickPage.indexOf(this.current) === -1) {
                    this.canvasToStrokePointList(this.current)
                }
            }
        },
        nextPage() {
            if (this.current < this.strokeList.length - 1) {
                this.current++;
                this.translateX = this.current * this.swiperWidth;
                if (this.clickPage.indexOf(this.current) === -1) {
                    this.canvasToStrokePointList(this.current)
                }
            }
        },
        // 监听网页大小
        watchWidth() {

        },
        // 画布初始化
        startCanvas() {

            var canvas = document.getElementById(this.canvasid);
            // 获取在 canvas 上绘图的 canvasRenderingContent2D 对象
            this.ctx = canvas.getContext("2d");
            this.ctx.strokeStyle = this.$store.state.zdy.activeColor;
            this.ctx.lineWidth = (this.$store.state.zdy.activeLineWidth / this.bookData.pageWidth * this.imwidth) / 2.5;
            this.ctx.lineCap = 'round';
            this.ctx.lineJoin = 'round';
            this.canvasToStrokePointList()
        },
        //Canvas字符串转化为笔迹点列表
        canvasToStrokePointList() {
            try {
                console.log(this.detail)
                let list = this.emptyRepeatStroke(this.detail);
                // console.log(list)
                list.forEach((j, k) => {
                    this.ctx.strokeStyle = (j.c)
                    this.ctx.lineWidth = (j.t / this.bookData.pageWidth * this.imwidth) / 15

                    this.getPoints(k, j.p)
                })
            } catch (e) {
                console.log('e',e)
                //TODO handle the exception
            }
        },
        // 去除重复笔迹
        emptyRepeatStroke(arr) {
            const res = new Map();
            return arr.filter((arr) => !res.has(arr.p) && res.set(arr.p, 1))
        },
        // 笔迹转点
        getPoints(n, svgPointsPath) {
            var points = [];
            var dataArray = svgPointsPath.split("l");

            if (dataArray.length == 2) {
                var firstPontArray = dataArray[0].replace(/M/, "").split(" ");
                var firstPointX = Math.round(firstPontArray[0].trim()) - (this.xS*(1/0.0375));
                var firstPointY = Math.round(firstPontArray[1].trim()) - (this.yS*(1/0.0375));
                // console.log(firstPointX,firstPointY)
                // debugger
                points.push({
                    x: firstPointX,
                    y: firstPointY
                })
                var pointsArray = dataArray[1].replace("l", "").replace(/-/g, " -").trim().split(" ");
                for (var i = 0; i < pointsArray.length; i++) {
                    if ((i + 1) < pointsArray.length && i % 2 == 0) {
                        firstPointX = Math.round(firstPointX) + Math.round(pointsArray[i].trim());
                        firstPointY = Math.round(firstPointY) + Math.round(pointsArray[i + 1].trim());
                        points.push({
                            x: firstPointX,
                            y: firstPointY
                        })
                    }

                }
            }
            this.detail[n].stList = points;
            this.drowStroke(points)
        },
        // 点集绘制笔记
        drowStroke(pointList) {
            this.ctx.beginPath();

            let pointLast = {};
            let pointNext = {};
            if (pointList.length > 1) {
                for (var i = 0; i < pointList.length; i++) {
                    if ((i + 1) < pointList.length) {
                        pointLast = pointList[i];
                        pointNext = pointList[i + 1];
                    } else {
                        pointLast = pointList[i - 1];
                        pointNext = pointList[i];
                    }
                    // 笔记粗细
                    // this.ctx2.lineWidth(1);
                    let pX1 = pointLast.x * this.scale * this.smScale;
                    let pY1 = pointLast.y * this.scale * this.smScale;
                    let pX2 = pointNext.x * this.scale * this.smScale;
                    let pY2 = pointNext.y * this.scale * this.smScale;
                    if (i === 0) {
                        this.ctx.moveTo(pX1, pY1);
                    } else {
                        // 笔迹
                        this.ctx.moveTo(pX1, pY1);
                        this.ctx.lineTo(pX2, pY2);
                    }
                }
            }
            this.ctx.stroke();
        },
    }
}
</script>

<style scoped>
.bgbbb {
    /* background-color: #fff; */
    z-index: 999;
    position: absolute;
}

.op0 {
    opacity: 0;
}

.op1 {
    opacity: 1;
}
</style>