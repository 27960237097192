<template>
    <div class="app-container">
        <el-card shadow="never">
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class=" mb15">
                    <el-col :span="8" class="mb0">
                        <el-form-item label="所属组织">
                            <el-tree-select @change="getList" clearable
                                :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                                v-model="queryParams.orgId" default-expand-all :data="treeData" check-strictly
                                :render-after-expand="true" />
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="10" class="mb0">
                    <el-form-item label="时间">
                        <el-date-picker :disabled-date="disabledDate" v-model="queryParams.rzTime" format="YYYY/MM/DD"
                            value-format="x" clearable @change="getList" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col> -->
                    <el-col :span="8" class="mb0">
                        <el-radio-group @change="changeTableType" v-model="queryParams.tableType" size="mini">
                            <el-radio-button label="按工作室分" />
                            <el-radio-button label="按组织分" />
                        </el-radio-group>
                    </el-col>
                    <el-col :span="8" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-button v-has-btn="'1,2,3'" style="" @click="changeZxtType" class="mb0 mr0 pull-right"
                                type="primary" size="mini">柱状图</el-button>
                            <el-button @click="handleExport" type="primary" size="mini">导出</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip height="calc(100vh - 190px)"
                    v-loading="loading" :data="dataList" highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column v-if="queryParams.tableType == '按工作室分'" width="350" label="工作室" prop="groupName">
                        <template #default="scope">
                            <div :title="scope.row.groupName" class="text-one">
                                {{ scope.row.groupName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="queryParams.tableType == '按工作室分' ? '所属组织' : '组织名称'" prop="orgName">
                        <template #default="scope">
                            <div :title="scope.row.orgName" class="text-one">
                                {{ scope.row.orgName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="90" label="协同备课" prop="xtbkAmount">
                        <template #default="scope">
                            <div @click="toBk(scope.row, 'xtbk')" style="color: #409efe;" class="text-one cursor">{{
                                scope.row.xtbkAmount }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="90" label="课题引领" prop="ktylAmount">
                        <template #default="scope">
                            <div @click="toBk(scope.row, 'ktyl')" style="color: #409efe;" class="text-one cursor">{{
                                scope.row.ktylAmount }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="90" label="教研赛事" prop="ykdsAmount">
                        <template #default="scope">
                            <div @click="toBk(scope.row, 'jyss')" style="color: #409efe;" class="text-one cursor">{{
                                scope.row.ykdsAmount }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="90" label="专题活动" prop="zthdAmount">
                        <template #default="scope">
                            <div @click="toBk(scope.row, 'zthd')" style="color: #409efe;" class="text-one cursor">{{
                                scope.row.zthdAmount }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-dialog :title="'自定义柱状图'" v-model="zxtTypeOpen" width="60%">
                <el-form-item label="按所属组织统计" label-width="130px">
                    <el-tree-select @change="changeZxtOrg" clearable multiple filterable style="width: 100%;"
                        :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                        v-model="zxtQueryParams.orgIdList" default-expand-all :data="treeData" check-strictly
                        :render-after-expand="true" />
                </el-form-item>
                <el-form-item label="按所选工作室统计" label-width="130px">
                    <el-select @change="changeZxtUser" style="width: 100%;" multiple filterable
                        v-model="zxtQueryParams.groupIdList" placeholder="请选择工作室">
                        <el-option v-for="item in groupIdList" :key="item.groupId" :label="item.groupName"
                            :value="item.groupId" />
                    </el-select>
                </el-form-item>
                <template #footer>
                    <div class="disflex-row-center">
                        <el-button type="primary" @click="submitXtTyp">确 定</el-button>
                        <el-button @click="zxtTypeOpen = false">取 消</el-button>
                    </div>
                </template>
            </el-dialog>
            <el-dialog v-if="zxtOpen" :title="'柱状图'" v-model="zxtOpen" width="70%">
                <div id="grbk" style="width: 100%;height:50vh;">
                </div>
            </el-dialog>
            <el-dialog :title="lookType == 'xtbk' ? '备课' : lookType == 'ktyl' ? '课题' : lookType == 'jyss' ? '赛事' : '活动'"
                v-model="bkOpen" width="700px">
                <el-table show-overflow-tooltip @current-change="handleCurrentChange" class="" height="calc(100vh - 500px)"
                    border v-loading="loading"
                    :data="lookType == 'xtbk' ? selectObj.xtbkList : lookType == 'ktyl' ? selectObj.ktylList : lookType == 'jyss' ? selectObj.ykdsList : lookType == 'zthd' ? selectObj.zthdList : []"
                    highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column width="300"
                        :label="lookType == 'xtbk' ? '备课标题' : lookType == 'ktyl' ? '课题名称' : lookType == 'jyss' ? '赛事名称' : '活动名称'"
                        prop="title">
                        <template #default="scope">
                            <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.title }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="lookType == 'xtbk'" width="300" label="任务时间" prop="applyTime">
                        <template #default="scope">
                            <div v-if="queryParams.rzTime && queryParams.rzTime.length != 0">
                                <span :style="scope.row.beginTime < queryParams.rzTime[0] ? 'color:red' : ''">{{
                                    timestampToTime(scope.row.beginTime) }}</span> - <span
                                    :style="scope.row.endTime > queryParams.rzTime[1] ? 'color:red' : ''">{{
                                        timestampToTime(scope.row.endTime) }}</span>
                            </div>
                            <div v-else>
                                {{ timestampToTime(scope.row.beginTime) }} - {{ timestampToTime(scope.row.endTime) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column v-else width="300" label="申请时间" prop="applyTime">
                        <template #default="scope">
                            <div>
                                {{ timestampToTime(scope.row.applyTime) }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>
        </el-card>
    </div>
</template>

<script>
import { statsTopic, statsRace, statsActivity, statsTrain, statsPrincipalStudio, statsTeacherStudio } from "@/api/admin/statistics";
import { orgListTree } from "@/api/admin/system";
import table2excel from 'js-table2excel';
import { timestampToTime } from "@/utils/date";
import echarts from "echarts";

export default {
    name: "teachStudio",
    components: {
    },
    data() {
        return {
            zxtOpen: false,
            cativeType: '课题引领',
            zxtTypeOpen: false,
            bkOpen: false,
            statusList: [
                { name: '未发布', value: 0 },
                { name: '已发布', value: 1 },
                { name: '隐藏', value: 2 },
            ],
            treeData: [],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 查询参数
            queryParams: {
                tableType: '按工作室分',
                orgId: '',
                rzTime: [],
            },
            zxtQueryParams: {
                orgIdList: [],
                groupIdList: [],
            },
            selectObj: [],
            grbkOption: {
                color: ['#3398DB'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: [],
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '协同备课',
                        type: 'bar',
                        barMaxWidth: 30,
                        data: []
                    },
                    {
                        name: '课题引领',
                        type: 'bar',
                        barMaxWidth: 30,
                        data: []
                    },
                    {
                        name: '教研赛事',
                        type: 'bar',
                        barMaxWidth: 30,
                        data: []
                    },
                    {
                        name: '专题活动',
                        type: 'bar',
                        barMaxWidth: 30,
                        data: []
                    },
                ]
            },
            userList: [],
            lookType: null,
        };
    },
    created() {
        this.getOrgListTree();
        this.getList();
    },
    mounted() {
        // this.getGrbkOption()
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        changeTableType() {
            this.getList();
        },
        changeCativeType(type) {
            console.log(type);
            this.cativeType = type;
            this.queryParams = {
                tableType: '按工作室分',
                orgId: '',
                rzTime: [],
            };
            this.zxtQueryParams = {
                orgIdList: [],
                groupIdList: [],
            };
            // this.getGrbkOption();
            this.getList();
        },
        toBk(row, type) {
            console.log(type);
            this.lookType = type;
            this.selectObj = row;
            this.bkOpen = true;
            console.log(row);
        },
        changeTime() {
            console.log(this.queryParams.rzTime);
        },
        changeZxtType() {
            this.zxtQueryParams = {
                orgIdList: [],
                groupIdList: [],
            },
                this.zxtTypeOpen = true;
        },
        submitXtTyp() {
            if (this.zxtQueryParams.orgIdList.length == 0 && this.zxtQueryParams.groupIdList.length == 0) {
                this.getGrbkOption()
            }
            if (this.zxtQueryParams.orgIdList.length != 0) {
                this.getGrbkOption()
            }
            if (this.zxtQueryParams.groupIdList.length != 0) {
                this.getUserGrbkOption()
            }
            this.zxtTypeOpen = false;
        },
        getOrgListTree() {
            orgListTree().then(res => {
                this.treeData = res.data.data;
            })
        },
        handleExport() {
            var timestamp = timestampToTime(Date.parse(new Date()));
            const tableDatas = JSON.parse(JSON.stringify(this.dataList))//这里面填写你接口的数据
            const datas = tableDatas
            if (this.queryParams.tableType != '按工作室分') {
                const column = [
                    {
                        title: '组织名称',
                        key: 'orgName',
                        type: 'text',
                    },
                    {
                        title: '协同备课',
                        key: 'xtbkAmount',
                        type: 'text',
                    },
                    {
                        title: '课题引领',
                        key: 'ktylAmount',
                        type: 'text',
                    },
                    {
                        title: '教研赛事',
                        key: 'ykdsAmount',
                        type: 'text',
                    },
                    {
                        title: '专题活动',
                        key: 'zthdAmount',
                        type: 'text',
                    }
                ]
                table2excel(column, datas, '名校长组织统计' + timestamp)
            } else {
                const column = [
                    {
                        title: '工作室',
                        key: 'groupName',
                        type: 'text',
                    },
                    {
                        title: '所属组织',
                        key: 'orgName',
                        type: 'text',
                    },
                    {
                        title: '协同备课',
                        key: 'xtbkAmount',
                        type: 'text',
                    },
                    {
                        title: '课题引领',
                        key: 'ktylAmount',
                        type: 'text',
                    },
                    {
                        title: '教研赛事',
                        key: 'ykdsAmount',
                        type: 'text',
                    },
                    {
                        title: '专题活动',
                        key: 'zthdAmount',
                        type: 'text',
                    }
                ]
                table2excel(column, datas, '名校长工作室统计' + timestamp)
            }
        },
        getGrbkOption() {
            statsPrincipalStudio().then(res => {
                this.grbkOption.xAxis[0].data = []
                this.grbkOption.series[0].data = []
                this.grbkOption.series[1].data = []
                this.grbkOption.series[2].data = []
                this.grbkOption.series[3].data = []

                if (this.zxtQueryParams.orgIdList.length != 0) {
                    res.data.data = res.data.data.filter(itemA => {
                        return this.zxtQueryParams.orgIdList.indexOf(itemA.orgId) != -1;
                    })
                }
                res.data.data.forEach(e => {
                    this.grbkOption.xAxis[0].data.push(e.orgName)
                    e.xtbkAmount = 0
                    e.ktylAmount = 0
                    e.ykdsAmount = 0
                    e.zthdAmount = 0

                    e.groupList.forEach(j => {
                        e.xtbkAmount += j.xtbkAmount
                        e.ktylAmount += j.ktylAmount
                        e.ykdsAmount += j.ykdsAmount
                        e.ykdsAmount += j.ykdsAmount
                    })
                    this.grbkOption.series[0].data.push(e.xtbkAmount)
                    this.grbkOption.series[1].data.push(e.ktylAmount)
                    this.grbkOption.series[2].data.push(e.ykdsAmount)
                    this.grbkOption.series[3].data.push(e.zthdAmount)

                })
                this.zxtOpen = true;
                setTimeout(() => {
                    let myChart3 = echarts.init(document.getElementById('grbk'))
                    myChart3.setOption(this.grbkOption, true)
                });
            })

        },
        getUserGrbkOption() {
            this.grbkOption.xAxis[0].data = []
            this.grbkOption.series[0].data = []
            this.grbkOption.series[1].data = []
            this.grbkOption.series[2].data = []
            this.grbkOption.series[3].data = []
            var userOption = JSON.parse(JSON.stringify(this.dataList));
            userOption = userOption.filter(itemA => {
                return this.zxtQueryParams.groupIdList.indexOf(itemA.groupId) != -1;
            })
            userOption.forEach(e => {
                this.grbkOption.xAxis[0].data.push(e.groupName)
                this.grbkOption.series[0].data.push(e.xtbkAmount)
                this.grbkOption.series[1].data.push(e.ktylAmount)
                this.grbkOption.series[2].data.push(e.ykdsAmount)
                this.grbkOption.series[3].data.push(e.zthdAmount)
            })
            console.log(this.grbkOption);
            this.zxtOpen = true;
            setTimeout(() => {
                let myChart3 = echarts.init(document.getElementById('grbk'))
                myChart3.setOption(this.grbkOption, true)
            });
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.dataList = [];
            this.groupIdList = []
            statsPrincipalStudio().then(res => {
                if (this.queryParams.orgId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.orgId == this.queryParams.orgId
                    })
                }
                res.data.data.forEach(e => {
                    e.groupList.forEach(j => {
                        if (this.queryParams.rzTime && this.queryParams.rzTime.length != 0) {
                            j.trainList = j.trainList.filter(itemA => {
                                return ((itemA.beginTime >= this.queryParams.rzTime[0]) && (itemA.beginTime <= this.queryParams.rzTime[1])) || ((itemA.endTime >= this.queryParams.rzTime[0]) && (itemA.endTime <= this.queryParams.rzTime[1]))
                            })
                        }
                        j.orgName = e.orgName;
                        j.orgId = e.orgId;
                        // j.count = j.trainList.length
                        if (this.queryParams.tableType == '按工作室分') {
                            this.dataList.push(j)
                        }
                        this.groupIdList.push(j)
                    })
                })
                if (this.queryParams.tableType == '按组织分') {
                    res.data.data.forEach(e => {
                        e.ktylAmount = 0;
                        e.xtbkAmount = 0;
                        e.ykdsAmount = 0;
                        e.zthdAmount = 0;
                        // e.trainList = [];
                        e.xtbkList = [];
                        e.ktylList = [];
                        e.ykdsList = [];
                        e.zthdList = [];
                        e.groupList.forEach(j => {
                            e.ktylAmount += j.ktylAmount
                            e.xtbkAmount += j.xtbkAmount
                            e.ykdsAmount += j.ykdsAmount
                            e.zthdAmount += j.zthdAmount

                            // e.trainList = [...e.trainList, ...j.trainList]
                            e.xtbkList = [...e.xtbkList, ...j.xtbkList]
                            e.ktylList = [...e.ktylList, ...j.ktylList]
                            e.ykdsList = [...e.ykdsList, ...j.ykdsList]
                            e.zthdList = [...e.zthdList, ...j.zthdList]
                        })
                        // e.count = e.trainList.length
                        this.dataList.push(e)
                    })
                }
                console.log(this.dataList);
                this.loading = false;
            })
        },
        goDetail(row) {
            console.log(row);
            if (this.lookType == 'xtbk') {
                this.$router.push({
                    path: "/lessonDetail",
                    query: {
                        lessonId: row.lessonId,
                        isPrincipalStudio: 1,
                    }
                });
            } else if (this.lookType == 'ktyl') {
                this.$router.push({
                    path: "/leadDetail",
                    query: {
                        topicId: row.id,
                        isPrincipalStudio: 1,
                    }
                });
            } else if (this.lookType == 'jyss') {
                this.$router.push({
                    path: "/raceDetail",
                    query: {
                        raceId: row.id,
                        isPrincipalStudio: 1,
                    }
                });
            } else if (this.lookType == 'zthd') {
                this.$router.push({
                    path: "/activityDetail",
                    query: {
                        activityId: row.id,
                        isPrincipalStudio: 1,
                    }
                })
            }

        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        changeZxtOrg() {
            this.zxtQueryParams.groupIdList = [];
        },
        changeZxtUser() {
            this.zxtQueryParams.orgIdList = [];
        },
        disabledDate(current) {
            return current && current > Number(new Date())
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}
</style>