<template>
  <div id="login-box">
    <el-container>
      <!--左侧-->
      <el-aside></el-aside>
      <el-container>
        <!--顶部-->
        <el-header>
          <el-row justify="end" type="flex">
          </el-row>
        </el-header>
        <!--登录框-->
        <el-main>
          <div id="login-form">
            <el-form ref="resetPwdFormRef" :model="resetPwdForm" :rules="resetPwdFormRules">
              <p class="login-type">重置密码</p>
              <el-form-item prop="username">
                <el-input type="text" v-model="resetPwdForm.username" placeholder="请输入注册账号" prefix-icon="Iphone"></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input type="text"
                          v-model="resetPwdForm.code"
                          placeholder="请输入6位数字验证码"
                          prefix-icon="Message">
                  <template #append>
                    <el-button class="code-btn" type="primary" @click="getCode" :loading="codeBtnLoading">{{ msgText }}</el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input type="password" show-password v-model="resetPwdForm.password" placeholder="请输入新密码" prefix-icon="Lock"></el-input>
              </el-form-item>
              <el-form-item prop="newPassword">
                <el-input type="password" show-password v-model="resetPwdForm.newPassword" placeholder="请再次输入新密码" prefix-icon="Lock"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="loginBtn" type="primary" @click="handleResetPwd" :loading="resetPwdBtnLoading">重置密码</el-button>
              </el-form-item>
            </el-form>
            <div class="other-tab">
              <el-row>
                <el-col class="tab-one" :span="8" style="text-align: left;"><a @click="toPwdLogin">密码登录</a></el-col>
                <el-col class="tab-one" :span="8" style="text-align: center;"><a @click="toCodeLogin">验证码登录</a></el-col>
                <el-col class="tab-one" :span="8" style="text-align: right;"><a @click="toRes">注册</a></el-col>
              </el-row>
            </div>
          </div>
        </el-main>
        <!--底部-->
        <el-footer>
          <el-row justify="center" type="flex">
            <el-col :span="16">
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>

const MSGINIT = "获取验证码";
const MSGSCUCCESS = "${time}秒后重发";
const MSGTIME = 60;
import {resetPwd,userHasReg,requestCode } from "@/api/user/login";
let validateUsername = (rule,value,callback) =>{
  userHasReg(value).then(res =>{
    const result = res.data.data
    if (!result){
      callback(new Error("该账号尚未注册"))
    }else {
      callback()
    }
  })
}
export default ({
  name:"resetPwd",
  data(){
    return{
      msgText: MSGINIT,
      msgTime: MSGTIME,
      msgKey: false,
      codeBtnLoading:false,
      resetPwdBtnLoading:false,
      /*登录数据绑定对象*/
      resetPwdForm:{
        username:"",
        code:"",
        password:"",
        newPassword:""
      },
      codeForm:{
        mobile:"",
        type:"resetPassword"
      },
      /*登录校验规则*/
      resetPwdFormRules:{
        username: [
          { required: true, message: "请输入已注册账号", trigger: "blur" },
          // { validator:validateUsername,trigger: "blur"}
        ],
        code: [{ required: true, message: "请输入6位数字验证码", trigger: "blur" }],
        password: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        newPassword: [{ required: true, message: "请再次输入新密码", trigger: "blur" }],
      }
    }
  },
  methods:{
    handleResetPwd(){
      this.$refs.resetPwdFormRef.validate( valid => {
        if (!valid) return
        if(this.resetPwdForm.password !== this.resetPwdForm.newPassword){
          this.$message.error("两次密码输入不一致")
          return;
        }
        this.resetPwdBtnLoading = true
        // let pwd = this.$encrypt(this.resetPwdForm.password)
        let pwd = this.resetPwdForm.password

        let resetForm = {username:this.resetPwdForm.username,code:this.resetPwdForm.code,newPassword:pwd}
        resetPwd(resetForm).then(res => {
          if (res.data.code === 1){
            this.resetPwdBtnLoading = false
            /*去登录*/
            this.$confirm("您的密码重置成功！","提示",{
              confirmButtonText:"去登录",
              cancelButtonText:"留在此页",
              type:"warning"
            }).then(() =>{
              this.$router.push({path:"/login"});
            })
          }else {
            this.$message.error(res.data.msg)
            this.resetPwdBtnLoading = false
          }
        })
      })
    },
    toRes(){
      this.$router.push("/register")
    },
    toPwdLogin(){
      this.$router.push("/login")
    },
    toCodeLogin(){
      this.$router.push("/codeLogin")
    },
    /*获取验证码*/
    getCode(){
      if (this.msgKey) return;
      if(this.resetPwdForm.username === ''){
        this.$message.error("请输入注册手机号")
        return;
      }
      this.codeBtnLoading = true
      this.codeForm.username = this.resetPwdForm.username
      requestCode(this.codeForm).then(res => {
        if (res.data.code === 1){
          this.$message.success("验证码发送成功")
          this.codeBtnLoading = false
          this.codeTimer()
        }else {
          this.$message.error(res.data.msg)
          this.codeBtnLoading = false
        }
      })
    },
    codeTimer(){
      this.msgText = MSGSCUCCESS.replace("${time}",this.msgTime)
      this.msgKey = true
      const timer = setInterval(() => {
        this.msgTime--
        this.msgText = MSGSCUCCESS.replace("${time}",this.msgTime)
        if (this.msgTime == 0){
          this.msgTime = MSGTIME
          this.msgText = MSGINIT
          this.msgKey = false
          clearInterval(timer)
        }
      },1000)
    }
  }
})
</script >
<!--样式-->
<style lang="scss" scoped>
@import url(../../styles/login.scss);
/*登录框*/
#login-box{
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
  }
  .el-header{
    padding: 0 0;
    font-size: 18px;
    text-align: center;
    height: 80px !important;
    .el-link{
      color: #4c596e;
      text-decoration:none;
    }
    .el-link:hover{
      color: #2489f2;
      text-decoration:none;
    }
  }
  .el-footer {
    color: #4c596e;
    text-align: center;
    height: 40px !important;
    font-size: 14px;
  }
  .el-aside {
    width: 34%;
    background-image: url("../../assets/login_bg1.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .el-main {
    padding: 0 0;
    position: relative;
  }
  .el-row {
    height: 100%;
    align-items: center;
  }
  .el-col {
    child-align: middle;
  }
}
.tab-one{
  cursor: pointer;
  font-size: 14px;
  color: #7f8997;
}
.tab-one > a:hover{
  color: #428bfa;
}
.code-btn{
  background-color: #428bfa!important;
  color: #ffffff!important;
  font-size: 13px!important;
}
</style>

