<template>
    <div class="app-container">
        <div style="height: calc(100vh - 5px);display: flex;">
            <div v-if="active == 1" class="left">
                <el-card style="height: 100%;overflow-y: auto;">
                    <div>
                        <div :id="'zmd' + index" @click="changeCurrent(index)" class="swbox"
                            v-for="(item, index) in bookData.pages" style="position: relative;">
                            <Canvasbox :bookData="bookData" :imwidth="150"
                                :imheight="bookData.fileHeight / bookData.fileWidth * 150"
                                :canvasid="bookData.fileId + '' + index"
                                :picSrc="bookData.imgUrl + '/'+(index + 1) + '.png'"></Canvasbox>
                            <div :class="current == index ? 'bor-lan' : ''"
                                :style="'width: 150px;height: ' + bookData.fileHeight / bookData.fileWidth * 150 + 'px;top: 0;position: absolute;border: 1px solid rgb(238, 238, 238);border-radius: 10px;'">

                            </div>

                            <div class="pageNumbox">{{ index +1 }}</div>
                        </div>
                    </div>
                </el-card>
            </div>
            <div class="right">
                <el-card style="height: calc(100vh - 5px);position: relative;">
                    <el-icon @click="current-=1" v-if="current != 0" class="cursor" :size="30" style="position: absolute;top: 50%;transform: translateY(-50%);"><ArrowLeftBold /></el-icon>
                    <el-icon @click="current+=1" v-if="current != (bookData.pages - 1)" class="cursor" :size="30" style="position: absolute;top: 50%;right: 20px;transform: translateY(-50%);"><ArrowRightBold /></el-icon>
                    <div ref="bjcan" id="bjcan" style="height: calc(100vh - 35px);overflow-y: auto;">
                        <img :width="zdyWidth" :src="bookData.imgUrl + '/'+(current + 1) + '.png'" alt="" style="border: 1px solid rgb(238, 238, 238);border-radius: 10px;">
                    </div>
                </el-card>
            </div>
        </div>

    </div>
</template>

<script>
// import { queryTeacherAllStroke } from "@/api/admin/bookManage";
import { timestampToTime } from "@/utils/date";

export default {
    name: "lessonFileDetail",
    components: {

    },
    props: {
        teacherBookId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            active: 1,
            current: 0,
            isZjShow: false,
            isShow: false,
            timestampToTime,
            bookData: {},
            strokeList: [],
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            zdyWidth: 0,
            zdyHeight: 0,
            isStart: false,
            isTime: false,
            isNull: true,
        };
    },
    created() {
        console.log('fdsdfds')
    },
    mounted() {
        this.bookData = JSON.parse(this.$route.query.data)
        console.log(this.bookData)
        this.zdyWidth = (document.getElementById("bjcan").clientWidth -  20)
        // this.getDetail()
        // document.querySelector("#bjcan").addEventListener("scroll", this.handleScroll)
    },
    methods: {
        // 滚动函数事件
        handleScroll() {
            if (!this.isTime) {
                if (this.isNull) {
                    let scrollTop = document.querySelector("#bjcan").scrollTop; //获取监听指定区域滚动位置的值
                    console.log(scrollTop, Math.round(scrollTop / document.getElementById("md0").clientHeight), document.getElementById("md0").clientHeight)
                    if (Math.round(scrollTop / document.getElementById("md0").clientHeight) - 1 < 0) {
                        this.current = 0
                    } else {
                        this.current = Math.round(scrollTop / document.getElementById("md0").clientHeight)
                    }

                    location.href = "#zmd" + (this.current);
                    this.isNull = false;

                    setTimeout(()=>{
                        this.isNull = true;
                    },500)
                }

            }

        },
        changeActive(num) {
            if (this.active != num || !this.isStart) {
                this.current = 0;
                this.isShow = false;

                this.active = num

                setTimeout(() => {
                    console.log(document.getElementById("bjcan").clientWidth)
                    console.log(document.getElementById("bjcan").clientHeight)
                    this.zdyHeight = document.getElementById("bjcan").clientHeight - 50;
                    if (this.active == 3) {
                        this.zdyWidth = (document.getElementById("bjcan").clientWidth - num * 20) / num
                    }else if(this.active == 2) {
                        this.zdyHeight = document.getElementById("bjcan").clientHeight - 90;
                        this.zdyWidth = this.zdyHeight * this.bookData.pageWidth / this.bookData.pageHeight

                    } else {
                        this.zdyWidth = this.zdyHeight * this.bookData.pageWidth / this.bookData.pageHeight
                    }
                    this.isShow = true;

                    console.log(this.zdyWidth)
                })
            }



        },
        canvasClosess(e) {
            this.isShow = false;
        },
        // 取消按钮
        cancel() {
            this.$emit('isTg', true)
        },
        changeCurrent(index) {
            this.current = index;
            console.log(this.$refs.bjcan)
            this.$refs.bjcan.scrollTo({top:0});
            // this.isTime = true;
            // setTimeout(() => {
            //     this.isTime = false;
            // }, 1000)
            // location.href = "#md" + index;
        },
        getDetail() {
            // queryTeacherAllStroke({ teacherBookId: this.teacherBookId }).then(res => {
            //     this.dataList = res.data.data
            //     this.bookData = JSON.parse(JSON.stringify(res.data.data))
            //     console.log(this.dataList)
            //     console.log(this.bookData)

            //     this.isZjShow = true
                // this.changeActive(1)
            //     setTimeout(() => {
            //         this.isStart = true;
            //     }, 2000)

            // })

        },
        getPageName(currentpageNum, totalPages, headPages, tailPages) {
            var pageNum = null;
            var tailpageNum = totalPages - currentpageNum;

            if (tailPages > tailpageNum) {
                // 尾页
                if (tailPages - tailpageNum == 0) {
                    // 尾页最后一页
                    pageNum = "尾页-" + tailPages;
                } else {
                    pageNum = "尾页-" + (tailPages - tailpageNum);
                }
            } else if (headPages >= currentpageNum) {
                // 扉页
                pageNum = "扉页-" + currentpageNum
            } else {
                // 正常页码
                pageNum = "第" + (currentpageNum - headPages) + "页"
            }
            return pageNum;
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-tabs--border-card {
    border: 0;
}

/deep/ .el-tabs__header {
    background-color: transparent !important;
}

/deep/ .el-tabs--border-card>.el-tabs__content {
    background: #fff !important;
}

/deep/ .el-tabs--border-card {
    background: transparent;
}

.left {
    width: 200px;
    height: 100%;
    margin-right: 15px;
}

.right {
    flex: 1;
}

.swbox {
    margin-bottom: 10px;
    flex: 1;
}

.pageNumbox {
    text-align: center;
    padding: 10px 0 15px 0;
}

.bor-lan {
    border: 1px solid #409eff !important;
}

.chosebtn {
    padding-top: 10px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    justify-content: flex-end;
}

.chosebtn img {
    height: 30px;
    object-fit: contain;
    margin-left: 15px;
}

#bjcan {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ac1 {
    width: 100%;
}
.ac2{
    width: 40%;
}
</style>