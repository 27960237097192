<template>
  <div id="login-box">
    <el-container>
      <!--左侧-->
      <el-aside></el-aside>
      <el-container>
        <!--顶部-->
        <el-header>
          <el-row justify="end" type="flex">
          </el-row>
        </el-header>
        <!--登录框-->
        <el-main>
          <div id="login-form">
            <el-form ref="loginFormRef" :model="loginForm" :rules="loginRules">
              <p class="login-type">账号密码登录</p>
              <el-form-item prop="username">
                <el-input type="text" v-model="loginForm.username" placeholder="请输入登录账号" prefix-icon="Iphone"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input type="password" show-password v-model="loginForm.password" placeholder="请输入密码"
                  prefix-icon="Lock"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="loginBtn" type="primary" :loading="loginBtnLoading" @click="subUserLogin">登录</el-button>
              </el-form-item>
            </el-form>
            <div class="other-tab">
              <el-row>
                <el-col class="tab-one" :span="8" style="text-align: left;"><a @click="toCodeLogin">验证码登录</a></el-col>
                <el-col class="tab-one" :span="8" style="text-align: center;"><a @click="toRes">注册</a></el-col>
                <el-col class="tab-one" :span="8" style="text-align: right;"><a @click="toResetPwd">重置密码</a></el-col>
              </el-row>
            </div>
          </div>
        </el-main>
        <!--底部-->
        <el-footer>
          <!-- <el-row justify="center" type="flex">
            <el-col :span="16">
              版权所有©北京王后雄教育技术研究院 | <a style="text-decoration:none" target="_blank" href="https://beian.miit.gov.cn">京ICP备2023028206号-1</a>
            </el-col>
          </el-row> -->
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { userLogin, isAuthGroup } from "@/api/user/login";
let validateUsername = (rule, value, callback) => {
  isAuthGroup(value).then(res => {
    const result = res.data.data
    if (!result) {
      callback(new Error("该账号暂无权限登录"))
    } else {
      callback()
    }
  })
}
export default ({
  name: "userLogin",
  data() {
    return {
      loginBtnLoading: false,
      /*登录数据绑定对象*/
      loginForm: {
        username: "",
        password: ""
      },
      /*登录校验规则*/
      loginRules: {
        username: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
          // { validator: validateUsername, trigger: "blur" }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      }
    }
  },
  created() {
    console.log(this.$store.state.zdy.isSetInter1);
    console.log(this.$store.state.zdy.isSetInter2);

    clearInterval(this.$store.state.zdy.isSetInter1)
    clearInterval(this.$store.state.zdy.isSetInter2)

    this.$store.dispatch('tagsView/delAllViews')
    this.$store.dispatch('tagsView/addView', {
      path: '/indexs',
      name: '首页',
      fullPath: '/indexs',
      title: '首页',
      meta: { title: '首页' },
    })
    let that = this
    document.onkeydown = function (e) {
      e = window.event || e;
      if (e.code === "Enter" || e.code === "enter") {
        that.subUserLogin();
      }
    }
  },
  methods: {
    subUserLogin() {
      this.$refs.loginFormRef.validate(valid => {
        if (!valid) return
        // let pwd = this.$encrypt(this.loginForm.password)
        let loginInfo = { username: this.loginForm.username, password: this.loginForm.password }
        this.loginBtnLoading = true
        userLogin(loginInfo).then(res => {
          if (res.data.code === 1) {
            const info = res.data.data
            debugger
            this.$storage.set("userInfo", JSON.stringify(info.userInfo));
            this.$storage.set("token", info.token);
            this.$storage.set("role", info.role+'');

            // this.$storage.set("role", info.role);
            // this.$storage.set("lastGroupAccount",JSON.stringify(info.lastGroupAccount));
            // this.$storage.set("groupAccounts",JSON.stringify(info.groupAccounts));
            this.loginBtnLoading = false
            this.$router.push("/homePage")
          } else {
            this.loginBtnLoading = false
            this.$message.error(res.data.msg)
          }
        })
      })
      // this.$storage.set("token",'fdsfdsdfdsds');
      // this.$router.push("/home")
    },
    toRes() {
      this.$router.push("/register")
    },
    toCodeLogin() {
      console.log("验证码登录")
      this.$router.push("/codeLogin")
    },
    toResetPwd() {
      this.$router.push("/resetPwd")
    },
  }
})
</script >
<!--样式-->
<style lang="scss" scoped>
@import url(../../styles/login.scss);

/*登录框*/
#login-box {
  width: 100%;
  height: 100%;

  .el-container {
    height: 100%;
  }

  .el-header {
    padding: 0 0;
    font-size: 18px;
    text-align: center;
    height: 80px !important;

    .el-link {
      color: #4c596e;
      text-decoration: none;
    }

    .el-link:hover {
      color: #2489f2;
      text-decoration: none;
    }
  }

  .el-footer {
    color: #4c596e;
    text-align: center;
    height: 40px !important;
    font-size: 14px;
  }

  .el-aside {
    width: 34%;
    background-image: url("../../assets/login_l_bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .el-main {
    padding: 0 0;
    position: relative;
  }

  .el-row {
    height: 100%;
    align-items: center;
  }

  .el-col {
    child-align: middle;
  }
}

.tab-one {
  cursor: pointer;
  font-size: 14px;
  color: #7f8997;
}

.tab-one>a:hover {
  color: #428bfa;
}
</style>

