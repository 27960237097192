<template>
    <div class="homePage">
        <div class="top-top">
            <div class="top-box">
                <div class="tt-tit">
                    <!-- <img v-if="userInfo.orgNatureName && userInfo.orgNatureName == '精准教研'" src="../../assets/lyjy.png"
                        alt=""> -->
                    <img src="../../assets/lyjy.png" alt="">
                    {{ userInfo.orgNatureName }}
                </div>
                <div class="tr-img">
                    <el-badge :is-dot="messageBol" class="item">
                        <img class="cursor" @click="toPage('personApp', 'messageCenter')" src="../../assets/homePage/rw.png"
                            alt="">
                    </el-badge>
                    <el-badge :is-dot="systemBol" class="item">
                        <img class="cursor" @click="toPage('personApp', 'systemCenter')" src="../../assets/homePage/xx.png"
                            alt="">
                    </el-badge>
                    <el-dropdown class="cursor">
                        <el-avatar style="margin-left: 15px;" :size="30" :src="userHeadImg + '?_=' + Date.now()" />
                        <template #dropdown>
                            <el-dropdown-menu>
                                <div @click="toPage('personApp', 'userInfo')" class="cursor" style="text-align: center;">{{
                                    popname }}</div>
                                <!-- <el-dropdown-item @click="openJs">打开教师助手</el-dropdown-item> -->
                                <el-dropdown-item style="text-align: center;" @click="logout">安全退出</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <div class="hello">
                {{ zdytxt }}好，{{ popname }}老师
            </div>
            <div class="center-box">
                <div class="ct-it-box">
                    <div class="ct-sm ct-1" @click="toPage('famousCourseManage', 'famousGspList')">
                        <img src="../../assets/homePage/bg-1.png" alt="">
                        <div class="zyzx">
                            资源中心
                        </div>
                    </div>
                    <div class="ct-sm ct-2" @click="toPage('lessonManage', 'ptStatistics')">
                        <img src="../../assets/homePage/bg-2.png" alt="">
                        <div class="zyzx">
                            一体化教研
                        </div>
                    </div>
                    <!-- <div class="ct-sm ct-2" v-if="role == 4 || role == 5 || role == 6" @click="toPage('lessonManage', 'myLess')">
                        <img src="../../assets/homePage/bg-2.png" alt="">
                        <div class="zyzx">
                            一体化教研
                        </div>
                    </div> -->
                    <div class="ct-sm ct-3" @click="toPage('teachActiManage', 'jkyStatistics')">
                        <img src="../../assets/homePage/bg-3.png" alt="">
                        <div class="zyzx">
                            教科研
                        </div>
                    </div>
                    <!-- <div v-if="role == 4 || role == 5 || role == 6" class="ct-sm ct-3"
                        @click="toPage('teachActiManage', 'lead')">
                        <img src="../../assets/homePage/bg-3.png" alt="">
                        <div class="zyzx">
                            教科研
                        </div>
                    </div> -->
                    <div class="ct-sm ct-4" @click="toPage('teacherTrainManage', 'szpxStatistics')">
                        <img src="../../assets/homePage/bg-4.png" alt="">
                        <div class="zyzx">
                            师资培训
                        </div>
                    </div>
                    <!-- <div v-if="role == 4 || role == 5 || role == 6" class="ct-sm ct-4" @click="toPage('teacherTrainManage', 'train')">
                        <img src="../../assets/homePage/bg-4.png" alt="">
                        <div class="zyzx">
                            师资培训
                        </div>
                    </div> -->
                    <div v-if="role == 6" class="ct-sm ct-5" @click="toPage('famousPrincipal', 'studioList')">
                        <img src="../../assets/homePage/bg-5.png" alt="">
                        <div class="zyzx">
                            名校长工作室
                        </div>
                    </div>
                    <div v-if="role == 6" class="ct-sm ct-6" @click="toPage('teachStudio', 'teachStudioList')">
                        <img src="../../assets/homePage/bg-6.png" alt="">
                        <div class="zyzx">
                            名师工作室
                        </div>
                    </div>
                    <div v-if="role != 6" class="ct-sm ct-5" @click="toPage('famousPrincipal', 'principalStudio')">
                        <img src="../../assets/homePage/bg-5.png" alt="">
                        <div class="zyzx">
                            名校长工作室
                        </div>
                    </div>
                    <div v-if="role != 6" class="ct-sm ct-6" @click="toPage('teachStudio', 'msgzsStatistics')">
                        <img src="../../assets/homePage/bg-6.png" alt="">
                        <div class="zyzx">
                            名师工作室
                        </div>
                    </div>
                </div>
            </div>

            <div class="bottom-box">
                <div class="bt-it-box">
                    <div class="bt-sm" @click="toPage('systemManage', 'news')">
                        <img src="../../assets/homePage/smlogo-1.png" alt="">
                        <div>新闻资讯{{ role == 1 || role == 2 ? '管理' : '' }}</div>
                    </div>
                    <div class="bt-sm" @click="toPage('systemManage', 'banner')">
                        <img src="../../assets/homePage/smlogo-2.png" alt="">
                        <div>头部banner{{ role == 1 || role == 2 ? '管理' : '' }}</div>
                    </div>
                    <div class="bt-sm" @click="toPage('systemManage', 'bannerNotice')">
                        <img src="../../assets/homePage/smlogo-3.png" alt="">
                        <div>活动banner{{ role == 1 || role == 2 ? '管理' : '' }}</div>
                    </div>
                    <div class="bt-sm" @click="toPage('systemManage', 'workBrieManage')">
                        <img src="../../assets/homePage/smlogo-4.png" alt="">
                        <div>工作简报{{ role == 1 || role == 2 ? '管理' : '' }}</div>
                    </div>
                    <div class="bt-sm" @click="toPage('systemManage', 'classLibrary')">
                        <img src="../../assets/homePage/smlogo-5.png" alt="">
                        <div>教程库{{ role == 1 || role == 2 ? '管理' : '' }}</div>
                    </div>
                    <div v-if="role == 1 || role == 2 || role == 3" class="bt-sm"
                        @click="toPage('systemManage', 'arrayMaintenance')">
                        <img src="../../assets/homePage/smlogo-6.png" alt="">
                        <div>组织管理</div>
                    </div>
                    <div v-if="role == 1 || role == 2 || role == 3" class="bt-sm"
                        @click="toPage('systemManage', 'teachResearch')">
                        <img src="../../assets/homePage/smlogo-7.png" alt="">
                        <div>群组管理</div>
                    </div>
                    <div v-if="role == 1 || role == 2 || role == 3" class="bt-sm"
                        @click="toPage('systemManage', 'uesrManage')">
                        <img src="../../assets/homePage/smlogo-8.png" alt="">
                        <el-badge :hidden="applyNum == 0" :showZero="false" :value="applyNum" is-dot class="item">
                            <div>用户管理</div>
                        </el-badge>
                    </div>
                    <!-- <div class="bt-sm" @click="toOutPage('fileFill')">
                        <img src="../../assets/homePage/smlogo-4.png" alt="">
                        <div>档案填报</div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { userMessages, } from "@/api/admin/message"
import { regApplyHandleNum } from "@/api/admin/public";

export default {
    components: {},
    name: "homePage",
    data() {
        return {
            popname: "",
            userHeadImg: "",
            role: "",
            zdytxt: "上午",
            userInfo: {},
            messageBol: false,
            systemBol: false,
            isSetInter: null,
            applyNum: 0,
        }
    },
    computed: {

    },
    watch: {
        $route(to, from) {
            console.log('to', to)
            // clearInterval(this.isSetInter)
        },
    },
    created() {
        clearInterval(this.$store.state.zdy.isSetInter1)
        clearInterval(this.$store.state.zdy.isSetInter2)
        this.getMessage()
        this.$store.state.zdy.isSetInter1 = setInterval(() => {
            this.getMessage()
        }, 30000)
        this.role = this.$storage.get("role")
        if (this.role == 1 || this.role == 2 || this.role == 3) {
            this.getApplyNum();
        }
        this.popname = JSON.parse(this.$storage.get("userInfo")).trueName
        this.userHeadImg = JSON.parse(this.$storage.get("userInfo")).avatar
        this.userInfo = JSON.parse(this.$storage.get("userInfo"))

        var currentDate = new Date();

        var currentHour = currentDate.getHours();

        if (0 <= currentHour && currentHour <= 6) {
            this.zdytxt = '凌晨'
        } else if (6 < currentHour && currentHour <= 12) {
            this.zdytxt = '上午'
        } else if (12 < currentHour && currentHour <= 18) {
            this.zdytxt = '下午'
        } else {
            this.zdytxt = '晚上'
        }
    },
    mounted() {
    },
    methods: {
        getApplyNum() {
            regApplyHandleNum().then(res => {
                this.applyNum = res.data.data;
            })
        },
        getMessage() {
            userMessages({ code: 1 }).then(res => {
                this.systemBol = false;
                res.data.data.forEach(e => {
                    if (e.isRead == 0) {
                        this.systemBol = true;
                    }
                })
            });
            userMessages().then(res => {
                this.messageBol = false;
                res.data.data.forEach(e => {
                    if (e.isRead == 0) {
                        if (e.code != 1) {
                            this.messageBol = true;
                        }
                    }
                })
            });
        },
        toPage(activePath, activeSecondPath) {
            window.sessionStorage.setItem('activePath', activePath)
            window.sessionStorage.setItem('activeSecondPath', activeSecondPath)
            this.$router.push({ path: '/home' });
        },
        toOutPage(activePath) {
            let routeUrl = this.$router.resolve({
                path: '/'+activePath,// 这里的路径就可以正常的写，不需要添加_blank: true
                query: {  }
            })
            window.open(routeUrl.href, '_blank')
        },
        logout() {
            this.$confirm("您正在退出系统，是否继续？", "退出系统", {
                confirmButtonText: "退出",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$storage.remove("token")
                this.$storage.remove("userInfo")
                this.$router.push({ path: "/login" });
            })
        },
    }
}
</script>
  
<style lang="scss" scoped>
.homePage {
    height: 100vh;
    background-color: #f2f2f2;

    .top-top {
        height: 35vh;
        // background-color: #fd862b;
        background-image: url("../../assets/homePage/bgtt.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .top-box {
            padding: 2vh 5vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .hello {
            padding: 6vh 10vw;
            color: #fff;
            font-size: 48px;
        }

        .tt-tit {
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 20px;

            img {
                width: 40px;
                margin-right: 10px;
            }
        }

        .tr-img {
            display: flex;
            align-items: center;

            img {
                width: 20px;
                height: 24px;
                margin-left: 15px;
            }
        }

        .bottom-box {
            background: #fff;
            margin: 5vh 10vw;
            border-radius: 10px;
            box-shadow: 1px 0px 6px 1px rgba(155, 155, 155, 0.75);

            .bt-it-box {
                display: flex;
                padding: 20px 20px 5px 20px;
                flex-wrap: wrap;
            }

            .bt-sm {
                width: 17.5%;
                border-radius: 10px;
                margin: 0 1%;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                padding: 20px 0;
                justify-content: center;
                border: 2px solid #e2e8fb;
                cursor: pointer;

                img {
                    width: 15%;
                    margin-right: 20px;
                }
            }
        }

        .center-box {
            background: #fff;
            margin: 5vh 10vw;
            border-radius: 10px;
            box-shadow: 1px 0px 4px 1px rgba(155, 155, 155, 0.75);
            margin-top: 2vh;
            .ct-sm {
                position: relative;
                width: 15%;
                border-radius: 5px;
                overflow: hidden;
                height: 8vh;
                margin: 0 1%;
                cursor: pointer;

                img {
                    width: 50%;
                }
            }

            .zyzx {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 25px;
                white-space: nowrap;
                color: #fff;
                // font-weight: bold;
            }

            .ct-it-box {
                display: flex;
                padding: 20px;
            }

            .ct-1 {
                background-color: #7e97fc;

                img {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }

            .ct-2 {
                background-color: #5097fa;

                img {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }

            .ct-3 {
                background-color: #42d0b8;

                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }

            .ct-4 {
                background-color: #fab616;

                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }

            .ct-5 {
                background-color: #fa9716;

                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }

            .ct-6 {
                background-color: #ff7f2e;

                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
}
</style>
  