<template>
    <div class="app-container">
        <el-row>
            <div style="display: flex;justify-content: flex-end;width: 100%;margin:10px 20px 10px 0;">
                <img title="全部已阅" @click="cleanAllUnread" class="cursor" style="width: 25px;"
                    src="../../assets/image/msg-cl.png" alt="">
            </div>
        </el-row>
        <el-card shadow="never" style="height:calc(100vh - 150px);overflow-y: auto;">
            <el-row v-loading="loading">
                <el-col style="margin-bottom: 15px;" class="" v-for="(item, index) in dataList" :key="index" :span="24">
                    <el-card :body-style="{ padding: '0px' }">
                        <div @click.stop="setcleanUnread(item)" class="btnStart cursor" style="display: flex;position: relative;">
                            <div style="padding:8px 0px 8px 14px;width: 50px;position: relative;">
                                <div style="position: relative;">
                                    <div v-if="item.isRead == 0"
                                        style="position: absolute;width:10px;height:10px;border-radius:10px;background: red;left: 2px;top:2px;">
                                    </div>
                                    <img v-if="item.code == 1" style="width:50px;height: 50px;"
                                        src="../../assets/image/msg-1.png" alt="">
                                    <img v-if="item.code == 2" style="width:50px;height: 50px;"
                                        src="../../assets/image/msg-2.png" alt="">
                                    <img v-if="item.code == 3" style="width:50px;height: 50px;"
                                        src="../../assets/image/msg-3.png" alt="">
                                    <img v-if="item.code == 4" style="width:50px;height: 50px;"
                                        src="../../assets/image/msg-4.png" alt="">
                                </div>
                            </div>
                            <div style="padding: 8px 14px 8px 14px" class="fs-14 wbba">
                                <div style="font-weight: bold;font-size: 15px;" class="">
                                    <div v-if="item.code == 1">系统消息</div>
                                    <div v-if="item.code == 2">教研消息</div>
                                    <div v-if="item.code == 3">培训消息</div>
                                    <div v-if="item.code == 4">教研活动</div>
                                </div>
                                <div class="">
                                    <div>{{ item.content }}</div>
                                </div>
                                <div class="">
                                    <div style="color: #a7a6a6;" :title="timestampToTime(item.createTime)" class="text-one">
                                        {{
                                            timestampToTime(item.createTime) }}</div>
                                </div>
                            </div>
                            <div v-if="item.isRead == 0" class="start cursor"
                                style="position: absolute;right: 0;top:0;padding: 10px;background: #f1f1f1;">
                                <el-icon @click.stop="setcleanUnread(item)" title="已读">
                                    <View />
                                </el-icon>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-empty style="margin: 0 auto;padding-top: 10vh;" v-if="dataList.length == 0" :image-size="200" />
            </el-row>
        </el-card>
    </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { userMessages, cleanUnread, messageCleanAllUnread } from "@/api/admin/message"
import { lessonListCommonLessons, lessonSubmit, delLessonFile, lessonStop, lessonRelease } from "@/api/admin/lesson"
import { timestampToTime } from "@/utils/date";
import { queryDict } from "@/api/admin/public";
import { download } from "@/api/adminservice/public";

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            subjectList: [],
            periodList: [],
            gradeList: [],
            gAFileList: [],
            token: this.$storage.get("token"),
            roleList: [
                { name: '已参与', value: 1 },
                { name: '未参与', value: 2 },
            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                role: '',
                title: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: ''
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: []
        };
    },
    created() {
        this.getQueryDict()
        this.getList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        cleanAllUnread() {
            var _this = this;
            ElMessageBox.confirm(
                '是否全部已阅?',
                '通知',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                messageCleanAllUnread().then(res => {
                    if (res.data.code == 1) {
                        this.$emit('changeCtMessage')
                        _this.$message.success("全部已阅");
                        _this.getList();
                    } else {
                        _this.$message.error("操作失败");
                    }
                })
            }).catch(() => {

            })
        },
        setcleanUnread(item) {
            cleanUnread({ messageId: item.messageId }).then(res => {
                if (res.data.code == 1) {
                    this.$emit('changeCtMessage')
                    this.$message.success("已阅");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        delFile(item) {
            var _this = this;
            ElMessageBox.confirm(
                '是否删除该共享课件?',
                '删除',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                let arr = []
                item.jaFiles.forEach(e => {
                    arr.push(new Promise(function (resolve, reject) {
                        delLessonFile({ fileUrl: e.fileUrl }).then(res => {
                            resolve(res.data.code)
                        })
                    }))
                })
                item.xaFiles.forEach(e => {
                    arr.push(new Promise(function (resolve, reject) {
                        delLessonFile({ fileUrl: e.fileUrl }).then(res => {
                            resolve(res.data.code)
                        })
                    }))
                })
                item.xtFiles.forEach(e => {
                    arr.push(new Promise(function (resolve, reject) {
                        delLessonFile({ fileUrl: e.fileUrl }).then(res => {
                            resolve(res.data.code)
                        })
                    }))
                })
                item.kjFiles.forEach(e => {
                    arr.push(new Promise(function (resolve, reject) {
                        delLessonFile({ fileUrl: e.fileUrl }).then(res => {
                            resolve(res.data.code)
                        })
                    }))
                })
                Promise.all(arr).then((result) => {
                    _this.getList()
                })

            }).catch(() => {

            })

            console.log(item);
        },
        downloadFile(item) {
            item.jaFiles.forEach(e => {
                download(e.fileUrl, e.fileName)
            })
            item.xaFiles.forEach(e => {
                download(e.fileUrl, e.fileName)
            })
            item.xtFiles.forEach(e => {
                download(e.fileUrl, e.fileName)
            })
            item.kjFiles.forEach(e => {
                download(e.fileUrl, e.fileName)
            })
            console.log(item);
        },
        lessonFileGADetail(item) {
            console.log(item);
            let routeUrl = this.$router.resolve({
                path: '/lessonFileGADetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: item.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAChange(e) {
            console.log(e)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('上传成功')
                    this.getList();
                } else {
                    this.$message.error('上传失败')
                }
            }

            // this.gAFileList = [];
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleGA(row) {

        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            userMessages({code:1}).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.code == 1
                })
                this.dataList = res.data.data;
                this.loading = false;
            });
        },
        goDetail(row) {
            this.$router.push({
                path: "/lessonDetail",
                query: {
                    lessonId: row.lessonId,
                }
            });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        handleRelease(row) {
            lessonRelease({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("发布失败");
                }
            })
        },
        handleSubmit(row) {
            console.log(row)
            if (row.jaFiles.length == 0 && row.kjFiles.length == 0 && row.xtFiles.length == 0) {
                this.$message.error("请先上传文件");
                return
            }
            lessonSubmit({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("提请成功");
                    this.getList();
                } else {
                    this.$message.error("提请失败");
                }
            })
        },
        handleStop(row) {
            lessonStop({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停止成功");
                    this.getList();
                } else {
                    this.$message.error("停止失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/lessonAdd",
                query: {
                    lessonId: row.lessonId,
                }
            });
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.w-90 {
    margin-bottom: 15px;
    margin-top: 10px;
}

.w-90 /deep/ .el-card.is-always-shadow {
    width: 90%;
    margin: 0 auto;
    cursor: pointer;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.image {
    width: 100%;
    display: block;
}

.chosebtn {
    padding-top: 10px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

.chosebtn div {
    margin-right: 10px;
}

.chosebtn img {
    padding: 5px;

    margin: 0 auto;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.bor-lan {
    border: 1px solid #409eff
}

.wbba {
    word-break: break-all;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.pxbutton {
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.start {
    display: none;
}

.btnStart:hover .start {
    display: block;
}

.cjtag {
    position: absolute;
    top: 14px;
    right: 0;
    font-size: 14px;
    background: #f1f1f1;
    padding: 2px 7px;
    border-radius: 4px;
    color: #d44c2d;
}
</style>