import request from "@/utils/request";
// 查询全部新闻资讯
// 学段年级学科：lession_xd_nj_xk
// 课题级别：topic_level
export const  queryDict =(query) =>{
    return request({
        url: "/businessservice/basicdata/queryDict",
        method:"get",
        params: query
    })
}
// 查询全部用户列表
export const  userList =(query) =>{
    return request({
        url: "/businessservice/user/listAll",
        method:"get",
        params: query
    })
}
// 查询全部用户列表
export const  userListJc =(query) =>{
    return request({
        url: "/businessservice/user/list",
        method:"get",
        params: query
    })
}
// 分页查询用户列表
export const  userPageList =(data) =>{
    return request({
        url: "/businessservice/user/pageList",
        method:"post",
        data: data
    })
}
// 查询用户证书
export const  certDelete =(query) =>{
    return request({
        url: "/businessservice/cert/delete",
        method:"get",
        params: query
    })
}
// 删除用户证书
export const  userCerts =(query) =>{
    return request({
        url: "/businessservice/cert/userCerts",
        method:"get",
        params: query
    })
}
// 更新用户信息
export const  userUpdate =(data) =>{
    return request({
        url: "/businessservice/user/update",
        method:"post",
        data: data
    })
}
// 点亮或点灭用户荣誉
export const  userLightHonorOrCancel =(data) =>{
    return request({
        url: "/businessservice/user/lightHonorOrCancel",
        method:"post",
        data: data
    })
}
// 查询用户信息
export const  queryUserInfo =(query) =>{
    return request({
        url: "/businessservice/user/queryUserInfo",
        method:"get",
        params: query
    })
}
// 查询所有学校
export const  authserviceSchoolList =(query) =>{
    return request({
        url: "/authservice/yg/schoolList",
        method:"get",
        params: query
    })
}
// 获取用户信息
export const  userCenterInfo =(query) =>{
    return request({
        url: "/businessservice/appHome/userCenter/info",
        method:"get",
        params: query
    })
}
// 更新用户信息
export const  userCenterUpdate =(data) =>{
    return request({
        url: "/businessservice/appHome/userCenter/update",
        method:"post",
        data: data
    })
}
// 查询群组管理者列表
export const  groupManagers =(query) =>{
    return request({
        url: "/businessservice/teachingGroup/group/managers",
        method:"get",
        params: query
    })
}
// 查询群组成员列表
export const  groupMemberList =(query) =>{
    return request({
        url: "/businessservice/teachingGroup/member/list",
        method:"get",
        params: query
    })
}
// 查询名校长工作室群组列表
export const  listHeadmasterGroup =(query) =>{
    return request({
        url: "/businessservice/teachingGroup/group/listHeadmasterGroup",
        method:"get",
        params: query
    })
}
// 查询名师工作室群组列表
export const  listTeacherGroup =(query) =>{
    return request({
        url: "/businessservice/teachingGroup/group/listTeacherGroup",
        method:"get",
        params: query
    })
}
// 删除用户
export const  userDelete =(query) =>{
    return request({
        url: "/businessservice/user/delete",
        method:"get",
        params: query
    })
}
// 添加平台管理员
export const  adminAdd =(query) =>{
    return request({
        url: "/businessservice/admin/add",
        method:"get",
        params: query
    })
}
// 取消平台管理员
export const  adminCancel =(query) =>{
    return request({
        url: "/businessservice/admin/cancel",
        method:"get",
        params: query
    })
}

// 删除字典基础数据
export const  deleteDict =(query) =>{
    return request({
        url: "/businessservice/basicdata/deleteDict",
        method:"get",
        params: query
    })
}

// 删除视频附件
export const  famousCourseDeleteVideoFile =(query) =>{
    return request({
        url: "/businessservice/famousCourse/deleteVideoFile",
        method:"get",
        params: query
    })
}

// 删除视频附件
export const  groupListAuthGroup =(query) =>{
    return request({
        url: "/businessservice/teachingGroup/group/listAuthGroup",
        method:"get",
        params: query
    })
}
// 查询参与附件
export const  fileJoinQueryFile =(data) =>{
    return request({
        url: "/businessservice/file/join/queryFile",
        method:"post",
        data: data
    })
}

// 同意注册申请
export const  regApplyAgree =(query) =>{
    return request({
        url: "/businessservice/regApply/agree",
        method:"get",
        params: query
    })
}
// 删除注册申请
export const  regApplyDelete =(query) =>{
    return request({
        url: "/businessservice/regApply/delete",
        method:"get",
        params: query
    })
}
// 分页查询注册申请列表
export const  regApplyPage =(data) =>{
    return request({
        url: "/businessservice/regApply/page",
        method:"post",
        data: data
    })
}
// 拒绝注册申请
export const  regApplyRefuse =(query) =>{
    return request({
        url: "/businessservice/regApply/refuse",
        method:"get",
        params: query
    })
}
// 查询待审批数
export const  regApplyHandleNum =(query) =>{
    return request({
        url: "/businessservice/regApply/handleNum",
        method:"get",
        params: query
    })
}