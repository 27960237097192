<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>{{ isTraVourse == 1 ? '培训视频' : '资源中心' }}</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="isLook == 0">上传视频</el-breadcrumb-item>
                <el-breadcrumb-item v-if="isLook == 1">{{ form.courseName }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row>
            <el-col :span="1">
                <div @click="isMl = false"
                    style="display: flex;flex-direction: column;justify-content: space-between;align-items: center;height: 100%;font-size: 15px;">
                    <div class="cursor" @click.stop="isMl = !isMl"
                        style="display: flex;justify-content: center;margin-top: 13vh;flex-direction: column;align-items: center;">
                        <img style="width:22px;" v-if="!isMl" src="../../assets/image/video-zj.png" alt="">
                        <img style="width:22px;" v-if="isMl" src="../../assets/image/video-zjAc.png" alt="">
                        <div v-if="!isMl" style="text-align: center;margin-top: 10px;">章节</div>
                        <div v-if="isMl" style="text-align: center;margin-top: 10px;color: #409eff;">章节</div>

                    </div>
                    <div class="cursor" @click="tofileShow(activeVideo)"
                        style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
                        <img style="width: 30px;" src="../../assets/image/video-file.png" alt="">
                        <div style="text-align: center;margin-top: 10px;">资料</div>
                    </div>
                    <div class="cursor" @click="toIsColledt"
                        style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
                        <img v-if="form.isCollect == 0" style="width: 30px;" src="../../assets/image/collect.png" alt="">
                        <img v-if="form.isCollect == 1" style="width: 30px;" src="../../assets/image/collect-blue.png"
                            alt="">
                        <div :title="form.collectCount" class="text-one" v-if="!form.isCollect"
                            style="text-align: center;margin-top: 10px;">收藏{{ form.collectCount }}</div>
                        <div :title="form.collectCount" class="text-one" v-else
                            style="text-align: center;margin-top: 10px;color: #409eff;">收藏{{ form.collectCount }}</div>
                    </div>
                    <div class="cursor" @click="toIsLike"
                        style="display: flex;justify-content: center;margin-bottom: 13vh;flex-direction: column;align-items: center;">
                        <img v-if="!form.isLike" style="width: 22px;" src="../../assets/image/dz.png" alt="">
                        <img v-else style="width:22px;" src="../../assets/image/dz-blue.png" alt="">
                        <div :title="form.likeCount" class="text-one" v-if="!form.isLike"
                            style="text-align: center;margin-top: 10px;">点赞{{ form.likeCount }}</div>
                        <div :title="form.likeCount" class="text-one" v-else
                            style="text-align: center;margin-top: 10px;color: #409eff;">点赞{{ form.likeCount }}</div>
                    </div>
                </div>
            </el-col>
            <el-col class="btx" :style="isMl ? 'opacity:1;' : 'opacity:0;width:0px;'" :span="10">
                <div id="vdWidth" class="custtoo" style="margin-right: 15px;height: calc(100vh - 140px);overflow: auto;">
                    <el-tree :data="dataSource" highlight-current ref="tree" @node-click="changeTree"
                        :props="{ label: 'courseName', id: 'courseId', children: 'childrenList' }" node-key="courseId"
                        default-expand-all :expand-on-click-node="false">
                        <template #default="{ node, data }">
                            <div class="custom-tree-node" style="display: flex;">
                                <div style="flex: 1;align-items: center; display: flex;" :title="node.label">
                                    <el-icon :size="17" v-if="data.videoList && data.videoList.length != 0">
                                        <VideoPlay /> &nbsp;
                                    </el-icon>
                                    <div :style="'width:' + (vdWidth - 150) + 'px;'" class="text-one" style="">{{ node.label
                                    }}
                                    </div>
                                </div>
                                <div v-if="isLook == 0">
                                    <a title="新增视频" v-if="isType == node.level" style="margin-left: 8px"
                                        @click.stop="addVideo(data)"><el-icon :size="17">
                                            <FolderAdd />
                                        </el-icon></a>
                                    <a title="新增" v-if="node.level != 3 && (isVideo == 0 ? true : (isVideo != node.level))"
                                        style="margin-left: 8px" @click.stop="append(data)"><el-icon :size="17">
                                            <Plus />
                                        </el-icon></a>
                                    <a title="编辑" style="margin-left: 8px" @click.stop="editCourse(data)"><el-icon
                                            :size="17">
                                            <EditPen />
                                        </el-icon></a>
                                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                        icon-color="#626AEF" title="确定删除该章节及其视频附件" @confirm="delCourse(node, data)">
                                        <template #reference>
                                            <a title="删除" v-if="node.level != 1" style="margin: 0 8px"> <el-icon :size="17">
                                                    <Delete />
                                                </el-icon></a>
                                        </template>
                                    </el-popconfirm>
                                </div>
                            </div>
                        </template>
                    </el-tree>
                </div>
            </el-col>
            <el-col :span="17" @click="isMl = false">
                <el-card shadow="never" style="height: calc(100vh - 140px);overflow: auto;position:relative;">
                    <div id="videoWidth" :style="'width:100%;height:'+videoHeight+'px'">
                        <video id="videoElement" v-if="isshow"
                            :poster="activeVideo.videoUrl + '?x-oss-process=video/snapshot,t_1,m_fast'" preload="none"
                            controls="controls" style="width: 100%;height: 100%;" controlsList="nodownload"
                            :src="activeVideo.videoUrl"></video>
                    </div>
                    <div class="mt5 ml5" style="display: flex;align-items: center;">
                        <el-icon :size="20">
                            <ChatDotSquare />
                        </el-icon>
                        &nbsp;{{ activeVideo.commentList && activeVideo.commentList.length }}
                    </div>
                    <div class="" style="">
                        <el-empty :image-size="80" v-if="activeVideo.commentList && activeVideo.commentList.length == 0"
                            description="暂无评论" />
                        <div v-for="(item, index) in activeVideo.commentList" class="mt15"
                            style="border-bottom: 1px solid #efefef;padding-bottom: 10px;">
                            <div style="display: flex;align-items: center;justify-content: space-between;">
                                <div style="display: flex;align-items: center;color: #959494;">
                                    <el-avatar :size="30" :src="item.avatar" />
                                    <div class="ml10">{{ item.trueName }}</div>
                                </div>
                                <div class="mr15 cursor" v-if="userId == item.userId">
                                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                        icon-color="#626AEF" title="确定确定删除该评论" @confirm="toDel(item, index)">
                                        <template #reference>
                                            <el-icon>
                                                <Delete />
                                            </el-icon>
                                        </template>
                                    </el-popconfirm>
                                </div>
                            </div>
                            <div style="padding-left: 40px;">
                                {{ item.content }}
                            </div>
                            <div style="padding-left: 40px;color: #959494;font-size: 12px;" class="mt5">
                                {{ timestampToTime(item.commentTime) }}
                            </div>
                        </div>
                    </div>
                    <div class="mt10">
                        <div class="mt-4 zsss">
                            <el-input v-model="content" placeholder="添加评论">
                                <template #append>
                                    <div class="cursor" @click="toContent"
                                        style="width: 100%;padding:5px 20px;display: flex;">
                                        <el-icon :size="24">
                                            <Position />
                                        </el-icon>
                                    </div>
                                </template>
                            </el-input>
                        </div>
                    </div>
                    <!-- <el-col :span="8" v-for="item in activeObj.videoList">
                            <div style="" class="tstih">
                                <div>
                                    <video :poster="item.videoUrl + '?x-oss-process=video/snapshot,t_1,m_fast'"
                                        preload="none" controls="controls" style="width: 100%;height: 200px;"
                                        :src="item.videoUrl"></video>
                                </div>
                                <div style="text-align: center;" class="text-one" :title="item.videoTitle">{{ item.videoTitle
                                }}</div>
                                <div class="start"
                                    style="position: absolute;right: 0;top:0;padding: 10px;background: #f1f1f1;">
                                    <el-icon v-if="isLook == 0" class="cursor" @click.stop="delFile(item)" title="删除">
                                        <Delete />
                                    </el-icon>
                                    <el-icon v-if="isLook == 0" class="cursor" style="margin: 0 10px;" title="上传资料"
                                        @click="addFile(item)">
                                        <DocumentAdd />
                                    </el-icon>
                                    <el-icon class="cursor" @click="tofileShow(item)" title="查看资料">
                                        <Document />
                                    </el-icon>
                                    {{ item.fileList.length }}
                                </div>
                            </div>

                        </el-col> -->
                    <!-- <div style="width: 100%;" v-if="!activeObj.videoList || activeObj.videoList.length == 0">
                            <el-empty class="qun-empty" description="暂无视频" />
                        </div> -->
                </el-card>
            </el-col>
            <el-col :span="6" @click="isMl = false">
                <div style="height: calc(100vh - 140px);overflow: auto;">
                    <div style="padding: 15px;">
                        <div style="display: flex;align-items:center;"><el-avatar :size="35"
                                :src="form.userAvatar + '?_=' + Date.now()" />
                            <div class="ml5">{{ form.userName }}</div>
                        </div>
                        <div class="mt5" style="font-size: 15px;">
                            {{ form.courseRecommend }}
                        </div>
                        <div style="margin-top:50px">
                            视频
                        </div>
                        <div class="cursor" @click="getActiveObj(item)" v-for="item in activeObj.videoList"
                            style="margin-top: 10px;">
                            <div>
                                <img :style="activeVideo.videoId == item.videoId ? 'border:1px solid #409eff' : ''"
                                    class="w-100 csvideo" :src="item.videoUrl + '?x-oss-process=video/snapshot,t_1,m_fast'" alt="">
                            </div>
                            <div class="text-one" :style="activeVideo.videoId == item.videoId ? 'color:#409eff' : ''">
                                {{ item.videoTitle }}
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-dialog draggable :title="'创建章节'" v-model="appCourseShow" width="60%">
            <el-form ref="appCourseForm" :model="appCourseForm" :rules="appCourseRules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="章节名称" prop="name">
                            <el-input v-model="appCourseForm.name" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitCourseForm">确 定</el-button>
                    <el-button @click="appCourseShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'编辑章节'" v-model="editCourseShow" width="60%">
            <el-form ref="editCourseForm" :model="appCourseForm" :rules="appCourseRules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="章节名称" prop="name">
                            <el-input v-model="appCourseForm.name" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submiteditCourseForm">确 定</el-button>
                    <el-button @click="editCourseShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'上传视频'" v-model="addVideoShow" width="60%">
            <el-upload :auto-upload="false" v-model:file-list="fileList" class="upload-demo" multiple
                :on-change="handleJAChange" :data="{ courseId: activeObj.courseId }" :headers="{ token }"
                accept=".MP4,.mp4,.avi,.AVI">
                <el-button type="primary">点击上传</el-button>
                <template #tip>
                    <div class="el-upload__tip">
                        只允许上传.MP4,.mp4,.avi,.AVI格式文件。
                    </div>
                </template>
            </el-upload>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="uploadVideo">确 定</el-button>
                    <el-button @click="addVideoShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'上传资料'" v-model="addFileShow" width="60%">
            <el-upload :auto-upload="false" v-model:file-list="fileList" class="upload-demo" multiple
                :on-change="handleJAChange" :before-upload="beforeJAUpload" :data="{ courseId: activeObj.courseId }"
                :headers="{ token }" accept=".pdf,.ppt,.pptx">
                <el-button type="primary">点击上传</el-button>
                <template #tip>
                    <div class="el-upload__tip">
                        只允许上传.pdf,.ppt,.pptx格式文件。
                    </div>
                </template>
            </el-upload>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="uploadVideoFile">确 定</el-button>
                    <el-button @click="addFileShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'附件'" v-model="fileShow" width="80%">
            <div style="display: flex;flex-wrap: wrap;">
                <div @click="xz(item)" style="margin: 15px;" v-for="item in showFileList" class="csbbb mb10 cursor">
                    <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载" class="eicon"
                        style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                        <Download />
                    </el-icon>
                    <img style="width: 250px;height:300px;object-fit: cover;" :src="item.imgUrl + '/1.png'" alt="">
                    <div style="text-align: center;" :title="item.fileName" class="text-one">{{ item.fileName }}</div>
                </div>
            </div>
            <div style="width: 100%;" v-if="showFileList.length == 0">
                <el-empty class="qun-empty" description="暂无附件" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import flvjs from 'flv.js/dist/flv.min.js'
// import flvjs from "flv.js";
import { ElMessage, ElMessageBox } from 'element-plus'

import { famousCourseCreate, famousCourseInfo, famousCourseUpdate, famousCourseChapter, famousCourseUploadTrainVideos, famousCourseDel, famousCourseUpdateChapter, famousCourseUploadVideoFile, famousCourseDeleteVideo, famousCourseView, likeClick, commentCreate, commentDelete, addOrCancel } from "@/api/admin/famousCourse"
import { trainInfo } from "@/api/admin/train";
import { queryDict, userList, queryUserInfo } from "@/api/admin/public";
import { ElLoading } from 'element-plus';
import { download } from "@/api/adminservice/public";
import { timestampToTime, timestampToDate } from "@/utils/date";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            timestampToTime,
            isshow: false,
            isMl: false,
            userId: '',
            content: '',
            activeVideo: {
                videoUrl: ''
            },
            userHeadImg: '',
            isLook: 0,
            isFamous: 0,
            isCollection: 0,
            isStudio: 0,
            isTeachStudio: 0,
            isStudioRoom: 0,
            isTraVourse: 0,
            isTraVourseList: 0,
            isList: 0,
            isVideo: 0,// 1 课 2 章 3 节
            isType: 1, // 1 课 2 章 3 节
            addFileShow: false,
            showFileList: [],
            fileShow: false,
            addVideoShow: false,
            addVideoCourseId: '',
            addVideoId: '',
            appCourseRules: {
                name: [
                    { required: true, message: "请输入章节名称", trigger: "blur" }
                ],
            },
            appCourseShow: false,
            appCourseForm: {
                name: '',
                parentId: ''
            },
            fileList: [],
            treedata: [],
            dataSource: [],
            courseId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            courseClassifyList: [],
            activeObj: {},
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            editCourseShow: false,
            // 表单参数
            form: {
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                leadUser: '',
                teamUsers: '',
                teamuseList: [],
            },
            vdWidth: 250,
            // 表单校验
            rules: {
            },
            delFileList: [],
            trainId: '',
            stageType: 0,
            flvPlayer: null,
            videoHeight:500,
        };
    },
    created() {
        this.getQueryDict();
    },
    mounted() {
        var videoWidth = document.getElementById('videoWidth');
        console.log(videoWidth.clientWidth);
        this.videoHeight = 9/16*videoWidth.clientWidth;
        this.userId = JSON.parse(this.$storage.get("userInfo")).userId
        this.vdWidth = (document.getElementById("vdWidth").clientWidth)
        console.log(this.vdWidth);
        this.courseId = this.$route.query.courseId
        if (this.$route.query.isLook) {
            this.isLook = this.$route.query.isLook
        }
        // this.courseId = '6520edf9e4b05da77f266b97'
        this.isLook = 1;
        if (this.$route.query.isList) {
            this.isList = this.$route.query.isList
        }
        if (this.$route.query.isFamous) {
            this.isFamous = this.$route.query.isFamous
        }
        if (this.$route.query.isTraVourse) {
            this.isTraVourse = this.$route.query.isTraVourse
        }
        if (this.$route.query.isTraVourseList) {
            this.isTraVourseList = this.$route.query.isTraVourseList
        }
        if (this.$route.query.trainId) {
            this.trainId = this.$route.query.trainId
        }
        if (this.$route.query.isStudio) {
            this.isStudio = this.$route.query.isStudio
        }
        if (this.$route.query.isTeachStudio) {
            this.isTeachStudio = this.$route.query.isTeachStudio
        }
        if (this.$route.query.isStudioRoom) {
            this.isStudioRoom = this.$route.query.isStudioRoom
        }
        if (this.$route.query.stageType) {
            this.stageType = this.$route.query.stageType
        }
        if (this.$route.query.isCollection) {
            this.isCollection = this.$route.query.isCollection
            if (this.$route.query.collectType == 1) {
                this.isFamous = 1
            }
        }
        this.getfamousCourseInfo()
    },
    unmounted() {
    },
    computed: {
    },
    methods: {
        handleDownload(item){
            console.log(item);
            download(item.fileUrl, item.fileName)
        },
        toDel(item, index) {
            commentDelete({ commentId: item.commentId }).then(res => {
                if (res.data.code == 1) {
                    this.activeVideo.commentList.splice(index, 1)
                    this.$message.success("删除成功");
                } else {
                    this.$message.error("操作失败");
                }
            })
            console.log(item, index);
        },
        toContent() {
            if (this.content == '') {
                this.$message.error("请输入评论内容")
                return;
            }
            console.log(this.activeVideo);
            commentCreate({ content: this.content, relationId: this.activeVideo.videoId }).then(res => {
                if (res.data.code == 1) {
                    this.content = '';
                    this.activeVideo.commentList.unshift(res.data.data)
                    this.$message.success("评论成功");
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        toIsLike() {
            // if (!this.form.isLike) {
            likeClick({ relationId: this.form.courseId }).then(res => {
                if (res.data.code == 1) {
                    if (this.form.isLike == 1) {
                        this.$message.success("取消点阵");
                        this.form.isLike = 0;
                        this.form.likeCount = this.form.likeCount - 1;
                    } else {
                        this.$message.success("点赞成功");
                        this.form.isLike = 1;
                        this.form.likeCount = this.form.likeCount + 1;
                    }
                    // this.getfamousCourseInfo()
                } else {
                    this.$message.error("操作失败")
                }
            })
            // }
        },
        toIsColledt() {
            // if (!this.form.isCollect) {
            addOrCancel({ collectType: this.isFamous ? 1 : 2, relationId: this.form.courseId }).then(res => {
                if (res.data.code == 1) {
                    if (this.form.isCollect == 1) {
                        this.$message.success("取消收藏");
                        this.form.isCollect = 0;
                        this.form.collectCount = this.form.collectCount - 1;
                    } else {
                        this.$message.success("收藏成功");
                        this.form.isCollect = 1;
                        this.form.collectCount = this.form.collectCount + 1;
                    }
                } else {
                    this.$message.error("操作失败")
                }
            })
            // }else{

            // }
        },
        getActiveObj(item) {
            this.flvPlayer.pause()
            this.flvPlayer.unload()
            this.flvPlayer.detachMediaElement()
            this.flvPlayer.destroy()
            this.flvPlayer = null
            this.isshow = false;
            this.activeVideo = item;

            setTimeout(() => {
                this.isshow = true;
                setTimeout(() => {
                    var videoElement = document.getElementById('videoElement');
                    console.log(videoElement);
                    var videoType = this.activeVideo.videoUrl.substring(this.activeVideo.videoUrl.length - 3).toLowerCase()

                    this.flvPlayer = flvjs.createPlayer({
                        type: videoType,
                        isLive: true,
                        hasAudio: true,
                        url: this.activeVideo.videoUrl
                    },
                        {
                            enableWorker: false, //不启用分离线程
                            enableStashBuffer: false, //关闭IO隐藏缓冲区
                            reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
                            autoCleanupSourceBuffer: true //自动清除缓存
                        })
                    this.flvPlayer.attachMediaElement(videoElement);
                    this.flvPlayer.load();
                    // this.flvPlayer.play();
                })
            })
            console.log(item);

        },
        delFile(item) {
            console.log(item);
            var _this = this;
            ElMessageBox.confirm(
                '是否删除该视频及附件?',
                '删除',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                famousCourseDeleteVideo({ videoId: item.videoId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("删除成功");
                        this.getfamousCourseInfo()
                        // this.$emit('isTg', true);
                    } else {
                        this.$message.error("操作失败")
                    }
                })

            }).catch(() => {

            })

            console.log(item);
        },
        xz(item) {
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
            // download(item.fileUrl, item.fileName)
        },
        tofileShow(item) {
            this.showFileList = item.fileList;
            this.fileShow = true;
        },
        addFile(item) {
            // console.log(item);
            this.addVideoId = item.videoId
            this.fileList = [];
            this.addFileShow = true;
        },
        uploadVideoFile() {
            console.log(this.activeObj.courseId)
            this.loadingInstance = ElLoading.service({
                lock: true,
                text: '提交中',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            console.log(this.fileList)
            let formdata = new FormData();
            this.fileList.map((item, index) => {
                console.log(item, index)
                var okey = 'files'
                formdata.append(okey, item.raw)
            })
            formdata.append('videoId', this.addVideoId)
            famousCourseUploadVideoFile(formdata).then(res => {
                if (res.data.code == 1) {
                    this.loadingInstance.close()
                    this.$message.success("新增成功");
                    this.addFileShow = false;
                    this.getfamousCourseInfo()
                    // this.$emit('isTg', true);
                } else {
                    this.loadingInstance.close()
                    this.$message.error("操作失败")
                }
            })
            // famousCourseUploadVideoFile
        },
        uploadVideo() {
            console.log(this.activeObj.courseId)
            this.loadingInstance = ElLoading.service({
                lock: true,
                text: '提交中',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            console.log(this.fileList)
            let formdata = new FormData();
            this.fileList.map((item, index) => {
                console.log(item, index)
                var okey = 'files'
                formdata.append(okey, item.raw)
            })
            formdata.append('courseId', this.addVideoCourseId)
            famousCourseUploadTrainVideos(formdata).then(res => {
                if (res.data.code == 1) {
                    this.loadingInstance.close()
                    this.$message.success("新增成功");
                    this.addVideoShow = false;
                    this.getfamousCourseInfo()
                    // this.$emit('isTg', true);
                } else {
                    this.loadingInstance.close()
                    this.$message.error("操作失败")
                }
            })
        },
        changeTree(data) {
            console.log(data);
            this.activeObj = data;
        },
        submiteditCourseForm() {
            this.$refs["editCourseForm"].validate(valid => {
                if (valid) {
                    famousCourseUpdateChapter({
                        courseId: this.appCourseForm.parentId,
                        name: this.appCourseForm.name,
                    }).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("创建成功");
                            this.editCourseShow = false;
                            this.appCourseForm = {
                                name: '',
                                parentId: ''
                            }
                            this.getfamousCourseInfo()
                        } else {
                            this.$message.error("操作失败")
                        }
                    })
                }
            });
        },
        submitCourseForm() {
            this.$refs["appCourseForm"].validate(valid => {
                if (valid) {
                    famousCourseChapter(this.appCourseForm).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("创建成功");
                            this.appCourseShow = false;
                            this.appCourseForm = {
                                name: '',
                                parentId: ''
                            }
                            this.getfamousCourseInfo()
                        } else {
                            this.$message.error("操作失败")
                        }
                    })
                }
            });
        },
        append(data) {
            this.appCourseForm = {
                name: '',
                parentId: data.courseId
            }
            if (data.videoList.length != 0) {
                this.$message.error('该层有视频，无法创建下级')
            } else {
                this.appCourseShow = true;
            }

            // famousCourseChapter({
            //     name: '节点111',
            //     parentId: data.courseId
            // }).then(res => {

            // })
            console.log(data);
        },
        addVideo(data) {
            console.log(data);
            this.fileList = [];
            if (data.childrenList) {
                if (data.childrenList.length == 0) {
                    this.addVideoCourseId = data.courseId
                    this.addVideoShow = true;
                } else {
                    this.$message.error('请在最下一级添加视频')
                }
            } else {
                this.addVideoCourseId = data.courseId
                this.addVideoShow = true;
            }


        },
        editCourse(data) {
            this.appCourseForm = {
                name: data.courseName,
                parentId: data.courseId
            }
            this.editCourseShow = true;
        },
        delCourse(node, data) {
            console.log(node, data);
            famousCourseDel({ courseId: data.courseId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getfamousCourseInfo()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        remove(node, data) {
            console.log(node, data);
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.courseCover != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        // handleRemove(file, uploadFiles) {
        //     console.log(file, uploadFiles)
        //     if (file.response.data) {
        //         this.delFileList.push(file.response.data)
        //     }
        // },
        getfamousCourseInfo() {
            console.log(this.activeObj);
            delete this.activeObj.videoList
            this.isType = 1;
            this.isVideo = 0;
            this.dataSource = [];
            // userList().then(res => {
            //     this.userList = res.data.data;
                famousCourseInfo({ courseId: this.courseId, isTraVourse: this.isTraVourse }).then(res => {
                    this.form = res.data.data;
                    famousCourseView({ courseId: this.form.courseId }).then(res => {

                    })
                    this.dataSource.push(this.form)
                    // queryUserInfo()
                    if (this.isLook == 0) {
                        var isz = false;
                        var isj = false;
                        if (this.form.videoList.length != 0) {
                            this.isVideo = 1;
                        } else {
                            this.isVideo = 0;
                        }
                        if (this.form.childrenList) {
                            this.form.childrenList.forEach(j => {
                                if (j.videoList.length != 0) {
                                    this.isVideo = 2;
                                }
                                if (j.childrenList) {
                                    j.childrenList.forEach(m => {
                                        if (m.videoList.length != 0) {
                                            this.isVideo = 3;
                                        }
                                        isj = true;
                                    })
                                } else {
                                    isz = true;
                                }

                            })
                        } else {

                        }
                        if (isj == true) {
                            this.isType = 3;
                        } else {
                            if (isz == true) {
                                this.isType = 2;
                            }
                        }
                        console.log(this.isType, this.isVideo);

                        // this.dataSource.push({
                        //     id: this.form.courseId,
                        //     label: this.form.courseName,
                        // })
                        console.log(this.dataSource);
                        if (this.activeObj.courseId) {
                            // this.form.forEach(e => {
                            if (this.form.childrenList) {
                                this.form.childrenList.forEach(j => {
                                    if (j.childrenList) {
                                        j.childrenList.forEach(m => {
                                            if (m.courseId == this.activeObj.courseId) {
                                                this.activeObj = m;
                                            }
                                            if (m.videoList && m.videoList.length != 0) {

                                            }
                                        })
                                    }
                                    if (j.videoList && j.videoList.length != 0) {

                                    }
                                    if (j.courseId == this.activeObj.courseId) {
                                        this.activeObj = j;
                                    }
                                })
                            }

                            if (this.form.courseId == this.activeObj.courseId) {
                                this.activeObj = this.form;
                            }
                            if (this.activeObj.videoList) {

                            } else {
                                this.activeObj = this.dataSource[0]
                            }
                            // })
                            this.$nextTick(() => {
                                // selectId：绑定的 node-key
                                this.$refs.tree.setCurrentKey(this.activeObj.courseId);
                            });
                        } else {
                            this.$nextTick(() => {
                                // selectId：绑定的 node-key
                                this.$refs.tree.setCurrentKey(this.dataSource[0].courseId);
                                this.activeObj = this.dataSource[0]
                            });
                        }
                    } else if (this.isLook == 1) {
                        if (this.form.videoList.length != 0) {
                            this.$nextTick(() => {
                                this.$refs.tree.setCurrentKey(this.dataSource[0].courseId);
                            });
                            this.activeObj = this.dataSource[0]
                            this.activeVideo = this.dataSource[0].videoList[0]

                        } else {
                            if (this.form.childrenList) {
                                try {
                                    this.form.childrenList.forEach(j => {
                                        if (j.childrenList) {
                                            j.childrenList.forEach(m => {
                                                if (m.courseId == this.activeObj.courseId) {
                                                    this.activeObj = m;
                                                    this.activeVideo = m.videoList[0]

                                                }
                                                if (m.videoList && m.videoList.length != 0) {
                                                    console.log(m.courseId);
                                                    this.activeObj = m;
                                                    this.activeVideo = m.videoList[0]

                                                    this.$nextTick(() => {
                                                        // selectId：绑定的 node-key
                                                        this.$refs.tree.setCurrentKey(m.courseId);
                                                    });
                                                    throw new Error()
                                                }
                                            })
                                        }
                                        if (j.videoList && j.videoList.length != 0) {
                                            console.log(j.courseId);
                                            this.activeObj = j;
                                            this.activeVideo = j.videoList[0]
                                            this.$nextTick(() => {
                                                // selectId：绑定的 node-key
                                                this.$refs.tree.setCurrentKey(j.courseId);
                                            });
                                            throw new Error()
                                        }
                                    })
                                } catch (error) {
                                    console.log(error);
                                }

                            }
                        }
                    }
                    setTimeout(() => {
                        this.isshow = true;
                        console.log(flvjs.isSupported());

                        setTimeout(() => {
                            var videoElement = document.getElementById('videoElement');
                            console.log(videoElement);
                            var videoType = this.activeVideo.videoUrl.substring(this.activeVideo.videoUrl.length - 3).toLowerCase()

                            this.flvPlayer = flvjs.createPlayer({
                                type: videoType,
                                isLive: true,
                                hasAudio: true,
                                url: this.activeVideo.videoUrl
                            },
                                {
                                    enableWorker: false, //不启用分离线程
                                    enableStashBuffer: false, //关闭IO隐藏缓冲区
                                    reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
                                    autoCleanupSourceBuffer: true //自动清除缓存
                                })
                            this.flvPlayer.attachMediaElement(videoElement);
                            this.$forceUpdate()
                            this.flvPlayer.load();
                            // this.flvPlayer.play();
                        }, 1000)

                    }, 500);

                })
            // })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)
        },
        handleXAChange(file) {
            console.log(file);
        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getQueryDict() {
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.courseCover = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)

            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.courseId) {
                        famousCourseUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/famousCourse', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        famousCourseCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/famousCourse', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            if (this.isCollection == 1) {
                this.replace('/collectionCenter', { isSx: 1 })
            } else {
                if (this.isStudio == 1) {
                    this.replace('/studioManage')
                }
                if (this.isTeachStudio == 1) {
                    this.replace('/teachStudioManage')
                }
                if (this.isStudioRoom == 1) {
                    this.replace('/studioRoomDetail', { groupId: this.$route.query.groupId })
                }
                if (this.isFamous == 1) {
                    if (this.isList == 0) {
                        this.replace('/famousCourse', { isSx: 1 })
                    } else {
                        if (this.stageType == 1) {
                            this.replace('/famousGspList', { isSx: 1, stageType: this.stageType })
                        } else if (this.stageType == 2) {
                            this.replace('/famousQbList', { isSx: 1, stageType: this.stageType })
                        } else if (this.stageType == 3) {
                            this.replace('/famousJbList', { isSx: 1, stageType: this.stageType })
                        } else if (this.stageType == 4) {
                            this.replace('/famousKhList', { isSx: 1, stageType: this.stageType })
                        }

                    }
                }
                if (this.isTraVourse == 1) {
                    if (this.isTraVourseList == 1) {
                        this.replace('/traCourseList')
                    } else {
                        if (this.isList == 0) {
                            this.replace('/trainDetail', { trainId: this.trainId })
                        } else {
                            this.replace('/traCourse', { isSx: 1 })
                        }
                    }
                }
            }



            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.csvideo{
    width: 100%;
    height: 150px;
    object-fit: cover;
}
video::-webkit-media-controls-timeline {
    display: none !important;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.tstih {
    position: relative;
    padding: 15px;
}

.start {
    display: none;
}

.tstih:hover .start {
    display: block;
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.custtoo /deep/ .el-tree-node__label {
    flex: 1;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.btx {
    width: 300px;
    position: absolute;
    left: 60px;
    background: #fff;
    z-index: 999;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.zsss /deep/ .el-input-group__append {
    padding: 0px !important;
}
</style>