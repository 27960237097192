<template>
    <div class="app-container">
        <el-card shadow="never">
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="16" class="mb0">
                        <el-form-item label="类型">
                            <el-select clearable @change="getList" v-model="queryParams.groupNature" placeholder="请选择">
                                <el-option :label="'名师工作室'" :value="2" />
                                <el-option :label="'名校长工作室'" :value="3" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.groupName" placeholder="请输入办公室名称" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <el-row style="max-height:calc(100vh - 235px);overflow-y: auto;" v-loading="loading">
                <el-col class="w-90" v-for="(item, index) in dataList" :key="index" :xs="12" :sm="12" :md="12" :lg="8">
                    <el-card @click="goPage(item)" :body-style="{ padding: '0px' }">
                        <div class="btnStart" style="display: flex;position: relative;">
                            <div style="padding:14px 0px 10px 14px;position: relative;">
                                <img style="width:110px;height: 110px;" :src="item.groupAvatar" alt=""
                                    class="image">
                            </div>
                            <div style="padding: 16px 10px 10px 10px" class="fs-14 wbba">
                                <div style="display: flex;" class="mb5">
                                    <div :title="item.groupName" style="color: #333;font-weight: bold;" class="text-one">{{ item.groupName }}</div>
                                </div>
                                <div style="display: flex;" class="mb5">
                                    <div class="">{{ item.groupNatureName }}</div>
                                </div>
                                <div style="display: flex;" class="mb5">
                                    <div :title="item.groupIntroduce" class="text-two">工作室介绍：{{ item.groupIntroduce }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb15" style="display: flex;justify-content: end;align-items: center;margin-right: 5%;">
                            <el-button @click.stop="getstudioCareOrCancel(item)" type="primary">
                                取消关注
                            </el-button>
                        </div>
                    </el-card>
                </el-col>
                <el-empty style="margin: 0 auto;padding-top: 10vh;" v-if="dataList.length == 0" :image-size="200" />
            </el-row>
        </el-card>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import { queryDict } from "@/api/admin/public";
import { userCareStudio } from "@/api/admin/system";
import { studioCareOrCancel } from "@/api/admin/studio";

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            subjectList: [],
            periodList: [],
            gradeList: [],
            gAFileList: [],
            token: this.$storage.get("token"),
            roleList: [
                { name: '已参与', value: 1 },
                { name: '未参与', value: 2 },
            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                groupNature: '',
                groupName: '',
            },
            courseClassifyList:[],
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: []
        };
    },
    created() {
        this.getQueryDict()
        this.getList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        goPage(item) {
            this.$router.push({
                path: "/studioRoomDetail",
                query: {
                    groupId: item.groupId,
                }
            });
        },
        getstudioCareOrCancel(row) {
            studioCareOrCancel({ groupId: row.groupId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('取消成功')
                    this.getList();
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'famous_course_classify' }).then(res => {
                this.courseClassifyList = res.data.data;
            })
        },
        /** 查询基础：基数数据列表 */
        getList() {
            userCareStudio().then(res => {
                if (this.queryParams.groupNature !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.groupNature == this.queryParams.groupNature
                    })
                }
                if (this.queryParams.groupName !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.groupName.toString().indexOf(this.queryParams.groupName) != -1
                    })
                }
                this.dataList = res.data.data;
                this.loading = false;
            });
        },
        goDetail(row) {
            this.$router.push({
                path: "/lessonDetail",
                query: {
                    lessonId: row.lessonId,
                }
            });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.w-90 {
    margin-bottom: 15px;
    margin-top: 10px;
}

.w-90 /deep/ .el-card.is-always-shadow {
    width: 90%;
    margin: 0 auto;
    cursor: pointer;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.image {
    width: 100%;
    display: block;
}

.chosebtn {
    padding-top: 10px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

.chosebtn div {
    margin-right: 10px;
}

.chosebtn img {
    padding: 5px;

    margin: 0 auto;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.bor-lan {
    border: 1px solid #409eff
}

.wbba {
    word-break: break-all;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.pxbutton {
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.start {
    display: none;
}

.btnStart:hover .start {
    display: block;
}

.cjtag {
    position: absolute;
    top: 14px;
    right: 0;
    font-size: 14px;
    background: #f1f1f1;
    padding: 2px 7px;
    border-radius: 4px;
    color: #d44c2d;
}
.stpBox{
    display: flex;
    align-items: center;
}
</style>