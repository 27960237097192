/*图片上传地址*/
//const  serviceUrl = "http://localhost:8282"
//const  serviceUrl = "http://47.93.86.37:8282"
const  serviceUrl = "https://zbh.zbform.com/"
/*页面跳转*/
const  testServiceUrl = "http://localhost:8787"
//const  testServiceUrl = "http://47.93.86.37:8787"
//const  testServiceUrl = "https://group.zbform.com"
/*文件服务*/
const  fileUrl = "https://test-oss-step1.oss-accelerate.aliyuncs.com"
//const  fileUrl = "https://zbh-oss-formal.oss-accelerate.aliyuncs.com"
const userAvatarUrl = serviceUrl + "/webgroupservice/user/headImg"
const qunAvatarUrl = serviceUrl + "/webgroupservice/qun/avatar"
const groupAvatarUrl = serviceUrl + "/webgroupservice/group/avatar"

export default {
    serviceUrl,
    testServiceUrl,
    fileUrl,
    userAvatarUrl,
    qunAvatarUrl,
    groupAvatarUrl,
}
