<template>
    <div class="">
        <el-switch v-if="isShowHidden" :disabled="disabled" v-model="isHide" inactive-text="是否匿名">
        </el-switch>
        <div class="mb30" v-for="(item, index) in previewData.questionList">
            <div class="mb15" v-if="item.type != 3">
                <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                }}.</span>{{ item.questionName }}
                    <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                        item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容'
                            : ''
                    }}]</span><span v-if="item.type == 4 && item.totalScore"
                        style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                            item.totalScore }})</span>
                    <span v-if="item.type == 3" style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                        item.totalScore }})</span>
                </h3>
            </div>
            <div class="mb15" v-if="item.type == 3">
                <div class="mb15">
                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                    }}.</span>{{ item.questionName }}
                        <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                            item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ?
                                '文字内容'
                                : ''
                        }}]</span>
                        <span style="color: #efa030;font-weight: normal;font-size: 14px;margin-right: 15px;">(满分：{{
                            item.totalScore }})</span>
                    </h3>
                </div>
                <div>
                    <el-input-number :disabled="disabled" v-model="item.questionAnswer" size="small" step-strictly :min="0"
                        :max="item.totalScore" />
                </div>
            </div>
            <div v-if="item.type == 1">
                <el-radio-group :disabled="disabled" class="ml-4" v-model="item.questionAnswer">
                    <el-radio :label="items.itemId" size="small" v-for="(items, indexs) in item.itemList">
                        <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                            items.itemValue }})</span></el-radio>
                </el-radio-group>
            </div>
            <div v-if="item.type == 2">
                <el-checkbox-group :disabled="disabled" class="ml-4" v-model="item.questionAnswer">
                    <el-checkbox :label="items.itemId" size="small" v-for="(items, indexs) in item.itemList">
                        <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                            items.itemValue }})</span>
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <div v-if="item.type == 4" style="width: 100%;">
                <el-input :disabled="disabled" style="width: 100%;" :rows="2" type="textarea" placeholder="" v-model="item.questionAnswer" />
            </div>
        </div>

    </div>
</template>
  
<script>

export default {
    name:'previewData',
    components: {
        
    },
    props:{
        isShowHidden:{
            type:Boolean,
            default:true
        },
        previewData:{
            type: Object,
            default:{}
        },
        isHide:{
            type:Boolean,
            default:false
        },
        disabled:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            source: "https://group.zbform.com/file/workBook.pdf",
            pageNum: 1,
            scale: 1,  // 缩放比例
            numPages: 0, // 总页数
        };
    },
    created(){
        console.log(this.previewData);
    },
    mounted() {
        console.log(this.previewData);
    },
    computed: {

    },
    methods: {

    },
};
</script>
  
<style scoped></style>
  