<template>
    <div class="app-container">
        <el-card shadow="never">
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                <el-row>
                    <el-col :span="24" class="mb0">
                        <el-button type="primary" @click="handleAdd">新增群组</el-button>
                    </el-col>
                </el-row>
            </el-form>

            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                <el-row>
                    <el-col :span="24" class="mb0 mr0">
                        <el-form-item class="pull-right" label="客户名称" prop="termId">
                            <el-select @change="changeCustom" v-model="queryParams.customId" placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId" :label="item.customName"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <el-table highlight-current-row class="mt15" height="calc(100vh - 220px)" border v-loading="loading"
                :data="dataList">
                <el-table-column width="300" label="群组名称" prop="qunName" />
                <el-table-column label="组织结构" prop="orgName" />
                <el-table-column width="120" label="群主账号" prop="adminAccount" />
                <el-table-column width="110" label="群主姓名" prop="adminName" />
                <el-table-column width="120" label="云本配置" prop="bookIds">
                    <template #default="scope">
                        <el-button type="primary" icon="el-icon-view" size="small" @click.prevent="readbookIds(scope.row)">
                            云本类型
                        </el-button>
                    </template>
                </el-table-column>

                <el-table-column width="100" label="操作" class-name="small-padding fixed-width">
                    <template #default="scope">
                        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">编辑
                        </el-button>
                        <!-- <el-popconfirm v-if="scope.row.isStop == 0" confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                            icon-color="#626AEF" title="确定停用该客户" @confirm="handleStop(scope.row)">
                            <template #reference>
                                <el-button size="mini" type="text" icon="el-icon-switch-button">停用
                                </el-button>
                            </template>
                        </el-popconfirm>
                        <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                            icon-color="#626AEF" title="确定删除该客户" @confirm="handleDelete(scope.row)">
                            <template #reference>
                                <el-button size="mini" type="text" icon="el-icon-delete">删除
                                </el-button>
                            </template>
                        </el-popconfirm> -->

                    </template>
                </el-table-column>
            </el-table>


            <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
        </el-card>
        <el-dialog :title="'云本资源'" v-model="readBook" width="60%">
            <div class="fl-warp" style="display: flex;">
                <div v-for="item in readBookList" class="csbbb">
                    <img style="width: 100px;margin-top: 10px;"
                        :src="'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/book/' + item.id + '/img/cover.png_original'"
                        alt="" class="image">
                    <div style="width: 100px;text-align: center;" class="text-one mb15">{{ item.bookName }}</div>
                </div>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import {
    queryAllGroup,
    getData,
    delData,
    addData,
    updateData,
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryCustoms, stopCustoms, delCustoms, queryTreeCustomOrg } from "@/api/adminservice/custom";
import { queryCustomQun } from "@/api/adminservice/qun"

export default {
    name: "groupSet",
    components: {
    },
    data() {
        return {
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                customName: '',
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            orgList: [],
            customList: []
        };
    },
    created() {
        this.getList();

    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
            if (this.$route.query.customId) {
                this.queryParams.customId = this.$route.query.customId
                this.changeCustom()
            } else {
            }
        }
    },
    mounted() {
        if (this.$route.query.customId) {
            this.queryParams.customId = this.$route.query.customId
            this.changeCustom()
        } else {
        }
        this.getList();

        this.getBookList();

    },
    methods: {
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgName
                        };
                        parentIds = [item.orgName];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgName);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgName
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            // console.log(parentIds)
            return parentIds

        },
        changeCustom() {
            queryTreeCustomOrg({ customId: this.queryParams.customId }).then(res => {

                this.orgList = res.data.data;
                // console.log(4343,this.findParentNode('f66df71c0ee9425d920132c40b7bba2e'))

                queryCustomQun({ customId: this.queryParams.customId }).then(res => {
                    res.data.data.forEach(e => {
                        console.log(e.orgId)

                        console.log(this.findParentNode(e.orgId))

                        e.orgName = this.findParentNode(e.orgId).join(' -> ')
                        // console.log(434,this.findParentNode(e.orgId))
                    })
                    this.dataList = res.data.data;
                })
            })
        },
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                this.customList = res.data.data;
                if (!this.$route.query.customId) {
                    this.queryParams.customId = res.data.data[0].customId;
                    this.changeCustom()
                } else {
                }
            });
        },

        // 停用
        handleStop(row) {
            stopCustoms({ customId: row.customId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停用成功");
                    this.getList();
                } else {
                    this.$message.error("停用失败");
                }
            })
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            delCustoms({ customId: row.customId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },

        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.did);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/groupSetAdd",
                query: {
                    customId: this.queryParams.customId,
                }
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/groupSetAdd",
                query: {
                    customId: this.queryParams.customId,
                    groupData: JSON.stringify(row),
                    orgId: row.orgId
                }
            });
            // this.taskdata = row;
            // this.open = true;
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
</style>