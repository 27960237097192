<template>
    <div class="app-container">
        <el-card shadow="never">
            <el-row>
                <el-col :span="16">
                    <el-radio-group @change="changeCativeType" v-model="cativeType" size="mini">
                        <el-radio-button label="线下培训" />
                        <el-radio-button label="线上录播" />
                        <el-radio-button label="线上直播" />
                    </el-radio-group>
                </el-col>
            </el-row>
            <teachTrain v-if="cativeType == '线下培训' || cativeType == '线上录播' || cativeType == '线上直播'" ref="topic"></teachTrain>
        </el-card>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import listeningEvaluat from "./listeningEvaluat.vue";
import teachTrain from "./teachTrain.vue"

export default {
    name: "ptStatistics",
    components: {
        teachTrain
    },
    data() {
        return {
            cativeType: '线下培训',
            timestampToTime,

        };
    },
    created() {
    },
    mounted() {
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {

        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        changeCativeType() {
            if (this.cativeType == '线下培训' || this.cativeType == '线上录播' || this.cativeType == '线上直播') {
                this.$refs.topic.changeCativeType(this.cativeType)
            }
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}
</style>