<template>
    <div class="app-container">
        <el-card class="mb15" v-has-btn="'1,2,3'">
            <el-row>
                <el-col :span="8" class="mb0">
                    <el-button v-has-btn="'1,2,3'" type="primary" @click="handleAdd">新增培训任务</el-button>
                    <el-button v-has-btn="'1,2,3'" type="primary" @click="handleLbAdd">新增培训任务量表</el-button>
                </el-col>
                <el-col :span="16" class="mb0" v-if="role == 1 || role == 2">
                    <div class="pull-right"
                        style="display: flex;align-items: center;transform: translateY(-3px);font-size: 14px;">
                        <el-checkbox @change="getList()" v-model="isSHowMine" size="large" />
                        <div class="ml5" style="white-space: nowrap;">我参与的</div>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="状态">
                            <el-select clearable @change="getList()" v-model="queryParams.status" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="类型">
                            <el-select clearable @change="getList()" v-model="queryParams.type" placeholder="请选择">
                                <el-option v-for="item in typeList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.title" placeholder="请输入培训任务标题" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                    :height="`calc(100vh - ${this.$store.state.zdy.role != 1 && this.$store.state.zdy.role != 2 && this.$store.state.zdy.role != 3 ? 290 : 380}px)`"
                    v-loading="loading" :data="dataList" highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column width="300" label="培训任务标题" prop="title">
                        <template #default="scope">
                            <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.title }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="160" label="参与人员" prop="joinUserList">
                        <template #default="scope">
                            <div :title="scope.row.joinUserText" class="text-one">{{ scope.row.joinUserText }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column width="90" label="类型" prop="type">
                        <template #default="scope">
                            <div v-if="scope.row.type == 0">线上直播</div>
                            <div v-if="scope.row.type == 1">线上录播</div>
                            <div v-if="scope.row.type == 2">线下培训</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="150" label="发布时间" prop="releaseTime">
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.releaseTime) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="260" label="任务时间" prop="time">
                        <template #default="scope">
                            <div>
                                {{ timestampToTime(scope.row.beginTime) }}至{{ timestampToTime(scope.row.endTime) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="150" label="签到时段" prop="time">
                        <template #default="scope">
                            <div v-if="scope.row.type == 2">
                                {{ timestampToDate(scope.row.signInBeginTime) }}至{{ timestampToDate(scope.row.signInEndTime)
                                }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="150" label="签退时段" prop="time">
                        <template #default="scope">
                            <div v-if="scope.row.type == 2">
                                {{ timestampToDate(scope.row.signOutBeginTime) }}至{{
                                    timestampToDate(scope.row.signOutEndTime)
                                }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column min-width="200" label="培训地点" prop="signInAddress">
                        <template #default="scope">
                            <div v-if="scope.row.type == 2" :title="scope.row.signInAddress" class="text-one">{{
                                scope.row.signInAddress }}</div>
                            <div v-else></div>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="200" label="培训链接" prop="linkUrl">
                        <template #default="scope">
                            <div :title="scope.row.linkUrl" class="text-one">{{ scope.row.linkUrl }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="附件" prop="file">
                        <template #default="scope">
                            <el-popover placement="bottom-start" :width="125" trigger="click" :min-width="'auto'">
                                <div>
                                    <div v-if="scope.row.fileList.length != 0">
                                        <div :title="item.fileName" style="display: flex;align-items:center;"
                                            v-for="(item, index) in scope.row.fileList">
                                            <div @click="goFileDetail(item)" :title="item.fileName"
                                                class="filtxt text-one cursor">
                                                {{ index + 1 }}. {{ item.fileName }}
                                            </div>
                                            <el-icon @click="xz(item)" style="color: #409efe;" class="cursor ml15">
                                                <Download />
                                            </el-icon>
                                        </div>
                                    </div>
                                </div>
                                <template #reference>
                                    <div style="color: #409efe;" class="cursor">
                                        {{ scope.row.fileList.length }}
                                    </div>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column width="90" label="任务状态" prop="status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 0">初始</div>
                            <div v-if="scope.row.status == 1">进行中</div>
                            <div v-if="scope.row.status == 2">已结束</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" label="打卡情况" prop="dkqk">
                        <template #default="scope">
                            <div @click="goDkqkDetail(scope.row)" style="color: #409efe;" class="text-one cursor">
                                <el-button
                                    v-if="(scope.row.status != 0)" size="mini" >去查看
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" width="390" label="操作" class-name="small-padding fixed-width">
                        <template #default="scope">
                            <!-- <el-button size="mini" type="text" icon="el-icon-view" @click="goDetail(scope.row)">查看
                        </el-button> -->
                            <el-button v-if="scope.row.status != 2" v-has-btn="'1,2,3'" size="mini" type="primary"
                                icon="el-icon-edit" @click="handleUpdate(scope.row)">编辑
                            </el-button>
                            <el-button v-has-btn="'1,2,3,4'" v-if="scope.row.status == 0" style="margin-left: 12px;"
                                size="mini" type="primary" icon="" @click="handleRelease(scope.row)">发布培训
                            </el-button>
                            <el-popover placement="bottom-start" :width="125" trigger="click" :min-width="'auto'">
                                <div>
                                    <div v-if="scope.row.scaleList && scope.row.scaleList.length != 0">
                                        <div @click="handleComplete(item, scope.row)" :title="item.title"
                                            class="filtxt text-one cursor" v-for="(item, index) in scope.row.scaleList">
                                            {{ index + 1 }}. {{ item.title }}
                                        </div>
                                    </div>
                                </div>
                                <template #reference>
                                    <el-button
                                        v-if="scope.row.scaleList && (scope.row.status == 1 || scope.row.status == 2) && scope.row.scaleList.length != 0"
                                        style="" size="mini" type="primary" icon="">{{ scope.row.status == 1 ? '去完成' : '去查看'
                                        }}
                                    </el-button>
                                </template>
                            </el-popover>
                            <el-button v-has-btn="'1,2,3'" size="mini" v-if="scope.row.status == 1" type="primary" icon=""
                                @click="handleStop(scope.row)">停止培训
                            </el-button>

                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该任务" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button v-has-btn="'1,2,3'" size="mini" type="danger" icon="el-icon-delete">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy" v-if="!isSHowMine">
                <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                    :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <previewData :disabled="disabled" ref="editData" v-if="previewShow" :previewData="previewData" :isHide="isHide">
            </previewData>
            <template #footer v-if="!disabled">
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitPreviewForm">确 定</el-button>
                    <el-button @click="closePreview">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'打卡情况'" v-model="dkqkOpen" width="650px">
            <el-table :span-method="objectSpanMethod" show-overflow-tooltip class="" height="calc(100vh - 500px)" border
                :data="dkqkList" highlight-current-row>
                <!-- <el-table-column type="selection" width="55" /> -->
                <el-table-column width="" :label="'姓名'" prop="title">
                    <template #default="scope">
                        <div class="text-one">
                            {{ scope.row.trueName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="签到时间" prop="levelName">
                    <template #default="scope">
                        <div class="">
                            {{ timestampToTime(scope.row.signInTime) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="签退时间" prop="levelName">
                    <template #default="scope">
                        <div class="">
                            {{ timestampToTime(scope.row.signOutTime) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="" label="状态" prop="signStatus">
                    <template #default="scope">
                        <div class="" v-if="scope.row.signStatus == 1">
                            签到未签退
                        </div>
                        <div class="" v-if="scope.row.signStatus == 2">
                            未签到签退
                        </div>
                        <div class="" v-if="scope.row.signStatus == 3">
                            正常打卡
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { trainPageTrain, trainListJoin, trainListAll, trainRelease, trainStop, trainDel, trainInfo } from "@/api/admin/train"
import { rateScaleInfo, rateRecordScore, rateRecordScoreInfo } from "@/api/admin/evaluation"
import { lessonList, lessonSubmit, lessonStop, lessonRelease, lessonGaFiles, delLessonFile } from "@/api/admin/lesson"
import { statsTrainSign } from "@/api/admin/statistics";

import {
    exportData
} from "@/api/demo/data";
import { timestampToTime, timestampToDate } from "@/utils/date";
import { queryDict, userList } from "@/api/admin/public";
import previewData from "@/components/previewData.vue";
import { download } from "@/api/adminservice/public";

export default {
    name: "scenarioMain",
    components: {
        previewData
    },
    data() {
        return {
            dkqkOpen: false,
            dkqkList: [],
            role: this.$storage.get("role"),
            isSHowMine: false,
            selectRow: {},
            scFa: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            timestampToDate,
            gAFileList: [],
            token: this.$storage.get("token"),
            statusList: [
                { name: '初始', value: 0 },
                { name: '进行中', value: 1 },
                { name: '已结束', value: 2 },
            ],
            typeList: [
                { name: '线上直播', value: 0 },
                { name: '线上录播', value: 1 },
                { name: '线下培训', value: 2 },
            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                status: '',
                title: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                type: '',
                pageNum: 1,
                pageSize: 10,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            selectObj: [],
            previewData: {},
            previewShow: false,
            lessonId: '',
            isHide: false,
            disabled: false,
        };
    },
    created() {
        if (this.$storage.get("role") == 6) {
            this.isSHowMine = true;
        }
        this.getQueryDict()
        this.getList();
    },
    activated() {

        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        objectSpanMethod(e) {
            // console.log(e);
            if (e.columnIndex === 0) {
                if (e.row.col != 0) {
                    return {
                        rowspan: e.row.col,
                        colspan: 1,
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            }
        },
        goDkqkDetail(row) {
            this.dkqkList = [];
            console.log(row);
            var userId = ''
            if (this.$storage.get("role") == 6) {
                userId = JSON.parse(this.$storage.get("userInfo")).userId
            } else {
                userId = ''
            }
            statsTrainSign({ trainId: row.trainId, userId: userId }).then(res => {
                res.data.data.forEach(e => {
                    if (e.signList && e.signList.length != 0) {
                        e.signList.forEach((j, n) => {
                            if (n == 0) {
                                this.dkqkList.push({ trueName: e.trueName, signStatus: j.signStatus, signInTime: j.signInTime, signOutTime: j.signOutTime, col: e.signList.length })
                            } else {
                                this.dkqkList.push({ trueName: e.trueName, signStatus: j.signStatus, signInTime: j.signInTime, signOutTime: j.signOutTime, col: 0 })
                            }
                        })
                    }else{
                        this.dkqkList.push({ trueName: e.trueName, signStatus: '', signInTime: '', signOutTime: '', col: 1 })
                    }

                })
                this.dkqkOpen = true;
            })
        },
        xz(e) {
            download(e.fileUrl, e.fileName)
        },
        tableRowClassName: function (row, index) {
            console.log(row.row)
            if (!row.row.isMine) {
                return 'hidden-row';
            }
            return '';
        },
        submitPreviewForm() {
            console.log(this.previewData);
            var obj = {};
            obj.scaleId = this.$refs.editData.previewData.scaleId;
            obj.lessonId = this.lessonId;
            obj.answerList = [];
            if (this.$refs.editData.isHide) {
                obj.isHide = 1;
            } else {
                obj.isHide = 0;
            }
            var totalScore = 0;
            this.$refs.editData.previewData.questionList.forEach(e => {
                if (e.type == 2) {
                    var txt = e.questionAnswer.toString()
                    obj.answerList.push({
                        questionId: e.questionId,
                        questionAnswer: txt
                    })
                    e.questionAnswer.forEach(m => {
                        e.itemList.forEach(j => {
                            if (m == j.itemId) {
                                totalScore += Number(j.itemValue)
                            }
                        })
                    })
                } else {
                    if (e.type == 1) {
                        e.itemList.forEach(j => {
                            if (e.questionAnswer == j.itemId) {
                                totalScore += Number(j.itemValue)
                            }
                        })
                    }
                    if (e.type == 3) {
                        totalScore += Number(e.questionAnswer)
                    }
                    obj.answerList.push({
                        questionId: e.questionId,
                        questionAnswer: e.questionAnswer
                    })
                }

            })
            obj.totalScore = totalScore;
            rateRecordScore(obj).then(res => {
                if (res.data.code == 1) {
                    this.previewShow = false;
                    this.isHide = false;
                    this.$message.success('提交成功')
                    this.getList();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
            console.log(this.previewData);
            console.log(obj);

        },
        closePreview() {
            this.previewShow = false;
        },
        handleComplete(item, row) {
            this.disabled = false;
            if (row.status == 2) {
                this.disabled = true
            }
            this.lessonId = row.trainId;
            this.isHide = false;
            if (item.isSub == 1) {
                rateRecordScoreInfo({ lessonId: this.lessonId, scaleId: item.scaleId }).then(res => {
                    if (res.data.data.info.isHide == 1) {
                        this.isHide = true;
                    }
                    this.previewData = res.data.data.info;
                    this.previewData.questionList.forEach(e => {
                        if (e.type == 2) {
                            e.questionAnswer = e.questionAnswer.split(',');
                        } else {
                            // e.questionAnswer = '';
                        }
                    })
                    this.previewShow = true;
                    console.log(this.previewData);
                })
            } else {
                rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                    this.previewData = res.data.data;
                    this.previewData.questionList.forEach(e => {
                        if (e.type == 2) {
                            e.questionAnswer = [];
                        } else {
                            e.questionAnswer = '';
                        }

                    })
                    this.previewShow = true;
                    console.log(this.previewData);
                })
            }

        },
        goFileDetail(item) {
            console.log(item);
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleDiscuss(row) {
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: row.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleExceed(e) {
            console.log(e)
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.url) {
                delLessonFile({ fileUrl: file.url })
            }
            if (file.response) {
                delLessonFile({ fileUrl: file.response.data })
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.selectRow.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAJaChange(e, type) {
            console.log(this.selectRow.gajaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gajaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAkjChange(e, type) {
            console.log(this.selectRow.gakjFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gakjFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxtChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gaxtFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleGA(row) {
            this.scFa = true;
            this.selectRow = row;
            console.log(this.selectRow)
            // lessonGaFiles({lessonId:row.lessonId}).then(res=>{

            // })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.dataList = [];
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            if (!this.isSHowMine) {
                trainPageTrain(this.queryParams).then(res => {
                    // if (this.queryParams.status !== '') {
                    //     res.data.data = res.data.data.filter(itemA => {
                    //         return itemA.status == this.queryParams.status
                    //     })
                    // }
                    // if (this.queryParams.type !== '') {
                    //     res.data.data = res.data.data.filter(itemA => {
                    //         return itemA.type == this.queryParams.type
                    //     })
                    // }
                    // if (this.queryParams.title !== '') {
                    //     res.data.data = res.data.data.filter(itemA => {
                    //         return itemA.title.toString().indexOf(this.queryParams.title) != -1
                    //     })
                    // }
                    this.total = res.data.data.total;

                    var userId = JSON.parse(this.$storage.get("userInfo")).userId
                    res.data.data.list.forEach(e => {
                        var isMine = false;
                        var textList = [];
                        e.joinUserList.forEach(j => {
                            textList.push(j.trueName)
                            if (userId == j.userId) {
                                isMine = true;
                            }
                        })
                        // console.log(textList)
                        e.joinUserText = textList.join('、')
                        // console.log(e.rateTeacherText)
                        // if (this.selectRow && this.selectRow.lessonId == e.lessonId) {
                        //     this.selectRow = e
                        // }
                        // e.scaleText = textList.join('、')
                        // console.log(e.scaleText)
                        e.isMine = isMine;
                    })
                    this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
                    // this.dataList.sort(function (a, b) {
                    //     if (a.releaseTime > b.releaseTime) { return -1; }
                    //     if (a.releaseTime < b.releaseTime) { return 1; }
                    //     return 0;
                    // })
                    this.loading = false;
                });
            } else {
                trainListJoin().then(res => {
                    if (this.queryParams.status !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.status == this.queryParams.status
                        })
                    }
                    if (this.queryParams.type !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.type == this.queryParams.type
                        })
                    }
                    if (this.queryParams.title !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.title.toString().indexOf(this.queryParams.title) != -1
                        })
                    }
                    var userId = JSON.parse(this.$storage.get("userInfo")).userId
                    res.data.data.forEach(e => {
                        var isMine = false;
                        var textList = [];
                        e.joinUserList.forEach(j => {
                            textList.push(j.trueName)
                            if (userId == j.userId) {
                                isMine = true;
                            }
                        })
                        // console.log(textList)
                        e.joinUserText = textList.join('、')
                        e.isMine = isMine;
                    })
                    this.dataList = JSON.parse(JSON.stringify(res.data.data));
                    this.dataList.sort(function (a, b) {
                        if (a.releaseTime > b.releaseTime) { return -1; }
                        if (a.releaseTime < b.releaseTime) { return 1; }
                        return 0;
                    })
                    this.loading = false;
                })
            }

        },
        /** 删除按钮操作 */
        handleDelete(row) {
            trainDel({ trainId: row.trainId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/trainDetail",
                query: {
                    trainId: row.trainId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/trainAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        handleLbAdd() {
            this.$router.push({
                path: "/rateScale",
                query: {
                    scaleType: 2
                }
            });
        },
        handleRelease(row) {
            trainRelease({ trainId: row.trainId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("发布失败");
                }
            })
        },
        handleSubmit(row) {
            console.log(row)
            if (row.jaFiles.length == 0 && row.kjFiles.length == 0 && row.xtFiles.length == 0) {
                this.$message.error("请先上传文件");
                return
            }
            lessonSubmit({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("提请成功");
                    this.getList();
                } else {
                    this.$message.error("提请失败");
                }
            })
        },
        handleStop(row) {
            trainStop({ trainId: row.trainId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停止成功");
                    this.getList();
                } else {
                    this.$message.error("停止失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/trainAdd",
                query: {
                    trainId: row.trainId,
                }
            });
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-table /deep/ .hidden-row {
    display: none;
}
</style>