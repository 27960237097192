import request from "@/utils/request";
// 新增课程章节
export const famousCourseChapter = (data) => {
    return request({
        url: "/businessservice/famousCourse/courseChapter",
        method: "post",
        data: data
    })
}
// 创建名师优课
export const famousCourseCreate = (data) => {
    return request({
        url: "/businessservice/famousCourse/create",
        method: "post",
        data: data
    })
}
// 删除课程或章节
export const famousCourseDel = (query) => {
    return request({
        url: "/businessservice/famousCourse/delete",
        method: "get",
        params: query
    })
}
// 删除课程视频
export const famousCourseDeleteVideo = (query) => {
    return request({
        url: "/businessservice/famousCourse/deleteVideo",
        method: "get",
        params: query
    })
}
// 查询名师优课详细信息
export const famousCourseInfo = (query) => {
    if (query.isTraVourse == 1) {
        return request({
            url: '/businessservice/course/train/info',
            method: "get",
            params: { courseId: query.courseId },
        })
    } else {
        return request({
            url: '/businessservice/famousCourse/info',
            method: "get",
            params: { courseId: query.courseId },
        })
    }

}
// 查询名师优课课程列表
export const famousCourseListFamous = (query) => {
    return request({
        url: "/businessservice/famousCourse/listFamousCourses",
        method: "get",
        params: query
    })
}
// 查询已发布名师优课课程列表
export const famousCourseListReleaseCourse = (query) => {
    return request({
        url: "/businessservice/famousCourse/listReleaseCourse",
        method: "get",
        params: query
    })
}
// 查询名师优课课程全部信息(包含层级、视频)
export const famousCourseListTreeFamous = (query) => {
    return request({
        url: "/businessservice/famousCourse/listTreeFamousCourses",
        method: "get",
        params: query
    })
}
// 分页查询名师优课
export const famousCoursePageFamousCourses = (data) => {
    return request({
        url: "/businessservice/famousCourse/pageFamousCourses",
        method: "post",
        data: data
    })
}
// 发布课程
export const famousCourseRelease = (query) => {
    return request({
        url: "/businessservice/famousCourse/releaseCourse",
        method: "get",
        params: query
    })
}
// 更新名师优课
export const famousCourseUpdate = (data) => {
    return request({
        url: "/businessservice/famousCourse/update",
        method: "post",
        data: data
    })
}
// 修改章节名称
export const famousCourseUpdateChapter = (data) => {
    return request({
        url: "/businessservice/famousCourse/updateChapter",
        method: "post",
        data: data
    })
}
// 上传课程封面
export const famousCourseUploadCover = (data) => {
    return request({
        url: "/businessservice/famousCourse/uploadCover",
        method: "post",
        data: data
    })
}
// 批量上传名师优课视频
export const famousCourseUploadTrainVideos = (data) => {
    return request({
        url: "/businessservice/famousCourse/uploadVideos",
        method: "post",
        ContentType: 'multipart/form-data',
        data: data
    })
}
// 批量上传视频附件
export const famousCourseUploadVideoFile = (data) => {
    return request({
        url: "/businessservice/famousCourse/uploadVideoFile",
        method: "post",
        data: data
    })
}
// 观看课程
export const famousCourseView = (query) => {
    return request({
        url: "/businessservice/famousCourse/view",
        method: "get",
        params: query
    })
}
// 点赞
export const likeClick = (query) => {
    return request({
        url: "/businessservice/dp/like/click",
        method: "get",
        params: query
    })
}
// 添加评论
export const commentCreate = (data) => {
    return request({
        url: "/businessservice/dp/comment/create",
        method: "post",
        data: data
    })
}
// 删除评论
export const commentDelete = (query) => {
    return request({
        url: "/businessservice/dp/comment/delete",
        method: "get",
        params: query
    })
}
// 收藏或取消收藏
export const addOrCancel = (data) => {
    return request({
        url: "/businessservice/collect/addOrCancel",
        method: "post",
        data: data
    })
}
// 查询用户收藏列表
export const collectList = (query) => {
    return request({
        url: "/businessservice/collect/list",
        method: "get",
        params: query
    })
}

// 上传课程视频
export const courseVideoUpload = (data) => {
    return request({
        url: "/businessservice/courseVideo/upload",
        method: "post",
        data: data
    })
}

// 重命名课程视频
export const famousCourseRenameVideo = (data) => {
    return request({
        url: "/businessservice/famousCourse/renameVideo",
        method: "post",
        data: data
    })
}

// 拖动课程视频
export const courseVideoMove = (data) => {
    return request({
        url: "/businessservice/courseVideo/move",
        method: "post",
        data: data
    })
}

// 置顶课程或取消置顶
export const famousCourseTop = (query) => {
    return request({
        url: "/businessservice/famousCourse/top",
        method: "get",
        params: query
    })
}

// 同步课程信息
export const syncCourse = (query) => {
    return request({
        url: "/businessservice/sync/course",
        method: "get",
        params: query
    })
}

// 添加或更新视频节点
export const videoNodeUpdate = (data) => {
    return request({
        url: "/businessservice/videoNode/createOrUpdate",
        method: "post",
        data: data
    })
}
// 删除视频节点
export const videoNodeDelete = (query) => {
    return request({
        url: "/businessservice/videoNode/delete",
        method: "get",
        params: query
    })
}
// 查询视频节点列表
export const videoNodeList = (query) => {
    return request({
        url: "/businessservice/videoNode/list",
        method: "get",
        params: query
    })
}

// 创建课件
export const coursewareCreate = (data) => {
    return request({
        url: "/businessservice/courseware/create",
        method: "post",
        data: data
    })
}

// 新增课程章节
export const coursewareCreateChapter = (data) => {
    return request({
        url: "/businessservice/courseware/createChapter",
        method: "post",
        data: data
    })
}

// 删除课件或章节
export const coursewareDelete = (query) => {
    return request({
        url: "/businessservice/courseware/delete",
        method: "get",
        params: query
    })
}

// 删除课件附件
export const coursewareDeleteAnnex = (query) => {
    return request({
        url: "/businessservice/courseware/deleteAnnex",
        method: "get",
        params: query
    })
}

// 删除课件SWF文件
export const coursewareDeleteSwf = (query) => {
    return request({
        url: "/businessservice/courseware/deleteSwf",
        method: "get",
        params: query
    })
}

// 查询课件详情
export const coursewareInfo = (query) => {
    return request({
        url: "/businessservice/courseware/info",
        method: "get",
        params: query
    })
}

// 分页查询课件
export const coursewarePage = (data) => {
    return request({
        url: "/businessservice/courseware/page",
        method: "post",
        data: data
    })
}

// 上传课件介绍图片
export const coursewareRcommendImg = (data) => {
    return request({
        url: "/businessservice/courseware/recommendImg",
        method: "post",
        data: data
    })
}

// 发布课件或取消发布
export const coursewareReleaseOrCancel = (query) => {
    return request({
        url: "/businessservice/courseware/releaseOrCancel",
        method: "get",
        params: query
    })
}

// 重命名课件SWF文件
export const coursewareRenameSwf = (data) => {
    return request({
        url: "/businessservice/courseware/renameSwf",
        method: "post",
        data: data
    })
}

// 置顶课件或取消置顶
export const coursewareTopOrCancel = (query) => {
    return request({
        url: "/businessservice/courseware/topOrCancel",
        method: "get",
        params: query
    })
}

// 更新课件
export const coursewareUpdate = (data) => {
    return request({
        url: "/businessservice/courseware/update",
        method: "post",
        data: data
    })
}

// 修改章节名称
export const coursewareUpdateChapter = (data) => {
    return request({
        url: "/businessservice/courseware/updateChapter",
        method: "post",
        data: data
    })
}

// 批量上传课件附件
export const coursewareUploadAnnex = (data) => {
    return request({
        url: "/businessservice/courseware/uploadAnnex",
        method: "post",
        ContentType:'multipart/form-data',
        data: data
    })
}

// 批量上传课件文件（SWF）
export const coursewareUploadSwfs = (data) => {
    return request({
        url: "/businessservice/courseware/uploadSwfs",
        method: "post",
        ContentType:'multipart/form-data',
        data: data
    })
}
// 同步课件信息
export const coursewareSync = (query) => {
    return request({
        url: "/businessservice/sync/courseware",
        method: "get",
        params: query
    })
}
// 查询用户授权课件
export const coursewareUserCourseware = (data) => {
    return request({
        url: "/businessservice/courseware/userCourseware",
        method: "post",
        data: data
    })
}