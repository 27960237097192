const state = {
    isActive:false,
    activeLineWidth: 2,
    activeColor: "#000000",
    role:1,
    isSetInter1:null,
    isSetInter2:null,

};

const mutations = {
    setIsActive: (state, n) => {
        state.isActive = n;
    },
    setRole:(state, n) => {
        state.role = Number(n);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
};