<template>
    <div class="app-container" style="padding-left: 0;">
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
            @submit.native.prevent="handleQuery">
            <el-row class=" mb15">
                <el-col :span="6" class="mb0" v-has-btn="'1,2,3'">
                    <el-form-item label="所属组织">
                        <el-tree-select @change="getList" clearable
                            :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                            v-model="queryParams.orgId" default-expand-all :data="treeData" check-strictly
                            :render-after-expand="true" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" class="mb0">
                    <el-form-item label="时间">
                        <el-date-picker style="width: 100%;" :disabled-date="disabledDate" v-model="queryParams.rzTime" format="YYYY/MM/DD"
                            value-format="x" clearable @change="getList" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="5" class="mb0">
                    <el-radio-group @change="changeTableType" v-model="queryParams.tableType" size="mini">
                        <el-radio-button label="按个人分" />
                        <el-radio-button label="按组织分" v-has-btn="'1,2,3'" />
                    </el-radio-group>
                </el-col>
                <el-col :span="5" class="mr0 mb0">
                    <el-form-item class="pull-right">
                        <el-button  v-has-btn="'1,2,3'" style="" @click="changeZxtType" class="mb0 mr0 pull-right" type="primary"
                            size="mini">柱状图</el-button>
                        <el-button @click="handleExport" type="primary" size="mini">导出</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="bre9e mt15">
            <el-table header-cell-class-name="bgf2" show-overflow-tooltip height="calc(100vh - 250px)" v-loading="loading"
                :data="dataList" highlight-current-row>
                <!-- <el-table-column type="selection" width="55" /> -->
                <el-table-column type="index" label="序号" width="60" />
                <el-table-column v-if="queryParams.tableType == '按个人分'" width="350" label="姓名" prop="trueName">
                    <template #default="scope">
                        <div :title="scope.row.trueName" class="text-one">
                            {{ scope.row.trueName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="queryParams.tableType == '按个人分' ? '所属组织' : '组织名称'" prop="orgName">
                    <template #default="scope">
                        <div :title="scope.row.orgName" class="text-one">
                            {{ scope.row.orgName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="90" label="授课总数" prop="skcount">
                    <template #default="scope">
                        <div @click="toBk(scope.row, 'sk')" style="color: #409efe;" class="text-one cursor">{{
                            scope.row.skcount }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="90" label="评课总数" prop="pkcount">
                    <template #default="scope">
                        <div @click="toBk(scope.row, 'pk')" style="color: #409efe;" class="text-one cursor">{{
                            scope.row.pkcount }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="90" label="听课总数" prop="tkcount">
                    <template #default="scope">
                        <div @click="toBk(scope.row, 'tk')" style="color: #409efe;" class="text-one cursor">{{
                            scope.row.tkcount }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog :title="'自定义柱状图'" v-model="zxtTypeOpen" width="60%">
            <el-form-item label="按所属组织统计" label-width="120px">
                <el-tree-select @change="changeZxtOrg" clearable multiple filterable style="width: 100%;"
                    :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                    v-model="zxtQueryParams.orgIdList" default-expand-all :data="treeData" check-strictly
                    :render-after-expand="true" />
            </el-form-item>
            <el-form-item label="按所选人员统计" label-width="120px">
                <el-select @change="changeZxtUser" style="width: 100%;" multiple filterable
                    v-model="zxtQueryParams.userIdList" placeholder="请选择人员">
                    <el-option v-for="item in userList" :key="item.userId" :label="item.trueName" :value="item.userId" />
                </el-select>
            </el-form-item>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitXtTyp">确 定</el-button>
                    <el-button @click="zxtTypeOpen = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'听评课'" v-model="bkOpen" width="80%">
            <el-table show-overflow-tooltip @current-change="handleCurrentChange" class="" height="calc(100vh - 500px)"
                border v-loading="loading"
                :data="lookType == 'sk' ? selectObj.skevaluationList : lookType == 'pk' ? selectObj.pkevaluationList : lookType == 'tk' ? selectObj.tkevaluationList : selectObj.evaluationList"
                highlight-current-row>
                <!-- <el-table-column type="selection" width="55" /> -->
                <el-table-column type="index" label="序号" width="60" />
                <el-table-column label="课程标题" prop="title">
                    <template #default="scope">
                        <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                            @click="goDetail(scope.row)">
                            {{ scope.row.title }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="270" label="任务时段" prop="releaseTime">
                    <template #default="scope">
                        <div v-if="queryParams.rzTime && queryParams.rzTime.length != 0">
                            <span :style="scope.row.beginTime < queryParams.rzTime[0] ? 'color:red' : ''">{{
                                timestampToTime(scope.row.beginTime) }}</span> - <span
                                :style="scope.row.endTime > queryParams.rzTime[1] ? 'color:red' : ''">{{
                                    timestampToTime(scope.row.endTime) }}</span>
                        </div>
                        <div v-else>
                            {{ timestampToTime(scope.row.beginTime) }} - {{ timestampToTime(scope.row.endTime) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="100" label="听课记录" prop="tkjl">
                    <template #default="scope">
                        <div style="color: #409efe;" class="text-one cursor" @click="getLessonRecord(scope.row)">
                            {{ scope.row.recordList.length }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="90" label="角色" prop="role">
                    <template #default="scope">
                        <div v-if="scope.row.role == 0">授课人</div>
                        <div v-if="scope.row.role == 1">评课人</div>
                        <div v-if="scope.row.role == 2">听课人</div>
                        <div v-if="scope.row.role == 3">评课组长</div>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="总分" width="60" prop="zf">
                    <template #default="scope">
                        <div v-if="scope.row.zf">{{ scope.row.zf }}</div>
                        <div v-else>0</div>
                    </template>
                </el-table-column> -->
                <el-table-column width="100" label="量表附件" prop="lbfj" v-if="lookType == 'pk'">
                    <template #default="scope">
                        <div v-if="scope.row.rateObj && scope.row.rateObj.submitTime" style="color: #409efe;"
                            class="text-one cursor" @click="goPklbXq(scope.row.rateObj)">
                            查看详情
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="分数" width="60" prop="totalScore" v-if="lookType == 'pk'">
                    <template #default="scope">
                        <div>
                            {{ scope.row.rateObj?.totalScore }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="提交时间" prop="submitTime" v-if="lookType == 'pk'">
                    <template #default="scope">
                        <div>
                            {{ timestampToTime(scope.row.rateObj?.submitTime) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="平均" width="60" prop="pj" v-if="lookType == 'sk'">
                    <template #default="scope">
                        <div v-if="scope.row.pj && scope.row.pj != 'NaN'">{{ scope.row.pj }}</div>
                        <div v-else>0</div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="评课量表" prop="rateList" v-if="lookType == 'sk'">
                    <template #default="scope">
                        <div style="color: #409efe;" class="text-one cursor" @click="gopklb(scope.row.rateList)">
                            全部量表
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="课程总结" prop="kczj" v-if="lookType == 'pk'">
                    <template #default="scope">
                        <div @click="totpkzj(scope.row)" v-if="scope.row.sum && scope.row.role == 3"
                            :title="scope.row.sum.content" style="color: #409efe;" class="text-one cursor">
                            {{ scope.row.sum.content }}
                        </div>
                        <div @click="totpkzj(scope.row)" v-if="!scope.row.sum && scope.row.role == 3"
                            style="color: #409efe;" class="text-one cursor">
                            发表总结
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog :title="'听评课总结'" v-model="tpkzj" width="60%">
            <el-input type="textarea" :rows="5" maxlength="1000" v-model="tpkzjObj.content" placeholder="请输入发表总结" />
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="sumbittpkzj">确 定</el-button>
                    <el-button @click="tpkzj = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-if="zxtOpen" :title="'柱状图'" v-model="zxtOpen" width="70%">
            <div id="grbk" style="width: 100%;height:50vh;">
            </div>
        </el-dialog>
        <el-dialog :title="'听课记录'" v-model="tpkjl" width="80%">
            <el-table show-overflow-tooltip class="" height="calc(100vh - 500px)" border :data="tpkList">
                <el-table-column type="index" label="序号" width="60" />
                <el-table-column label="姓名" width="140" prop="trueName">
                    <template #default="scope">
                        <div>
                            {{ scope.row.trueName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="听课记录" prop="tkjl">
                    <template #default="scope">
                        <div>
                            <img v-for="item in scope.row.imgList" style="width: 100px;height:100px;margin-right:10px;"
                                :src="item" alt="">
                            <div>
                                {{ scope.row.content }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="创建时间" prop="tkjl">
                    <template #default="scope">
                        <div>
                            {{ timestampToTime(scope.row.createTime) }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog :title="'评课量表'" v-model="pklb" width="550px">
            <el-table show-overflow-tooltip class="" height="calc(100vh - 500px)" border :data="pklbList">
                <el-table-column type="index" label="序号" width="60" />
                <el-table-column label="姓名" width="140" prop="trueName">
                    <template #default="scope">
                        <div v-if="scope.row.isHide == 1">
                            匿名
                        </div>
                        <div v-else>
                            {{ scope.row.trueName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="量表附件" prop="lbfj">
                    <template #default="scope">
                        <div style="color: #409efe;" class="text-one cursor" @click="goPklbXq(scope.row)">
                            查看详情
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="分数" width="60" prop="totalScore">
                    <template #default="scope">
                        <div>
                            {{ scope.row?.totalScore }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="提交时间" prop="submitTime">
                    <template #default="scope">
                        <div>
                            {{ timestampToTime(scope.row?.submitTime) }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <div style="height: 60vh;overflow-y: auto;">
                <previewData :disabled="true" ref="editData" v-if="previewShow" :previewData="previewData" :isHide="isHide">
                </previewData>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { statsOwnLesson, statsTeamLesson, statsEvaluationLesson, summaryCreateOrUpdate, lessonRecordListAll } from "@/api/admin/statistics";
import { orgListTree } from "@/api/admin/system";
import table2excel from 'js-table2excel';
import { bannerListAll } from "@/api/admin/news";
import { timestampToTime } from "@/utils/date";
import echarts from "echarts";
import { rateScaleInfo, rateRecordScoreInfo } from "@/api/admin/evaluation";
import previewData from "@/components/previewData.vue";

export default {
    name: "integTeachingResearch",
    components: {
        previewData
    },
    data() {
        return {
            previewShow: false,
            previewData: {},
            zxtOpen: false,
            tpkzj: false,
            tpkjl: false,
            pklb: false,
            pklbList: [],
            tpkList: [],
            tpkzjObj: {
                content: '',
                lessonId: ''
            },
            zxtTypeOpen: false,
            bkOpen: false,
            statusList: [
                { name: '未发布', value: 0 },
                { name: '已发布', value: 1 },
                { name: '隐藏', value: 2 },
            ],
            treeData: [],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 查询参数
            queryParams: {
                tableType: '按个人分',
                orgId: '',
                rzTime: [],
            },
            zxtQueryParams: {
                orgIdList: [],
                userIdList: [],
            },
            selectObj: [],
            grbkOption: {
                color: ['#3398DB'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '总数',
                        type: 'bar',
                        barMaxWidth: 30,
                        data: [10, 52, 200, 334, 390, 330, 220]
                    }
                ]
            },
            userList: [],
            lookType: null,
            isHide: false,
        };
    },
    created() {
        this.getOrgListTree();
        this.getList();
    },
    mounted() {
        // this.getGrbkOption()
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        goPklbXq(row) {
            this.isHide = false;
            console.log(row)
            // this.previewShow = true;
            rateRecordScoreInfo({ lessonId: row.lessonId, scaleId: row.scaleId, userId: row.userId }).then(res => {
                if (res.data.data.info.isHide == 1) {
                    this.isHide = true;
                }
                this.previewData = res.data.data.info;
                this.previewData.questionList.forEach(e => {
                    if (e.type == 2) {
                        if (e.questionAnswer) {
                            e.questionAnswer = e.questionAnswer.split(',');
                        }

                    } else {
                        // e.questionAnswer = '';
                    }
                })
                this.previewShow = true;
            })
        },
        gopklb(row) {
            console.log(row);
            this.pklb = true;
            this.pklbList = row;
        },
        getLessonRecord(row) {

            console.log(row);
            lessonRecordListAll({ lessonId: row.lessonId }).then(res => {
                this.tpkList = res.data.data;
                this.tpkjl = true;
            })
        },
        sumbittpkzj() {
            summaryCreateOrUpdate(this.tpkzjObj).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('操作成功')
                    this.tpkzj = false;
                    this.bkOpen = false;
                    this.getList();
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        totpkzj(row) {
            if (row.isPkzz) {
                this.tpkzjObj.lessonId = row.lessonId;
                this.tpkzjObj.content = row.sum ? row.sum.content : '';
                this.tpkzj = true;
            }
            console.log(row);

        },
        changeTableType() {
            this.getList();
        },
        changeCativeType(type) {
            console.log(type);
            this.queryParams = {
                tableType: '按个人分',
                orgId: '',
                rzTime: [],
            };
            this.zxtQueryParams = {
                orgIdList: [],
                userIdList: [],
            };
            // this.getGrbkOption();
            this.getList();
        },
        toBk(row, type) {
            console.log(type);
            this.lookType = type;
            this.selectObj = row;
            if (this.lookType == 'sk') {
                this.selectObj.skevaluationList.forEach(e => {
                    var zf = 0;
                    var zr = 0;
                    e.rateList.forEach(j => {
                        if (j.status == 1) {
                            zr++;
                            if (j.totalScore) {
                                zf += j.totalScore
                            }

                        }
                    })
                    console.log(zr, zf);
                    e.zf = zf;
                    e.pj = Math.round(zf / zr)
                })
            } else if (this.lookType == 'pk') {
                this.selectObj.pkevaluationList.forEach(e => {
                    var zf = 0;
                    var zr = 0;
                    e.rateList.forEach(j => {


                        if (j.status == 1) {
                            zr++;
                            if (j.totalScore) {
                                zf += j.totalScore
                            }

                        }
                        if (this.selectObj.userId == j.userId) {
                            e.rateObj = j;
                        }
                    })
                    console.log(zr, zf);
                    e.zf = zf;
                    e.pj = Math.round(zf / zr)

                })
            } else if (this.lookType == 'tk') {
                this.selectObj.tkevaluationList.forEach(e => {
                    var zf = 0;
                    var zr = 0;
                    e.rateList.forEach(j => {
                        if (j.status == 1) {
                            zr++;
                            if (j.totalScore) {
                                zf += j.totalScore
                            }

                        }
                    })
                    console.log(zr, zf);
                    e.zf = zf;
                    e.pj = Math.round(zf / zr)
                })
            } else {
                this.selectObj.evaluationList.forEach(e => {
                    var zf = 0;
                    var zr = 0;
                    e.rateList.forEach(j => {
                        if (j.status == 1) {
                            zr++;
                            if (j.totalScore) {
                                zf += j.totalScore
                            }

                        }
                    })
                    console.log(zr, zf);
                    e.zf = zf;
                    e.pj = Math.round(zf / zr)
                })
            }
            this.bkOpen = true;
            console.log(type);
            console.log(row);
        },
        changeTime() {
            console.log(this.queryParams.rzTime);
        },
        changeZxtType() {
            this.zxtQueryParams = {
                orgIdList: [],
                userIdList: [],
            },
            this.zxtTypeOpen = true;
        },
        submitXtTyp() {
            if (this.zxtQueryParams.orgIdList.length == 0 && this.zxtQueryParams.userIdList.length == 0) {
                this.getGrbkOption()
            }
            if (this.zxtQueryParams.orgIdList.length != 0) {
                this.getGrbkOption()
            }
            if (this.zxtQueryParams.userIdList.length != 0) {
                this.getUserGrbkOption()
            }
            this.zxtTypeOpen = false;
        },
        getOrgListTree() {
            orgListTree().then(res => {
                this.treeData = res.data.data;
            })
        },
        handleExport() {
            var timestamp = timestampToTime(Date.parse(new Date()));
            const tableDatas = JSON.parse(JSON.stringify(this.dataList))//这里面填写你接口的数据
            const datas = tableDatas
            if (this.queryParams.tableType != '按个人分') {
                const column = [
                    {
                        title: '组织名称',
                        key: 'orgName',
                        type: 'text',
                    },
                    {
                        title: '授课总数',
                        key: 'skcount',
                        type: 'text',
                    },
                    {
                        title: '评课总数',
                        key: 'pkcount',
                        type: 'text',
                    },
                    {
                        title: '听课总数',
                        key: 'tkcount',
                        type: 'text',
                    }
                ]
                table2excel(column, datas, '听评课组织统计' + timestamp)

            } else {
                const column = [
                    {
                        title: '姓名',
                        key: 'trueName',
                        type: 'text',
                    },
                    {
                        title: '组织名称',
                        key: 'orgName',
                        type: 'text',
                    },
                    {
                        title: '授课总数',
                        key: 'skcount',
                        type: 'text',
                    },
                    {
                        title: '评课总数',
                        key: 'pkcount',
                        type: 'text',
                    },
                    {
                        title: '听课总数',
                        key: 'tkcount',
                        type: 'text',
                    }
                ]
                table2excel(column, datas, '听评课个人统计' + timestamp)

            }

        },
        getGrbkOption() {
            statsTeamLesson().then(res => {
                this.grbkOption.xAxis[0].data = []
                this.grbkOption.series[0].data = []
                if (this.zxtQueryParams.orgIdList.length != 0) {
                    res.data.data = res.data.data.filter(itemA => {
                        return this.zxtQueryParams.orgIdList.indexOf(itemA.orgId) != -1;
                    })
                }
                res.data.data.forEach(e => {
                    this.grbkOption.xAxis[0].data.push(e.orgName)
                    this.grbkOption.series[0].data.push(e.amount)
                })
                this.zxtOpen = true;
                setTimeout(() => {
                    let myChart3 = echarts.init(document.getElementById('grbk'))
                    myChart3.setOption(this.grbkOption, true)
                });
            })
        },
        getUserGrbkOption() {
            this.grbkOption.xAxis[0].data = []
            this.grbkOption.series[0].data = []
            var userOption = JSON.parse(JSON.stringify(this.dataList));
            userOption = userOption.filter(itemA => {
                return this.zxtQueryParams.userIdList.indexOf(itemA.userId) != -1;
            })
            userOption.forEach(e => {
                this.grbkOption.xAxis[0].data.push(e.trueName)
                this.grbkOption.series[0].data.push(e.aidAmount + e.guideAmount + e.leadAmount)
            })
            console.log(this.grbkOption);
            this.zxtOpen = true;
            setTimeout(() => {
                let myChart3 = echarts.init(document.getElementById('grbk'))
                myChart3.setOption(this.grbkOption, true)
            });
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.dataList = [];
            this.userList = [];
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            statsEvaluationLesson().then(res => {
                if (this.queryParams.orgId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.orgId == this.queryParams.orgId
                    })
                }
                res.data.data.forEach(e => {
                    if (this.$storage.get("role") != 1 && this.$storage.get("role") != 2 && this.$storage.get("role") != 3) {
                        e.userList = e.userList.filter(itemA => {
                            return itemA.userId == userId
                        })
                    }
                    e.userList.forEach(j => {
                        if (this.$storage.get("role") != 1 && this.$storage.get("role") != 2 && this.$storage.get("role") != 3) {
                            j.evaluationList = j.evaluationList.filter(itemA => {
                                console.log(itemA.lectureTeacher, itemA.leadTeacher, userId);
                                return (itemA.lectureTeacher == userId || itemA.leadTeacher == userId)
                            })
                        }
                        j.skevaluationList = [];
                        j.pkevaluationList = [];
                        j.tkevaluationList = [];
                        if (this.queryParams.rzTime && this.queryParams.rzTime.length != 0) {
                            j.evaluationList = j.evaluationList.filter(itemA => {
                                return ((itemA.beginTime >= this.queryParams.rzTime[0]) && (itemA.beginTime <= this.queryParams.rzTime[1])) || ((itemA.endTime >= this.queryParams.rzTime[0]) && (itemA.endTime <= this.queryParams.rzTime[1]))
                            })
                        }
                        j.evaluationList.forEach(m => {
                            if (m.leadTeacher == userId) {
                                m.isPkzz = true;
                            }
                            //听评课身份 0-授课人  1-评课人 2-听课人 3-评课组长
                            if (m.role == 0) {
                                j.skevaluationList.push(m)
                            } else if (m.role == 1 || m.role == 3) {
                                j.pkevaluationList.push(m)
                            } else if (m.role == 2) {
                                j.tkevaluationList.push(m)
                            }
                        })
                        j.orgName = e.orgName;
                        j.orgId = e.orgId;
                        j.count = j.evaluationList.length
                        j.skcount = j.skevaluationList.length
                        j.pkcount = j.pkevaluationList.length
                        j.tkcount = j.tkevaluationList.length
                        if (this.queryParams.tableType == '按个人分') {
                            this.dataList.push(j)
                        }
                        this.userList.push(j)
                    })
                })
                if (this.queryParams.tableType == '按组织分') {
                    res.data.data.forEach(e => {
                        e.skevaluationList = [];
                        e.pkevaluationList = [];
                        e.tkevaluationList = [];
                        e.userList.forEach(j => {
                            j.evaluationList.forEach(m => {
                                //听评课身份 0-授课人  1-评课人 2-听课人 3-评课组长
                                if (m.role == 0) {
                                    e.skevaluationList.push(m)
                                } else if (m.role == 1 || m.role == 3) {
                                    e.pkevaluationList.push(m)
                                } else if (m.role == 2) {
                                    e.tkevaluationList.push(m)
                                }
                            })
                        })
                        e.skcount = e.skevaluationList.length
                        e.pkcount = e.pkevaluationList.length
                        e.tkcount = e.tkevaluationList.length
                        this.dataList.push(e)
                    })
                }
                this.loading = false;
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/evaluationDetail",
                query: {
                    lessonId: row.lessonId,
                    isPtStatistics: 1,
                }
            });
            // this.$router.push({
            //     path: "/bannerDetail",
            //     query: {
            //         data: JSON.stringify(row),
            //     }
            // });
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        changeZxtOrg() {
            this.zxtQueryParams.userIdList = [];
        },
        changeZxtUser() {
            this.zxtQueryParams.orgIdList = [];
        },
        disabledDate(current) {
            return current && current > Number(new Date())
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}
</style>