<template>
    <div class="" style="width: 100%;">
        <div style="width: 100%;" class="">
            <el-link @click="openPeople" type="primary" :underline="false">+{{ showTitle }}</el-link>
        </div>
        <div>
            <el-tag type="info" @close="tagClose(tag)" style="margin-right: 10px;margin-bottom: 5px;"
                v-if="checkList.length != 0" v-for="tag in checkList" :key="tag.userId" class="mx-1" closable>
                {{ tag.trueName }}<span v-if="tag.username">{{ '-' + tag.username }}</span>
            </el-tag>
        </div>

        <el-dialog :title="showTitle" v-model="addPeoplesssOpen" width="60%" @close="canceladdPeo">
            <el-row>
                <el-col style="border-right: 2px solid #f4f4f4;" :span="6" v-show="!isQun">
                    <div @click="xsAllPeo" class="cursor">全部</div>
                    <div style="height: 340px;overflow-y: auto;">
                        <el-tree :data="orgList" :props="defaultProps" height="340px" @node-click="handleNodeClick" />
                    </div>
                </el-col>
                <el-col style="" :span="isQun?24:18">
                    <div class="" style="width:50%;padding-left:15px;padding-bottom:10px;">
                        <el-input v-model="peopletitle" placeholder="请输入姓名" class="input-with-select">
                            <template #append>
                                <el-button @click="getPeotttList" type="primary" icon="el-icon-search" />
                            </template>
                        </el-input>

                        <div @click="changeAllCative" class="disflex-al-ct cursor" v-if="peoList.length != 0 && !radio">
                            <img v-if="!isAllCative" style="width: 15px;height:15px;margin: 10px 5px 0 5px;"
                                src="../assets/img/uncheck.png" alt="">
                            <img v-if="isAllCative" style="width: 15px;height:15px;margin: 10px 5px 0 5px;"
                                src="../assets/img/checked.png" alt="">
                            <div style="margin-top: 10px;">全选</div>
                        </div>
                    </div>
                    <div style="height: 330px;overflow-y: auto;">
                        <el-empty :size="10" v-if="peoList.length == 0" description="暂无人员" />
                        <div style="margin-left: 10px;display: flex;flex-wrap: wrap;">
                            <div @click="checkChange(item)" v-show="!item.noShow" v-for="item in peoList" class="cursor"
                                style="margin-bottom: 10px;width: 100px;">
                                <!-- disabled -->
                                <div class="disflex-al-ct">
                                    <img v-if="item.disabled" style="width: 15px;height:15px;margin: 0 5px 0 10px;"
                                        src="../assets/img/disuncheck.png" alt="">
                                    <img v-if="!item.checked && !item.disabled"
                                        style="width: 15px;height:15px;margin: 0 5px 0 10px;"
                                        src="../assets/img/uncheck.png" alt="">
                                    <img v-if="item.checked && !item.disabled"
                                        style="width: 15px;height:15px;margin: 0 5px 0 10px;"
                                        src="../assets/img/checked.png" alt="">
                                    <div>{{ item.trueName }}</div>
                                </div>
                                <div v-if="item.username" class="text-one"
                                    style="color: #c0c4cc;padding-left: 30px;font-size: 10px;padding-bottom: 5px;">
                                    {{ item.username }}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import { userList, userListJc, groupMemberList } from "@/api/admin/public";
import { userListOrgUsers, userListOrgMember, orgListTree } from "@/api/admin/system";

export default {
    name: 'Pagination',
    props: {
        page: {
            type: Number,
            default: 1
        },
        layout: {
            type: String,
            default: 'total, sizes, prev, pager, next, jumper'
        },
        isExpert: {
            type: Boolean,
            default: false
        },
        background: {
            type: Boolean,
            default: true
        },
        radio: {
            type: Boolean,
            default: false
        },
        showTitle: {
            type: String,
            default: '选择人员'
        },
        noShowIdList: {
            type: Array,
            default: []
        }
    },
    computed: {

    },
    created() {
        this.getUserList();
    },
    data() {
        return {
            checkList: [],
            peoList: [],
            isAllCative: false,
            addPeoplesssOpen: false,
            defaultProps: {
                children: 'groupList',
                label: 'title',
            },
            orgList: [],
            peoOldList: [],
            peopletitle: '',
            disabledList: [],
            userOldList: [],
            isQun: false,
        }
    },
    methods: {
        getQunPeople(e) {
            if (e) {
                groupMemberList({ groupId: e }).then(res => {
                    console.log(res)
                    this.isQun = true;
                    res.data.data.forEach(e => {
                        e.userId = e.memberUserId;
                        e.username = e.memberAccount;
                        e.trueName = e.memberName;
                    })
                    this.orgList = [];
                    this.userOldList = res.data.data;
                    this.xsAllPeo()
                })
            }
        },
        xsAllPeo() {
            var userOldList = JSON.parse(JSON.stringify(this.userOldList))
            console.log(userOldList);

            userOldList.forEach(e => {
                e.checked = false;
                e.disabled = false;
                this.noShowIdList.forEach(j => {
                    if (e.userId == j) {
                        e.noShow = true;;
                    }
                })
                this.disabledList.forEach(j => {
                    if (e.userId == j.userId) {
                        e.disabled = true;;
                    }
                })
                this.checkList.forEach(j => {
                    if (j.userId == e.userId) {
                        e.checked = true;
                    }
                })
            })
            this.peoList = userOldList;
            this.peoOldList = userOldList;
            if (this.isExpert) {
                this.peoList = this.peoList.filter(itemA => {
                    return itemA.roleType == '8'
                })
            }
        },
        canceladdPeo() {
            this.$emit('gxDisable')
        },
        checkChange(item) {
            console.log(this.disabledList);
            if (item.disabled == false) {
                item.checked = !item.checked;
                if (this.radio) {
                    this.peoList.forEach((e, i) => {
                        if (item.checked) {
                            if (e.userId == item.userId) {
                                e.checked = true
                            } else {
                                e.checked = false
                            }
                        } else {
                            e.checked = false
                        }
                    })
                    this.checkList = [];
                }
                if (!item.checked) {
                    this.checkList.forEach((e, i) => {
                        if (item.userId == e.userId) {
                            this.checkList.splice(i, 1)
                        }
                    })
                } else {
                    this.checkList.push({ userId: item.userId, trueName: item.trueName, username: item.username })
                }
            }
            var isAllCative = false;
            for (var i = 0; i < this.peoList.length; i++) {
                if (this.peoList[i].checked) {
                    isAllCative = true;
                } else {
                    isAllCative = false;
                    break
                }
            }
            this.isAllCative = isAllCative;
        },
        changeAllCative() {
            this.peoList.forEach((j, k) => {
                this.checkList.forEach((e, i) => {
                    if (j.userId == e.userId) {
                        this.checkList.splice(i, 1)
                    }
                })
            })
            this.isAllCative = !this.isAllCative;
            if (!this.isAllCative) {

            } else {
                this.peoList.forEach((j, k) => {
                    if (!j.disabled) {
                        this.checkList.push({ userId: j.userId, trueName: j.trueName, username: j.username })
                    }
                })
            }
            for (var i = 0; i < this.peoList.length; i++) {
                this.peoList[i].checked = this.isAllCative;
            }
        },
        handleNodeClick(e, i) {
            this.peoOldList = [];
            console.log(e, i)
            if (!e.groupId) {
                var userOldList = JSON.parse(JSON.stringify(this.userOldList))
                userOldList = userOldList.filter(itemA => {
                    return itemA.orgId == e.orgId
                })
                userOldList.forEach(e => {
                    e.checked = false;
                    e.disabled = false;
                    this.noShowIdList.forEach(j => {
                        if (e.userId == j) {
                            e.noShow = true;;
                        }
                    })
                    this.disabledList.forEach(j => {
                        if (e.userId == j.userId) {
                            e.disabled = true;;
                        }
                    })
                    this.checkList.forEach(j => {
                        if (j.userId == e.userId) {
                            e.checked = true;
                        }
                    })
                })
                this.peoList = userOldList;
                this.peoOldList = userOldList;
                if (this.isExpert) {
                    this.peoList = this.peoList.filter(itemA => {
                        return itemA.roleType == '8'
                    })
                }
            } else {
                userListOrgMember({ groupId: e.groupId }).then(res => {
                    res.data.data.forEach(e => {
                        e.checked = false;
                        e.disabled = false;
                        this.noShowIdList.forEach(j => {
                            if (e.userId == j) {
                                e.noShow = true;;
                            }
                        })
                        this.disabledList.forEach(j => {
                            if (e.userId == j.userId) {
                                e.disabled = true;;
                            }
                        })
                        this.checkList.forEach(j => {
                            if (j.userId == e.userId) {
                                e.checked = true;
                            }
                        })
                    })
                    this.peoList = res.data.data;
                    this.peoOldList = res.data.data;
                    if (this.isExpert) {
                        this.peoList = this.peoList.filter(itemA => {
                            return itemA.roleType == '8'
                        })
                    }
                })
            }
            var isAllCative = false;
            for (var i = 0; i < this.peoList.length; i++) {
                if (this.peoList[i].checked) {
                    isAllCative = true;
                } else {
                    isAllCative = false;
                    break
                }
            }
            this.isAllCative = isAllCative;
        },
        getUserList() {
            this.isQun = false;
            if (this.$storage.get("role") == 1 || this.$storage.get("role") == 2) {
                this.defaultProps = {
                    children: 'childrenList',
                    label: 'orgName',
                }
                userListJc().then(res => {
                    this.userOldList = res.data.data
                    this.xsAllPeo()
                })
                orgListTree().then(res => {
                    this.orgList = res.data.data;
                    this.xsAllPeo()
                })
            } else {
                this.defaultProps = {
                    children: 'groupList',
                    label: 'title',
                }
                userListOrgUsers().then(res => {
                    res.data.data.orgList.forEach(e => {
                        e.title = e.orgName;
                        if (e.groupList) {
                            e.groupList.forEach(j => {
                                j.title = j.groupName
                            })
                        }
                    })
                    this.orgList = res.data.data.orgList;
                    this.userOldList = res.data.data.userList;
                    this.xsAllPeo()
                })
            }
        },
        tagClose(tag) {
            this.checkList.forEach((e, i) => {
                if (tag.userId == e.userId) {
                    this.checkList.splice(i, 1)
                }
            })
            this.$emit('gxDisable')

        },
        openPeople() {
            this.peoList.forEach(e => {
                e.checked = false;
                e.disabled = false;
                this.noShowIdList.forEach(j => {
                    if (e.userId == j) {
                        e.noShow = true;;
                    }
                })
                this.disabledList.forEach(j => {
                    if (e.userId == j.userId) {
                        e.disabled = true;
                    }
                })
                this.checkList.forEach(j => {
                    if (j.userId == e.userId) {
                        e.checked = true;
                    }
                })
            })
            this.addPeoplesssOpen = true;
            var isAllCative = false;
            for (var i = 0; i < this.peoList.length; i++) {
                if (this.peoList[i].checked) {
                    isAllCative = true;
                } else {
                    isAllCative = false;
                    break
                }
            }
            this.isAllCative = isAllCative;
        },
        getPeotttList() {
            this.peoList = this.peoOldList;
            if (this.peopletitle !== '') {
                this.peoList = this.peoList.filter(itemA => {
                    return itemA.trueName.indexOf(this.peopletitle) != -1
                })
            } else {
                this.peoList = this.peoOldList;
            }
            if (this.isExpert) {
                this.peoList = this.peoList.filter(itemA => {
                    return itemA.roleType == '8'
                })
            }
        },
    }
}
</script>

<style scoped></style>