import request from "@/utils/request";
// 创建优课大赛
export const raceCreate =(data) =>{
    return request({
        url: "/businessservice/classRace/race/create",
        method:"post",
        data: data
    })
}
// 删除优课大赛
export const  raceDelete =(query) =>{
    return request({
        url: "/businessservice/classRace/race/delete",
        method:"get",
        params: query
    })
}
// 删除优课大赛附件
export const  raceDeleteFile =(query) =>{
    return request({
        url: "/businessservice/classRace/race/deleteFile",
        method:"get",
        params: query
    })
}
// 查询全部优课大赛
export const  raceListAll =(query) =>{
    return request({
        url: "/businessservice/classRace/race/listAll",
        method:"get",
        params: query
    })
}
// 分页查询课题
export const classRacePage =(data) =>{
    return request({
        url: "/businessservice/classRace/lead/page",
        method:"post",
        data: data
    })
}
// 查询用户参与优课大赛
export const  raceListJoin =(query) =>{
    return request({
        url: "/businessservice/classRace/race/listJoin",
        method:"get",
        params: query
    })
}
// 发布优课大赛
export const  raceRelease =(query) =>{
    return request({
        url: "/businessservice/classRace/race/release",
        method:"get",
        params: query
    })
}
// 停止优课大赛
export const  raceStop =(query) =>{
    return request({
        url: "/businessservice/classRace/race/stop",
        method:"get",
        params: query
    })
}
// 更新优课大赛
export const raceUpdate =(data) =>{
    return request({
        url: "/businessservice/classRace/race/update",
        method:"post",
        data: data
    })
}
// 优课大赛详情
export const  raceInfo =(query) =>{
    return request({
        url: "/businessservice/classRace/race/info",
        method:"get",
        params: query
    })
}
// 上传优课大赛内容页
export const raceUploadCover =(data) =>{
    return request({
        url: "/businessservice/classRace/race/uploadCover",
        method:"post",
        data: data
    })
}
// 上传优课大赛附件
export const raceUploadFile =(data) =>{
    return request({
        url: "/businessservice/classRace/race/uploadFile",
        method:"post",
        data: data
    })
}
// 同意优课大赛申报
export const  raceHandleAgree =(query) =>{
    return request({
        url: "/businessservice/raceHandle/apply/agree",
        method:"get",
        params: query
    })
}
// 申报优课大赛
export const  raceHandleCreate =(data) =>{
    return request({
        url: "/businessservice/raceHandle/apply/create",
        method:"post",
        data: data
    })
}
// 删除申报附件
export const  raceHandleDeleteFile =(query) =>{
    return request({
        url: "/businessservice/raceHandle/apply/deleteFile",
        method:"get",
        params: query
    })
}
// 优课大赛申报详情
export const  raceHandleInfo =(query) =>{
    return request({
        url: "/businessservice/raceHandle/apply/info",
        method:"get",
        params: query
    })
}
// 查询全部申报列表
export const  raceHandleListAll =(query) =>{
    return request({
        url: "/businessservice/raceHandle/apply/listAll",
        method:"get",
        params: query
    })
}
// 分页查询教研赛事申报列表
export const  raceHandlePage =(data) =>{
    return request({
        url: "/businessservice/raceHandle/apply/page",
        method:"post",
        data: data
    })
}
// 查询待处理申报列表
export const  raceHandleListHandle =(query) =>{
    return request({
        url: "/businessservice/raceHandle/apply/listHandle",
        method:"get",
        params: query
    })
}
// 查询已处理申报列表
export const  raceHandleListHistory =(query) =>{
    return request({
        url: "/businessservice/raceHandle/apply/listHistory",
        method:"get",
        params: query
    })
}
// 拒绝优课大赛申报
export const  raceHandleRefuse =(query) =>{
    return request({
        url: "/businessservice/raceHandle/apply/refuse",
        method:"get",
        params: query
    })
}
// 上传优课大赛申报附件
export const  raceHandleUploadFile =(data) =>{
    return request({
        url: "/businessservice/raceHandle/apply/uploadFile",
        method:"post",
        data: data
    })
}