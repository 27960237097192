import request from "@/utils/request";
// 创建场景
export const  sceneCreate =(data) =>{
    return request({
        url: "/adminservice/scene/create",
        method:"post",
        data:data
    })
}
// 删除场景
export const  sceneDelete =(query) =>{
    return request({
        url: "/adminservice/scene/delete",
        method:"get",
        params: query
    })
}
// 查询场景详情
export const  sceneInfo =(query) =>{
    return request({
        url: "/adminservice/scene/info",
        method:"get",
        params: query
    })
}
// 查询场景列表(不传客户标识查询全部)
export const  queryScene =(query) =>{
    return request({
        url: "/adminservice/scene/queryScene",
        method:"get",
        params: query
    })
}
// 修改场景
export const  sceneUpdate =(data) =>{
    return request({
        url: "/adminservice/scene/update",
        method:"post",
        data:data
    })
}
// 场景踢出成员
export const  sceneMemberDel =(query) =>{
    return request({
        url: "/adminservice/sceneMember/delete",
        method:"get",
        params: query
    })
}
// 查询场景下表单列表
export const  querySceneForm =(query) =>{
    return request({
        url: "/adminservice/form/querySceneForm",
        method:"get",
        params: query
    })
}
// 配置成员表单
export const  sceneConfigForm =(data) =>{
    return request({
        url: "/adminservice/sceneMember/configForm",
        method:"post",
        data:data
    })
}

// 表单配置成员
export const  configMember =(data) =>{
    return request({
        url: "/adminservice/form/configMember",
        method:"post",
        data:data
    })
}

// 查询用户权限下场景成员
export const  queryAuthMembers =(query) =>{
    return request({
        url: "/adminservice/sceneMember/queryAuthMembers",
        method:"get",
        params: query
    })
}

// 场景添加成员
export const  addMember =(data) =>{
    return request({
        url: "/authservice/admin/addMember",
        method:"post",
        data:data
    })
}