<template>
    <div>
        <TbHeader></TbHeader>
        <el-row style="background: #f2f2f2;">
            <el-col :span="3"></el-col>
            <el-col :span="18" style="background-color: #fff;">
                <div ref="containerRef" style="height:calc(100vh - 60px);overflow-y:auto">
                    <div style="position: fixed;width: 74%;z-index: 999;">
                        <el-row class="" style="padding: 15px 0;background: rgba(255, 255, 255,0.8);">
                            <el-col :span="10" class="mb0">
                                <el-form-item label="题目检索" class="ml15" label-width="80px">
                                    <el-input v-model="queryParams.title" placeholder="请输入要搜索的题目关键字"
                                        class="input-with-select">
                                        <template #append>
                                            <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14" class="mr0 mb0">
                                <el-form-item class="pull-right">
                                    <el-button @click="save(0)" class="mr15" type="warning">保存</el-button>
                                    <el-button @click="save(1)" class="mr15" type="primary">提交</el-button>

                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="pd15 mb45" style="" :id="oneItem.smId" v-for="oneItem in allList">
                        <div class="bghTit" style="margin-top: 70px;">
                            <div class="bghTitTxt">{{ oneItem.smTit }}</div>
                        </div>
                        <template v-for="item in oneItem.smList">
                            <template v-if="item.noShowLabel">
                                <div class="tip custom-block" v-if="item.bgShow">
                                    <div class="ml5" style="font-size: 17px;">
                                        {{ item.bgTit }}
                                    </div>
                                </div>
                                <div :class="item.bgTit ? 'ml30' : ''" v-if="item.bgShow">
                                    {{ item.title }}
                                </div>
                                <el-select class="ml30 mt15" style="width:50%;" v-model="item.value" placeholder="请选择"
                                    v-if="item.key == 'isSelect' && item.noShowLabel">
                                    <el-option v-for="sz in item.optionList" :key="sz" :label="sz" :value="sz" />
                                </el-select>
                            </template>
                            <el-form v-if="!item.noShowLabel" class="mt40" :label-position="'left'" label-width="130px"
                                style="max-width: 50%">
                                <el-form-item :label="item.title" v-if="item.key == 'gender'">
                                    <el-select v-model="item.value" placeholder="请选择">
                                        <el-option :key="0" :label="'男'" :value="0" />
                                        <el-option :key="1" :label="'女'" :value="1" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="item.title" v-if="item.key == 'birthDate'">
                                    <el-date-picker v-model="item.value" type="date" value-format="x" placeholder="请选择日期"
                                        style="width: 100%" />
                                </el-form-item>
                                <el-form-item :label="item.title" v-if="item.key == 'politicalClimate'">
                                    <el-select v-model="item.value" placeholder="请选择">
                                        <el-option v-for="sz in politicalClimateList" :key="sz.dictKey"
                                            :label="sz.dictValue" :value="sz.dictKey" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="item.title" v-if="item.key == 'nation'">
                                    <el-select v-model="item.value" placeholder="请选择">
                                        <el-option v-for="sz in nationList" :key="sz.dictKey" :label="sz.dictValue"
                                            :value="sz.dictKey" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="item.title" v-if="item.key == 'isSelect'">
                                    <el-select v-model="item.value" placeholder="请选择">
                                        <el-option v-for="sz in item.optionList" :key="sz" :label="sz" :value="sz" />
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            <template v-if="item.tsList">
                                <div class="tip custom-block" v-if="item.bgShow">
                                    <div class="ml5" style="font-size: 17px;">
                                        {{ item.bgTit }}
                                    </div>
                                </div>
                                <div :class="item.bgTit ? 'ml30' : ''">
                                    {{ item.title }}
                                </div>
                                <div class="cdx tip custom-block ml70 " style="width: 50%;" v-for="(ts, ti) in item.value">
                                    <div style="flex:1;position: relative;" class="ml15 mr10 cursor"
                                        @click="editZL(item, ti)">
                                        <p class="custom-block-title">{{ ts.oneTxt }}</p>
                                        <div class="text-one" :title="ts.twoTxt">{{ ts.twoTxt }}
                                        </div>
                                        <div v-if="item.value.length > 1"
                                            style="position: absolute;left: -50px;top: 50%;transform: translateY(-50%);font-size: 30px;color: #9E9E9E;">
                                            {{ ti + 1 }}
                                        </div>
                                    </div>
                                    <div class="cdxtpbx">
                                        <div style="width:100%;height:100%;" @click="editZL(item, ti)">
                                            <img class="cdxRgIcon mr10 cursor" src="../../assets/img/biaoq.png" alt="">
                                        </div>
                                        <div class="cdxDel">
                                            <el-icon class="cursor ml5" @click="delZl(item.value, ti)">
                                                <Delete />
                                            </el-icon><span class="ml5 cursor" @click="delZl(item.value, ti)">点击删除资料</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ml70 mt15" style="width: 50%;">
                                    <div class="pl10 mr10 cursor cdxAdd" @click="handleAddFile(item)">
                                        + 点击添加档案资料，如有多项符合条件，请逐项添加
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </el-col>
            <el-col :span="3">
                <div style="top: 50%;position: absolute;width: 12.5%;transform: translateY(-50%);">
                    <el-anchor style="background: transparent;font-size:20px" :container="containerRef" type="underline"
                        direction="vertical" :offset="30" @click="handleClick">
                        <el-anchor-link :href="`#${oneItem.smId}`" :title="oneItem.smTit" v-for="oneItem in allList">
                        </el-anchor-link>
                    </el-anchor>
                </div>
            </el-col>
        </el-row>
        <el-dialog draggable :title="showModelObj.tit" v-model="showModelObj.isShow" width="700px">
            <el-form :label-position="'left'" ref="showModelObj" :model="showModelObj" :rules="schoolModelRules"
                label-width="150px">
                <template v-for="item in showModelObj.activeCate.tsList">
                    <el-form-item :label="item.title" prop="qualification" v-if="item.key == 'qualification'">
                        <el-select filterable v-model="showModelObj.txObj.qualification" placeholder="请选择">
                            <el-option v-for="sz in qualificationList" :key="sz.dictKey" :label="sz.dictValue"
                                :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="prizeLevel" v-if="item.key == 'prizeLevel'">
                        <el-select v-if="item.optionName == 'mandarin_level'" filterable
                            v-model="showModelObj.txObj.prizeLevel" placeholder="请选择">
                            <el-option v-for="sz in mandarinLevelList" :key="sz.dictKey" :label="sz.dictValue"
                                :value="sz.dictKey" />
                        </el-select>
                        <el-select v-if="item.optionName == 'award_level'" filterable
                            v-model="showModelObj.txObj.prizeLevel" placeholder="请选择">
                            <el-option v-for="sz in awardLevelList" :key="sz.dictKey" :label="sz.dictValue"
                                :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="timeList" v-if="item.key == 'timeList'">
                        <el-date-picker value-format="x" v-model="showModelObj.txObj.timeList" type="monthrange"
                            range-separator="到" start-placeholder="开始时间" end-placeholder="结束时间" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="periodSubjectList" v-if="item.key == 'periodSubjectList'">
                        <el-cascader style="width:100%;" v-model="showModelObj.txObj.periodSubjectList"
                            :options="archiveXdXkList"
                            :props="{ children: 'childrenList', value: 'dictKey', label: 'dictValue' }" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="startTime" v-if="item.key == 'startTime'">
                        <el-date-picker value-format="x" style="width: 100%;" v-model="showModelObj.txObj.startTime"
                            type="month" placeholder="请选择" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="orgName" v-if="item.key == 'orgName'">
                        <el-input v-model="showModelObj.txObj.orgName" placeholder="最多32个字" maxlength="32" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="name" v-if="item.key == 'name'">
                        <el-input v-if="!item.optionName" v-model="showModelObj.txObj.name" placeholder="最多32个字"
                            maxlength="32" />
                        <el-select v-if="item.optionName == 'technical_title'" filterable v-model="showModelObj.txObj.name"
                            placeholder="请选择">
                            <el-option v-for="sz in technicalTitleList" :key="sz.dictKey" :label="sz.dictValue"
                                :value="sz.dictKey" />
                        </el-select>
                        <el-select v-if="item.optionName == 'bzrType'" filterable v-model="showModelObj.txObj.name"
                            placeholder="请选择">
                            <el-option label="班主任" value="班主任" />
                            <el-option label="副班主任" value="副班主任" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="major" v-if="item.key == 'major'">
                        <el-input v-model="showModelObj.txObj.major" placeholder="最多32个字" maxlength="32" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="publisher" v-if="item.key == 'publisher'">
                        <el-input v-model="showModelObj.txObj.publisher" placeholder="最多32个字" maxlength="32" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="publishNo" v-if="item.key == 'publishNo'">
                        <el-input v-model="showModelObj.txObj.publishNo" placeholder="最多32个字" maxlength="32" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="relationName" v-if="item.key == 'relationName'">
                        <el-input v-if="!item.optionName" v-model="showModelObj.txObj.relationName"
                            :placeholder="item.placeholder ? item.placeholder : '最多32个字'" maxlength="32" />
                        <el-select v-if="item.optionName && item.optionName == 'assume_role'" filterable
                            v-model="showModelObj.txObj.relationName" placeholder="请选择">
                            <el-option v-for="sz in assumeRoleList" :key="sz.dictKey" :label="sz.dictValue"
                                :value="sz.dictKey" />
                        </el-select>
                        <el-select v-if="item.optionName && item.optionName == 'opus_role'" filterable
                            v-model="showModelObj.txObj.relationName" placeholder="请选择">
                            <el-option v-for="sz in opusRoleList" :key="sz.dictKey" :label="sz.dictValue"
                                :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="address" v-if="item.key == 'address'">
                        <el-input v-model="showModelObj.txObj.address" placeholder="最多32个字" maxlength="32" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="content" v-if="item.key == 'content'">
                        <el-input v-model="showModelObj.txObj.content" :rows="3" type="textarea" placeholder="最多512个字"
                            maxlength="512" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="remarks" v-if="item.key == 'remarks'">
                        <el-input v-model="showModelObj.txObj.remarks" :rows="3" type="textarea" placeholder="最多512个字"
                            maxlength="512" />
                    </el-form-item>
                    <el-form-item :label="item.title" prop="isSelect" v-if="item.key == 'isSelect'">
                        <el-select v-if="item.optionList" v-model="showModelObj.txObj.isSelect" placeholder="请选择">
                            <el-option v-for="sz in item.optionList" :key="sz" :label="sz" :value="sz" />
                        </el-select>
                        <el-select v-else filterable v-model="showModelObj.txObj.isSelect" placeholder="请选择">
                            <el-option :key="0" :label="'是'" :value="'是'" />
                            <el-option :key="1" :label="'否'" :value="'否'" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="schoolYear" v-if="item.key == 'schoolYear'">
                        <el-select filterable v-model="showModelObj.txObj.schoolYear" placeholder="请选择">
                            <el-option v-for="item in (new Date().getFullYear() - 2009)" :key="item"
                                :label="2009 + item + '学年'" :value="2009 + item + '学年'" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="schoolTerm" v-if="item.key == 'schoolTerm'">
                        <el-select v-if="!item.isNum" v-model="showModelObj.txObj.schoolTerm" placeholder="请选择">
                            <el-option :key="0" :label="'上学期'" :value="'上学期'" />
                            <el-option :key="1" :label="'下学期'" :value="'下学期'" />
                        </el-select>
                        <el-select v-if="item.isNum" v-model="showModelObj.txObj.schoolTerm" placeholder="请选择">
                            <el-option v-for="item in 10" :key="item" :label="item" :value="item" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="grade" v-if="item.key == 'grade'">
                        <el-select filterable v-model="showModelObj.txObj.grade" placeholder="请选择">
                            <el-option v-for="item in (new Date().getFullYear() - 1964)" :key="item"
                                :label="1969 + item + '级'" :value="1969 + item + '级'" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="classInfo" v-if="item.key == 'classInfo'">
                        <el-select filterable v-model="showModelObj.txObj.classInfo" placeholder="请选择">
                            <el-option v-for="item in 1000" :key="item" :label="item" :value="item" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="num" v-if="item.key == 'num'">
                        <el-select filterable v-model="showModelObj.txObj.num" placeholder="请选择">
                            <el-option v-for="item in 1000" :key="item" :label="item" :value="item" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="typeName" v-if="item.key == 'typeName'">
                        <el-select v-if="item.optionList" filterable v-model="showModelObj.txObj.typeName"
                            placeholder="请选择">
                            <el-option v-for="sz in item.optionList" :key="sz" :label="sz" :value="sz" />
                        </el-select>
                        <template v-else>
                            <el-select v-if="item.optionName" filterable v-model="showModelObj.txObj.typeName"
                                placeholder="请选择">
                                <el-option v-if="item.optionName == 'core_teacher_type'" v-for="sz in coreTeacherTypeList"
                                    :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'moral_edu_type'" v-for="sz in moralEduTypeList"
                                    :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'class_honorary_title'"
                                    v-for="sz in classHonoraryTitleList" :key="sz.dictKey" :label="sz.dictValue"
                                    :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'young_pioneers_type'"
                                    v-for="sz in youngPioneersTypeList" :key="sz.dictKey" :label="sz.dictValue"
                                    :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'class_teach_type'" v-for="sz in classTeachTypeList"
                                    :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'assistance_nature'" v-for="sz in assistanceNatureList"
                                    :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'archive_achieve_type'"
                                    v-for="sz in archiveAchieveTypeList" :key="sz.dictKey" :label="sz.dictValue"
                                    :value="sz.dictKey" />
                                <el-option v-if="item.optionName == 'opus_type'" v-for="sz in opusTypeList"
                                    :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                            </el-select>

                            <el-input v-else v-model="showModelObj.txObj.typeName"
                                :placeholder="item.placeholder ? item.placeholder : '最多32个字'" maxlength="32" />
                        </template>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="categoryName" v-if="item.key == 'categoryName'">
                        <el-select v-if="item.optionName" filterable v-model="showModelObj.txObj.categoryName"
                            placeholder="请选择">
                            <el-option v-if="item.optionName == 'content_theme_type'" v-for="sz in contentThemeTypeList"
                                :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="levelName" v-if="item.key == 'levelName'">
                        <el-select filterable v-model="showModelObj.txObj.levelName" placeholder="请选择">
                            <el-option v-if="item.optionName == 'archive_area_level'" v-for="sz in archiveAreaLevelList"
                                :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                            <el-option v-if="item.optionName == 'teach_support_level'" v-for="sz in teachSupportLevelList"
                                :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="publishType" v-if="item.key == 'publishType'">
                        <el-select filterable v-model="showModelObj.txObj.publishType" placeholder="请选择">
                            <el-option v-if="item.optionName == 'archive_area_level'" v-for="sz in archiveAreaLevelList"
                                :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="relationOrgName" v-if="item.key == 'relationOrgName'">
                        <el-select filterable v-model="showModelObj.txObj.relationOrgName" placeholder="请选择">
                            <el-option v-if="item.optionName == 'award_org'" v-for="sz in awardOrgList" :key="sz.dictKey"
                                :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="statusName" v-if="item.key == 'statusName'">
                        <el-select filterable v-model="showModelObj.txObj.statusName" placeholder="请选择">
                            <el-option v-if="item.optionName == 'archive_topic_status'" v-for="sz in archiveTopicStatusList"
                                :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="schoolArea" v-if="item.key == 'schoolArea'">
                        <el-select filterable v-model="showModelObj.txObj.schoolArea" placeholder="请选择">
                            <el-option v-if="item.optionName == 'school_area'" v-for="sz in schoolAreaList"
                                :key="sz.dictKey" :label="sz.dictValue" :value="sz.dictKey" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="annex1UrlList" v-if="item.key == 'annex1UrlList'">
                        <div v-if="!item.accept" style="display: flex;flex-wrap:wrap;">
                            <div v-for="(item, index) in showModelObj.txObj.annex1UrlList" class="csbbb cursor">
                                <el-icon title="点击删除" @click="delImg(showModelObj.txObj.annex1UrlList, index)" :size="15" class="eicon"
                                    style="cursor: pointer;z-index: 99;color: red;">
                                    <Delete />
                                </el-icon>
                                <img title="点击预览" :src="item.response.data" v-if="item.response" @click="ylImgs(showModelObj.txObj.annex1UrlList)"
                                    style="width: 100px;height: 65px;margin-right: 5px;margin-bottom: 5px;">
                            </div>
                        </div>
                        <el-upload v-if="!item.accept" v-model:file-list="showModelObj.txObj.annex1UrlList" :headers="{ token }" multiple
                            :show-file-list="false" action="https://www.jingzhunjy.com/businessservice/archive/fill/uploadFile"
                            class="avatar-uploader" :before-upload="beforeAvatarUpload" accept=".jpeg,.png,.jpg"
                            list-type="picture-card" :on-remove="handleRemove">
                            <el-icon class="avatar-uploader-icon" style="font-size:50px;">
                                <Plus />
                            </el-icon>
                        </el-upload>
                        <div v-if="item.accept" style="display: flex;flex-wrap:wrap;">
                            <div v-for="(item, index) in showModelObj.txObj.annex1UrlList" class="csbbb cursor">
                                <el-icon title="点击删除" @click="delImg(showModelObj.txObj.annex1UrlList, index)" :size="15" class="eicon"
                                    style="cursor: pointer;z-index: 99;color: red;">
                                    <Delete />
                                </el-icon>
                                <img title="点击下载" src="../../assets/img/pdf.png" v-if="item.response" @click="downloadFile(item.response.data)"
                                    style="width: 100px;height: 100px;margin-right: 5px;margin-bottom: 5px;">
                            </div>
                        </div>
                        <el-upload v-if="item.accept" v-model:file-list="showModelObj.txObj.annex1UrlList" :headers="{ token }" multiple
                            :show-file-list="false" action="https://www.jingzhunjy.com/businessservice/archive/fill/uploadFile"
                            class="avatar-uploader" :before-upload="beforeJAUpload" :accept="item.accept"
                            list-type="picture-card" :on-remove="handleRemove">
                            <el-icon class="avatar-uploader-icon" style="font-size:50px;">
                                <Plus />
                            </el-icon>
                        </el-upload>
                    </el-form-item>
                    <el-form-item :label="item.title" prop="annex2UrlList" v-if="item.key == 'annex2UrlList'">
                        <div style="display: flex;flex-wrap:wrap;">
                            <div v-for="(item, index) in showModelObj.txObj.annex2UrlList" class="csbbb">
                                <el-icon title="点击删除" @click="delImg(showModelObj.txObj.annex2UrlList, index)" :size="20" class="eicon"
                                    style="cursor: pointer;z-index: 99;color: red;">
                                    <Delete />
                                </el-icon>
                                <img title="点击预览" :src="item.response.data" v-if="item.response" @click="ylImgs(showModelObj.txObj.annex2UrlList)"
                                    style="width: 100px;height: 65px;margin-right: 5px;margin-bottom: 5px;">
                            </div>
                        </div>
                        <el-upload v-model:file-list="showModelObj.txObj.annex2UrlList" :headers="{ token }" multiple
                            :show-file-list="false" action="https://www.jingzhunjy.com/businessservice/archive/fill/uploadFile"
                            class="avatar-uploader" :before-upload="beforeAvatarUpload" accept=".jpeg,.png,.jpg"
                            list-type="picture-card" :on-remove="handleRemove">
                            <el-icon class="avatar-uploader-icon" style="font-size:50px;">
                                <Plus />
                            </el-icon>
                        </el-upload>
                    </el-form-item>
                </template>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="showModelSumbit">确 定</el-button>
                    <el-button @click="showModelObj.isShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-image-viewer v-if="isOpen" :url-list="activeimgs" @close="this.isOpen = false;" />
    </div>
</template>
<script>
import { archiveQueryArchive, archiveFillBatch, archiveFillDelete } from '@/api/admin/archive';
import { queryDict } from "@/api/admin/public";
import { timestampToTime } from "@/utils/date";
import { download } from "@/api/adminservice/public";

export default {
    components: {
    },
    name: "fileFill",
    data() {
        return {
            isOpen: false,
            activeimgs: [],
            timestampToTime,
            userInfo: {},
            queryParams: {
                title: ''
            },
            grXX: {
                gender: '',
                birthDate: '',
                politicalClimate: '',
                nation: '',
            },
            containerRef: null,
            showModelObj: {
                tit: '',
                isShow: false,
                activeCate: {},
                txObj: {},
            },
            schoolModelRules: [],
            delFileList: [],
            token: this.$storage.get("token"),
            politicalClimateList: [],
            nationList: [],
            archiveXdXkList: [],
            qualificationList: [],
            mandarinLevelList: [],
            coreTeacherTypeList: [],
            moralEduTypeList: [],
            classHonoraryTitleList: [],
            youngPioneersTypeList: [],
            archiveAreaLevelList: [],
            teachSupportLevelList: [],
            contentThemeTypeList: [],
            classTeachTypeList: [],
            awardLevelList: [],
            awardOrgList: [],
            archiveTopicStatusList: [],
            assistanceNatureList: [],
            schoolAreaList: [],
            archiveAchieveTypeList: [],
            assumeRoleList: [],
            opusTypeList: [],
            opusRoleList: [],
            archiveIdList: [],
            allList: [
                {
                    smTit: '第一部分:教师基础信息',
                    smId: 'part1',
                    smList: [
                        { topCategory: 1, secondCategory: 1, key: 'gender', value: '', title: '性别' },
                        { topCategory: 1, secondCategory: 2, key: 'birthDate', value: '', title: '出生日期' },
                        { topCategory: 1, secondCategory: 3, key: 'politicalClimate', value: '', title: '政治面貌' },
                        { topCategory: 1, secondCategory: 4, key: 'nation', value: '', title: '民族' },
                        {
                            topCategory: 1, secondCategory: 5, key: '', tsList: [
                                { key: 'qualification', title: '全日制学校毕业学历' },
                                { key: 'timeList', title: '起止时间' },
                                { key: 'orgName', title: '毕业院校' },
                                { key: 'major', title: '毕业专业' },
                                { key: 'isSelect', title: '是否师范类专业' },
                                { key: 'annex1UrlList', title: '毕业证书' },
                                { key: 'annex2UrlList', title: '学位证书' },
                            ], value: [], title: '全日制学校毕业学历(备注:只填写入职前全日制学校毕业的最高学历)', bgTit: '学历和工作信息', bgShow: true
                        },
                        {
                            topCategory: 1, secondCategory: 6, key: '', tsList: [
                                { key: 'qualification', title: '全日制学校毕业学历' },
                                { key: 'timeList', title: '起止时间' },
                                { key: 'orgName', title: '毕业院校' },
                                { key: 'major', title: '毕业专业' },
                                { key: 'isSelect', title: '是否师范类专业' },
                                { key: 'annex1UrlList', title: '毕业证书' },
                                { key: 'annex2UrlList', title: '学位证书' },
                            ], value: [], title: '最高学历', bgTit: '学历和工作信息', bgShow: false
                        },
                        { topCategory: 1, secondCategory: 7, key: 'teachAge', value: '', title: '教龄' },
                        { topCategory: 1, secondCategory: 8, key: 'workAge', value: '', title: '工龄' },
                        { topCategory: 1, secondCategory: 9, key: 'isSelect', value: '', title: '是否从事一线教学', optionList: ['正在从事一线教学', '目前未从事一线教学'] },
                        {
                            topCategory: 1, secondCategory: 10, key: '', tsList: [
                                { key: 'name', title: '职称', optionName: 'technical_title' },
                                { key: 'startTime', title: '评定时间' },
                                { key: 'annex1UrlList', title: '证明材料' },
                            ], value: [], title: '现专业技术职称(请填写目前最高股称)', bgTit: '', bgShow: false
                        },
                        {
                            topCategory: 1, secondCategory: 11, key: '', tsList: [
                                { key: 'periodSubjectList', title: '学段/学科' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '请上传持有的教师资格证，如有多个，请逐一上传', bgTit: '', bgShow: false
                        },
                        {
                            topCategory: 1, secondCategory: 12, key: '', tsList: [
                                { key: 'prizeLevel', title: '等级', optionName: 'mandarin_level' },
                                { key: 'startTime', title: '通过时间' },
                                { key: 'annex1UrlList', title: '合格证书' },
                            ], value: [], title: '请上传普通话水平测试等级证书', bgTit: '', bgShow: false
                        },
                        {
                            topCategory: 1, secondCategory: 13, key: '', tsList: [
                                { key: 'orgName', title: '工作单位' },
                                { key: 'timeList', title: '起止时间' },
                                { key: 'content', title: '工作内容' },
                                { key: 'remarks', title: '备注' },
                            ], value: [], title: '【主要工作经历】分阶段填写毕业至今的主要工作经历(包括本校的工作经历，截止时间到本月)', bgTit: '主要工作经历和管理工作', bgShow: true
                        },
                        {
                            topCategory: 1, secondCategory: 14, key: '', tsList: [
                                { key: 'orgName', title: '工作单位' },
                                { key: 'timeList', title: '起止时间' },
                                { key: 'content', title: '工作内容' },
                                { key: 'remarks', title: '备注' },
                                { key: 'annex1UrlList', title: '证明材料' },
                            ], value: [], title: '【担任外单位管理工作】履现职以来，若在外单位担任过科任教学、班主任工作之外的其他管理岗位工作，请提供具体资料，若在外单位担任过管理工作，需原单位提供组织机构沿革表。', bgTit: '主要工作经历和管理工作', bgShow: false
                        },
                        {
                            topCategory: 1, secondCategory: 15, key: '', tsList: [
                                { key: 'name', title: '岗位名称' },
                                { key: 'timeList', title: '起止时间' },
                                { key: 'content', title: '工作内容' },
                                { key: 'remarks', title: '备注' },
                                { key: 'annex1UrlList', title: '证明材料' },
                            ], value: [], title: '【担任本校管理工作】履现职以来，若在本校担任过科任教学、班主任工作之外的其他管理岗位工作，请提供具体资料。', bgTit: '主要工作经历和管理工作', bgShow: false
                        },
                        {
                            topCategory: 1, secondCategory: 16, key: '', tsList: [
                                { key: 'orgName', title: '兼职单位名称' },
                                { key: 'name', title: '岗位名称' },
                                { key: 'timeList', title: '起止时间' },
                                { key: 'content', title: '工作内容' },
                                { key: 'remarks', title: '备注' },
                                { key: 'annex1UrlList', title: '证明材料' },
                            ], value: [], title: '【担任外单位兼职工作】履现职以来，若在外单位担任兼职工作，请提供具体资料', bgTit: '主要工作经历和管理工作', bgShow: false
                        },
                        {
                            topCategory: 1, secondCategory: 17, key: '', tsList: [
                                { key: 'name', title: '岗位名称' },
                                { key: 'timeList', title: '起止时间' },
                                { key: 'content', title: '工作内容' },
                                { key: 'remarks', title: '备注' },
                                { key: 'annex1UrlList', title: '证明材料' },
                            ], value: [], title: '【担任本校兼职工作】履现职以来，若在本校担任兼职工作，请提供具体资料', bgTit: '主要工作经历和管理工作', bgShow: false
                        }
                    ]
                },
                {
                    smTit: '第二部分:教师基础信息',
                    smId: 'part2',
                    smList: [
                        {
                            topCategory: 2, secondCategory: 1, key: '', tsList: [
                                { key: 'typeName', title: '骨干教师类型', optionName: 'core_teacher_type' },
                                { key: 'startTime', title: '获得称号时间' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '请对应您所获得的每种类型的骨干教师称号，添加具体资料', bgTit: '骨干教师信息', bgShow: true
                        },
                    ]
                },
                {
                    smTit: '第三部分:德育工作',
                    smId: 'part3',
                    smList: [
                        {
                            topCategory: 3, secondCategory: 1, key: '', tsList: [
                                { key: 'typeName', title: '德育称号', optionName: 'moral_edu_type' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '获得称号时间' },
                                { key: 'relationOrgName', title: '颁发部门', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '【德育称号】参加工作以来，若有获得德育称号，请对应每种类型的称号，添加具体资料', bgTit: '德育工作', bgShow: true
                        },
                        {
                            topCategory: 3, secondCategory: 2, key: '', tsList: [
                                { key: 'typeName', title: '少先队称号', optionName: 'young_pioneers_type' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '获得称号时间' },
                                { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '【少先队称号】参加工作以来，若有获得少先队称号，请对应每种类型的称号，添加具体资料', bgTit: '德育工作', bgShow: false
                        },
                        {
                            topCategory: 3, secondCategory: 3, key: '', tsList: [
                                { key: 'name', title: '班主任/副班主任', optionName: 'bzrType' },
                                { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                                { key: 'grade', title: '年级' },
                                { key: 'classInfo', title: '班级' },
                                { key: 'typeName', title: '荣誉称号', optionName: 'class_honorary_title' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '获奖时间' },
                                { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '【品质班集体相关荣誉】近5学年，若担任班主任/副班主任时，所带班级曾被评为品质班级或先进班集体班级，请提供具体资料。【班主任、副班主任所填报的荣营需在任期内】(含区级、市级、省级、国家级)', bgTit: '德育工作', bgShow: false
                        },
                        {
                            topCategory: 3, secondCategory: 4, key: '', tsList: [
                                { key: 'typeName', title: '活动类型', placeholder: '如主题班会、主题队会、主题教育实践活动、社会实践活动等' },
                                { key: 'name', title: '活动名称' },
                                { key: 'address', title: '活动地点' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '时间' },
                                { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证明材料' },
                            ], value: [], title: '【育人活动】近 5 年内，若有参与示范展示类育人活动(含校级、区级、市级、省级、国家级)，请添加具体资料【代表校区/学校进行展示的活动】', bgTit: '德育工作', bgShow: false
                        },
                        {
                            topCategory: 3, secondCategory: 5, key: '', tsList: [
                                { key: 'name', title: '活动名称' },
                                { key: 'typeName', title: '分享主题/名称' },
                                { key: 'address', title: '活动地点' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '时间' },
                                { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证明材料' },
                            ], value: [], title: '【育人经验工作分享】近 5 年内，若有承担各级育人经验工作分享(含校级、区级、市级、省级、国家级)', bgTit: '德育工作', bgShow: false
                        },
                        {
                            topCategory: 3, secondCategory: 6, key: 'isSelect', value: '', noShowLabel: true, optionList: ['符合', '不符合'], title: '遵守师德规范，提高道德修养，勤勉敬业，乐于奉献，廉洁从教，树立良好的师德形象。', bgTit: '师德师风', bgShow: true
                        },
                        {
                            topCategory: 3, secondCategory: 7, key: '', tsList: [
                                { key: 'timeList', title: '起止时间' },
                            ], value: [], title: '参加工作以来担任班主任工作情况', bgTit: '班主任工作', bgShow: true
                        },
                        {
                            topCategory: 3, secondCategory: 8, key: '', tsList: [
                                { key: 'schoolYear', title: '学年' },
                                { key: 'schoolTerm', title: '学期' },
                                { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                                { key: 'grade', title: '年级' },
                                { key: 'classInfo', title: '班级' },
                                { key: 'remarks', title: '备注' },
                            ], value: [], title: '近 5 学年担任班主任具体情况', bgTit: '班主任工作', bgShow: false
                        },
                        {
                            topCategory: 3, secondCategory: 9, key: '', tsList: [
                                { key: 'timeList', title: '起止时间' },
                            ], value: [], title: '参加工作以来担任副班主任工作情况', bgTit: '副班主任工作', bgShow: true
                        },
                        {
                            topCategory: 3, secondCategory: 10, key: '', tsList: [
                                { key: 'schoolYear', title: '学年' },
                                { key: 'schoolTerm', title: '学期' },
                                { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                                { key: 'grade', title: '年级' },
                                { key: 'classInfo', title: '班级' },
                                { key: 'remarks', title: '备注' },
                            ], value: [], title: '近 5 学年担任副班主任具体情况', bgTit: '副班主任工作', bgShow: false
                        },
                        {
                            topCategory: 3, secondCategory: 11, key: '', tsList: [
                                { key: 'timeList', title: '起止时间' },
                            ], value: [], title: '参加工作以来担任辅导员工作情况', bgTit: '辅导员工作', bgShow: true
                        },
                        {
                            topCategory: 3, secondCategory: 12, key: '', tsList: [
                                { key: 'schoolYear', title: '学年' },
                                { key: 'schoolTerm', title: '学期' },
                                { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                                { key: 'grade', title: '年级' },
                                { key: 'classInfo', title: '班级' },
                                { key: 'remarks', title: '备注' },
                            ], value: [], title: '近 5 学年担任辅导员工作具体情况', bgTit: '辅导员工作', bgShow: false
                        },
                    ]
                },
                {
                    smTit: '第四部分:教学工作',
                    smId: 'part4',
                    smList: [
                        {
                            topCategory: 4, secondCategory: 1, key: '', tsList: [
                                { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                                { key: 'grade', title: '年级' },
                                { key: 'classInfo', title: '班级' },
                                { key: 'periodSubjectList', title: '学科' },
                                { key: 'timeList', title: '起止时间' },
                                { key: 'isSelect', title: '是否担任兴趣班课程' },
                            ], value: [], title: '当前所教年级、班级和学科', bgTit: '教学基本信息', bgShow: true
                        },
                        {
                            topCategory: 4, secondCategory: 2, key: '', tsList: [
                                { key: 'name', title: '课例名称' },
                                { key: 'categoryName', title: '内容/主题类别', optionName: 'content_theme_type' },
                                { key: 'relationName', title: '所属活动名称' },
                                { key: 'typeName', title: '类型', optionName: 'class_teach_type' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                                { key: 'startTime', title: '获奖时间' },
                                { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '获奖证书' },
                            ], value: [], title: '【课堂教学获奖】参加工作以来，若有课堂教学(含录像课例、现场课例、微课、说课、教学技能)获奖(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: true
                        },
                        {
                            topCategory: 4, secondCategory: 3, key: '', tsList: [
                                { key: 'name', title: '活动名称' },
                                { key: 'categoryName', title: '内容/主题类别', optionName: 'content_theme_type' },
                                { key: 'relationName', title: '学生姓名', placeholder: '可填多个学生姓名，分号分隔' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                                { key: 'startTime', title: '时间' },
                                { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '获奖证书' },
                            ], value: [], title: '【指导学生获奖】近 5 年内，若指导学生在活动(仅包括教育局统一发文、学校统一组织的活动)中获奖(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
                        },
                        {
                            topCategory: 4, secondCategory: 4, key: '', tsList: [
                                { key: 'name', title: '荣誉名称' },
                                { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                                { key: 'startTime', title: '时间' },
                                { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '获奖证书' },
                            ], value: [], title: '【其他教学工作获奖】参加工作以来，若在教学工作方面有上述未提及的重要荣誉奖项(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
                        },
                        {
                            topCategory: 4, secondCategory: 5, key: '', tsList: [
                                { key: 'isSelect', title: '研究课/示范课', optionList: ['研究课', '示范课'] },
                                { key: 'name', title: '课例名称' },
                                { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                                { key: 'relationName', title: '所属活动名称' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '时间' },
                                { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '【主讲研究课】近5年内，若主讲过研究课/示范课，请添加具体资料:(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
                        },
                        {
                            topCategory: 4, secondCategory: 6, key: '', tsList: [
                                { key: 'name', title: '研讨会名称' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '时间' },
                                { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '【教育教学、学校管理思想研讨会】近5年内，若组织过教育教学、学校管理思想研讨会，请提供具体资料(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
                        },
                        {
                            topCategory: 4, secondCategory: 7, key: '', tsList: [
                                { key: 'name', title: '研讨名称' },
                                { key: 'relationName', title: '所属活动名称' },
                                { key: 'startTime', title: '时间' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '【主讲教学研讨和交流】近 5 年内，若主讲过教学研讨和交流(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
                        },
                        {
                            topCategory: 4, secondCategory: 8, key: '', tsList: [
                                { key: 'address', title: '地点' },
                                { key: 'periodSubjectList', title: '上课科目' },
                                { key: 'timeList', title: '起止时间' },
                                { key: 'relationName', title: '所属活动名称' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证明材料' },
                            ], value: [], title: '【送教下乡】近 5 年内，若有过送教下乡，请添加具体资料', bgTit: '教学工作获奖', bgShow: false
                        },
                        {
                            topCategory: 4, secondCategory: 9, key: '', tsList: [
                                { key: 'name', title: '支教名称' },
                                { key: 'levelName', title: '级别', optionName: 'teach_support_level' },
                                { key: 'startTime', title: '支教起始时间' },
                                { key: 'schoolTerm', title: '支教总学期数', isNum: true },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证明材料' },
                            ], value: [], title: '近5年内，若有外出支教、且单次支教时间达到或超过一学期(含区内支教、区外市内支教、市外省内支教、省外支教)，请添加具体资料', bgTit: '外出支教', bgShow: true
                        },
                        {
                            topCategory: 4, secondCategory: 10, key: '', tsList: [
                                { key: 'schoolYear', title: '年份' },
                                { key: 'typeName', title: '履行情况', optionList: ['合格', '良好', '优秀'] },
                            ], value: [], title: '请埴写近 5 年来的履职考核结果', bgTit: '五年履职考核', bgShow: true
                        },
                        {
                            topCategory: 4, secondCategory: 11, key: '', tsList: [
                                { key: 'schoolYear', title: '年份' },
                                { key: 'classInfo', title: '平均课时' },
                            ], value: [], title: '请埴写近 5 年来的平均课量', bgTit: '五年学科任课量', bgShow: true
                        },
                    ]
                },
                {
                    smTit: '第五部分:指导青年教师',
                    smId: 'part5',
                    smList: [
                        {
                            topCategory: 5, secondCategory: 1, key: '', tsList: [
                                { key: 'typeName', title: '帮扶性质', optionName: 'assistance_nature' },
                                { key: 'num', title: '指导人数' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '近 5 年内，若有常规指导青年教师(含校内、区内、区外帮扶师徒结对)', bgTit: '指导青年教师', bgShow: true
                        },
                        {
                            topCategory: 5, secondCategory: 2, key: '', tsList: [
                                { key: 'relationName', title: '被指导教师' },
                                { key: 'startTime', title: '指导起始时间' },
                                { key: 'typeName', title: '教学成绩趋势', optionList: ['上升', '持平', '下降'] },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '成绩变化证明' },
                            ], value: [], title: '请填报近 5 学年内常规指导的青年教师，其教学成绩变化的具体情况(3 名及以下需全部填报，3 名以上可选填)，证明材料需体现成绩排名变化情况，由教务处加盖公章', bgTit: '指导青年教师', bgShow: false
                        },
                        {
                            topCategory: 5, secondCategory: 3, key: '', tsList: [
                                { key: 'name', title: '活动名称' },
                                { key: 'relationName', title: '被指导教师' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                                { key: 'startTime', title: '时间' },
                                { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '获奖证书' },
                            ], value: [], title: '近 5 年内，若有指导青年教师在各级教育教学评比活动中获奖(含校级、区级、市级、省级、国家级)，请添加具体资料(指导的每位青年教师仅需选取最高的三个奖项填写即可)', bgTit: '指导青年教师', bgShow: false
                        },
                        {
                            topCategory: 5, secondCategory: 4, key: '', tsList: [
                                { key: 'name', title: '活动名称' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '时间' },
                                { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '【担任评市专家】近5年内，若担任过教育教学评比活动的评市专家，请添加具体资料(含校级、区级、市级、省级、国家级)', bgTit: '指导青年教师', bgShow: false
                        },
                        {
                            topCategory: 5, secondCategory: 5, key: '', tsList: [
                                { key: 'name', title: '工作室名称' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '组建时间' },
                                { key: 'num', title: '成员数量' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '【名师工作室】近5年内，若有主持名师工作室/名校校长工作室，请添加具体资料(含区级、市级、省级、国家级)', bgTit: '指导青年教师', bgShow: false
                        },
                    ]
                },
                {
                    smTit: '第六部分:科研工作',
                    smId: 'part6',
                    smList: [
                        {
                            topCategory: 6, secondCategory: 1, key: '', tsList: [
                                { key: 'name', title: '课题名称' },
                                { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                                { key: 'startTime', title: '开题时间' },
                                { key: 'statusName', title: '课题状态', optionName: 'archive_topic_status' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'relationOrgName', title: '认定机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '【主持课题研究】参加工作以来，若有主持课题研究，请添加具体资料(含校级、区级、市级、省级、国家级)', bgTit: '科研工作', bgShow: true
                        },
                        {
                            topCategory: 6, secondCategory: 2, key: '', tsList: [
                                { key: 'name', title: '课题名称' },
                                { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                                { key: 'startTime', title: '开题时间' },
                                { key: 'statusName', title: '课题状态', optionName: 'archive_topic_status' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'relationOrgName', title: '认定机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '【叁与课题研究】参加工作以来，若有参与课题研究，请添加具体资料 (含校级、区级、市级、省级、国家级)', bgTit: '科研工作', bgShow: false
                        },
                        {
                            topCategory: 6, secondCategory: 3, key: '', tsList: [
                                { key: 'typeName', title: '成果类型', optionName: 'archive_achieve_type' },
                                { key: 'isSelect', title: '是否课题类成果' },
                                { key: 'name', title: '成果名称' },
                                { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                                { key: 'relationName', title: '承担角色', optionName: 'assume_role' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                                { key: 'startTime', title: '获奖时间' },
                                { key: 'relationOrgName', title: '认定机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '【论文成里获奖】参加工作以来，若有论文、教学案例、育人案例、教学课例成果获奖，请提供具体资料(含校级、区级、市级、省级、国家级)', bgTit: '科研工作', bgShow: false
                        },
                        {
                            topCategory: 6, secondCategory: 4, key: '', tsList: [
                                { key: 'name', title: '成果名称' },
                                { key: 'isSelect', title: '是否课题类成果' },
                                { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                                { key: 'relationName', title: '承担角色', optionName: 'assume_role' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                                { key: 'startTime', title: '获奖时间' },
                                { key: 'relationOrgName', title: '认定机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '【教育教学成果获奖】参加工作以来，若有教育教学成果获奖，请提供具体资料', bgTit: '科研工作', bgShow: false
                        },
                        {
                            topCategory: 6, secondCategory: 5, key: '', tsList: [
                                { key: 'name', title: '论著名称' },
                                { key: 'isSelect', title: '是否课题类成果' },
                                { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                                { key: 'typeName', title: '论著类别', optionName: 'opus_type' },
                                { key: 'relationName', title: '编著身份', optionName: 'opus_role' },
                                { key: 'startTime', title: '出版时间' },
                                { key: 'publisher', title: '出版社' },
                                { key: 'publishNo', title: '版号' },
                                { key: 'annex1UrlList', title: '论著封面、目录页、姓名页' },
                            ], value: [], title: '参加工作以来，若有出版论著，请添加具体资料', bgTit: '科研工作', bgShow: false
                        },
                        {
                            topCategory: 6, secondCategory: 6, key: '', tsList: [
                                { key: 'name', title: '作品名称' },
                                { key: 'isSelect', title: '是否课题类成果' },
                                { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '发表时间' },
                                { key: 'publishType', title: '期刊性质', optionName: 'archive_area_level' },
                                { key: 'publishNo', title: '期刊刊号' },
                                { key: 'publisher', title: '期刊名称' },
                                { key: 'annex1UrlList', title: '发表复印件' },
                            ], value: [], title: '【论文成果获奖】参加工作以来，若有论文、教学案例、育人案例、教学课例成果获奖，请提供具体资料(含校级、区级、市级、省级、国家级)', bgTit: '科研工作', bgShow: false
                        },
                    ]
                },
                {
                    smTit: '第七部分:学习提高',
                    smId: 'part7',
                    smList: [
                        {
                            topCategory: 7, secondCategory: 1, key: '', tsList: [
                                { key: 'name', title: '培训名称' },
                                { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '时间' },
                                { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '近5年内，若有承担各级培训任务，请添加具体资料(含校级、区级、市级、省级、国家级)', bgTit: '继续教育', bgShow: true
                        },
                        {
                            topCategory: 7, secondCategory: 2, key: '', tsList: [
                                { key: 'schoolYear', title: '学年度' },
                                { key: 'annex1UrlList', title: '学年度学时单',accept:'.pdf' },
                            ], value: [], title: '近5学年，若有参加各级研修，请上传学时网的学时单', bgTit: '继续教育', bgShow: false
                        },
                        {
                            topCategory: 7, secondCategory: 3, key: '', tsList: [
                                { key: 'name', title: '研修名称' },
                                { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '时间' },
                                { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '近 5 年内，若有参加各级校外研修(含区级、市级、省级、国家级)，请添加具体资料(依据个人学时单填写)', bgTit: '校外研修', bgShow: true
                        },
                        {
                            topCategory: 7, secondCategory: 4, key: '', tsList: [
                                { key: 'isSelect', title: '来源', optionList: ['校本培训', '校本研修'] },
                                { key: 'annex1UrlList', title: '证明材料(合格证)' },
                            ], value: [], title: '本学年若参与了校本研修或培训，请添加具体资料(依据个人学时单填写)', bgTit: '校本研修', bgShow: true
                        },
                    ]
                },
                {
                    smTit: '第八部分:其他荣誉类称号',
                    smId: 'part8',
                    smList: [
                        {
                            topCategory: 8, secondCategory: 1, key: '', tsList: [
                                { key: 'name', title: '称号名称' },
                                { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                                { key: 'startTime', title: '时间' },
                                { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                                { key: 'orgName', title: '证明材料签章单位' },
                                { key: 'annex1UrlList', title: '证书' },
                            ], value: [], title: '请对应您所获得的每种类型的其他荣誉类称号，添加具体资料', bgTit: '其他荣誉类称号', bgShow: true
                        },
                    ]
                },
            ],
        }
    },
    computed: {
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    created() {
        this.getArchiveQuery();
        this.getQueryDict();
    },
    mounted() {
        this.containerRef = this.$refs.containerRef;
    },
    methods: {
        showModelSumbit() {
            console.log(this.showModelObj);
            var obj = {
                oneTxt: '',
                twoTxt: '',
                topCategory: this.showModelObj.activeCate.topCategory,
                secondCategory: this.showModelObj.activeCate.secondCategory,
            }
            this.showModelObj.activeCate.tsList.forEach(e => {
                if (e.key == 'timeList') {
                    if (this.showModelObj.txObj[e.key].length != 0) {
                        obj.startTime = this.showModelObj.txObj[e.key][0]
                        obj.endTime = this.showModelObj.txObj[e.key][1]
                    }
                } else if (e.key == 'periodSubjectList') {
                    if (this.showModelObj.txObj[e.key].length != 0) {
                        obj.period = this.showModelObj.txObj[e.key][0]
                        obj.subject = this.showModelObj.txObj[e.key][1]
                    }
                } else if (e.key == 'annex1UrlList' || e.key == 'annex2UrlList') {
                    var fileList = [];
                    this.showModelObj.txObj[e.key].forEach(e => {
                        if (e.response.code == 1 || e.response.data) {
                            fileList.push(e.response.data);
                        }
                    })
                    obj[e.key.slice(0, -4)] = fileList.toString();
                } else {
                    obj[e.key] = this.showModelObj.txObj[e.key]
                }
            })
            // this.
            this.allList.forEach(oneItem => {
                oneItem.smList.forEach(e => {
                    if (e.secondCategory == this.showModelObj.activeCate.secondCategory && e.topCategory == this.showModelObj.activeCate.topCategory) {
                        if ((e.topCategory == 1 && e.secondCategory == 5) || (e.topCategory == 1 && e.secondCategory == 6)) {
                            obj.twoTxt = obj.orgName + ' ' + obj.major,
                                this.qualificationList.forEach(j => {
                                    if (j.dictKey == obj.qualification) {
                                        obj.oneTxt = j.dictValue
                                    }
                                })
                            if (obj.startTime) {
                                obj.twoTxt = timestampToTime(obj.startTime).slice(0, -9) + '~' + timestampToTime(obj.endTime).slice(0, -9) + '    ' + obj.orgName + '    ' + obj.major
                            }
                        }
                        if ((e.topCategory == 1 && e.secondCategory == 10)) {
                            obj.twoTxt = obj.orgName + ' ' + obj.major
                            this.technicalTitleList.forEach(j => {
                                if (j.dictKey == obj.name) {
                                    obj.oneTxt = j.dictValue
                                }
                            })
                            if (obj.startTime) {
                                obj.twoTxt = timestampToTime(obj.startTime).slice(0, -9)
                            }
                        }
                        if ((e.topCategory == 1 && e.secondCategory == 11)) {
                            this.archiveXdXkList.forEach(j => {
                                if (obj.period == j.dictKey && obj.period) {
                                    j.childrenList.forEach(m => {
                                        if (obj.subject == m.dictKey) {
                                            obj.oneTxt = j.dictValue + ' ' + m.dictValue
                                        }
                                    })
                                }
                            })
                        }
                        if ((e.topCategory == 1 && e.secondCategory == 12)) {
                            obj.twoTxt = ''
                            this.mandarinLevelList.forEach(j => {
                                if (j.dictKey == obj.prizeLevel) {
                                    obj.oneTxt = j.dictValue
                                }
                            })
                            if (obj.startTime) {
                                obj.twoTxt = timestampToTime(obj.startTime).slice(0, -9)
                            }
                        }
                        if (e.topCategory == 1 && (e.secondCategory == 13 || e.secondCategory == 14 || e.secondCategory == 15 || e.secondCategory == 16 || e.secondCategory == 17)) {
                            obj.twoTxt = ' '
                            obj.oneTxt = obj.orgName ? obj.orgName : obj.name
                            if (obj.startTime) {
                                obj.twoTxt = timestampToTime(obj.startTime).slice(0, -9) + '~' + timestampToTime(obj.endTime).slice(0, -9)
                            }
                        }
                        if ((e.topCategory == 2 && e.secondCategory == 1)) {
                            obj.twoTxt = ''
                            this.coreTeacherTypeList.forEach(j => {
                                if (j.dictKey == obj.typeName) {
                                    obj.oneTxt = j.dictValue
                                }
                            })
                            if (obj.startTime) {
                                obj.twoTxt = timestampToTime(obj.startTime).slice(0, -9)
                            }
                        }
                        if ((e.topCategory == 3 && (e.secondCategory == 1 || e.secondCategory == 2))) {
                            obj.twoTxt = ''
                            if (e.secondCategory == 1) {
                                this.moralEduTypeList.forEach(j => {
                                    if (j.dictKey == obj.typeName) {
                                        obj.oneTxt = j.dictValue
                                    }
                                })
                            } else if (e.secondCategory == 2) {
                                this.youngPioneersTypeList.forEach(j => {
                                    if (j.dictKey == obj.typeName) {
                                        obj.oneTxt = j.dictValue
                                    }
                                })
                            }
                            this.archiveAreaLevelList.forEach(j => {
                                if (j.dictKey == obj.levelName) {
                                    obj.twoTxt += (j.dictValue + ' ')
                                }
                            })
                            if (obj.startTime) {
                                obj.twoTxt += (timestampToTime(obj.startTime).slice(0, -9) + ' ')
                            }
                            this.awardOrgList.forEach(j => {
                                if (j.dictKey == obj.relationOrgName) {
                                    obj.twoTxt += (j.dictValue + ' ')
                                }
                            })
                        }
                        if ((e.topCategory == 3 && e.secondCategory == 3)) {
                            obj.oneTxt = obj.name
                            obj.twoTxt = ''
                            this.schoolAreaList.forEach(j => {
                                if (j.dictKey == obj.schoolArea) {
                                    obj.twoTxt += (j.dictValue + ' ')
                                }
                            })
                            obj.twoTxt += (obj.grade + ' ' + obj.classInfo)
                        }
                        if ((e.topCategory == 3 && (e.secondCategory == 4 || e.secondCategory == 5)) || (e.topCategory == 4 && (e.secondCategory == 6 || e.secondCategory == 7))) {
                            obj.twoTxt = ' '
                            obj.oneTxt = obj.name
                            if (obj.startTime) {
                                obj.twoTxt += timestampToTime(obj.startTime).slice(0, -9)
                            }
                        }
                        if (e.topCategory == 3 && (e.secondCategory == 7 || e.secondCategory == 9 || e.secondCategory == 11)) {
                            if (obj.startTime) {
                                obj.oneTxt = timestampToTime(obj.startTime).slice(0, -9) + '~' + timestampToTime(obj.endTime).slice(0, -9)
                            }
                        }
                        if (e.topCategory == 3 && (e.secondCategory == 8 || e.secondCategory == 10 || e.secondCategory == 12)) {
                            obj.oneTxt = obj.schoolYear + ' ' + obj.schoolTerm
                            obj.twoTxt = ' '
                            if (obj.schoolArea) {
                                this.schoolAreaList.forEach(j => {
                                    if (j.dictKey == obj.schoolArea) {
                                        obj.twoTxt = j.dictValue + ' ' + obj.grade
                                    }
                                })
                            } else {
                                obj.twoTxt = obj.grade
                            }
                        }
                        if ((e.topCategory == 4 && e.secondCategory == 1)) {
                            this.schoolAreaList.forEach(j => {
                                if (j.dictKey == obj.schoolArea) {
                                    obj.oneTxt = (j.dictValue)
                                }
                            })
                            obj.twoTxt = obj.grade + ' ' + obj.classInfo + ' '
                            this.archiveXdXkList.forEach(j => {
                                if (obj.period == j.dictKey && obj.period) {
                                    j.childrenList.forEach(m => {
                                        if (obj.subject == m.dictKey) {
                                            obj.twoTxt += m.dictValue
                                        }
                                    })
                                }
                            })
                        }
                        if ((e.topCategory == 4 && (e.secondCategory == 2 || e.secondCategory == 3 || e.secondCategory == 4 || e.secondCategory == 5))) {
                            obj.oneTxt = obj.name
                            obj.twoTxt = ''
                            this.contentThemeTypeList.forEach(j => {
                                if (j.dictKey == obj.categoryName) {
                                    obj.twoTxt += (j.dictValue + '  ')
                                }
                            })
                            this.archiveAreaLevelList.forEach(j => {
                                if (j.dictKey == obj.levelName) {
                                    obj.twoTxt += (j.dictValue + '  ')
                                }
                            })
                            this.awardLevelList.forEach(j => {
                                if (j.dictKey == obj.prizeLevel) {
                                    obj.twoTxt += (j.dictValue + '  ')
                                }
                            })
                        }
                        if (e.topCategory == 4 && (e.secondCategory == 8)) {
                            obj.twoTxt = ''
                            obj.oneTxt = obj.address
                            if (obj.startTime) {
                                obj.twoTxt = timestampToTime(obj.startTime).slice(0, -9) + '~' + timestampToTime(obj.endTime).slice(0, -9)
                            }
                        }
                        if (e.topCategory == 4 && (e.secondCategory == 9)) {
                            obj.twoTxt = ''
                            obj.oneTxt = obj.name
                            this.teachSupportLevelList.forEach(j => {
                                if (j.dictKey == obj.levelName) {
                                    obj.twoTxt += (j.dictValue + '  ')
                                }
                            })
                            if (obj.startTime) {
                                obj.twoTxt += timestampToTime(obj.startTime).slice(0, -9) + ' '
                            }
                            obj.twoTxt += obj.orgName
                        }
                        if (e.topCategory == 4 && (e.secondCategory == 10)) {
                            obj.oneTxt = obj.schoolYear
                            obj.twoTxt = obj.typeName
                        }
                        if ((e.topCategory == 4 && e.secondCategory == 11) || (e.topCategory == 7 && e.secondCategory == 2)) {
                            obj.oneTxt = obj.schoolYear
                            obj.twoTxt = obj.classInfo
                        }
                        if (e.topCategory == 5 && e.secondCategory == 1) {
                            this.assistanceNatureList.forEach(j => {
                                if (j.dictKey == obj.typeName) {
                                    obj.oneTxt = j.dictValue
                                }
                            })
                            obj.twoTxt = obj.num
                        }
                        if (e.topCategory == 5 && e.secondCategory == 2) {
                            if (obj.startTime) {
                                obj.oneTxt = timestampToTime(obj.startTime).slice(0, -9)
                            }
                            obj.twoTxt = obj.typeName
                        }
                        if (e.topCategory == 5 && e.secondCategory == 3) {
                            obj.oneTxt = obj.name
                            obj.twoTxt = obj.relationName + ' '
                            this.archiveAreaLevelList.forEach(j => {
                                if (j.dictKey == obj.levelName) {
                                    obj.twoTxt += (j.dictValue + ' ')
                                }
                            })
                            this.awardLevelList.forEach(j => {
                                if (j.dictKey == obj.prizeLevel) {
                                    obj.twoTxt += j.dictValue
                                }
                            })
                        }
                        if ((e.topCategory == 5 && (e.secondCategory == 4 || e.secondCategory == 5)) || (e.topCategory == 8 && e.secondCategory == 1)) {
                            obj.oneTxt = obj.name
                            obj.twoTxt = ''
                            this.archiveAreaLevelList.forEach(j => {
                                if (j.dictKey == obj.levelName) {
                                    obj.twoTxt += (j.dictValue + '  ')
                                }
                            })
                            if (obj.startTime) {
                                obj.twoTxt += timestampToTime(obj.startTime).slice(0, -9)
                            }
                        }
                        if (e.topCategory == 6 && (e.secondCategory == 1 || e.secondCategory == 2)) {
                            obj.oneTxt = obj.name
                            obj.twoTxt = ''
                            this.contentThemeTypeList.forEach(j => {
                                if (j.dictKey == obj.categoryName) {
                                    obj.twoTxt += (j.dictValue + '  ')
                                }
                            })
                            if (obj.startTime) {
                                obj.twoTxt += (timestampToTime(obj.startTime).slice(0, -9) + ' ')
                            }
                            this.archiveTopicStatusList.forEach(j => {
                                if (j.dictKey == obj.statusName) {
                                    obj.twoTxt += (j.dictValue + '  ')
                                }
                            })
                        }
                        if (e.topCategory == 6 && (e.secondCategory == 3 || e.secondCategory == 4)) {
                            this.archiveAchieveTypeList.forEach(j => {
                                if (j.dictKey == obj.typeName) {
                                    obj.oneTxt = j.dictValue
                                }
                            })
                            if (e.secondCategory == 4) {
                                obj.oneTxt = obj.name
                            }
                            obj.twoTxt = obj.name + ' '
                            this.contentThemeTypeList.forEach(j => {
                                if (j.dictKey == obj.categoryName) {
                                    obj.twoTxt += (j.dictValue + '  ')
                                }
                            })
                            this.assumeRoleList.forEach(j => {
                                if (j.dictKey == obj.relationName) {
                                    obj.twoTxt += (j.dictValue + '  ')
                                }
                            })
                        }
                        if (e.topCategory == 6 && (e.secondCategory == 5 || e.secondCategory == 6)) {
                            obj.oneTxt = obj.name
                            obj.twoTxt = obj.isSelect + '  '
                            this.contentThemeTypeList.forEach(j => {
                                if (j.dictKey == obj.categoryName) {
                                    obj.twoTxt += (j.dictValue + '  ')
                                }
                            })
                            this.opusTypeList.forEach(j => {
                                if (j.dictKey == obj.typeName) {
                                    obj.twoTxt += (j.dictValue + '  ')
                                }
                            })
                        }
                        if (e.topCategory == 7 && (e.secondCategory == 1 || e.secondCategory == 3)) {
                            obj.oneTxt = obj.name
                            obj.twoTxt = ''
                            this.contentThemeTypeList.forEach(j => {
                                if (j.dictKey == obj.categoryName) {
                                    obj.twoTxt += (j.dictValue + '  ')
                                }
                            })
                            this.archiveAreaLevelList.forEach(j => {
                                if (j.dictKey == obj.levelName) {
                                    obj.twoTxt += (j.dictValue + ' ')
                                }
                            })
                            if (obj.startTime) {
                                obj.twoTxt += (timestampToTime(obj.startTime).slice(0, -9) + ' ')
                            }
                        }
                        if (e.topCategory == 7 && e.secondCategory == 4) {
                            obj.oneTxt = obj.isSelect
                        }
                        if (!this.showModelObj.txObj.archiveId) {
                            obj.archiveId = this.scSjm();
                            e.value.push(obj)
                        } else {
                            this.allList.forEach(cItem => {
                                cItem.smList.forEach(j => {
                                    if (j.tsList) {
                                        e.value.forEach(m => {
                                            if (m.archiveId == this.showModelObj.txObj.archiveId) {
                                                Object.assign(m, obj)
                                            }
                                        })
                                    }
                                })
                            })
                        }

                    }
                })
            })
            this.showModelObj.isShow = false
        },
        scSjm() {
            let date = (new Date()).valueOf(); //获取时间戳
            // console.log(new Date().getTime().toString().substring(4),date);
            let txt = '1234567890'; //生成的随机机器码
            let len = 13; //机器码有多少位
            let pwd = ''; //定义空变量用来接收机器码
            for (let i = 0; i < len; i++) {
                pwd += txt.charAt(Math.floor(Math.random() * txt.length)); //循环机器码位数随机填充
            }
            return date + pwd
        },
        ylImgs(data){
            this.activeimgs = []
            console.log(data);
            data.forEach(e=>{
                this.activeimgs.push(e.response.data)
            })
            this.isOpen = true;
        },
        save(type) {
            var objList = []
            var archiveId = this.scSjm();
            var archiveIdList = [];
            this.allList.forEach(oneItem => {
                oneItem.smList.forEach(e => {
                    if (e.tsList) {
                        e.value.forEach((j, k) => {
                            var obj = {}
                            obj.archiveId = j.archiveId ? j.archiveId : (archiveId + e.topCategory + e.secondCategory + k);
                            obj.topCategory = e.topCategory
                            obj.secondCategory = e.secondCategory
                            Object.assign(obj, j)
                            objList.push(obj)
                            archiveIdList.push(obj.archiveId)
                        })
                    } else {
                        var obj = {}
                        obj.archiveId = e.archiveId ? e.archiveId : (archiveId + e.topCategory + e.secondCategory);
                        obj.topCategory = e.topCategory
                        obj.secondCategory = e.secondCategory
                        obj[e.key] = e.value
                        objList.push(obj)
                        archiveIdList.push(obj.archiveId)
                    }
                })
            })

            console.log();
            archiveFillBatch({ archiveList: objList, subType: type }).then(res => {
                if (res.data.code == 1) {
                    this.archiveIdList.forEach(e => {
                        if (archiveIdList.indexOf(e) === -1) {
                            archiveFillDelete({ archiveId: e })
                        }
                    })
                }
            })
        },
        getArchiveQuery() {
            this.archiveIdList = []
            archiveQueryArchive().then(res => {
                res.data.data.forEach(e => {
                    this.archiveIdList.push(e.archiveId)
                    this.allList.forEach(oneItem => {
                        oneItem.smList.forEach(j => {
                            if (e.secondCategory == j.secondCategory && e.topCategory == j.topCategory) {
                                if (j.tsList) {
                                    j.value.push(e)
                                } else {
                                    j.archiveId = e.archiveId;
                                    j.value = e[j.key]
                                }
                            }
                        })
                    })

                    // archiveFillDelete({archiveId:e.archiveId })
                })
            })
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles, this.recommendImgList)
            if (file.response) {
                this.delFileList.push(file.response.data)
            }
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf')) {
                this.$message.error('文件必须是.pdf格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleAddFile(item) {
            this.showModelObj.txObj = {};
            this.showModelObj.activeCate = item;
            item.tsList.forEach(e => {
                if (e.key.substr(-4) == 'List') {
                    this.showModelObj.txObj[e.key] = []
                } else {
                    this.showModelObj.txObj[e.key] = ''
                }

            })
            this.showModelObj.tit = item.title
            console.log(this.showModelObj);
            this.showModelObj.isShow = true;
        },
        handleClick(e) {
            console.log(e, this.containerRef, this.$refs.containerRef);
            console.log(this.$refs.containerRef);
            e.preventDefault()
        },
        delImg(imgList, index) {
            imgList.splice(index, 1)
        },
        delZl(zlList, index) {
            zlList.splice(index, 1)
        },
        editZL(item, index) {
            this.showModelObj.txObj = {};
            this.showModelObj.activeCate = item;
            var editObj = JSON.parse(JSON.stringify(item.value[index]))
            console.log(item, index, editObj)
            item.tsList.forEach(e => {
                if (e.key == 'timeList') {
                    this.showModelObj.txObj[e.key] = []
                    if (editObj.startTime) {
                        this.showModelObj.txObj[e.key] = [editObj.startTime, editObj.endTime]
                    }
                } else if (e.key == 'periodSubjectList') {
                    this.showModelObj.txObj[e.key] = []
                    if (editObj.period) {
                        this.showModelObj.txObj[e.key] = [editObj.period, editObj.subject]
                    }
                } else if (e.key == 'annex1UrlList' || e.key == 'annex2UrlList') {
                    this.showModelObj.txObj[e.key] = []
                    console.log(e, e.key.slice(0, -4), editObj[e.key.slice(0, -4)]);
                    if (editObj[e.key.slice(0, -4)]) {
                        var fileList = editObj[e.key.slice(0, -4)].split(',')
                        console.log(fileList);
                        fileList.forEach(j => {
                            this.showModelObj.txObj[e.key].push({
                                url: j,
                                response: {
                                    data: j
                                }
                            })
                        })
                    }
                } else {
                    this.showModelObj.txObj[e.key] = editObj[e.key]
                }
            })
            if (editObj.archiveId) {
                this.showModelObj.txObj.archiveId = editObj.archiveId
            }
            this.showModelObj.tit = item.title
            console.log(this.showModelObj);
            this.showModelObj.isShow = true;
        },
        downloadFile(fileUrl) {
            download(fileUrl)
        },
        getQueryDict() {
            queryDict({ code: 'political_climate' }).then(res => {
                this.politicalClimateList = res.data.data;
            })
            queryDict({ code: 'nation' }).then(res => {
                this.nationList = res.data.data;
            })
            queryDict({ code: 'archive_xd_xk' }).then(res => {
                this.archiveXdXkList = res.data.data;
            })
            queryDict({ code: 'technical_title' }).then(res => {
                this.technicalTitleList = res.data.data;
            })
            queryDict({ code: 'mandarin_level' }).then(res => {
                this.mandarinLevelList = res.data.data;
            })
            queryDict({ code: 'core_teacher_type' }).then(res => {
                this.coreTeacherTypeList = res.data.data;
            })
            queryDict({ code: 'moral_edu_type' }).then(res => {
                this.moralEduTypeList = res.data.data;
            })
            queryDict({ code: 'class_honorary_title' }).then(res => {
                this.classHonoraryTitleList = res.data.data;
            })
            queryDict({ code: 'young_pioneers_type' }).then(res => {
                this.youngPioneersTypeList = res.data.data;
            })
            queryDict({ code: 'archive_area_level' }).then(res => {
                this.archiveAreaLevelList = res.data.data;
            })
            queryDict({ code: 'teach_support_level' }).then(res => {
                this.teachSupportLevelList = res.data.data;
            })
            queryDict({ code: 'award_org' }).then(res => {
                this.awardOrgList = res.data.data;
            })
            queryDict({ code: 'school_area' }).then(res => {
                this.schoolAreaList = res.data.data;
            })
            queryDict({ code: 'content_theme_type' }).then(res => {
                this.contentThemeTypeList = res.data.data;
            })
            queryDict({ code: 'class_teach_type' }).then(res => {
                this.classTeachTypeList = res.data.data;
            })
            queryDict({ code: 'award_level' }).then(res => {
                this.awardLevelList = res.data.data;
            })
            queryDict({ code: 'qualification' }).then(res => {
                this.qualificationList = res.data.data;
            })
            queryDict({ code: 'assistance_nature' }).then(res => {
                this.assistanceNatureList = res.data.data;
            })
            queryDict({ code: 'archive_topic_status' }).then(res => {
                this.archiveTopicStatusList = res.data.data;
            })
            queryDict({ code: 'archive_achieve_type' }).then(res => {
                this.archiveAchieveTypeList = res.data.data;
            })
            queryDict({ code: 'assume_role' }).then(res => {
                this.assumeRoleList = res.data.data;
            })
            queryDict({ code: 'opus_type' }).then(res => {
                this.opusTypeList = res.data.data;
            })
            queryDict({ code: 'opus_role' }).then(res => {
                this.opusRoleList = res.data.data;
            })
        }
    }
}
</script>
  <!--内联样式-->
<style lang="scss" scoped>
/deep/ .el-anchor__link {
    font-size: 16px !important;
}

/deep/ .el-form-item__label {
    color: #333 !important;
    // font-weight: bold;
    line-height: 32px;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    position: relative;
}

.csbbb .eicon {
    display: none;
    position: absolute;
    right: 20px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb:hover .eicon {
    display: block;
}

.avatar-uploader .avatar {
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
}

.avatar-uploader {
    padding: 0 5px;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    text-align: center;
}
</style>
  