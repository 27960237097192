<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel" v-if="isPrincipalStudio == 1"><a>名校长工作室统计</a></el-breadcrumb-item>
                <el-breadcrumb-item @click="cancel" v-if="isMsgzsStatistics == 1"><a>名师工作室统计</a></el-breadcrumb-item>
                <el-breadcrumb-item @click="cancel" v-if="isJkyStatistics == 1"><a>教科研统计</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="isMsgzsStatistics != 1 && isJkyStatistics != 1 && isPrincipalStudio != 1"
                    @click="cancel"><a>教研赛事</a></el-breadcrumb-item>
                <el-breadcrumb-item>教研赛事详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 140px);overflow: auto;">
            <el-descriptions :column="3" class="detailFF" title="基本信息">
                <el-descriptions-item label="赛事名称">{{ form.raceName }}</el-descriptions-item>
                <el-descriptions-item label="赛事性质">
                    <span v-if="form.raceNature == 1">普通</span>
                    <span v-if="form.raceNature == 2">名师工作室</span>
                    <span v-if="form.raceNature == 3">名校长工作室</span>
                    <span v-if="form.raceNature == 4">群组任务</span>
                </el-descriptions-item>
                <el-descriptions-item label="工作室" v-if="form.groupName">{{ form.groupName }}</el-descriptions-item>
                <el-descriptions-item v-if="form.raceNature && form.raceNature == '1' && form.periodName" label="学段">{{ form.periodName
                }}</el-descriptions-item>
                <el-descriptions-item v-if="form.raceNature && form.raceNature == '1' && form.gradeName" label="年级">{{ form.gradeName
                }}</el-descriptions-item>
                <el-descriptions-item v-if="form.raceNature && form.raceNature == '1' && form.subjectName" label="学科">{{ form.subjectName
                }}</el-descriptions-item>
                <el-descriptions-item label="赛事级别">{{ form.raceLevelName }}</el-descriptions-item>
                <el-descriptions-item label="组织单位" v-if="form.orgTxt">{{ form.orgTxt }}</el-descriptions-item>
                <el-descriptions-item label="展示类型">
                    <span v-if="form.raceType == 0">文本展示</span>
                    <span v-if="form.raceType == 1">图片展示</span>
                </el-descriptions-item>
            </el-descriptions>
            <el-form ref="form" :model="form" :rules="rules" label-width="68px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="赛事概况" prop="raceOverview">
                            {{ form.raceOverview }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="赛事规则" prop="raceRule">
                            {{ form.raceRule }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="评审标准" prop="reviewCriterion">
                            {{ form.reviewCriterion }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="赛事奖励" prop="raceAwards">
                            {{ form.raceAwards }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="98px" label="申报起止时间" prop="formTime">
                            {{ timestampToTime(form.beginTime) }}至{{ timestampToTime(form.endTime) }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="审核人员" prop="checkUsersList">
                            {{ form.checkUserTxt }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.judgesUserText">
                    <el-col :span="24">
                        <el-form-item label="专家评委" prop="judgesUsersList">
                            {{ form.judgesUserText }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.remarks">
                    <el-col :span="24">
                        <el-form-item label-width="40px" label="其他" prop="remarks">
                            {{ form.remarks }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-col :span="12" v-if="form.scaleList && form.scaleList.length != 0">
                    <el-form-item label="听评量表" prop="scaleList">
                        <div v-for="item in form.scaleList" style="color: #409efe;width: 100%;">
                            <div class="cursor" @click="preview(item)">{{ item.title }}</div>
                        </div>
                    </el-form-item>
                </el-col>
                <el-row v-if="form.fileList && form.fileList.length != 0">
                    <el-col :span="24">
                        <el-form-item label-width="40px" label="附件" prop="">
                            <div @click="xz(item)" v-for="item in form.fileList" class="mb10 cursor">
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.raceType == 1">
                    <el-col :span="24">
                        <el-form-item label="导入图片" prop="imgUrl">
                            <div class="avatar-uploader">
                                <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar" />
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <div class="mb30" v-for="(item, index) in previewData.questionList">
                <div class="mb15" v-if="item.type != 3">
                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                    }}.</span>{{ item.questionName }}
                        <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                            item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容'
                                : ''
                        }}]</span><span v-if="item.type == 4 && item.totalScore"
                            style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                                item.totalScore }})</span>
                        <span v-if="item.type == 3" style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                            item.totalScore }})</span>
                    </h3>
                </div>
                <div class="mb15" v-if="item.type == 3">
                    <div class="mb15">
                        <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                        }}.</span>{{ item.questionName }}
                            <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ?
                                    '文字内容'
                                    : ''
                            }}]</span>
                            <span style="color: #efa030;font-weight: normal;font-size: 14px;margin-right: 15px;">(满分：{{
                                item.totalScore }})</span>
                        </h3>
                    </div>
                    <div>

                        <el-input-number size="small" step-strictly disabled :min="0" :max="100" />
                    </div>
                </div>
                <div v-if="item.type == 1">
                    <el-radio-group disabled class="ml-4">
                        <el-radio label="1" size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span></el-radio>
                    </el-radio-group>
                </div>
                <div v-if="item.type == 2">
                    <el-checkbox-group disabled class="ml-4">
                        <el-checkbox size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div v-if="item.type == 4" style="width: 100%;">
                    <el-input style="width: 100%;" disabled :rows="2" type="textarea" placeholder="" />
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import { queryDict, userList, listHeadmasterGroup, listTeacherGroup } from "@/api/admin/public";
import { orgListTypeOrg, orgListSchoolOrg } from "@/api/admin/system";
import { rateScaleInfo } from "@/api/admin/evaluation";
import { raceDeleteFile, raceCreate, raceUpdate, raceInfo } from "@/api/admin/race";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            timestampToTime,
            raceId: '',
            classifyName: '',
            fileList: [],
            toParentsMap: {

            },
            isShow: false,
            oldCenter: [],
            periodList: [],
            gradeList: [],
            subjectList: [],
            topicClassifyList: [],
            orgList: [],
            raceLevelList: [
            ],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                raceNature: '',
                raceName: '',
                raceLevel: '',
                raceType: 0,
                raceOverview: '',
                checkUsersList: [],
                judgesUsersList: [],
                orgIdsList: [],
                beginTime: '',
                endTime: '',
                remarks: '',
                fileUrls: '',
                imgUrl: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: ''
            },
            // 表单校验
            rules: {
            },
            delFileList: [],
            countyList: [],
            schoolList: [],
            groupList: [],
            previewData: {},
            previewShow: false,
            isMsgzsStatistics: 0,
            isJkyStatistics: 0,
            isPrincipalStudio: 0,
        };
    },
    created() {
        this.getOrgListTypeOrg();
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.raceId) {
            this.raceId = this.$route.query.raceId
            this.getlessonInfo()
        } else {
            this.isShow = true
        }
        if (this.$route.query.isJkyStatistics) {
            this.isJkyStatistics = this.$route.query.isJkyStatistics
        }
        if (this.$route.query.isMsgzsStatistics) {
            this.isMsgzsStatistics = this.$route.query.isMsgzsStatistics
        }
        if (this.$route.query.isPrincipalStudio) {
            this.isPrincipalStudio = this.$route.query.isPrincipalStudio
        }
    },
    unmounted() {
        if (this.raceId) {
            try {
                console.log(332)
                this.fileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        raceDeleteFile({ fileUrl: e.response.data });
                    }
                })
            } catch (e) {
                console.log('e', e)
            }
        } else {
            try {
                this.fileList.forEach(e => {
                    raceDeleteFile({ fileUrl: e.response.data });
                })
            } catch (e) {
                console.log('e', e)
            }

        }
    },
    computed: {
    },
    methods: {
        preview(item) {
            console.log(item)
            rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                this.previewData = res.data.data;
                this.previewShow = true;
            })
        },
        changeLessonNature(e) {
            console.log(e);
            this.groupList = [];
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            if (e == 2) {
                listTeacherGroup().then(res => {
                    res.data.data.forEach(j => {
                        if (this.form.groupId == j.groupId) {
                            this.form.groupName = j.groupName
                        }
                        j.memberList.forEach(m => {
                            if (m.memberRole == 0 || m.memberRole == 1) {
                                if (m.memberUserId == userId) {
                                    this.groupList.push(j)
                                    // throw new Error();
                                }
                            }
                        })
                    })
                })
            } else if (e == 3) {
                listHeadmasterGroup().then(res => {
                    res.data.data.forEach(j => {
                        if (this.form.groupId == j.groupId) {
                            this.form.groupName = j.groupName
                        }
                        j.memberList.forEach(m => {
                            if (m.memberRole == 0 || m.memberRole == 1) {
                                if (m.memberUserId == userId) {
                                    this.groupList.push(j)
                                    // throw new Error();
                                }
                            }
                        })
                    })
                })
            }
        },
        xz(item) {
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
            // download(item.fileUrl, item.fileName)
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.imgUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        getGeocoder(e) {
            console.log(e);
            this.form.lat = e.center[1];
            this.form.lng = e.center[0];
            this.form.signInAddress = e.formattedAddress;
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getlessonInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                queryDict({ code: 'topic_level' }).then(resb => {
                    this.raceLevelList = resb.data.data;
                    raceInfo({ raceId: this.raceId }).then(res => {

                        this.form = res.data.data;
                        this.changeLessonNature(this.form.raceNature)

                        this.form.checkUsersList = this.form.checkUsers.split(',')
                        this.form.judgesUsersList = this.form.judgesUsers.split(',')
                        this.form.raceLevel = this.form.raceLevel
                        this.form.orgIdsList = this.form.orgIds.split(',')
                        var checkUserTxt = []
                        this.raceLevelList.forEach(e => {
                            if (e.dictKey == this.form.raceLevel) {
                                this.form.raceLevelName = e.dictValue
                            }
                        })
                        this.form.checkUserList.forEach(j => {
                            checkUserTxt.push(j.trueName)
                        })
                        this.form.checkUserTxt = checkUserTxt.toString()
                        orgListTypeOrg({ orgType: this.form.raceLevel }).then(res => {
                            this.orgList = res.data.data;
                        })
                        this.periodList.forEach(j => {
                            if (this.form.periodKey == j.dictKey) {
                                this.gradeList = j.childrenList;
                            }
                        })
                        this.periodList.forEach(e => {
                            if (this.form.periodKey == e.dictKey) {
                                e.childrenList.forEach(j => {
                                    if (this.form.gradeKey == j.dictKey) {
                                        this.subjectList = j.childrenList;
                                    }
                                })
                            }
                        })
                        this.form.fileList.forEach(e => {
                            this.fileList.push({
                                name: e.fileName,
                                fileId: e.fileId,
                                url: e.fileUrl,
                                response: {
                                    data: e.fileUrl
                                }
                            })
                        })
                        console.log(this.form)
                        this.isShow = true;

                    })

                })
            })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)

        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        startSignInDateConfirm(e) {
            if (e > this.form.signInEndTime) {
                this.form.signInEndTime = '';
            }
        },
        startSignOutDateConfirm(e) {
            if (e > this.form.signOutEndTime) {
                this.form.signOutEndTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        endSignInDateConfirm(e) {
            console.log(e,)
            if (e < this.form.signInBeginTime) {
                this.form.signInBeginTime = '';
            }
        },
        endSignOutDateConfirm(e) {
            console.log(e,)
            if (e < this.form.signOutBeginTime) {
                this.form.signOutBeginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changeCounty() {
            this.form.schoolId = '';
            this.schoolList = [];
            orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                this.schoolList = res.data.data;
            })
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getOrgListTypeOrg() {
            this.form.orgIdsList = [];
            orgListTypeOrg({ orgType: this.form.raceLevel }).then(res => {
                this.orgList = res.data.data;
            })
        },
        getQueryDict() {
            queryDict({ code: 'topic_classify' }).then(res => {
                this.topicClassifyList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            // queryDict({ code: 'topic_level' }).then(res => {
            //     this.lessonLevelList = res.data.data;
            // })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            var fileList = [];
            this.fileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.form.checkUsers = this.form.checkUsersList.toString()
            this.form.judgesUsers = this.form.judgesUsersList.toString()
            this.form.orgIds = this.form.orgIdsList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    obj.fileUrls = fileList.toString();
                    delete obj.checkUsersList
                    delete obj.judgesUsersList
                    delete obj.orgIdsList
                    if (this.raceId) {
                        raceUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    raceDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("编辑成功");
                                this.replace('/race', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });

                    } else {
                        raceCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    raceDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("新增成功");
                                this.replace('/race', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            if (this.isJkyStatistics == 1) {
                this.replace('/jkyStatistics')
            } else if (this.isMsgzsStatistics == 1) {
                this.replace('/msgzsStatistics')
            } else if (this.isPrincipalStudio == 1) {
                this.replace('/principalStudio')
            } else {
                this.replace('/race')
            }
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}</style>