<template>
    <div class="app-container">
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="类型">
                            <el-select @change="getList()" v-model="queryParams.type" placeholder="请选择">
                                <el-option :key="'课题引领'" :label="'课题引领'" :value="'课题引领'" />
                                <el-option :key="'教研赛事'" :label="'教研赛事'" :value="'教研赛事'" />
                                <el-option :key="'专题活动'" :label="'专题活动'" :value="'专题活动'" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="状态">
                            <el-select clearable @change="getList()" v-model="queryParams.status" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.name" placeholder="请输入培训任务标题" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                    height="calc(100vh - 290px)" v-loading="loading" :data="dataList" highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column width="90" label="姓名" prop="trueName" />
                    <el-table-column width="120" label="电话" prop="mobile" />
                    <el-table-column width="200" label="归属" prop="orgName" />
                    <el-table-column v-if="queryParams.type == '教研赛事'" width="250" label="赛事名称" prop="raceName">
                        <template #default="scope">
                            <div :title="scope.row.raceName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.raceName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="queryParams.type == '课题引领'" label="课题名称" prop="topicName">
                        <template #default="scope">
                            <div :title="scope.row.topicName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.topicName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="queryParams.type == '专题活动'" width="250" label="活动名称" prop="activityName">
                        <template #default="scope">
                            <div :title="scope.row.activityName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.activityName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="160" label="课题状态" prop="status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 0">未审核</div>
                            <div v-if="scope.row.status == 1">审核通过</div>
                            <div v-if="scope.row.status == 2">审核拒绝</div>
                        </template>
                    </el-table-column>

                    <el-table-column width="140" label="申报时间" prop="applyTime">
                        <template #default="scope">
                            <div>
                                {{ timestampToTime(scope.row.applyTime) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" label="申报附件" prop="file">
                        <template #default="scope">
                            <el-popover placement="bottom-start" :width="125" trigger="click" :min-width="'auto'">
                                <div>
                                    <div v-if="scope.row.fileList.length != 0">
                                        <div :title="item.fileName" style="display: flex;align-items:center;"
                                            v-for="(item, index) in scope.row.fileList">
                                            <div class="filtxt text-one cursor" @click="goFileDetail(item)">{{ index + 1 }}.
                                                {{ item.fileName }}</div>
                                            <el-icon @click="xz(item)" style="color: #409efe;" class="cursor ml15">
                                                <Download />
                                            </el-icon>
                                        </div>
                                    </div>
                                </div>
                                <template #reference>
                                    <div style="color: #409efe;" class="cursor">
                                        {{ scope.row.fileList.length }}
                                    </div>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column width="120" label="参与附件" prop="file">
                        <template #default="scope">
                            <el-popover placement="bottom-start" :width="125" trigger="click"
                                :min-width="'auto'">
                                <div>
                                    <div v-if="scope.row.joinFileList.length != 0" style="width: 100%;">
                                        <div :title="item.fileName" v-for="(item, index) in scope.row.joinFileList"
                                            style="display: flex;align-items:center;">
                                            <div class="filtxt text-one cursor" @click="goFileDetail(item)">{{ index + 1
                                            }}. {{ item.fileName }}</div>

                                            <el-icon @click="xz(item)" style="color: #409efe;" class="cursor ml15">
                                                <Download />
                                            </el-icon>
                                        </div>
                                    </div>
                                </div>
                                <template #reference>
                                    <div style="color: #409efe;" class="cursor">
                                        {{ scope.row.joinFileList.length }}
                                    </div>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>

                    <el-table-column fixed="right" width="160" label="操作" class-name="small-padding fixed-width">
                        <template #default="scope">
                            <!-- <el-button size="mini" type="text" icon="el-icon-view" @click="goDetail(scope.row)">查看
                        </el-button> -->
                            <el-button v-if="scope.row.status == 0 && scope.row.checkUser" size="mini" type="primary"
                                icon="" @click="handleAgree(scope.row)">同意
                            </el-button>
                            <el-button size="mini" v-if="scope.row.status == 0 && scope.row.checkUser" type="danger" icon=""
                                @click="handleRefuse(scope.row)">拒绝
                            </el-button>

                            <!-- <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                            icon-color="#626AEF" title="确定删除该表单" @confirm="handleDelete(scope.row)">
                            <template #reference>
                                <el-button size="mini" type="text" icon="el-icon-delete">删除
                                </el-button>
                            </template>
                        </el-popconfirm> -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy">
                <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                    :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>
        <el-image-viewer v-if="isOpen" :url-list="activeimgs" @close="closePic" />
    </div>
</template>

<script>
import { trainListAll, trainRelease, trainStop } from "@/api/admin/train"
import { evaluationListAll, evaluationCreate, evaluationDel, evaluationRelease, evaluationStop, evaluationSubmit } from "@/api/admin/evaluation"
import { lessonList, lessonSubmit, lessonStop, lessonRelease, lessonGaFiles, delLessonFile } from "@/api/admin/lesson"
import { topicApplyListAll, topicApplyPage, topicApplyRefuse, topicApplyAgree } from "@/api/admin/lead"
import { raceHandleListAll, raceHandlePage, raceHandleRefuse, raceHandleAgree } from "@/api/admin/race"
import { activityHandleListAll, activityHandlePage, activityHandleRefuse, activityHandleAgree } from "@/api/admin/activity"

import {
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryDict, userList, fileJoinQueryFile } from "@/api/admin/public";
import { download } from "@/api/adminservice/public";

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            activeimgs: [],
            isOpen: false,
            selectRow: {},
            scFa: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            gAFileList: [],
            token: this.$storage.get("token"),
            statusList: [
                { name: '未审核', value: 0 },
                { name: '审核通过', value: 1 },
                { name: '审核拒绝', value: 2 },
            ],
            typeList: [
                { name: '线上培训', value: 0 },
                { name: '线下培训', value: 1 },
            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                type: '课题引领',
                status: '',
                title: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                type: '',
                name: '',
                pageNum: 1,
                pageSize: 10,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            showFileList: []
        };
    },
    created() {
        this.getQueryDict()
        this.queryParams.type = '课题引领'
        this.getList();
    },
    activated() {

        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        xz(e) {
            download(e.fileUrl, e.fileName)
        },
        getShowList(e) {
            this.showFileList = [];
            var type = ''
            var activityId = ''
            if (this.queryParams.type == '课题引领') {
                type = 1
                activityId = e.topicId;
            } else if (this.queryParams.type == '教研赛事') {
                type = 2
                activityId = e.raceId;
            } else if (this.queryParams.type == '专题活动') {
                type = 3
                activityId = e.activityId;
            }
            fileJoinQueryFile({ activityId: activityId, type: type, userId: e.applyUserId }).then(res => {
                if (res.data.data) {
                    this.showFileList = res.data.data;
                }
            })
        },
        closePic() {
            this.isOpen = false;
        },
        handleAgree(row) {
            if (this.queryParams.type == '课题引领') {
                topicApplyAgree({ applyId: row.applyId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("同意成功");
                        this.getList();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            } else if (this.queryParams.type == '教研赛事') {
                raceHandleAgree({ applyId: row.applyId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("同意成功");
                        this.getList();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            } else if (this.queryParams.type == '专题活动') {
                activityHandleAgree({ applyId: row.applyId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("同意成功");
                        this.getList();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            }
        },
        handleRefuse(row) {
            if (this.queryParams.type == '课题引领') {
                topicApplyRefuse({ applyId: row.applyId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("拒绝成功");
                        this.getList();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            } else if (this.queryParams.type == '教研赛事') {
                raceHandleRefuse({ applyId: row.applyId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("拒绝成功");
                        this.getList();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            } else if (this.queryParams.type == '专题活动') {
                activityHandleRefuse({ applyId: row.applyId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("拒绝成功");
                        this.getList();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            }
        },
        goFileDetail(item) {
            this.activeimgs = []
            if (item.fileType == 'pdf' || item.fileType == 'ppt' || item.fileType == 'pptx' || item.fileType == 'doc' || item.fileType == 'docx') {
                if (item.imgUrl) {
                    console.log(item);
                    let routeUrl = this.$router.resolve({
                        path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                        query: { data: JSON.stringify(item) }
                    })
                    window.open(routeUrl.href, '_blank')
                } else {
                    this.activeimgs.push(item.fileUrl)
                    this.isOpen = true;
                }
            } else if (item.fileType == 'jpg' || item.fileType == 'jpeg' || item.fileType == 'png') {
                this.activeimgs.push(item.fileUrl)
                this.isOpen = true;
            } else {
                this.xz(item)
            }

        },
        handleDiscuss(row) {
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: row.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleExceed(e) {
            console.log(e)
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.url) {
                delLessonFile({ fileUrl: file.url })
            }
            if (file.response) {
                delLessonFile({ fileUrl: file.response.data })
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.selectRow.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAJaChange(e, type) {
            console.log(this.selectRow.gajaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gajaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAkjChange(e, type) {
            console.log(this.selectRow.gakjFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gakjFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxtChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gaxtFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleGA(row) {
            this.scFa = true;
            this.selectRow = row;
            console.log(this.selectRow)
            // lessonGaFiles({lessonId:row.lessonId}).then(res=>{

            // })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.dataList = []
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            console.log(this.queryParams.type);
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            if (this.queryParams.type == '课题引领') {
                topicApplyPage(this.queryParams).then(res => {
                    // if (this.queryParams.status !== '') {
                    //     res.data.data = res.data.data.filter(itemA => {
                    //         return itemA.status == this.queryParams.status
                    //     })
                    // }
                    // if (this.queryParams.topicName !== '') {
                    //     res.data.data = res.data.data.filter(itemA => {
                    //         return itemA.topicName.toString().indexOf(this.queryParams.topicName) != -1
                    //     })
                    // }
                    this.total = res.data.data.total;

                    this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
                    this.dataList.sort(function (a, b) {
                        if (a.releaseTime > b.releaseTime) { return -1; }
                        if (a.releaseTime < b.releaseTime) { return 1; }
                        return 0;
                    })
                    this.dataList.forEach(e => {
                        e.checkUserList.forEach(j => {
                            if (j.userId == userId) {
                                e.checkUser = true;
                            }
                        })

                    })
                    this.loading = false;
                });
            } else if (this.queryParams.type == '教研赛事') {
                raceHandlePage(this.queryParams).then(res => {
                    // if (this.queryParams.status !== '') {
                    //     res.data.data = res.data.data.filter(itemA => {
                    //         return itemA.status == this.queryParams.status
                    //     })
                    // }
                    // if (this.queryParams.topicName !== '') {
                    //     res.data.data = res.data.data.filter(itemA => {
                    //         return itemA.topicName.toString().indexOf(this.queryParams.topicName) != -1
                    //     })
                    // }
                    this.total = res.data.data.total;

                    this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
                    this.dataList.sort(function (a, b) {
                        if (a.releaseTime > b.releaseTime) { return -1; }
                        if (a.releaseTime < b.releaseTime) { return 1; }
                        return 0;
                    })
                    this.dataList.forEach(e => {
                        e.checkUserList.forEach(j => {
                            if (j.userId == userId) {
                                e.checkUser = true;
                            }
                        })

                    })
                    this.loading = false;
                });
            } else if (this.queryParams.type == '专题活动') {
                activityHandlePage(this.queryParams).then(res => {
                    // if (this.queryParams.status !== '') {
                    //     res.data.data = res.data.data.filter(itemA => {
                    //         return itemA.status == this.queryParams.status
                    //     })
                    // }
                    // if (this.queryParams.topicName !== '') {
                    //     res.data.data = res.data.data.filter(itemA => {
                    //         return itemA.topicName.toString().indexOf(this.queryParams.topicName) != -1
                    //     })
                    // }
                    this.total = res.data.data.total;

                    this.dataList = JSON.parse(JSON.stringify(res.data.data.list));
                    this.dataList.sort(function (a, b) {
                        if (a.releaseTime > b.releaseTime) { return -1; }
                        if (a.releaseTime < b.releaseTime) { return 1; }
                        return 0;
                    })
                    this.dataList.forEach(e => {
                        e.checkUserList.forEach(j => {
                            if (j.userId == userId) {
                                e.checkUser = true;
                            }
                        })

                    })
                    this.loading = false;
                });
            }

        },
        /** 删除按钮操作 */
        handleDelete(row) {
            evaluationDel({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            if (this.queryParams.type == '课题引领') {
                this.$router.push({
                    path: "/topicApplyDetail",
                    query: {
                        applyId: row.applyId,
                    }
                });
            } else if (this.queryParams.type == '教研赛事') {
                this.$router.push({
                    path: "/handleApplyDetail",
                    query: {
                        applyId: row.applyId,
                    }
                });
            } else if (this.queryParams.type == '专题活动') {
                this.$router.push({
                    path: "/activitApplyDetail",
                    query: {
                        applyId: row.applyId,
                    }
                });
            }

        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/trainAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        handleLbAdd() {
            this.$router.push({
                path: "/rateScale",
            });
        },
        handleRelease(row) {
            trainRelease({ trainId: row.trainId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("发布失败");
                }
            })
        },
        handleSubmit(row) {
            console.log(row)
            if (row.jaFiles.length == 0 && row.kjFiles.length == 0 && row.xtFiles.length == 0) {
                this.$message.error("请先上传文件");
                return
            }
            lessonSubmit({ lessonId: row.lessonId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("提请成功");
                    this.getList();
                } else {
                    this.$message.error("提请失败");
                }
            })
        },
        handleStop(row) {
            trainStop({ trainId: row.trainId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停止成功");
                    this.getList();
                } else {
                    this.$message.error("停止失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/trainAdd",
                query: {
                    trainId: row.trainId,
                }
            });
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}
</style>