import request from "@/utils/request";

// 创建课题
export const leadCreate = (data) => {
    return request({
        url: "/businessservice/topic/lead/create",
        method: "post",
        data: data
    })
}
// 查询课题详情
export const leadInfo = (query) => {
    return request({
        url: "/businessservice/topic/lead/info",
        method: "get",
        params: query
    })
}
// 查询所有课题
export const leadListAll = (query) => {
    return request({
        url: "/businessservice/topic/lead/listAll",
        method: "get",
        params: query
    })
}
// 分页查询课题
export const leadPage = (data) => {
    return request({
        url: "/businessservice/topic/lead/page",
        method: "post",
        data: data
    })
}
// 删除课题
export const leadDelete = (query) => {
    return request({
        url: "/businessservice/topic/lead/delete",
        method: "get",
        params: query
    })
}
// 查询用户参与课题
export const leadListJoin = (query) => {
    return request({
        url: "/businessservice/topic/lead/listJoin",
        method: "get",
        params: query
    })
}
// 发布课题
export const leadRelease = (query) => {
    return request({
        url: "/businessservice/topic/lead/release",
        method: "get",
        params: query
    })
}
// 停止课题
export const leadStop = (query) => {
    return request({
        url: "/businessservice/topic/lead/stop",
        method: "get",
        params: query
    })
}
// 更新课题
export const leadUpdate = (data) => {
    return request({
        url: "/businessservice/topic/lead/update",
        method: "post",
        data: data
    })
}
// 上传课题封面
export const leadUploadCover = (data) => {
    return request({
        url: "/businessservice/topic/lead/uploadCover",
        method: "post",
        data: data
    })
}
// 上传课题附件
export const leadUploadFile = (data) => {
    return request({
        url: "/businessservice/topic/lead/uploadFile",
        method: "post",
        data: data
    })
}
// 删除课题文件
export const leadDeleteFile = (query) => {
    return request({
        url: "/businessservice/topic/lead/deleteFile",
        method: "get",
        params: query
    })
}
// 添加课题分类
export const leadAddClassify = (query) => {
    return request({
        url: "/businessservice/topic/lead/addClassify",
        method: "get",
        params: query
    })
}

// 申报课题
export const topicApplyCreate = (data) => {
    return request({
        url: "/businessservice/topicApply/apply/create",
        method: "post",
        data: data
    })
}
// 删除申报文件
export const topicApplyDeleteFile = (query) => {
    return request({
        url: "/businessservice/topicApply/apply/deleteFile",
        method: "get",
        params: query
    })
}
// 上传申报附件
export const topicApplyUploadFile = (data) => {
    return request({
        url: "/businessservice/topicApply/apply/uploadFile",
        method: "post",
        data: data
    })
}
// 同意课题申报
export const topicApplyAgree = (query) => {
    return request({
        url: "/businessservice/topicApply/apply/agree",
        method: "get",
        params: query
    })
}
// 申报详情
export const topicApplyInfo = (query) => {
    return request({
        url: "/businessservice/topicApply/apply/info",
        method: "get",
        params: query
    })
}
// 查询全部申报列表
export const topicApplyListAll = (query) => {
    return request({
        url: "/businessservice/topicApply/apply/listAll",
        method: "get",
        params: query
    })
}
// 分页查询课题申报列表
export const topicApplyPage = (data) => {
    return request({
        url: "/businessservice/topicApply/apply/page",
        method: "post",
        data: data
    })
}
// 查询待处理申报列表
export const topicApplyListHandle = (query) => {
    return request({
        url: "/businessservice/topicApply/apply/listHandle",
        method: "get",
        params: query
    })
}
// 查询已处理申报列表
export const topicApplyListHistory = (query) => {
    return request({
        url: "/businessservice/topicApply/apply/listHistory",
        method: "get",
        params: query
    })
}
// 拒绝课题申报
export const topicApplyRefuse = (query) => {
    return request({
        url: "/businessservice/topicApply/apply/refuse",
        method: "get",
        params: query
    })
}