<template>
    <div class="app-container">
        <div style="height: calc(100vh - 5px);display: flex;">
            <div class="left">
                <div class="pd10">
                    <span style="font-weight: bold;">大纲</span>
                </div>
                <el-card style="height: calc(100vh - 45px);overflow-y: auto;">
                    <div v-if="bookData.imgUrl">
                        <div :id="'zmd' + index" @click="changeCurrent(index)" class="swbox"
                            v-for="(item, index) in bookData.pages" style="position: relative;">
                            <Canvasbox :bookData="bookData" :imwidth="150"
                                :imheight="bookData.fileHeight / bookData.fileWidth * 150"
                                :canvasid="bookData.fileId + '' + index"
                                :picSrc="bookData.imgUrl + '/' + (index + 1) + '.png'"></Canvasbox>
                            <div :class="current == index ? 'bor-lan' : ''"
                                :style="'width: 150px;height: ' + bookData.fileHeight / bookData.fileWidth * 150 + 'px;top: 0;position: absolute;border: 1px solid rgb(238, 238, 238);border-radius: 10px;'">

                            </div>

                            <div class="pageNumbox">{{ index + 1 }}</div>
                        </div>
                    </div>
                </el-card>
            </div>
            <div class="right">
                <el-card style="height: calc(100vh - 5px);">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div style="display: flex;">
                            <div>
                                <span style="font-weight: bold;">备课标题：</span>{{ form.title }}
                            </div>
                            <div style="padding-left: 30px;">
                                <span style="font-weight: bold;">主备人：</span>{{ form.leadUserName }}
                            </div>
                        </div>
                        <div v-if="!isRd" class="cursor" @click="changeIspls"
                            style="display: flex;flex-direction: column;align-items: center;margin-right: 15px;">
                            <el-icon style="font-size: 24px;color:#409eff;">
                                <EditPen />
                            </el-icon>
                            <div v-if="form.status == 2" style="font-size: 14px;" class="mt5">一轮研讨</div>
                            <div v-if="form.status == 4" style="font-size: 14px;" class="mt5">二轮研讨</div>
                        </div>
                    </div>
                    <div style="padding: 15px 0;display: flex;align-items: center;justify-content: space-between;">
                        <el-radio-group v-if="this.form.status != 4" @change="changeLessonType" v-model="lessonType" size="large">
                            <el-radio-button v-show="form.jaFiles && form.jaFiles.length != 0" label="教案" />
                            <el-radio-button v-show="form.xaFiles && form.xaFiles.length != 0" label="学案" />
                            <el-radio-button v-show="form.kjFiles && form.kjFiles.length != 0" label="课件" />
                            <el-radio-button v-show="form.xtFiles && form.xtFiles.length != 0" label="习题" />
                        </el-radio-group>
                        <el-radio-group v-if="this.form.status == 4" @change="changeLessonType" v-model="lessonType" size="large">
                            <el-radio-button v-show="form.ytjaFiles && form.ytjaFiles.length != 0" label="教案" />
                            <el-radio-button v-show="form.ytxaFiles && form.ytxaFiles.length != 0" label="学案" />
                            <el-radio-button v-show="form.ytkjFiles && form.ytkjFiles.length != 0" label="课件" />
                            <el-radio-button v-show="form.ytxtFiles && form.ytxtFiles.length != 0" label="习题" />
                        </el-radio-group>
                        <el-select @change="changeFile" v-model="fileId" placeholder="请选择文件" size="large">
                            <el-option v-for="item in fileList" :key="item.fileId" :label="item.fileName"
                                :value="item.fileId" />
                        </el-select>
                    </div>
                    <div ref="bjcan" id="bjcan"
                        :style="`height: calc(100vh - 150px);overflow-y: auto;padding-bottom: 15px;`">
                        <div
                            :style="`width: ${zdyWidth}px;position: relative;height:${bookData.fileHeight / bookData.fileWidth * zdyWidth}px`">
                            <img :width="zdyWidth" :src="bookData.imgUrl + '/' + (current + 1) + '.png'" alt=""
                                style="border: 1px solid #eeeeee;border-radius: 10px;position: absolute;">
                            <el-popover trigger="contextmenu" v-if="rightDown">
                                <template #reference>
                                    <div style="position: absolute;
                                                z-index: 2000; background-color: transparent;"
                                        @click="this.rightDown = false"
                                        :style="`left:${this.rightData.x0
                                            }px;top:${this.rightData.y0
                                            }px;width:${this.rightData.w0
                                            }px;height:${this.rightData.h0}px;pointer-events: ${this.rightDown ? '' : 'none'};`">
                                    </div>
                                </template>
                                <el-button-group style="width: 100%">
                                    <div v-for="item in contextMenus" :key="item" style="width: 100%">
                                        <el-button style="width: 100%" :type="item.type ?? ''" @click="item.handler">
                                            <el-icon class="el-icon--left">
                                                <component :is="item.icon"></component>
                                            </el-icon>
                                            {{ item.label }}
                                        </el-button>
                                    </div>
                                </el-button-group>
                            </el-popover>
                            <div v-if="isComShow && !isPls" v-for="items in commentListto"
                                :style="'word-break: break-all;position: relative;left: ' + items.x0 + 'px;top: ' + items.y0 + 'px;'">
                                <div v-show="items.pageNum == current && activeDiscussId == items.discussId"
                                    :oncontextmenu="_ => { this.onContextMenus(items, _) }"
                                    :style="'word-break: break-all;background:rgba(220,234,247,0.5);border-radius:10px;width: ' + items.w0 + 'px;min-height: 300px;position: absolute;left: 0px;top: 0px;color:' + items.contentColor + ';font-size:28px;'"
                                    draggable="false">
                                    {{ items.content }}
                                    <div v-show="items.pageNum == current && activeDiscussId == items.discussId"
                                        :style="'position: absolute;left: 10px;bottom: -25px;z-index:999999999;pointer-events: none;opacity: 0.5;color:' + items.contentColor + ';font-size:18px;'">
                                        --{{ items.createUserName }}</div>
                                </div>

                                <!-- <el-button @click.stop="delPl(items)" v-if="items.isDel" size="small" type="danger"
                                    icon="Delete" circle
                                    :style="'position: absolute;left: ' + (items.x0 + items.w0 - 20) + 'px;top: ' + (items.y0) + 'px;z-index:999999999;'" /> -->
                            </div>

                            <VueDragResizeRotate :resizable="false" v-if="isPls" style="z-index: 99999;" :x="x" :y="y"
                                :w="w" :h="h" :minWidth="zdyWidth / 3" :parent="true" @dragging="onDrag"
                                @resizing="onResize">
                                <el-input spellcheck="false" type="textarea" :style="`color:${col};font-size:28px;`"
                                    class="inputStyle" v-model="value1" style="">
                                </el-input>
                            </VueDragResizeRotate>
                            <div v-if="isPls" class="df" :style="`top:${y + h}px;left:${x}px`">
                                <div style="display: flex;">
                                    <div class="cursor"
                                        style="position: relative;height:30px;width:30px;border: 1px solid #dbd6d6ee;padding:0 5px;background: #fff;">
                                        <el-input class="cursor tst"
                                            style="padding: 0;height:32px;width:42px;position: absolute;left: 0;top: 0;z-index: 999;opacity: 0;"
                                            type="color" v-model="col">
                                        </el-input>
                                        <img style="height: 31px;width:31px;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"
                                            src="../../assets/image/yt-sb.png" alt="">
                                    </div>

                                    <!-- <el-button @click="sumbit" size="mini" icon="el-icon-select" /> -->
                                    <el-button style="padding: 0 5px;" @click="sumbit" tag="div" role="button"
                                        tabindex="0"><img style="height: 30px;width:30px" src="../../assets/image/yt-tj.png"
                                            alt=""></el-button>
                                    <!-- <img @click="sumbit" src="../../assets/image/yt-tj.png" alt=""> -->
                                    <el-button v-if="!activeDiscussId" style="padding: 0 5px;" @click="closeDf" tag="div"
                                        role="button" tabindex="0"><img style="height: 30px;width:30px"
                                            src="../../assets/image/yt-del.png" alt=""></el-button>
                                    <el-popconfirm v-if="activeDiscussId" confirm-button-text="是" cancel-button-text="否"
                                        :icon="InfoFilled" icon-color="#626AEF" title="确定删除该研讨" @confirm="deletecs">
                                        <template #reference>
                                            <el-button style="padding: 0 5px;margin-left: 0;" tag="div" role="button" tabindex="0"><img
                                                    style="height: 30px;width:30px" src="../../assets/image/yt-del.png"
                                                    alt=""></el-button>
                                        </template>
                                    </el-popconfirm>
                                </div>

                            </div>

                        </div>
                    </div>
                </el-card>
            </div>
            <div class="lastRight" style="width: 200px;">
                <div class="pd10">
                    <span style="font-weight: bold;">列表栏</span>
                </div>
                <el-card style="height: calc(100vh - 45px);overflow-y: auto;">
                    <div class="lblitem" v-for="(items, index) in commentListto" @click="changeDiscussId(items)"
                        :style="activeDiscussId == items.discussId ? 'background:#dceaf7;border-radius:5px;' : ''">
                        <div :title="items.content" class="text-three" style="word-break: break-all;">{{ index + 1 + '.' +
                            items.content }}</div>
                        <div style="text-align: right;">--{{ items.createUserName }}</div>
                    </div>
                </el-card>
            </div>
        </div>

    </div>
</template>

<script>
// import { queryTeacherAllStroke } from "@/api/admin/bookManage";
import { lessonInfo, discussCreate, discussDel, discussList, discussUpdate } from "@/api/admin/lesson";
import { timestampToTime } from "@/utils/date";

import echarts from "echarts";

export default {
    name: "taskDetail",
    components: {

    },
    props: {
        teacherBookId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            activeDiscussId: '',
            userId: JSON.parse(this.$storage.get("userInfo")).userId,
            rightDown: false,
            isComShow: false,
            commentListto: [],
            isPls: false,
            lessonId: '',
            lessonType: '', // 1教案 2课件 3习题 4学案
            active: 1,
            current: 0,
            isZjShow: false,
            isShow: false,
            timestampToTime,
            bookData: {},
            fileId: '',
            fileList: [],
            strokeList: [],
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            form: {
                jaFiles:[],
                xaFiles:[],
                kjFiles:[],
                xtFiles:[]
            },
            zdyWidth: 0,
            zdyHeight: 0,
            isStart: false,
            isTime: false,
            isNull: true,
            x: 0,
            y: 0,
            w: 200,
            h: 300,
            col: '#333333',
            value1: '',
            rightData: {},
            isCreat: false,
            contextMenus: [
                {
                    label: '编辑',
                    icon: 'Edit',
                    handler: this.editCont
                },
                {
                    label: '复制',
                    icon: 'CopyDocument',
                    handler: this.triggerCopy
                },
                {
                    label: '删除',
                    icon: 'Delete',
                    handler: this.delete
                },
                // {
                //     label: '置于顶层', condition: (data) => data.selectRects.length === 1,
                //     icon: 'Top',
                //     handler: _ => {
                //         this.putLayer('top')
                //     }
                // },
                // {
                //     label: '置于底层', condition: (data) => data.selectRects.length === 1,
                //     icon: 'Bottom',
                //     handler: _ => {
                //         this.putLayer('bottom')
                //     }
                // },
            ],
            isRd:'',
        };
    },
    created() {
        console.log('fdsdfds')
    },
    mounted() {
        if(this.$route.query.isRd){
            this.isRd = this.$route.query.isRd
        }
        this.lessonId = this.$route.query.lessonId
        console.log(this.bookData)
        this.zdyWidth = (document.getElementById("bjcan").clientWidth - 20)
        this.getDetail()
        // document.querySelector("#bjcan").addEventListener("scroll", this.handleScroll)
    },
    methods: {
        changeDiscussId(item) {
            this.isPls = false;
            this.activeDiscussId = item.discussId;
            if (this.userId == item.userId) {
                this.isPls = true;
                this.commentListto.forEach((e, i) => {
                    if (this.activeDiscussId == e.discussId) {
                        this.x = e.x0;
                        this.y = e.y0;
                        this.w = this.zdyWidth / 3;
                        this.h = 300;
                        this.value1 = e.content;
                        this.col = e.contentColor;
                        this.rightData = e;
                    }

                })
            }
            console.log(item);
        },
        next() {
            this.x = 0
            this.y = 0
            this.w = this.zdyWidth / 3
            this.h = 300
            this.col = '#333333'
            this.value1 = ''
        },
        editCont() {
            console.log(this.rightData)
            this.x = this.rightData.x0;
            this.y = this.rightData.y0;
            this.w = this.zdyWidth / 3;
            this.h = 300;
            this.value1 = this.rightData.content;
            this.col = this.rightData.contentColor;
            this.isPls = true;
            this.rightDown = false;
        },
        copyToClipboard(textToCopy) {
            // navigator clipboard 需要https等安全上下文
            if (navigator.clipboard && window.isSecureContext) {
                // navigator clipboard 向剪贴板写文本
                return navigator.clipboard.writeText(textToCopy);
            } else {
                // 创建text area
                let textArea = document.createElement("textarea");
                textArea.value = textToCopy;
                // 使text area不在viewport，同时设置不可见
                textArea.style.position = "absolute";
                textArea.style.opacity = 0;
                textArea.style.left = "-999999px";
                textArea.style.top = "-999999px";
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                return new Promise((res, rej) => {
                    // 执行复制命令并移除文本框
                    document.execCommand('copy') ? res() : rej();
                    textArea.remove();
                });
            }
        },
        triggerCopy() {
            this.copyToClipboard(this.rightData.content)
            this.rightDown = false;
        },
        deletecs() {
            discussDel({ discussId: this.rightData.discussId }).then(res => {
                if (res.data.code == 1) {
                    this.rightDown = false;
                    this.$message.success('删除成功')
                    this.getDisList()

                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        onContextMenus(item, e) {
            if (this.userId == item.userId) {
                this.rightDown = true;
                this.rightData = item;
            }
            console.log(item)
            e.preventDefault()
        },
        sumbit() {
            var obj = {
                x: null,
                y: null,
                width: null,
                height: null,
                pageNum: this.current,
                fileId: this.fileId,
                content: this.value1,
                contentColor: this.col,
            }
            obj.x = this.x * this.bookData.fileWidth / this.zdyWidth;
            obj.y = this.y * this.bookData.fileWidth / this.zdyWidth;
            obj.width = this.w * this.bookData.fileWidth / this.zdyWidth;
            obj.height = this.h * this.bookData.fileWidth / this.zdyWidth;
            if (!this.activeDiscussId) {
                discussCreate(obj).then(res => {
                    if (res.data.code == 1) {
                        this.isComShow = false;
                        this.$message.success("新增成功");
                        this.value1 = ''
                        this.getDisList();
                        // this.$emit('isTg', true);
                    } else {
                        this.$message.error("操作失败")
                    }
                })
            } else {
                obj.discussId = this.rightData.discussId
                discussUpdate(obj).then(res => {
                    if (res.data.code == 1) {
                        this.isComShow = false;
                        this.$message.success("修改成功");
                        this.value1 = ''
                        this.getDisList();
                        // this.$emit('isTg', true);
                    } else {
                        this.$message.error("操作失败")
                    }
                })
            }

        },
        getDisList() {
            this.isPls = false;
            discussList({ fileId: this.fileId, pageNum: this.current }).then(res => {
                this.isCreat = true;
                this.commentListto = res.data.data;
                this.commentListto.forEach((e, i) => {

                    if (e.userId == this.userId) {
                        this.isCreat = false;
                    }
                    e.x0 = e.x / this.bookData.fileWidth * this.zdyWidth;
                    e.y0 = e.y / this.bookData.fileWidth * this.zdyWidth;
                    e.w0 = this.zdyWidth / 3;
                    e.h0 = e.height / this.bookData.fileWidth * this.zdyWidth;
                })
                this.activeDiscussId = this.commentListto[0].discussId
                console.log(this.isCreat)
                this.isComShow = true;
            })
        },
        changeIspls() {

            console.log(this.$refs.bjcan.scrollTop);
            this.activeDiscussId = '';
            this.isPls = true;
            this.$refs.show
            // this.isPls = !this.isPls;
            // if (this.isCreat) {
            this.x = (this.zdyWidth) / 3.5
            this.y = this.$refs.bjcan.scrollTop + 150
            this.w = this.zdyWidth / 3
            this.h = 300
            this.col = '#333333'
            this.value1 = ''
            // } else {
            //     this.commentListto.forEach((e, i) => {
            //         if (this.activeDiscussId == e.discussId) {
            //             this.x = e.x0;
            //             this.y = e.y0;
            //             this.w = e.w0;
            //             this.h = 300;
            //             this.value1 = e.content;
            //             this.col = e.contentColor;
            //             this.rightData = e;
            //         }

            //     })
            // }
        },
        closeDf() {
            this.isPls = false
        },
        onDrag(left, top) {
            this.x = left;
            this.y = top;
        },
        onResize(left, top, width, height) {
            this.x = left;
            this.y = top;
            this.w = width
            this.h = height
        },
        changeLessonType(e) {
            if (this.form.status != 4) {
                if (this.lessonType == '教案') {
                    this.fileList = this.form.jaFiles
                    if (this.form.jaFiles.length != 0) {
                        this.fileId = this.form.jaFiles[0].fileId
                        this.bookData = this.form.jaFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                } else if (this.lessonType == '学案') {
                    this.fileList = this.form.xaFiles
                    if (this.form.xaFiles.length != 0) {
                        this.fileId = this.form.xaFiles[0].fileId
                        this.bookData = this.form.xaFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                } else if (this.lessonType == '课件') {
                    this.fileList = this.form.kjFiles
                    if (this.form.kjFiles.length != 0) {
                        this.fileId = this.form.kjFiles[0].fileId
                        this.bookData = this.form.kjFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                } else if (this.lessonType == '习题') {
                    this.fileList = this.form.xtFiles
                    if (this.form.xtFiles.length != 0) {
                        this.fileId = this.form.xtFiles[0].fileId
                        this.bookData = this.form.xtFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                }
            } else {
                if (this.lessonType == '教案') {
                    this.fileList = this.form.ytjaFiles
                    if (this.form.ytjaFiles.length != 0) {
                        this.fileId = this.form.ytjaFiles[0].fileId
                        this.bookData = this.form.ytjaFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                } else if (this.lessonType == '学案') {
                    this.fileList = this.form.ytxaFiles
                    if (this.form.ytxaFiles.length != 0) {
                        this.fileId = this.form.ytxaFiles[0].fileId
                        this.bookData = this.form.ytxaFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                } else if (this.lessonType == '课件') {
                    this.fileList = this.form.ytkjFiles
                    if (this.form.ytkjFiles.length != 0) {
                        this.fileId = this.form.ytkjFiles[0].fileId
                        this.bookData = this.form.ytkjFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                } else if (this.lessonType == '习题') {
                    this.fileList = this.form.ytxtFiles
                    if (this.form.ytxtFiles.length != 0) {
                        this.fileId = this.form.ytxtFiles[0].fileId
                        this.bookData = this.form.ytxtFiles[0]
                    } else {
                        this.fileId = ''
                        this.bookData = {}
                    }
                }
            }

            this.$refs.bjcan.scrollTo({ top: 0 });
            this.current = 0;
            this.getDisList();
        },
        changeFile(e) {
            this.fileList.forEach(j => {
                if (j.fileId == this.fileId) {
                    this.bookData = j;
                }
            })
            this.current = 0;
            this.$refs.bjcan.scrollTo({ top: 0 });
            this.getDisList();
        },
        // 滚动函数事件
        handleScroll() {
            if (!this.isTime) {
                if (this.isNull) {
                    let scrollTop = document.querySelector("#bjcan").scrollTop; //获取监听指定区域滚动位置的值
                    console.log(scrollTop, Math.round(scrollTop / document.getElementById("md0").clientHeight), document.getElementById("md0").clientHeight)
                    if (Math.round(scrollTop / document.getElementById("md0").clientHeight) - 1 < 0) {
                        this.current = 0
                    } else {
                        this.current = Math.round(scrollTop / document.getElementById("md0").clientHeight)
                    }

                    location.href = "#zmd" + (this.current);
                    this.isNull = false;

                    setTimeout(() => {
                        this.isNull = true;
                    }, 500)
                }

            }

        },
        changeActive(num) {
            if (this.active != num || !this.isStart) {
                this.current = 0;
                this.isShow = false;

                this.active = num

                setTimeout(() => {
                    console.log(document.getElementById("bjcan").clientWidth)
                    console.log(document.getElementById("bjcan").clientHeight)
                    this.zdyHeight = document.getElementById("bjcan").clientHeight - 50;
                    if (this.active == 3) {
                        this.zdyWidth = (document.getElementById("bjcan").clientWidth - num * 20) / num
                    } else if (this.active == 2) {
                        this.zdyHeight = document.getElementById("bjcan").clientHeight - 90;
                        this.zdyWidth = this.zdyHeight * this.bookData.pageWidth / this.bookData.pageHeight

                    } else {
                        this.zdyWidth = this.zdyHeight * this.bookData.pageWidth / this.bookData.pageHeight
                    }
                    this.isShow = true;

                    console.log(this.zdyWidth)
                })
            }



        },
        canvasClosess(e) {
            this.isShow = false;
        },
        // 取消按钮
        cancel() {
            this.$emit('isTg', true)
        },
        changeCurrent(index) {
            this.current = index;
            console.log(this.$refs.bjcan)
            this.$refs.bjcan.scrollTo({ top: 0 });
            this.next();
            this.getDisList();
            // this.isTime = true;
            // setTimeout(() => {
            //     this.isTime = false;
            // }, 1000)
            // location.href = "#md" + index;
        },
        getDetail() {
            lessonInfo({ lessonId: this.lessonId }).then(res => {
                var textList = [];
                res.data.data.teamUserList.forEach(j => {
                    textList.push(j.trueName)
                })
                console.log(textList)
                res.data.data.teamUserText = textList.join('、')
                this.form = res.data.data;
                if (this.form.status != 4) {
                    if (this.form.jaFiles.length != 0) {
                        this.lessonType = '教案'
                        this.fileList = this.form.jaFiles
                        this.bookData = this.form.jaFiles[0]
                        this.fileId = this.form.jaFiles[0].fileId
                    } else {
                        if (this.form.xaFiles.length != 0) {
                            this.lessonType = '学案'
                            this.bookData = this.form.xaFiles[0]
                            this.fileList = this.form.xaFiles
                            this.fileId = this.form.xaFiles[0].fileId
                        } else {
                            if (this.form.kjFiles.length != 0) {
                                this.lessonType = '课件'
                                this.bookData = this.form.kjFiles[0]
                                this.fileList = this.form.kjFiles
                                this.fileId = this.form.kjFiles[0].fileId
                            } else {
                                if (this.form.xtFiles.length != 0) {
                                    this.lessonType = '习题'
                                    this.bookData = this.form.xtFiles[0]
                                    this.fileList = this.form.xtFiles
                                    this.fileId = this.form.xtFiles[0].fileId
                                } else {

                                }
                            }
                        }

                    }
                } else {
                    if (this.form.ytjaFiles.length != 0) {
                        this.lessonType = '教案'
                        this.fileList = this.form.ytjaFiles
                        this.bookData = this.form.ytjaFiles[0]
                        this.fileId = this.form.ytjaFiles[0].fileId
                    } else {
                        if (this.form.ytxaFiles.length != 0) {
                            this.lessonType = '学案'
                            this.bookData = this.form.ytxaFiles[0]
                            this.fileList = this.form.ytxaFiles
                            this.fileId = this.form.ytxaFiles[0].fileId
                        } else {
                            if (this.form.ytkjFiles.length != 0) {
                                this.lessonType = '课件'
                                this.bookData = this.form.ytkjFiles[0]
                                this.fileList = this.form.ytkjFiles
                                this.fileId = this.form.ytkjFiles[0].fileId
                            } else {
                                if (this.form.ytxtFiles.length != 0) {
                                    this.lessonType = '习题'
                                    this.bookData = this.form.ytxtFiles[0]
                                    this.fileList = this.form.ytxtFiles
                                    this.fileId = this.form.ytxtFiles[0].fileId
                                } else {

                                }
                            }
                        }

                    }
                }

                this.current = 0;
                this.getDisList();

            })
        },
        getPageName(currentpageNum, totalPages, headPages, tailPages) {
            var pageNum = null;
            var tailpageNum = totalPages - currentpageNum;

            if (tailPages > tailpageNum) {
                // 尾页
                if (tailPages - tailpageNum == 0) {
                    // 尾页最后一页
                    pageNum = "尾页-" + tailPages;
                } else {
                    pageNum = "尾页-" + (tailPages - tailpageNum);
                }
            } else if (headPages >= currentpageNum) {
                // 扉页
                pageNum = "扉页-" + currentpageNum
            } else {
                // 正常页码
                pageNum = "第" + (currentpageNum - headPages) + "页"
            }
            return pageNum;
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-tabs--border-card {
    border: 0;
}

/deep/ .el-tabs__header {
    background-color: transparent !important;
}

/deep/ .el-tabs--border-card>.el-tabs__content {
    background: #fff !important;
}

/deep/ .el-tabs--border-card {
    background: transparent;
}

.left {
    width: 200px;
    height: 100%;
    margin-right: 15px;
}

.lastRight {
    width: 200px;
    height: 100%;
    margin-left: 15px;
}

.right {
    flex: 1;
}

.swbox {
    margin-bottom: 10px;
    flex: 1;
}

.pageNumbox {
    text-align: center;
    padding: 10px 0 15px 0;
}

.bor-lan {
    border: 1px solid #409eff !important;
}

.chosebtn {
    padding-top: 10px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    justify-content: flex-end;
}

.chosebtn img {
    height: 30px;
    object-fit: contain;
    margin-left: 15px;
}

#bjcan {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ac1 {
    width: 100%;
}

.ac2 {
    width: 40%;
}

.bgbbb {
    /* background-color: #fff; */
    z-index: 999;
    position: absolute;
}

.op0 {
    opacity: 0;
}

.op1 {
    opacity: 1;
}

.inputStyle {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
    top: 0;
}

.inputStyle /deep/ .el-textarea__inner {
    height: 100% !important;
    color: unset !important;
    text-decoration: none !important;
    text-decoration-style: none !important;
    padding: 0 !important;
}

.el-input {
    width: auto;
}

.inputStyle .el-input__prefix {
    display: inline-block;
    position: relative;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding-left: 30px;
    left: 0;
    visibility: hidden;
}

.inputStyle .el-input__inner {
    position: absolute;
}

.inputStyle /deep/ .el-textarea__inner {
    /* background: transparent; */
    background: rgba(220, 234, 247, 0.5);
    border-radius: 10px;
    min-height: 300px;
}

.df {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 145px;
    height: 50px;
    right: 0;
    /* justify-content: end; */
    justify-content: space-between;
}

.lblitem {
    padding: 5px;
}

.tst /deep/ .el-input__wrapper {
    padding: 0 !important;
}</style>