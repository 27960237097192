<template>
    <div class="app-container" style="padding-left: 0;">
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
            @submit.native.prevent="handleQuery">
            <el-row class=" mb15">
                <el-col :span="6" class="mb0" v-has-btn="'1,2,3'">
                    <el-form-item label="所属组织">
                        <el-tree-select @change="getList" clearable
                            :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                            v-model="queryParams.orgId" default-expand-all :data="treeData" check-strictly
                            :render-after-expand="true" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" class="mb0">
                    <el-form-item label="时间">
                        <el-date-picker style="width: 100%;" :disabled-date="disabledDate" v-model="queryParams.rzTime" format="YYYY/MM/DD"
                            value-format="x" clearable @change="getList" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="5" class="mb0">
                    <el-radio-group style="width: 100%;" @change="changeTableType" v-model="queryParams.tableType" size="mini">
                        <el-radio-button label="按个人分" />
                        <el-radio-button label="按组织分" v-has-btn="'1,2,3'" />
                    </el-radio-group>
                </el-col>
                <el-col :span="5" class="mr0 mb0">
                    <el-form-item class="pull-right">
                        <el-button  v-has-btn="'1,2,3'" style="" @click="changeZxtType" class="mb0 mr0 pull-right" type="primary"
                            size="mini">柱状图</el-button>
                        <el-button @click="handleExport" type="primary" size="mini">导出</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="bre9e mt15">
            <el-table header-cell-class-name="bgf2" show-overflow-tooltip height="calc(100vh - 250px)" v-loading="loading"
                :data="dataList" highlight-current-row>
                <!-- <el-table-column type="selection" width="55" /> -->
                <el-table-column type="index" label="序号" width="60" />
                <el-table-column v-if="queryParams.tableType == '按个人分'" width="350" label="姓名" prop="trueName">
                    <template #default="scope">
                        <div :title="scope.row.trueName" class="text-one">
                            {{ scope.row.trueName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="queryParams.tableType == '按个人分' ? '所属组织' : '组织名称'" prop="orgName">
                    <template #default="scope">
                        <div :title="scope.row.orgName" class="text-one">
                            {{ scope.row.orgName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="cativeType == '个人备课'" width="90" label="总备课数" prop="count">
                    <template #default="scope">
                        <div @click="toBk(scope.row)" style="color: #409efe;" class="text-one cursor">{{ scope.row.count }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="cativeType == '协同备课'" width="90" label="主备总数" prop="count">
                    <template #default="scope">
                        <div @click="toBk(scope.row, 'zb')" style="color: #409efe;" class="text-one cursor">{{
                            scope.row.zbcount }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="cativeType == '协同备课'" width="90" label="协备总数" prop="count">
                    <template #default="scope">
                        <div @click="toBk(scope.row, 'xb')" style="color: #409efe;" class="text-one cursor">{{
                            scope.row.xbcount }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="cativeType == '协同备课'" width="90" label="指导总数" prop="count">
                    <template #default="scope">
                        <div @click="toBk(scope.row, 'zd')" style="color: #409efe;" class="text-one cursor">{{
                            scope.row.zdcount }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog :title="'自定义柱状图'" v-model="zxtTypeOpen" width="60%">
            <el-form-item label="按所属组织统计" label-width="120px">
                <el-tree-select @change="changeZxtOrg" clearable multiple filterable style="width: 100%;"
                    :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                    v-model="zxtQueryParams.orgIdList" default-expand-all :data="treeData" check-strictly
                    :render-after-expand="true" />
            </el-form-item>
            <el-form-item label="按所选人员统计" label-width="120px">
                <el-select @change="changeZxtUser" style="width: 100%;" multiple filterable
                    v-model="zxtQueryParams.userIdList" placeholder="请选择人员">
                    <el-option v-for="item in userList" :key="item.userId" :label="item.trueName" :value="item.userId" />
                </el-select>
            </el-form-item>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitXtTyp">确 定</el-button>
                    <el-button @click="zxtTypeOpen = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-if="zxtOpen" :title="'柱状图'" v-model="zxtOpen" width="70%">
            <div id="grbk" style="width: 100%;height:50vh;">
            </div>
        </el-dialog>
        <el-dialog :title="'备课'" v-model="bkOpen" width="680px">
            <el-table show-overflow-tooltip @current-change="handleCurrentChange" class="" height="calc(100vh - 500px)"
                border v-loading="loading"
                :data="lookType == 'zb' ? selectObj.zblessonList : lookType == 'xb' ? selectObj.xblessonList : lookType == 'zd' ? selectObj.zdlessonList : selectObj.lessonList"
                highlight-current-row>
                <!-- <el-table-column type="selection" width="55" /> -->
                <el-table-column type="index" label="序号" width="60" />
                <el-table-column width="" label="备课标题" prop="title">
                    <template #default="scope">
                        <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                            @click="goDetail(scope.row)">
                            {{ scope.row.title }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="90" label="等级" prop="lessonLevelName">
                </el-table-column>
                <el-table-column width="90" label="角色" prop="role" v-if="cativeType != '个人备课'">
                    <template #default="scope">
                        <div v-if="scope.row.role == 0">
                            主备人
                        </div>
                        <div v-if="scope.row.role == 1">
                            协备人
                        </div>
                        <div v-if="scope.row.role == 2">
                            听课人
                        </div>
                        <div v-if="scope.row.role == 3">
                            指导专家
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="发布时间" prop="releaseTime">
                    <template #default="scope">
                        <div>
                            {{ timestampToTime(scope.row.releaseTime) }}
                        </div>
                    </template>
                </el-table-column>

            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { statsOwnLesson, statsTeamLesson } from "@/api/admin/statistics";
import { orgListTree } from "@/api/admin/system";
import table2excel from 'js-table2excel';
import { bannerListAll } from "@/api/admin/news";
import { timestampToTime } from "@/utils/date";
import echarts from "echarts";

export default {
    name: "cooperate",
    components: {
    },
    data() {
        return {
            cativeType: '个人备课',
            zxtTypeOpen: false,
            zxtOpen: false,
            bkOpen: false,
            statusList: [
                { name: '未发布', value: 0 },
                { name: '已发布', value: 1 },
                { name: '隐藏', value: 2 },
            ],
            treeData: [],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 查询参数
            queryParams: {
                tableType: '按个人分',
                orgId: '',
                rzTime: [],
            },
            zxtQueryParams: {
                orgIdList: [],
                userIdList: [],
            },
            selectObj: [],
            grbkOption: {
                color: ['#3398DB'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '总数',
                        type: 'bar',
                        barMaxWidth: 30,
                        data: [10, 52, 200, 334, 390, 330, 220]
                    }
                ]
            },
            userList: [],
            lookType: null,
        };
    },
    created() {
        this.getOrgListTree();
        // this.getList();
    },
    mounted() {
        // this.getGrbkOption()
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        changeTableType() {
            this.getList();
        },
        changeCativeType(type) {
            console.log(type);
            this.cativeType = type;
            this.queryParams = {
                tableType: '按个人分',
                orgId: '',
                rzTime: [],
            };
            this.zxtQueryParams = {
                orgIdList: [],
                userIdList: [],
            };
            // this.getGrbkOption();
            this.getList();
        },
        toBk(row, type) {
            console.log(type);
            this.lookType = type;
            this.selectObj = row;
            this.bkOpen = true;
            console.log(row);
        },
        changeTime() {
            console.log(this.queryParams.rzTime);
        },
        changeZxtType() {
            this.zxtQueryParams = {
                orgIdList: [],
                userIdList: [],
            },
                this.zxtTypeOpen = true;
        },
        submitXtTyp() {
            if (this.zxtQueryParams.orgIdList.length == 0 && this.zxtQueryParams.userIdList.length == 0) {
                this.getGrbkOption()
            }
            if (this.zxtQueryParams.orgIdList.length != 0) {
                this.getGrbkOption()
            }
            if (this.zxtQueryParams.userIdList.length != 0) {
                this.getUserGrbkOption()
            }
            this.zxtTypeOpen = false;
        },
        getOrgListTree() {
            orgListTree().then(res => {
                this.treeData = res.data.data;
            })
        },
        handleExport() {
            if (this.cativeType == '个人备课') {
                var timestamp = timestampToTime(Date.parse(new Date()));
                const tableDatas = JSON.parse(JSON.stringify(this.dataList))//这里面填写你接口的数据
                const datas = tableDatas
                if (this.queryParams.tableType != '按个人分') {
                    const column = [
                        {
                            title: '组织名称',
                            key: 'orgName',
                            type: 'text',
                        },
                        {
                            title: '总备课数',
                            key: 'count',
                            type: 'text',
                        }
                    ]
                    table2excel(column, datas, '个人备课组织统计' + timestamp)

                } else {
                    const column = [
                        {
                            title: '所属组织',
                            key: 'orgName',
                            type: 'text',
                        },
                        {
                            title: '姓名',
                            key: 'trueName',
                            type: 'text',
                        },
                        {
                            title: '总备课数',
                            key: 'count',
                            type: 'text',
                        }
                    ]
                    table2excel(column, datas, '个人备课统计' + timestamp)

                }

            } else {
                var timestamp = timestampToTime(Date.parse(new Date()));
                const column = [
                    {
                        title: '所属组织',
                        key: 'orgName',
                        type: 'text',
                    },
                    {
                        title: '姓名',
                        key: 'trueName',
                        type: 'text',
                    },
                    {
                        title: '总备课数',
                        key: 'count',
                        type: 'text',
                    }
                ]
                if (this.queryParams.tableType != '按个人分') {
                    column.splice(1, 1)
                }
                const tableDatas = JSON.parse(JSON.stringify(this.dataList))//这里面填写你接口的数据
                const datas = tableDatas
                table2excel(column, datas, '协同备课统计' + timestamp)
            }

        },
        getGrbkOption() {
            if (this.cativeType == '个人备课') {
                statsOwnLesson().then(res => {
                    this.grbkOption.xAxis[0].data = []
                    this.grbkOption.series[0].data = []
                    if (this.zxtQueryParams.orgIdList.length != 0) {
                        res.data.data = res.data.data.filter(itemA => {
                            return this.zxtQueryParams.orgIdList.indexOf(itemA.orgId) != -1;
                        })
                    }
                    res.data.data.forEach(e => {
                        this.grbkOption.xAxis[0].data.push(e.orgName)
                        this.grbkOption.series[0].data.push(e.amount)
                    })
                    this.zxtOpen = true;
                    setTimeout(() => {
                        let myChart3 = echarts.init(document.getElementById('grbk'))
                        myChart3.setOption(this.grbkOption, true)
                    });
                })
            } else if (this.cativeType == '协同备课') {
                statsTeamLesson().then(res => {
                    this.grbkOption.xAxis[0].data = []
                    this.grbkOption.series[0].data = []
                    if (this.zxtQueryParams.orgIdList.length != 0) {
                        res.data.data = res.data.data.filter(itemA => {
                            return this.zxtQueryParams.orgIdList.indexOf(itemA.orgId) != -1;
                        })
                    }
                    res.data.data.forEach(e => {
                        this.grbkOption.xAxis[0].data.push(e.orgName)
                        this.grbkOption.series[0].data.push(e.amount)
                    })
                    this.zxtOpen = true;
                    setTimeout(() => {
                        let myChart3 = echarts.init(document.getElementById('grbk'))
                        myChart3.setOption(this.grbkOption, true)
                    });
                })
            }

        },
        getUserGrbkOption() {
            this.grbkOption.xAxis[0].data = []
            this.grbkOption.series[0].data = []
            var userOption = JSON.parse(JSON.stringify(this.dataList));
            userOption = userOption.filter(itemA => {
                return this.zxtQueryParams.userIdList.indexOf(itemA.userId) != -1;
            })
            userOption.forEach(e => {
                this.grbkOption.xAxis[0].data.push(e.trueName)
                this.grbkOption.series[0].data.push(e.aidAmount + e.guideAmount + e.leadAmount)
            })
            console.log(this.grbkOption);
            this.zxtOpen = true;
            setTimeout(() => {
                let myChart3 = echarts.init(document.getElementById('grbk'))
                myChart3.setOption(this.grbkOption, true)
            });

        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.dataList = [];
            this.userList = []
            var userId = JSON.parse(this.$storage.get("userInfo")).userId

            if (this.cativeType == '个人备课') {
                statsOwnLesson().then(res => {
                    if (this.queryParams.orgId !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.orgId == this.queryParams.orgId
                        })
                    }
                    res.data.data.forEach(e => {
                        if (this.$storage.get("role") != 1 && this.$storage.get("role") != 2 && this.$storage.get("role") != 3) {
                            e.userList = e.userList.filter(itemA => {
                                return itemA.userId == userId
                            })
                        }
                        e.userList.forEach(j => {
                            if (this.queryParams.rzTime && this.queryParams.rzTime.length != 0) {
                                j.lessonList = j.lessonList.filter(itemA => {
                                    return (itemA.releaseTime >= this.queryParams.rzTime[0]) && (itemA.releaseTime <= this.queryParams.rzTime[1])
                                })
                            }
                            j.orgName = e.orgName;
                            j.orgId = e.orgId;
                            j.count = j.lessonList.length
                            if (this.queryParams.tableType == '按个人分') {
                                this.dataList.push(j)
                            }
                            this.userList.push(j)
                        })
                    })
                    if (this.queryParams.tableType == '按组织分') {
                        res.data.data.forEach(e => {
                            e.lessonList = [];
                            e.userList.forEach(j => {
                                e.lessonList = [...e.lessonList, ...j.lessonList]
                            })
                            e.count = e.lessonList.length
                            this.dataList.push(e)
                        })
                    }
                    console.log(this.dataList);
                    this.loading = false;
                })
            } else if (this.cativeType == '协同备课') {
                statsTeamLesson().then(res => {
                    if (this.queryParams.orgId !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.orgId == this.queryParams.orgId
                        })
                    }
                    res.data.data.forEach(e => {
                        e.userList.forEach(j => {
                            j.zblessonList = [];
                            j.xblessonList = [];
                            j.zdlessonList = [];
                            if (this.queryParams.rzTime && this.queryParams.rzTime.length != 0) {
                                j.lessonList = j.lessonList.filter(itemA => {
                                    return (itemA.releaseTime >= this.queryParams.rzTime[0]) && (itemA.releaseTime <= this.queryParams.rzTime[1])
                                })
                            }
                            j.lessonList.forEach(m => {
                                //协同备课听评课身份 0-主备人/授课人  1-协备人/评课人 2-未参与/听课人 3指导专家
                                if (m.role == 0) {
                                    j.zblessonList.push(m)
                                } else if (m.role == 1) {
                                    j.xblessonList.push(m)
                                } else if (m.role == 3) {
                                    j.zdlessonList.push(m)
                                }
                            })
                            j.orgName = e.orgName;
                            j.orgId = e.orgId;
                            j.count = j.lessonList.length
                            j.zbcount = j.zblessonList.length
                            j.xbcount = j.xblessonList.length
                            j.zdcount = j.zdlessonList.length
                            if (this.queryParams.tableType == '按个人分') {
                                this.dataList.push(j)
                            }
                            this.userList.push(j)
                        })
                    })
                    if (this.queryParams.tableType == '按组织分') {
                        res.data.data.forEach(e => {
                            e.zblessonList = [];
                            e.xblessonList = [];
                            e.zdlessonList = [];
                            e.userList.forEach(j => {
                                j.lessonList.forEach(m => {
                                    //协同备课听评课身份 0-主备人/授课人  1-协备人/评课人 2-未参与/听课人 3指导专家
                                    if (m.role == 0) {
                                        e.zblessonList.push(m)
                                    } else if (m.role == 1) {
                                        e.xblessonList.push(m)
                                    } else if (m.role == 3) {
                                        e.zdlessonList.push(m)
                                    }
                                })
                            })
                            e.zbcount = e.zblessonList.length
                            e.xbcount = e.xblessonList.length
                            e.zdcount = e.zdlessonList.length
                            this.dataList.push(e)
                        })
                    }
                    this.loading = false;
                })
            }

        },
        goDetail(row) {
            this.$router.push({
                path: "/lessonDetail",
                query: {
                    lessonId: row.lessonId,
                    isMyLess: 1,
                    isPtStatistics: 1,
                }
            });
            // this.$router.push({
            //     path: "/bannerDetail",
            //     query: {
            //         data: JSON.stringify(row),
            //     }
            // });
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        changeZxtOrg() {
            this.zxtQueryParams.userIdList = [];
        },
        changeZxtUser() {
            this.zxtQueryParams.orgIdList = [];
        },
        disabledDate(current) {
            return current && current > Number(new Date())
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}
</style>