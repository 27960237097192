<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>成果展示</a></el-breadcrumb-item>
                <el-breadcrumb-item>成果展示详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-descriptions :column="3" class="detailFF" title="基本信息">
                <el-descriptions-item label="成果标题">{{ form.achieveTitle }}</el-descriptions-item>
                <el-descriptions-item label="学段">{{ form.periodName }}</el-descriptions-item>
                <el-descriptions-item label="年级">{{ form.gradeName }}</el-descriptions-item>
                <el-descriptions-item label="学科">{{ form.subjectName }}</el-descriptions-item>
                <el-descriptions-item label="发表人">{{ form.userName }}</el-descriptions-item>
                <el-descriptions-item label="归属">{{ form.orgName }}</el-descriptions-item>
                <el-descriptions-item label="类别">{{ form.achieveTypeName }}</el-descriptions-item>
            </el-descriptions>
            <el-form ref="form" :model="form" :rules="rules" label-width="68px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="成果简介" prop="achieveInfo">
                            {{ form.achieveInfo }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item class="avatar-uploader" label="成果封面" prop="coverUrl">
                            <img v-if="form.coverUrl" :src="form.coverUrl" class="avatar" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.achieveType == 1 || form.achieveType == 3">
                    <el-col :span="24">
                        <el-form-item label-width="40px" label="附件" prop="fileUrl">
                            <div @click="xz(item)" v-for="item in form.fileList" class="mb10 cursor">
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;" :src="item.imgUrl+'/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.achieveType == 2">
                    <el-col :span="24">
                        <el-form-item label-width="40px" label="附件" prop="fileUrl">
                            <div @click="xz(item)" v-for="item in form.fileList" class="mb10 cursor">
                                <video controlsList="nodownload" :poster="item.fileUrl + '?x-oss-process=video/snapshot,t_1,m_fast'" preload="none" controls="controls" style="width: 400px;height: 300px;" :src="item.fileUrl"></video>
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import { achieveCreate, achieveInfo, achieveUpdate, achieveDelFile } from "@/api/admin/achieve"
import { trainListReleaseCourse } from "@/api/admin/traCourse"
import { queryDict, userList } from "@/api/admin/public";
import { orgListTypeOrg, orgListSchoolOrg } from "@/api/admin/system";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            achieveTypeList: [],
            fileList: [],
            toParentsMap: {

            },
            isShow: false,
            oldCenter: [],
            achieveId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            lessonLevelList: [
            ],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            fileList: [],
            // 表单参数
            form: {
                coverUrl: '',
                achieveTitle: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                userId: '',
                orgName: '',
                achieveInfo: '',
                achieveType: '1',
            },
            // 表单校验
            rules: {
            },
            delFileList: [],
            countyList: [],
            schoolList: [],
            trainList: [],
        };
    },
    created() {
        this.getTrainListCourse();
        this.getOrgListTypeOrg();
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.achieveId) {
            this.achieveId = this.$route.query.achieveId
            console.log(this.form)
            this.getlessonInfo()
        } else {
            this.isShow = true
        }
    },
    unmounted() {
        if (this.achieveId) {
            try {
                console.log(332)
                this.fileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        achieveDelFile({ fileUrl: e.response.data });
                    }
                })
            } catch (e) {
                console.log('e', e)
            }
        } else {
            try {
                this.fileList.forEach(e => {
                    achieveDelFile({ fileUrl: e.response.data });
                })
            } catch (e) {
                console.log('e', e)
            }

        }
    },
    computed: {
    },
    methods: {
        xz(item){
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
            // download(item.fileUrl, item.fileName)
        },
        handleExceed(e) {
            console.log(e, this.fileList);
            if (this.fileList.length == 1) {
                this.$message.error('只能上传一个附件')
            }
        },
        changeUserId(e) {
            this.userList.forEach(j => {
                if (e == j.userId) {
                    this.form.orgName = j.orgName
                }
            })
        },
        getTrainListCourse() {
            trainListReleaseCourse().then(res => {
                this.trainList = res.data.data;
                console.log(this.trainList)
            })
        },
        changeSignInTime(e) {
            console.log(this.form.signInTime);
            this.form.signInBeginTime = this.form.signInTime[0];
            this.form.signInEndTime = this.form.signInTime[1];
        },
        changeSignOutTime(e) {
            console.log(this.form.signOutTime);
            this.form.signOutBeginTime = this.form.signOutTime[0];
            this.form.signOutEndTime = this.form.signOutTime[1];
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.coverUrl != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        checkfileUrlValidator(rule, value, callback) {
            if (this.fileList.length != 0) {
                var filetype = this.fileList[0].name.slice(-4);
                if (this.form.achieveType == 2) {
                    if (filetype != '.MP4' && filetype != '.mp4' && filetype != '.avi' && filetype != '.AVI') {
                        callback(new Error('只允许上传.MP4,.mp4,.avi,.AVI格式文件。'))
                    } else {
                        callback()
                    }
                } else {
                    if (filetype != '.pdf' && filetype != '.ppt' && filetype != 'pptx') {
                        callback(new Error('只允许上传.pdf,.ppt,.pptx格式文件。'))
                    } else {
                        callback()
                    }
                }

            } else {
                callback(new Error('请上传附件'))
            }
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getlessonInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                achieveInfo({ achieveId: this.achieveId }).then(res => {
                    this.form = res.data.data;
                    this.form.orgName = res.data.data.userOrgName;
                    this.form.fileList.forEach(e => {
                        this.fileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey == j.dictKey) {
                                    this.subjectList = j.childrenList;
                                }
                            })
                        }
                    })
                })
            })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)

        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        startSignInDateConfirm(e) {
            if (e > this.form.signInEndTime) {
                this.form.signInEndTime = '';
            }
        },
        startSignOutDateConfirm(e) {
            if (e > this.form.signOutEndTime) {
                this.form.signOutEndTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        endSignInDateConfirm(e) {
            console.log(e,)
            if (e < this.form.signInBeginTime) {
                this.form.signInBeginTime = '';
            }
        },
        endSignOutDateConfirm(e) {
            console.log(e,)
            if (e < this.form.signOutBeginTime) {
                this.form.signOutBeginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changeCounty() {
            this.form.schoolId = '';
            this.schoolList = [];
            orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                this.schoolList = res.data.data;
            })
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getOrgListTypeOrg() {
            orgListTypeOrg({ orgType: 2 }).then(res => {
                this.countyList = res.data.data;
            })
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'topic_level' }).then(res => {
                this.lessonLevelList = res.data.data;
            })
            queryDict({ code: 'achieve_type  ' }).then(res => {
                this.achieveTypeList = res.data.data;
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            console.log(this.fileList);
            var fileList = [];
            this.fileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    obj.fileUrl = fileList.toString();

                    if (this.achieveId) {
                        achieveUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    achieveDelFile({ fileUrl: e });
                                })
                                this.$message.success("新增成功");
                                this.replace('/achieve', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });

                    } else {
                        achieveCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    achieveDelFile({ fileUrl: e });
                                })
                                this.$message.success("编辑成功");
                                this.replace('/achieve', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            // this.jAFileList = [];
            this.replace('/achieve')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>