<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>课题审核</a></el-breadcrumb-item>
                <el-breadcrumb-item>课题审核详情</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 140px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="申报课题:" prop="topicName">
                            {{ form.raceName }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="trueName">
                            {{ form.trueName }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="电话:" prop="mobile">
                            {{ form.mobile }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="归属:" prop="orgName">
                            {{ form.orgName }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="职称:" prop="rankInfo">
                            {{ form.rankInfo }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="指导教师:" prop="instructor">
                            {{ form.instructor }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="附件:" prop="">
                            <div @click="xz(item)" v-for="item in form.fileList" class="mb10 cursor">
                                <img v-if="item.fileType == 'pdf' || item.fileType == 'ppt' || item.fileType == 'pptx' || item.fileType == 'doc' || item.fileType == 'docx'"
                                    style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl ? item.imgUrl + '/1.png' : item.fileUrl" alt="">
                                <img v-else style="width: 350px;height:300px;object-fit: cover;" :src="item.fileUrl" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="申报时间:" prop="applyTime">
                            {{ timestampToTime(form.applyTime) }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="留言:" prop="leaveWord">
                            {{ form.leaveWord }}
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
        </el-card>
        <el-image-viewer v-if="isOpen" :url-list="activeimgs" @close="closePic" />
    </div>
</template>

<script>
import { lessonInfo } from "@/api/admin/lesson"
import { timestampToTime } from "@/utils/date";
import { download } from "@/api/adminservice/public";
import { topicApplyInfo } from "@/api/admin/lead"
import { raceHandleInfo } from "@/api/admin/race"
import { queryDict } from "@/api/admin/public";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            activeimgs: [],
            isOpen: false,
            applyId: '',
            timestampToTime,
            // 表单参数
            form: {
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                leadUser: '',
                teamUsers: '',
                teamuseList: [],
            },
            // 表单校验
            rules: {
            },
        };
    },
    created() {
    },
    mounted() {
        this.applyId = this.$route.query.applyId
        this.getlessonInfo()
    },
    computed: {
    },
    methods: {
        closePic() {
            this.isOpen = false;
        },
        xz(item) {
            if (item.fileType == 'pdf' || item.fileType == 'ppt' || item.fileType == 'pptx' || item.fileType == 'doc' || item.fileType == 'docx') {
                if (item.imgUrl) {
                    let routeUrl = this.$router.resolve({
                        path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                        query: { data: JSON.stringify(item) }
                    })
                    window.open(routeUrl.href, '_blank')
                } else {
                    window.open(item.fileUrl, '_blank')
                }
            }else{
                window.open(item.fileUrl, '_blank')
            }
        },
        ylPre(item){
            this.activeimgs.push(item.fileUrl)
                this.isOpen = true;
        },
        getlessonInfo() {
            raceHandleInfo({ applyId: this.applyId }).then(res => {
                // var textList = [];
                // res.data.data.teamUserList.forEach(j => {
                //     textList.push(j.trueName)
                // })
                // console.log(textList)
                // res.data.data.teamUserText = textList.join('、')
                this.form = res.data.data;
                queryDict({ code: 'teacher_rank' }).then(response => {
                    response.data.data.forEach(e => {
                        if (this.form.rankInfo == e.dictKey) {
                            this.form.rankInfo = e.dictValue
                        }
                    })
                })
            })
        },
        // 取消按钮
        cancel() {
            this.replace('/topicApply')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>